import React from "react";
import { useNavigate } from "react-router-dom";
import sessionStatus from "../../utilities/session/sessionStatus";
import sessionInfo from "../../utilities/session/sessionInfo";
import NotFoundPageIllustration from "../../assets/svgs/404-illustration";
import NavBar from "../RialtoNavBar";
import "./NotFoundPage.css";

const NotFoundPage = () => {
  const navigate = useNavigate();
  const session_info = sessionInfo();
  const userLoggedIn = sessionStatus() === 3;

  const handleNavigate = () => {
    const cribId = session_info.account_id;
    navigate(userLoggedIn ? `/crib/${cribId}` : "/");
  };

  return (
    <>
      {userLoggedIn ? <NavBar /> : null}
      <div className="notfound-page">
        <div className="notfound-page__svg--container">
          <NotFoundPageIllustration className="notfound-page__svg" />
        </div>
        <div className="notfound-page__text--container">
          <h2 className="notfound-page__header">Page not found</h2>
          <p className="notfound-page__text">
            Sorry, we can't find the page you're looking for.
          </p>
          <button className="notfound-page__btn" onClick={handleNavigate}>
            Take me home
          </button>
        </div>
      </div>
    </>
  );
};

export default NotFoundPage;
