import React, { useState } from "react";
import urls from "../../api/baseUrl";
import classes from "./ResourcePopup.module.css";
import sessionInfo from "../../utilities/session/sessionInfo";
import { useParams } from "react-router-dom";
import CloseButton from "../../assets/Icons/close_x.svg";
import axios from "axios";

const ResourcePopup = ({ neighboorhoodname }) => {
  const [show, setShow] = useState(true);
  const { token } = sessionInfo();

  const { neighborhood_id } = useParams("neighborhood_id");

  const acknowledeRank = async () => {
    const url = `${urls.base_url}neighborhood/${neighborhood_id}/acknowledge_rank`;
    const acknowledge = "resources";
    const body = {
      acknowledge,
    };
    try {
      const resp = await axios.post(url, body, {
        headers: {
          Authorization: `Bearer: ${token}`,
        },
      });
      

      return resp.data;
    } catch (error) {
      return error;
    }
  };
  const offPopUp = () => {
    setShow((prev) => !prev);
    acknowledeRank();
  };
  return (
    <div>
      {show ? (
        <div className={classes.resource_popup}>
          <div className={classes.resourse_header}>
            <h1 className={classes.resource_title}>NEW!</h1>
            <img
              src={CloseButton}
              onClick={offPopUp}
              className={classes.resource_closebtn}
            />
          </div>
          <p className={classes.resource_content}>
            Now you can upload resources to the {neighboorhoodname} &nbsp; Neighborhood.
           
          </p>
        </div>
      ) : null}
    </div>
  );
};

export default ResourcePopup;
