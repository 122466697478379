import React from "react";

const LinkedinOutline = ({ className }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.335938 21.9111H6.03235V6.93164H0.335938V21.9111ZM1.625 8.2207H4.74329V20.622H1.625V8.2207Z"
        fill="#D8D8D8"
      />
      <path
        d="M3.18394 0.0888672C1.42828 0.0888672 0 1.51719 0 3.27281C0 5.02842 1.42828 6.45679 3.18394 6.45679C4.9396 6.45679 6.36793 5.02847 6.36793 3.27281C6.36793 1.51715 4.93956 0.0888672 3.18394 0.0888672ZM1.28906 3.27281C1.28906 2.22798 2.13907 1.37793 3.18394 1.37793C4.22881 1.37793 5.07886 2.22794 5.07886 3.27281C5.07886 4.31768 4.22881 5.16773 3.18394 5.16773C2.13907 5.16773 1.28906 4.31764 1.28906 3.27281Z"
        fill="#D8D8D8"
      />
      <path
        d="M21.9861 11.6361C21.7833 8.72043 19.3513 6.47363 16.3361 6.47363C14.9875 6.47363 13.7144 6.94251 12.7113 7.77447V6.93228H7.25293V21.9117H12.9494V13.7192C12.9494 12.7958 13.7005 12.0447 14.6239 12.0447C15.5471 12.0447 16.2983 12.7958 16.2983 13.7195L16.3038 21.9118H21.9998V11.6499L21.9861 11.6361ZM11.6603 20.6228H8.54199V8.22139H11.4221V10.0625L12.4536 10.0684L12.6453 9.77088C13.4552 8.5134 14.835 7.76274 16.3361 7.76274C18.7353 7.76274 20.6563 9.59329 20.7095 11.9301L20.7108 20.6228H17.592L17.5874 13.7192C17.5874 12.0851 16.2579 10.7556 14.6239 10.7556C12.9898 10.7556 11.6603 12.0851 11.6603 13.7192V20.6228Z"
        fill="#D8D8D8"
      />
    </svg>
  );
};

export default LinkedinOutline;
