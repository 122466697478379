/* 
    Code to info storage, retrieval and delete in browser local storage        
*/
const l_Storage = window.localStorage;

const store = {
    // get local storage info by fieldname
    retrieve : (field) => {
        return l_Storage.getItem(field);
    },
    // set local storage info
    write : (field, value) => {
        l_Storage.setItem(field, value);
    },
    // remove all local storage info
    clear : () => {
        l_Storage.clear();
    },
    remove : (field) => {
        l_Storage.removeItem(field);
    }
}

export default store

