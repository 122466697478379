import axios from "axios";
import urls from "./baseUrl.js";

const baseUrl = urls.base_url;

const signupFromInvite = {
  async getInviteInfo(invite_id, invite_token) {
    try {
      const url = invite_token
        ? `${baseUrl}account/invite/${invite_id}/${invite_token}`
        : `${baseUrl}account/invite/${invite_id}`;
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async signupFromInvite({ invite_id, invite_token, data }) {
    const url = invite_token
      ? `${baseUrl}account/invite/${invite_id}/${invite_token}`
      : `${baseUrl}account/invite/${invite_id}`;
    try {
      const response = await axios.post(url, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async getInviteNeighborhood(neighborhood_id, invite_id) {
    const url = `${urls.base_url}neighborhood/${neighborhood_id}/invite/${invite_id}`;
    try {
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return error;
    }
  },
};

export default signupFromInvite;
