import React from "react";

const Error = ({ className }) => {
  return (
    <svg
      className={className}
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.0001 4.16669C13.5001 4.16669 4.16675 13.5 4.16675 25C4.16675 36.5 13.5001 45.8334 25.0001 45.8334C36.5001 45.8334 45.8334 36.5 45.8334 25C45.8334 13.5 36.5001 4.16669 25.0001 4.16669ZM22.9167 35.4167V31.25H27.0834V35.4167H22.9167ZM22.9167 14.5834V27.0834H27.0834V14.5834H22.9167Z"
        fill="#EB5757"
      />
    </svg>
  );
};

export default Error;
