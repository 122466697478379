import { useContext } from 'react';
import { useMutation } from 'react-query';
import { SnackbarContext } from 'contextStore/SnackbarContext';
import { postSandiChat } from 'api/sandibot/postSandiChat';

export function usePostSandiChat(onSuccess) {
	const { triggerAlert } = useContext(SnackbarContext);
	const postSandiChatMutation = useMutation({
		mutationFn: postSandiChat,
		onSuccess,
		onError: () => {
			triggerAlert({
				open: true,
				message:
					'Mmm... your response is not in the correct format. Please update and try again',
				type: 'warning',
			});
		},
	});

	return postSandiChatMutation;
}
