import React from "react";

const Checkmark = ({ className }) => {
  return (
    <svg
      className={className}
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2_8991)">
        <path
          d="M25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z"
          fill="#3B7A71"
        />
        <path
          d="M21.2109 37.9684L9.6875 26.445C9.53125 26.2888 9.53125 26.0544 9.6875 25.8981L13.0078 22.5778C13.1641 22.4216 13.3984 22.4216 13.5547 22.5778L21.4844 30.5075L36.4062 15.5856C36.5625 15.4294 36.7969 15.4294 36.9531 15.5856L40.2734 18.9059C40.4297 19.0622 40.4297 19.2966 40.2734 19.4528L21.7578 37.9684C21.6016 38.1247 21.3672 38.1247 21.2109 37.9684Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2_8991">
          <rect width="50" height="50" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Checkmark;
