import React, { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation } from "react-query";
import { useErrorContext } from "../../../hooks/useErrorContext";
import neighborhood from "../../../api/neighborhood";
import store from "utilities/storage";
import ImportMembersProgress from "./import-members-progress";
import ImportMembersFile from "./import-members-file";
import ImportMembersSuccess from "./import-members-success";
import ViewDetails from "./ViewDetails/view-details";
import "./import-members.css";

const ImportMembers = ({ onClose, handleClose }) => {
  const params = useParams();
  const triggerAlert = useErrorContext();

  const setup_neighborhood_id = store.retrieve("setup_neighborhood_id");
  const neighborhood_id = params?.neighborhood_id || setup_neighborhood_id;

  const [activeScreen, setActiveScreen] = useState("ImportMembersFile");
  const [responseData, setResponseData] = useState({});
  const [progress, setProgress] = useState(0);

  const mutation = useMutation((data) => neighborhood.inviteBulkMembers(data), {
    onError: (error) => {
      triggerAlert({
        open: true,
        message: error?.response?.data?.error
          ? error?.response?.data?.error
          : "There was an error, please try again later",
        type: "error",
      });
    },
    onSuccess: (response) => {
      if (response?.status === "success") {
        setResponseData(response?.data);
        setActiveScreen("ImportMembersSuccess");
        triggerAlert({
          open: true,
          message: response?.data?.message,
          type: "success",
        });
      } else {
        triggerAlert({
          open: true,
          message: "Something happened, please try again later",
          type: "error",
        });
      }
    },
  });

  const onDrop = useCallback((acceptedFiles) => {
    const allowedExtensions = /(\.xlsx|\.csv)$/i;
    if (acceptedFiles.length === 1) {
      // Checking file type
      if (!allowedExtensions.exec(acceptedFiles[0].path)) {
        triggerAlert({
          open: true,
          message: "Unsupported file format",
          type: "error",
        });
      } else {
        const formData = new FormData();
        formData.append("file", acceptedFiles[0]);

        const data = {
          formData,
          neighborhood_id,
          setProgress,
        };
        setActiveScreen("ImportMembersProgress");
        mutation.mutate(data);
      }
    } else {
      triggerAlert({
        open: true,
        message: "You can only upload one file at a time",
        type: "error",
      });
    }
  });

  const handleViewDetails = () => {
    setActiveScreen("ViewDetails");
  };

  return (
    <div className="import-members">
      {activeScreen === "ImportMembersFile" && (
        <ImportMembersFile onClose={onClose} onDrop={onDrop} />
      )}
      {activeScreen === "ImportMembersProgress" && !mutation.isError && (
        <ImportMembersProgress progress={progress} />
      )}
      {activeScreen === "ImportMembersSuccess" && (
        <ImportMembersSuccess
          neighborhood_id={neighborhood_id}
          setup_neighborhood_id={setup_neighborhood_id}
          responseData={responseData}
          handleClose={handleClose}
          handleViewDetails={handleViewDetails}
        />
      )}
      {activeScreen === "ViewDetails" && (
        <ViewDetails handleClose={handleClose} responseData={responseData} />
      )}
    </div>
  );
};

export default ImportMembers;
