import React from "react";
import "./add-members-manually.css";

const AddMembersManually = ({ onClose, children }) => {
  return (
    <div className="add-members-manually">
      <div>
        <div className="add-members-manually__box">
          <button className="add-members-manually__cta" onClick={onClose}>
            BACK
          </button>
        </div>
        {
          <div className="add-members-manually__wrapper">
            <h2 className="add-members-manually__heading">
              Add Members Manually
            </h2>
            <div className="add-members-manually__btn--container">
              {children}
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default AddMembersManually;
