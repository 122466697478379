import axios from 'axios';
import sessionInfo from '../utilities/session/sessionInfo.js';
import urls from './baseUrl.js';

const { token } = sessionInfo();

const API = {
    postNeighborhoodBulletin: async (data, neighborhood_id) => {
        try {
            const url = `${urls.base_url}neighborhood/${neighborhood_id}/bulletin`;
            const res = await axios.post(url,
                data,
                {
                    headers: {
                        "Authorization": `Bearer: ${token}`,
                        'Content-Type': 'multipart/form-data'
                    }
                }
            )
            return res;
        } catch (error) {
            console.error(error.response);
            return false;
        }
    },
    getNeighborhoodBulletinPost: async (bulletin_id, neighborhood_id) => {
        try {
            const url = `${urls.base_url}neighborhood/${neighborhood_id}/bulletin/${bulletin_id}`;
            const headers = {
                "Authorization": `Bearer: ${token}`,
                "Content-Type": "application/json"
            }

            const resp_bulletin = await axios.get(url, { headers });
            const resp = [ ...resp_bulletin.data.data ];
            return resp;
        } catch (error) {
            console.error(error.response)
            return error;
        }
    },
    getNeighborhoodBulletinPosts: async (neighborhood_id, page, per_page) => {
        try {
            const url = `${urls.base_url}neighborhood/${neighborhood_id}/bulletin?page=${page}&limit=${per_page}`;
            const headers = {
                "Authorization": `Bearer: ${token}`,
                "Content-Type": "application/json"
            }

            const resp_bulletin = await axios.get(url, { headers });
            const resp = resp_bulletin.data.data;
            return resp;
        } catch (error) {
            console.error(error.response)
            return error;
        }
    },
     getNeighborhoodBulletinPostStats: async (bulletin_id, neighborhood_id, CancelToken) => {
        try {
            const url = `${urls.base_url}neighborhood/${neighborhood_id}/bulletin/stats`;
            const headers = {
                "Authorization": `Bearer: ${token}`,
                "Content-Type": "application/json"
            };

            const resp_stats = await axios.get(url, { headers }, { CancelToken });
            const resp = { ...resp_stats.data.data.stats };

            return resp;
        } catch (error) {
            console.error(error.response)
            return error
        }
    },
    getNeighborhoodBulletinComments: async (reference_id, neighborhood_id, comment_type) => {
        try {
            const url = `${urls.base_url}neighborhood/${neighborhood_id}/bulletin/comment/${reference_id}?comment_type=${comment_type}`;
            const headers = {
                "Authorization": `Bearer: ${token}`,
                "Content-Type": "application/json"
            };

            const resp_comments = await axios.get(url, { headers });

            return resp_comments.data.data;
        } catch (error) {
            console.error(error.response)
            return error
        }
    },
    postNewComment: async (data, neighborhood_id) => {
        try {
            const url = `${urls.base_url}neighborhood/${neighborhood_id}/bulletin/comment`;
            const res = await axios.post(url,
                data,
                {
                    headers: {
                        "Authorization": `Bearer: ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            )
            return res;
        } catch (error) {
            console.error(error.response);
            return false;
        }
    },
}

export default API;
