import styled from 'styled-components';
import { COLORS } from 'styles/colors/minwo';

export const SandiHeaderContainer = styled.div`
  border-radius: inherit;
  background: ${COLORS.purple.p400};
  padding: 14px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 16px;
`;

export const Title = styled.p`
  margin: unset;
  color: ${COLORS.neutral.n0};
  font-size: 1.625rem;
  font-weight: 700;
`;

export const RightHeader = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
`;
