import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import classes from "./Features.module.css";
import minwo_feature_gold_irregular from "../../../assets/Images/minwo_feature_gold_irregular.svg";
import minwo_feature_green_irregular from "../../../assets/Images/minwo_feature_green_irregular.svg";
import minwo_header_footer_root from "../../../assets/Images/minwo_header_footer_root.svg";
import minwo_header_footer_root2 from "../../../assets/Images/minwo_header_footer_root2.svg";
import minwo_feature_right_corner from "../../../assets/Images/minwo_feature_right_corner.png";
import minwo_feature_section from "../../../assets/Images/minwo_feature_section.png";
import minwo_feature_section_big from "../../../assets/Images/minwo_feature_section_big.svg";
import minwo_feature_firstImg from "../../../assets/Images/minwo_feature_firstImg.png";
import minwo_feature_secondImg from "../../../assets/Images/minwo_feature_secondImg.png";
import minwo_feature_thirdImg from "../../../assets/Images/minwo_feature_thirdImg.png";
import minwo_main_big4 from "../../../assets/Images/minwo_main_big4.png";
import minwo_main_big5 from "../../../assets/Images/minwo_main_big5.png";
import minwo_main_small4 from "../../../assets/Images/minwo_main_small4.png";
import minwo_blob2 from "../../../assets/Images/minwo_blob2.png";
import minwo_blob3 from "../../../assets/Images/minwo_blob3.png";
import minwo_blob5 from "../../../assets/Images/minwo_blob5.png";
import minwo_feature_gold from "../../../assets/Images/minwo_feature_gold.svg";
import minwo_feature_last6 from "../../../assets/Images/minwo_feature_last6.png";
import minwo_feature_last7 from "../../../assets/Images/minwo_feature_last7.png";
import minwo_feature_last8 from "../../../assets/Images/minwo_feature_last8.png";
import minwo_feature_lastBig6 from "../../../assets/Images/minwo_feature_lastBig6.png";
import minwo_feature_lastBig7 from "../../../assets/Images/minwo_feature_lastBig7.png";
import minwo_feature_lastBig8 from "../../../assets/Images/minwo_feature_lastBig8.png";
import minwo_small_feature_corner from "../../../assets/Images/minwo_small_feature_corner.png";
import minwo_small_feature_corner2 from "../../../assets/Images/minwo_small_feature_corner2.svg";
import minwo_small_feature_corner3 from "../../../assets/Images/minwo_small_feature_corner3.png";

import minwo_curve_small1 from "../../../assets/Images/minwo_ curve_small1.svg";
import minwo_curve_small2 from "../../../assets/Images/minwo_ curve_small2.svg";
import minwo_curve_smalminwo_curve_big1 from "../../../assets/Images/minwo_curve_big1.svg";
import minwo_curve_smalminwo_curve_big2 from "../../../assets/Images/minwo_curve_big2.svg";
import lastBigFeature1 from "../../../assets/Images/lastBigFeature1.svg";
import lastBigFeature2 from "../../../assets/Images/lastBigFeature2.svg";
import lastBigFeature3 from "../../../assets/Images/lastBigFeature3.svg";
import minwo_header_rightBottom_corner from "../../../assets/Images/minwo_header-rightBottom_corner.svg";

const Features = () => {
  const [size, setSize] = useState(false);
  const navigate = useNavigate();
  const handleGoToPrice = (event) => {
    event.preventDefault();
    navigate("/pricing");
  };
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 602) setSize(true);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <section className={classes.features}>
      <header>
        <div
          className={`${classes.placedImage} ${classes.bottomImgCorner} ${classes.notShow}`}
        >
          <img src={minwo_header_rightBottom_corner} alt="root pattern" />
        </div>
        <div
          className={`${classes.placedImage} ${classes.bottomImg} ${classes.smallest}`}
        >
          <img src={minwo_header_footer_root} alt="root pattern" />
        </div>
        <div
          className={`${classes.placedImage} ${classes.bottomImg2} ${classes.notShow}`}
        >
          <img src={minwo_header_footer_root2} alt="root pattern" />
        </div>
        <div className={`${classes.placedImage} ${classes.gold}`}>
          <img src={minwo_feature_gold_irregular} alt="irregular gold circle" />
        </div>

        <div className={`${classes.placedImage} ${classes.green}`}>
          <img
            src={minwo_feature_green_irregular}
            alt="irregular gold circle"
          />
        </div>

        <h1>
          Collaborate, create, learn, and grow your <span>business</span> within
          one consolidated platform.
        </h1>
      </header>
      <section className={classes.featureDetails}>
        <div className={`${classes.placedImage} ${classes.right_corner}`}>
          <img src={minwo_feature_right_corner} alt="irregular gold circle" />
        </div>
        <div
          className={`${classes.placedImage} ${classes.big} ${classes.goldbig}`}
        >
          <img src={minwo_feature_gold} alt="irregular gold circle" />
        </div>
        <div
          className={`${classes.placedImage} ${classes.corner} ${classes.notShow}`}
        >
          <img src={minwo_feature_section_big} alt="irregular circle" />
        </div>
        <article>
          <div className={classes.suheader}>
            <h1>
              <span>Neighborhoods : </span>
              focus on the relationships that matter.
            </h1>
            <p>
              Rialto Neighborhoods are a centralized meet-up spot on the
              platform that is redefining how founders access each other and the
              information they need to push their business forward. With tools
              like Bulletin Boards, Forums, Events, Resources and more, a Rialto
              Neighborhood is just what you need to supercharge your community.
            </p>
            <button className={classes.transparent} onClick={handleGoToPrice}>
              Create Your Neighborhood
            </button>
          </div>
          <div className={classes.subImg}>
            <div className={`${classes.placedImage} ${classes.blobs}`}></div>
            <img
              src={minwo_feature_section}
              alt="feature display"
              className={classes.smallest}
            />
            <img
              src={minwo_feature_firstImg}
              alt="feature display"
              className={classes.notShow}
            />
          </div>
        </article>
        <article>
          <div className={`${classes.subImg} ${classes.big}`}>
            <img src={minwo_feature_secondImg} alt="feature display" />
          </div>
          <div className={classes.suheader}>
            <h1>
              <span>Forums : </span> visibility and engagement to grow your
              community.
            </h1>
            <p>
              Rialto Forums connects you to other founders, subject matter
              experts, resource providers and community organizers by enabling
              coversations, with direct mentions, catalogued replies and topic
              categorization. You can post questions, promotions, and discussion
              topics to engage with other members within and outside of your
              Neighborhoods.
            </p>
            <button
              className={`${classes.purple} ${classes.transparent}`}
              onClick={handleGoToPrice}
            >
              Grow Your Community
            </button>
          </div>
          <div
            className={`${classes.subImg} ${classes.subImg1} ${classes.small}`}
          >
            <img src={minwo_blob2} alt="feature display" />
          </div>
        </article>
        <article style={{ position: "relative", zIndex: "10" }}>
          <div className={classes.suheader}>
            <h1>
              <span>Office Hours:</span> give voice to the experts in your
              network.
            </h1>
            <p>
              Rialto Office Hours facilitates knowledge transfer through
              conversations between experts and members of your Neighborhood.
              With direct access to these subject matter experts, Rialto cuts
              down your members’ googling time and empowers them with the
              knowledge they need to execute and move to the next level.
            </p>
            <button
              className={classes.transparent}
              id="btn"
              onClick={handleGoToPrice}
            >
              Get Started
            </button>
          </div>
          <div className={classes.subImg}>
            <img
              src={minwo_feature_thirdImg}
              alt="feature display"
              className={classes.notShow}
            />
            <img
              src={minwo_blob3}
              alt="feature display"
              className={classes.smallest}
            />
          </div>
        </article>
      </section>

      <section className={classes.featureSec}>
        <div
          className={`${classes.placedImage} ${classes.hideBig} ${classes.curverBigCorner1}`}
        >
          <img
            src={minwo_curve_smalminwo_curve_big1}
            alt="irregular gold circle"
          />
        </div>

        <div
          className={`${classes.placedImage} ${classes.hideBig} ${classes.curverBigCorner2}`}
        >
          <img
            src={minwo_curve_smalminwo_curve_big2}
            alt="irregular gold circle"
          />
        </div>
        <div
          className={`${classes.placedImage} ${classes.hide} ${classes.curverCorner1}`}
        >
          <img src={minwo_curve_small1} alt="irregular gold circle" />
        </div>
        <div
          className={`${classes.placedImage}  ${classes.hide}  ${classes.curverCorner2}`}
        >
          <img src={minwo_curve_small2} alt="irregular gold circle" />
        </div>
        <article>
          <div className={`${classes.subImg} ${classes.big}`}>
            <img src={minwo_main_big4} alt="feature display" />
          </div>
          <div className={classes.featureSubHeader}>
            <h1>
              <span>Resources:</span> leverage the collective knowledge of the
              community.
            </h1>

            <p>
              With Rialto’s Resources feature, you are able to access and share
              knowledge in compact modules within multiple Neighborhoods. This
              opens you up to the highly skilled and experienced members of your
              communities and their wealth of knowledge.
            </p>
            <button id="btn" onClick={handleGoToPrice}>
              Sign up
            </button>
          </div>
          <div
            className={` ${classes.subImg} ${classes.subImg2}  ${classes.small}`}
          >
            <img src={minwo_main_small4} alt="feature" />
          </div>
        </article>
        <article>
          <div className={classes.featureSubHeader}>
            <h1>
              <span>Bulletin Boards:</span> keep your community up to speed with
              the important things.
            </h1>
            <p>
              With Rialto Bulletin Boards, your community will never be in the
              dark about the important things. Share announcements, events,
              news, blogs and more with the Bulletin Board.
            </p>
            <button
              className={classes.start}
              id="btn"
              onClick={handleGoToPrice}
            >
              Start Sharing
            </button>
          </div>

          <div className={`${classes.subImg} ${classes.subImg3}`}>
            <img src={minwo_blob5} alt="feature" className={classes.hide} />
            <img
              src={minwo_main_big5}
              alt="feature"
              className={classes.bigFeature}
            />
          </div>
        </article>
      </section>

      <section className={classes.lastFeatures}>
        <div
          className={`${classes.placedImage} ${classes.big} ${classes.lastPlacedFeature1}`}
        >
          <img src={lastBigFeature1} alt="irregular gold circle" />
        </div>
        <div
          className={`${classes.placedImage} ${classes.big} ${classes.lastPlacedFeature2}`}
        >
          <img src={lastBigFeature2} alt="irregular gold circle" />
        </div>
        <article>
          <div className={`${classes.lastFeatureImg} ${classes.big}`}>
            <img src={minwo_feature_lastBig6} alt="feature" />
          </div>
          <div className={classes.lastSubHeader}>
            <div
              className={`${classes.lastFeatureImg} ${classes.smallFeature}`}
            >
              <div
                className={` ${classes.smallCornerImg}  ${classes.smallest}`}
              >
                <img
                  src={minwo_small_feature_corner}
                  alt="irregular gold circle"
                />
              </div>
            </div>
            <h1>
              <span>Events:</span> real-time video conferences to keep your
              community connected.
            </h1>
            <p>
              Rialto lets you create and host high quality, secure video
              conferences with your community members. Boost collaboration
              within your community with seamless virtual events and meetings on
              Rialto.
            </p>
            <button className={classes.lastBtn} onClick={handleGoToPrice}>
              Start Collaborating
            </button>
          </div>
          <div
            className={`${classes.lastFeatureImg} ${classes.smallFeature}  ${classes.smallest} ${classes.subImg} ${classes.subImg1}`}
          >
            <div className={`${classes.placedBlob} ${classes.placed1}`}></div>
            <img
              src={minwo_feature_last6}
              alt="feature"
              className={classes.img}
            />
          </div>
          <div className={`${classes.lastFeatureImg} ${classes.smallFeature}`}>
            <div className={` ${classes.smallCornerImg2}  ${classes.smallest}`}>
              <img
                src={minwo_small_feature_corner2}
                alt="irregular gold circle"
              />
            </div>
          </div>
        </article>

        <article>
          <div className={classes.lastSubHeader}>
            <h1>
              {" "}
              <span>Chatbot:</span> save time and energy with smart
              recommendations.
            </h1>
            <p>
              Rialto’s advanced Chatbot leverages the power of data analytics to
              provide intelligently curated recommendations for neighborhoods,
              office hours, resources, events, and connections that are right
              for you and members of your community.
            </p>
            <button className={classes.lastBtn} onClick={handleGoToPrice}>
              Start Collaborating
            </button>
          </div>
          <div className={`${classes.lastFeatureImg} ${classes.big}`}>
            <img src={minwo_feature_lastBig7} alt="feature" />
          </div>
          <div
            className={`${classes.lastFeatureImg} ${classes.smallFeature} ${classes.smallest} ${classes.subImg} ${classes.subImg4}`}
          >
            <img
              src={minwo_feature_last7}
              alt="feature"
              className={classes.img}
            />
          </div>
          <div
            className={`${classes.placedImage} ${classes.big} ${classes.lastPlacedFeature3}`}
          >
            <img src={lastBigFeature3} alt="irregular gold circle" />
          </div>
        </article>

        <article>
          <div className={`${classes.lastFeatureImg} ${classes.big}`}>
            <img src={minwo_feature_lastBig8} alt="feature" />
          </div>
          <div className={classes.lastSubHeader}>
            <div
              className={`${classes.lastFeatureImg} ${classes.smallFeature}`}
            >
              <div
                className={` ${classes.smallCornerImg3}  ${classes.smallest}`}
              >
                <img
                  src={minwo_small_feature_corner3}
                  alt="irregular gold circle"
                />
              </div>
            </div>
            <h1>
              <span>Explore:</span> where it all comes together.
            </h1>
            <p>
              Rialto’s Explore page helps to make sure you’re never left
              confused or wondering where to find what. Find Neighborhoods,
              Office Hours, Resource Providers, Events, and Connections that
              align with your goals and fit your needs and those of your
              community.
            </p>
            <button className={classes.lastBtn} onClick={handleGoToPrice}>
              Start Collaborating
            </button>
          </div>
          <div
            className={`${classes.lastFeatureImg} ${classes.smallFeature}  ${classes.smallest} ${classes.subImg} ${classes.subImg4}`}
          >
            <img
              src={minwo_feature_last8}
              alt="feature"
              className={classes.img}
            />
          </div>
        </article>
      </section>
    </section>
  );
};

export default Features;
