import React from "react";
import { Outlet } from "react-router-dom";
import CustomRouteProvider from "contextStore/CustomRouteContext";

const CustomRouteLayout = () => {
  return (
    <CustomRouteProvider>
      <Outlet />
    </CustomRouteProvider>
  );
};

export default CustomRouteLayout;
