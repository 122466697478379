import styled from 'styled-components';
import { COLORS } from 'styles/colors/minwo';

export const ScrollChatWrapper = styled.div`
  padding: 16px;
  background-color: ${COLORS.neutral.n2};
  overflow-y: scroll;
`;

export const ChatBottom = styled.div`
  padding-top: 30px;
`;
