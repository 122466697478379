import axios from "axios";
import sessionInfo from "../utilities/session/sessionInfo.js";
import urls from "./baseUrl.js";

const { token, account_id } = sessionInfo();

const API = {
  resourceS3FileUpload: (neighborhood_id, Key, contentType, file) => {
    try {
      // Generate Upload Link
      return new Promise(async (resolve) => {
        const headers = {
          Authorization: `Bearer: ${token}`,
        };
        const link = `${urls.base_url}neighborhood/${neighborhood_id}/resource/post-url?content_type=${contentType}&key=${Key}`;
        const resp = await axios.get(link, { headers: headers });
        const { url, fields } = resp.data.data;

        // Upload Item
        const form = new FormData();
        Object.entries(fields).forEach(([field, value]) => {
          form.append(field, value);
        });
        form.append("file", file);
        const req = await fetch(url, { method: "POST", body: form });
        console.log(req.status);
        resolve(req.status === 204);
      });
    } catch (error) {
      console.error(error.response);
      return false;
    }
  },
  upload: async (data) => {
    data.crib_id = account_id;
    data.resource_cover_file = "";
    try {
      const resp_resource = await axios.post(
        `${urls.base_url}neighborhood/${data.neighborhood_id}/resource/create`,
        data,
        {
          headers: {
            Authorization: `Bearer: ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      data.resource_id = resp_resource.data.data.data.resource_id;
      await axios.post(
        `${urls.base_url}neighborhood/${data.neighborhood_id}/resource/upload-items`,
        data,
        {
          headers: {
            Authorization: `Bearer: ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return data.resource_id;
    } catch (error) {
      console.error(error.response);
      return error;
    }
  },
  getNeighborhoodResource: async (resource_id, neighborhood_id) => {
    try {
      const url = `${urls.base_url}resource/${resource_id}/neighborhood/${neighborhood_id}`;
      const headers = {
        Authorization: `Bearer: ${token}`,
        "Content-Type": "application/json",
      };

      const resp_resource = await axios.get(url, { headers });
      const resp = { ...resp_resource.data.data };
      return resp;
    } catch (error) {
      console.error(error.response);
      return error;
    }
  },
  getNeighborhoodResources: async (
    neighborhood_id,
    page,
    per_page,
    CancelToken
  ) => {
    try {
      const url = `${urls.base_url}resource/neighborhood/${neighborhood_id}?page=${page}&limit=${per_page}`;
      const headers = {
        Authorization: `Bearer: ${token}`,
        "Content-Type": "application/json",
      };

      const resp_resource = await axios.get(url, { headers }, { CancelToken });
      const resp = { ...resp_resource.data.data };

      return resp;
    } catch (error) {
      console.error(error.response);
      return error;
    }
  },
  getNeighborhoodResourceStats: async (
    resource_id,
    neighborhood_id,
    CancelToken
  ) => {
    try {
      const url = `${urls.base_url}resource/${resource_id}/stats/neighborhood`;
      const headers = {
        Authorization: `Bearer: ${token}`,
        "Content-Type": "application/json",
      };

      const resp_stats = await axios.get(url, { headers }, { CancelToken });
      const resp = { ...resp_stats.data.data.stats };

      return resp;
    } catch (error) {
      console.error(error.response);
      return error;
    }
  },
  getNeighborhoodResourceWithStats: async (
    neighborhood_id,
    page,
    per_page,
    CancelToken
  ) => {
    try {
      const url = `${urls.base_url}resource/neighborhood/${neighborhood_id}/stats?page=${page}&limit=${per_page}`;
      const headers = {
        Authorization: `Bearer: ${token}`,
        "Content-Type": "application/json",
      };

      const resp_stats = await axios.get(url, { headers }, { CancelToken });
      const resp = { ...resp_stats.data.data };

      return resp;
    } catch (error) {
      console.error(error.response);
      return error;
    }
  },
  getNeighborhoodResourceUploads: async (resource_id) => {
    try {
      const url = `${urls.base_url}resource/${resource_id}/uploads/neighborhood`;
      const headers = {
        Authorization: `Bearer: ${token}`,
        "Content-Type": "application/json",
      };

      const resp_uploads = await axios.get(url, { headers });
      const resp = resp_uploads.data.data.uploads;
      return resp;
    } catch (error) {
      console.error(error.response);
      return error;
    }
  },
  getNeighborhoodResourceFile: async (resource_id, file_key, CancelToken) => {
    try {
      const url = `${urls.base_url}resource/${resource_id}/${file_key}/neighborhood/download`;
      const headers = {
        Authorization: `Bearer: ${token}`,
        "Content-Type": "application/json",
      };
      const resp_stats = await axios.get(url, { headers }, { CancelToken });
      const resp = resp_stats.data.data;

      const file = await axios.get(resp);
      return file;
    } catch (error) {
      console.error(error.response);
      return error;
    }
  },
  getNeighborhoodResourceFileUrl: async (
    resource_id,
    file_key,
    neighborhood_id
  ) => {
    try {
      const url = `${urls.base_url}resource/${resource_id}/${file_key}/neighborhood/download`;
      const headers = {
        Authorization: `Bearer: ${token}`,
        "Content-Type": "application/json",
      };
      const resp_stats = await axios.get(url, { headers });
      const resp = resp_stats.data.data;

      return resp;
    } catch (error) {
      console.error(error.response);
      return error;
    }
  },
  searchNeighborhoodResources: async (
    neighborhood_id,
    key_word,
    page,
    per_page
  ) => {
    try {
      const url = `${urls.base_url}resource/neighborhood/${neighborhood_id}/search?key_word=${key_word}&page=${page}&limit=${per_page}`;
      const headers = {
        Authorization: `Bearer: ${token}`,
        "Content-Type": "application/json",
      };

      const resp_resource = await axios.get(url, { headers });
      const resp = resp_resource.data.data;

      return resp;
    } catch (error) {
      console.error(error.response);
      return error;
    }
  },
  filterNeighborhoodResources: async (
    neighborhood_id,
    filters,
    page,
    per_page
  ) => {
    try {
      const url = `${urls.base_url}resource/neighborhood/${neighborhood_id}/filter?${filters}&page=${page}&limit=${per_page}`;
      const headers = {
        Authorization: `Bearer: ${token}`,
        "Content-Type": "application/json",
      };
      const resp_resource = await axios.get(url, { headers });
      const resp = resp_resource.data.data;

      return resp;
    } catch (error) {
      console.error(error.response);
      return error;
    }
  },
  getNeighborhoodRelatedResources: async (
    neighborhood_id,
    category,
    page,
    per_page
  ) => {
    try {
      const cat =
        typeof category === "string" ? category.replace("&", "%26") : "";
      const url = `${urls.base_url}resource/neighborhood/${neighborhood_id}/related?category=${cat}&page=${page}&limit=${per_page}`;
      const headers = {
        Authorization: `Bearer: ${token}`,
        "Content-Type": "application/json",
      };
      const resp_resource = await axios.get(url, { headers });
      const resp = resp_resource.data.data;

      return resp;
    } catch (error) {
      console.error(error.response);
      return error;
    }
  },
  rateResources: async (resource_id, rating) => {
    try {
      const url = `${urls.base_url}resource/${resource_id}/neighborhood/rate/${rating}`;
      const headers = {
        Authorization: `Bearer: ${token}`,
        "Content-Type": "application/json",
      };
      const resp_resource = await axios({ method: "POST", url, headers });

      return resp_resource;
    } catch (error) {
      console.error(error.response);
      return error;
    }
  },
  likeResource: async (resource_id) => {
    try {
      const url = `${urls.base_url}resource/${resource_id}/neighborhood/favorite/add`;
      const headers = {
        Authorization: `Bearer: ${token}`,
        "Content-Type": "application/json",
      };
      const resp_resource = await axios({ method: "POST", url, headers });
      return resp_resource;
    } catch (error) {
      console.error(error.response);
      return error;
    }
  },
  unlikeResource: async (resource_id) => {
    try {
      const url = `${urls.base_url}resource/${resource_id}/neighborhood/favorite/remove`;
      const headers = {
        Authorization: `Bearer: ${token}`,
        "Content-Type": "application/json",
      };
      const resp_resource = await axios({ method: "DELETE", url, headers });
      return resp_resource;
    } catch (error) {
      console.error(error.response);
      return error;
    }
  },
  updateNeigborhoodResource: async (
    resource_id,
    neighborhood_id,
    request_body
  ) => {
    try {
      console.log({ resource_id, neighborhood_id, request_body });
      const { resource, uploads } = request_body;

      const url = `${urls.base_url}resource/${resource_id}/neighborhood/${neighborhood_id}`;
      const headers = {
        Authorization: `Bearer: ${token}`,
        "Content-Type": "application/json",
      };

      const data = { ...resource };
      await axios({ method: "PUT", url, headers, data });
      {
        const new_upload_items = uploads.filter((upload) => {
          return upload?.hasOwnProperty("resource_upload_id") === false;
        });
        if (new_upload_items.length > 0) {
          const data = { uploads: new_upload_items, resource_id };
          await axios.post(
            `${urls.base_url}neighborhood/${neighborhood_id}/resource/upload-items`,
            data,
            {
              headers: {
                Authorization: `Bearer: ${token}`,
                "Content-Type": "application/json",
              },
            }
          );
        }
      }
      {
        const old_upload_items = uploads.filter((upload) => {
          return Number(upload.resource_upload_id) > 0;
        });
        const url = `${urls.base_url}resource/${resource_id}/items/neighborhood/${neighborhood_id}`;
        const headers = {
          Authorization: `Bearer: ${token}`,
          "Content-Type": "application/json",
        };

        const data = { uploads: old_upload_items };

        const resp_resource = await axios({
          method: "PUT",
          url,
          headers,
          data,
        });
        return resp_resource;
      }
    } catch (error) {
      console.error(error.response);
      return error;
    }
  },
};

export default API;
