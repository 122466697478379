import React from 'react';
import { MessageHistoryContainer } from '../MessageContainer/MessageHistoryContainer';
import { FlexMessageBox } from './MessageList.styles';
import { getChatBotGraphics } from '../utils/getBotGraphics';

export function PreviousChatsList(props) {
  const {
    chats,
    setLatestSubmitResponse,
    setMessagesToSubmit,
    messagesToSubmit,
    setMessageSentByUser,
  } = props;

  return (
    <>
      {chats?.map((chat, _idx) => {
        if (chat.byUser) {
          return <MessageHistoryContainer key={_idx} chat={chat} />;
        }
        return (
          <FlexMessageBox key={_idx}>
            {getChatBotGraphics(chats, _idx)}
            <MessageHistoryContainer
              chats={chats}
              chat={chat}
              setLatestSubmitResponse={setLatestSubmitResponse}
              setMessagesToSubmit={setMessagesToSubmit}
              messagesToSubmit={messagesToSubmit}
              setMessageSentByUser={setMessageSentByUser}
            />
          </FlexMessageBox>
        );
      })}
    </>
  );
}
