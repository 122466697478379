import React from "react";
import NavBar from "../components/RialtoNavBar";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import { TextField, FormControl, Select, InputLabel } from "@material-ui/core";
import HiddenURLLink from "../components/HiddenURLLink";
import StepTrackerCard from "../components/StepTrackerCard";
import SnackbarAlert from "../components/SnackbarAlert";
import Onboard from "../api/crib";
import ArrowForward from "../assets/svgs/ArrowForward.jsx";
import ArrowBackward from "../assets/svgs/ArrowBackward.jsx";
import "../styles/components/StepContents.css";
import "../styles/pages/OnboardingScreen.css";

export default class SetupCribStep5 extends React.Component {
  constructor(props) {
    super(props);
    console.log("this.props 5");
    console.log(this.props);

    const step = "step5";
    this.state = {
      form: this.props.form,
      current: this.props.form[`${step}`],
      step: step,
      Snackbar: {
        message: "",
        severity: "success", // info : warning : success : error
      },
    };

    this.handleChange = this.handleChange.bind(this);
    this.updateForm = this.updateForm.bind(this);
    this.Next = this.Next.bind(this);
    this.Back = this.Back.bind(this);
  }

  componentDidMount() {
    window.onbeforeunload = () => "promptBeforeUnload";
  }

  handleChange = (e) => {
    console.log("step forms");
    console.log(this.state.form);

    const newState = this.state;
    const newForm = newState.current;
    const fieldName = e.target.name;
    const value = e.target.value;

    newForm[`${fieldName}`] = value;
    newState.current = newForm;
    this.setState(newState);
  };

  updateForm() {
    const newState = this.state;
    const step = this.state.step;
    newState.form[`${step}`] = this.state.current;

    this.setState(newState);
  }

  Next = async () => {
    this.updateForm();
    const res = await Onboard.setupCribProfile(this.state.form);
    const newState = this.state;
    const snackbar = this.state.Snackbar;
    if (res.status === 200) {
      snackbar.message = "Your input has been submitted";
      snackbar.open = true;
      snackbar.severity = "success";
      newState.Snackbar = snackbar;
    } else {
      snackbar.message = "Some error has occured, please try again";
      snackbar.open = true;
      snackbar.severity = "error";
      newState.Snackbar = snackbar;
    }

    this.setState(newState);
    console.log("res Form ::: :: :");
    const el = document.querySelectorAll(".URLLink");
    el[2].click();
  };

  Back = () => {
    const el = document.querySelectorAll(".URLLink");
    el[1].click();
  };

  render() {
    return (
      <div>
        <NavBar />
        <div className="OnboardingContent">
          <div className="ContentWraper">
            <span className="HeaderText">Display your services</span>
            <p className="Details">
              Share the skills you have to offer to other members.
            </p>
            <StepTrackerCard
              currentStepIndex={5}
              stepsFor={"SetupCribOwnerAndSME"}
            />

            {/** Current Step Track 
                     Current Form Board */}
            <div className="FormWrapper">
              <FormControl variant="outlined" className="white Field">
                <InputLabel htmlFor="my-input">Category</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={this.state.current.ServiceType}
                  name="ServiceType"
                  onChange={this.handleChange}
                  label="Category"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="1">Marketing Operation</MenuItem>
                  <MenuItem value="2">Mail</MenuItem>
                  <MenuItem value="3">Email</MenuItem>
                </Select>
              </FormControl>
              <TextField
                className="Field"
                variant="outlined"
                name="ServiceName"
                value={this.state.current.ServiceName}
                onChange={this.handleChange}
                label="Service Name"
              />
              <TextField
                className="Field"
                variant="outlined"
                name="Cost"
                value={this.state.current.Cost}
                onChange={this.handleChange}
                label="Cost"
              />
              <TextField
                className="Field"
                variant="outlined"
                name="YearsOfExperience"
                value={this.state.current.YearsOfExperience}
                onChange={this.handleChange}
                label="Years Of Experience"
              />
              <TextField
                className="MultilineField FullWidth"
                variant="outlined"
                multiline
                name="Description"
                value={this.state.current.Description}
                onChange={this.handleChange}
                rows={5}
                label="Description"
              />
              <Button className="Finish-Setup" onClick={() => this.Next()}>
                Finish Setup <ArrowForward className="ArrowForward" />
              </Button>
            </div>
            <SnackbarAlert
              message={this.state.Snackbar.message}
              severity={this.state.Snackbar.severity}
              open={this.state.Snackbar.open}
              onClose={() =>
                this.setState({ ...this.state, Snackbar: { open: false } })
              }
            />
            <HiddenURLLink url={"/setup/crib/step4"} data={this.state.form} />
            <HiddenURLLink url={"/CribProfile"} data={this.state.form} />
            <div className="StepControl">
              <Button className="Back" onClick={() => this.Back()}>
                <ArrowBackward className="ArrowBackward" /> Back
              </Button>
              <Button className="Skip" onClick={() => this.Next()}>
                Skip
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
