import { useState, useEffect } from "react";
import connected from "../../api/checkConnectionEstablished.js";

export const useRialtoAPIConnectionEstablished = () => {
  const [isConnectedToRialtoAPI, setIsConnectedToRialtoAPI] = useState(false);

  let checkRialtoAPIConnectionStatus = async () => {
    try {
      const isConnected = await connected.rialtoAPI();
      setIsConnectedToRialtoAPI(isConnected);
      return isConnected;
    } catch (error) {
      setIsConnectedToRialtoAPI(false);
      return false;
    }
  };

  useEffect(() => {
    checkRialtoAPIConnectionStatus();
    return () => setIsConnectedToRialtoAPI(false);
  }, []);

  return { isConnectedToRialtoAPI, checkRialtoAPIConnectionStatus };
};

export const useOfficehourAPIConnectionEstablished = () => {
  const [isConnectedToOfficehourAPI, setIsConnectedToOfficehourAPI] = useState(false);

  let checkOfficehourAPIConnectionStatus = async () => {
    try {
      const isConnected = await connected.officehoursAPI();
      setIsConnectedToOfficehourAPI(isConnected);
      return isConnected;
    } catch (error) {
      setIsConnectedToOfficehourAPI(false);
      return false;
    }
  };

  useEffect(() => {
    checkOfficehourAPIConnectionStatus();
    return () => setIsConnectedToOfficehourAPI(false);
  }, []);

  return { isConnectedToOfficehourAPI, checkOfficehourAPIConnectionStatus };
};
