import styled, { keyframes } from 'styled-components';
import { COLORS } from 'styles/colors/minwo';

const TypingKeyframe = keyframes`
    to {
        transform: translateY(-3px);
    }
`;

export const TypingIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  height: 28px;
  border: 1px solid ${COLORS.purple.p500};
  background-color: ${COLORS.neutral.n1};
  border-radius: 8px;
  padding: 6px 12px;

  & > div {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: ${COLORS.gray.gray02};
    opacity: 1;
    animation: ${TypingKeyframe} 0.35s infinite alternate;
  }

  & > div:nth-child(2) {
    animation-delay: 0.2s;
  }

  & > div:nth-child(3) {
    animation-delay: 0.4s;
  }
`;
