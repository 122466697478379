import sessionInfo from './session/sessionInfo.js';

/**
 * Returns neighborhood access right for current user session 
 * 
 *     * 3 - Neighborhood owner
 *     * 2 - Council member    
 *     * 1 - Member
 *     * 0 - Not a member
 *   
 * @param {Number} neighborhood_id
 * @returns {Number}
 */
const getNeighborhoodAccessRight = (neighborhood_id) => {
    const _sessionInfo = sessionInfo();
    const accessRights = _sessionInfo?.my_neighborhood_access_rights || [];
    if (accessRights.length > 0) {
        const match = accessRights.filter((data) => Number(data?.neighborhood_id) === Number(neighborhood_id));
        return match[0]?.access_level || 0;
    } else {
        return 0;        
    }
}

export default getNeighborhoodAccessRight;
