import React from "react";

const Twitter = ({ className }) => {
  return (
    <svg
      className={className}
      width="21"
      height="18"
      viewBox="0 0 21 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.4165 1.00915C19.5626 1.61145 18.6172 2.07212 17.6167 2.3734C16.5167 1.10861 14.744 0.665069 13.178 1.26279C11.612 1.86051 10.5857 3.37234 10.6082 5.04841V5.94008C7.42549 6.0226 4.41287 4.50625 2.58314 1.90082C2.58314 1.90082 -0.983532 9.92584 7.04148 13.4925C5.20512 14.739 3.01752 15.3641 0.799805 15.2758C8.82482 19.7342 18.6332 15.2758 18.6332 5.02166C18.6324 4.77329 18.6085 4.52553 18.5618 4.28157C19.4719 3.3841 20.1141 2.25099 20.4165 1.00915Z"
        stroke="#D8D8D8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Twitter;
