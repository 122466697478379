async function getDataURLFromByteArray(BYTEARRAY, file_type) {
  return new Promise((resolve, reject) => {
    if (!BYTEARRAY) {
      console.log("could not read file");
      reject(null);
    } else {
      try {
        const blob = new Blob([new Uint8Array(BYTEARRAY)], { type: file_type });
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = function () {
          const file = reader.result;
          resolve(file);
        };
        reader.onerror = function (error) {
          throw error;
        };
      } catch (error) {
        console.log("Error: ", error);
        reject(null);
      }
    }
  });
}

export default getDataURLFromByteArray;
