import React from 'react';
import { SandiHeaderContainer, Title, RightHeader } from './SandiHeader.styles';
import ChevronUp from 'assets/svgs/chevron-up.svg';
import ChevronDown from 'assets/svgs/chevron-down.svg';
import * as testId from 'utilities/testIds';

export function SandiHeader(props) {
  const { botSize, toggleBotSize } = props;
  const { sandiChatbot } = testId;

  const iconProps = {
    className: 'cursor-pointer',
    role: 'button',
    ['data-testid']: sandiChatbot.toggleButton,
    onClick: toggleBotSize,
  };
  return (
    <SandiHeaderContainer data-bot-height={botSize}>
      <Title data-testid={sandiChatbot.headerTitle}>Sandi</Title>
      <RightHeader className="new-icon">
        <img src={botSize === 'min' ? ChevronDown : ChevronUp} {...iconProps} />
      </RightHeader>
    </SandiHeaderContainer>
  );
}
