import React from "react";
import {
  TextareaField,
  TextInputDiv,
  TextInputField,
  TextInputPlaceholder,
} from "./TextInput.styles";

export function TextInput(props) {
  const {
    label,
    size,
    as = "input",
    variant,
    testId,
    error,
    disabled,
    ...rest
  } = props;
  const InputField = as === "textarea" ? TextareaField : TextInputField;

  return (
    <TextInputPlaceholder>
      <TextInputDiv>
        <label>{label}</label>
        <InputField
          data-testid={testId}
          inputProps={{
            disableUnderline: true,
          }}
          variant={variant}
          size={size || "small"}
          disabled={disabled}
          {...rest}
        />
      </TextInputDiv>
      <p className="error">{error}</p>
    </TextInputPlaceholder>
  );
}
