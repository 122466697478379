import React from "react";
import { ReactComponent as GoogleIcon } from "../../../assets/svgs/GoogleIcon.svg";
import BtnLoader from "components/Loader/btn-loader";
import "./GoogleAuthButton.css";

const GoogleAuthButton = ({
  className,
  btnText,
  isLoading,
  handleGoogleSignIn,
}) => {
  return (
    <button
      className={`google-btn ${className}`}
      onClick={handleGoogleSignIn}
      disabled={isLoading}
    >
      {isLoading ? (
        <BtnLoader
          border_bottom={"3px solid #3B7A71"}
          border_right={"3px solid #3B7A71"}
          border_left={"3px solid #D9D9D9"}
          border_top={"3px solid #D9D9D9"}
        />
      ) : (
        <>
          <GoogleIcon />
          <span className="google-btn__text">{btnText}</span>
        </>
      )}
    </button>
  );
};

export default GoogleAuthButton;
