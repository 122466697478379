import { MenuItem } from "@szhsin/react-menu";
import styled from "styled-components";
import { COLORS } from "styles/colors/minwo";

export const DropdownItem = styled(MenuItem)`
  color: ${COLORS.purple.p500};
  font-size: 13px;
  cursor: pointer;
  border: none;
  outline: none;
  list-style: none;

  :not(:last-child) {
    margin-bottom: 14px;
  }
`;
