import clsx from 'clsx';
import React, { useCallback } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { Icon as BlueIcon } from '@blueprintjs/core';
import {
  Input,
  InputContainer,
  SendSandiChatButton,
} from './FreeTextForm.styles';
import * as testId from 'utilities/testIds';
import { usePostSandiChat } from '../utils/usePostSandiChat';
import { COLORS } from 'styles/colors/minwo';
import { getFilteredNextConversations } from '../utils/getFilteredNextConversations';
import { getFreeTextPayload } from './FreeTextForm.utils';

export function FreeTextForm(props) {
  const {
    setLatestSubmitResponse,
    messagesToSubmit,
    setMessagesToSubmit,
    approvedMsgCount,
    setMessageSentByUser,
    record,
  } = props;

  const { register, reset, control } = useFormContext();

  const {
    field: { value },
    formState: { isDirty, isLoading, isValid },
  } = useController({ name: 'content', control });

  const postSandiChatMutation = usePostSandiChat((data) => {
    setLatestSubmitResponse(data);
  });

  const slicedMessages = messagesToSubmit?.slice(approvedMsgCount);

  const filteredNewNextConversations =
    getFilteredNextConversations(slicedMessages);

  const getFreeTextPayloadCb = useCallback(
    (value) => {
      const payload = getFreeTextPayload(
        record,
        value,
        slicedMessages,
        messagesToSubmit
      );

      return payload;
    },
    [value, messagesToSubmit, record?.chats]
  );

  const handleMessagePost = (e) => {
    e.preventDefault();
    setMessageSentByUser(true);
    const hasEndofsequence = filteredNewNextConversations?.some(
      (item) => item?.endOfSequence
    );

    const commonPayload = {
      createdAt: new Date(),
      content: value,
    };
    const payload = getFreeTextPayloadCb(value);

    setMessagesToSubmit((prev) => [
      ...prev,
      {
        ...commonPayload,
        byUser: true,
      },
    ]);
    reset();
    postSandiChatMutation.mutate(
      {
        chats: [
          ...(hasEndofsequence ? [] : filteredNewNextConversations),
          payload,
        ],
      },
      {
        onSuccess() {
          reset();
        },
      }
    );
  };

  return (
    <InputContainer onSubmit={handleMessagePost}>
      <Input
        type="text"
        placeholder="Type a reply...."
        {...register('content')}
        data-testid={testId.sandiChatbot.freeTextInput}
      />
      <SendSandiChatButton
        type="submit"
        disabled={!isDirty || isLoading || !isValid}
        data-testid={testId.sandiChatbot.freeTextButton}
      >
        <BlueIcon
          icon="send-message"
          color={COLORS.purple.p300}
          className={clsx('new-icon', 'cursor-pointer')}
        />
      </SendSandiChatButton>
    </InputContainer>
  );
}
