import React from "react";
import { ReactComponent as TickSuccess } from "../assets/Icons/TickSuccess.svg";
import { ReactComponent as TickError } from "../assets/Icons/TickError.svg";

const FormFieldValidationTick = ({ isValid }) => {
  if (isValid === false) {
    return <TickError className="FormFieldTick" />
  }
  if (isValid === true) {
    return <TickSuccess className="FormFieldTick" />;
  }

  return <></>
}

export default FormFieldValidationTick;
