import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation } from "react-query";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useErrorContext } from "../../../hooks/useErrorContext";
import store from "utilities/storage";
import neighborhood from "../../../api/neighborhood";
import InfoIcon from "../../../assets/svgs/infoIcon";
import "./share-link.css";

const ShareLink = ({ onClose }) => {
  const params = useParams();
  const triggerAlert = useErrorContext();

  const setup_neighborhood_id = store.retrieve("setup_neighborhood_id");
  const neighborhood_id = params?.neighborhood_id || setup_neighborhood_id;

  const [magicLink, setMagicLink] = useState("");
  const [copied, setCopied] = useState(false);

  const mutation = useMutation(
    () => neighborhood.getInviteLink(neighborhood_id),
    {
      onError: (error) => {
        triggerAlert({
          open: true,
          message: error?.response?.data?.error
            ? error?.response?.data?.error
            : "There was an error, please try again later",
          type: "error",
        });
      },
      onSuccess: (response) => {
        if (response?.status === "success") {
          setMagicLink(response?.data);
        } else {
          triggerAlert({
            open: true,
            message: "Something went wrong, please try again later",
            type: "error",
          });
        }
      },
    }
  );

  useEffect(() => {
    mutation.mutate(neighborhood_id);
  }, []);

  return (
    <div className="share-link" data-testid="shareLink">
      <div className="share-link__wrapper">
        <button className="share-link__cta" onClick={onClose}>
          Back
        </button>
        <div className="share-link__div">
          <div className="share-link__form-control">
            <div className={`share-link__input ${copied && "copied"}`}>
              <span className="share-link__span" title={magicLink}>
                {mutation.isLoading ? "Generating invite link..." : magicLink}
              </span>
            </div>
            <CopyToClipboard text={magicLink} onCopy={() => setCopied(true)}>
              <button
                className={`share-link__btn ${copied && "copied"}`}
                disabled={magicLink === ""}
              >
                {copied ? "Copied!" : "Copy link"}
              </button>
            </CopyToClipboard>
          </div>
        </div>
        <div className="share-link__info">
          <InfoIcon className="share-link__info--svg" />
          <p className="share-link__info--text">
            You can invite people to join your Neighborhood by sharing this link
            with them
          </p>
        </div>
      </div>
    </div>
  );
};

export default ShareLink;
