import isEmpty from "../isEmpty";
import sessionInfo from "./sessionInfo";



/**
 * Get hours difference between two milliseconds date
 * 
 * @param {Number} ms2 - time in milliseconds
 * @param {Number} ms1 - time in milliseconds 
 * @returns 
 */
const diffHours = (ms2, ms1) => {
    var diff = (ms2 - ms1) / 1000;
    diff /= (60 * 60);
    return Math.abs(Math.round(diff));
}

/**
 * Check if user session is expired
 * 
 * @returns {Boolean}
 */
const sessionExpired = () => {
    const signInHours = Number(sessionInfo().login_time);
    const currentHours = new Date().getTime();
    const hoursElapsed = diffHours(signInHours, currentHours);

    return hoursElapsed > 7;
}

const noSession = () => {
    return isEmpty(sessionInfo().token);
}

/**
 * Get session status
 * 
 *  - return 1 for no session
 *  - return 2 for expired
 *  - return 3 for active
 * 
 * @returns {Number} status
 */

const sessionStatus = () => {
    if (noSession()) {
        return 1;
    }
    if (sessionExpired()) {
        return 2;
    }
    return 3;
}

export default sessionStatus;
