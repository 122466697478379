import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQueries, useQueryClient } from "react-query";
import { useErrorContext } from "../../../hooks/useErrorContext";
import { Chip, TextField } from "@material-ui/core";
import store from "utilities/storage";
import neighborhood from "../../../api/neighborhood";
import useDebounce from "../../../hooks/useDebounce";
import Autocomplete from "@material-ui/lab/Autocomplete";
import LoadingSkeleton from "./components/LoadingSkeleton";
import AddMembersActions from "./components/AddMembersActions";
import SelectInputList from "./components/SelectInputList";
import "./add-members-within-rialto.css";

const AddMembersWithinRialto = ({ onClose }) => {
  const params = useParams();
  const triggerAlert = useErrorContext();

  const setup_neighborhood_id = store.retrieve("setup_neighborhood_id");
  const neighborhood_id = params?.neighborhood_id || setup_neighborhood_id;

  const [cribMembers, setCribMembers] = useState([]);
  const [nameValue, setNameValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [emailMembers, setEmailMembers] = useState([]);
  const [invitees, setInvitees] = useState([]);
  const [emailInvitees, setEmailInvitees] = useState([]);

  const debouncedNameValue = useDebounce(nameValue, 200);
  const debouncedEmailValue = useDebounce(emailValue, 200);

  const queryClient = useQueryClient();

  const results = useQueries([
    {
      queryKey: ["getMembersViaNameWithinRialto", debouncedNameValue],
      queryFn: () =>
        neighborhood.getMembersViaNameWithinRialto(
          neighborhood_id,
          debouncedNameValue
        ),
      onError: (error) => {
        triggerAlert({
          open: true,
          message: error?.response?.data?.error
            ? error?.response?.data?.error
            : "There was an error, please try again later",
          type: "error",
        });
      },
      onSuccess: (response) => {
        if (response?.status === "success") {
          setCribMembers(response?.data?.result);
        } else {
          triggerAlert({
            open: true,
            message: "Something went wrong, please try again later",
            type: "error",
          });
        }
      },
    },
    {
      queryKey: ["getMembersViaEmailWithinRialto", debouncedEmailValue],
      queryFn: () =>
        neighborhood.getMembersViaEmailWithinRialto(
          neighborhood_id,
          debouncedEmailValue
        ),
      onError: (error) => {
        triggerAlert({
          open: true,
          message: error?.response?.data?.error
            ? error?.response?.data?.error
            : "There was an error, please try again later",
          type: "error",
        });
      },
      onSuccess: (response) => {
        if (response?.status === "success") {
          setEmailMembers(response?.data?.result);
        } else {
          triggerAlert({
            open: true,
            message: "Something went wrong, please try again later",
            type: "error",
          });
        }
      },
    },
  ]);

  const getDisabledOption = (option, isEmailInvitees = false) => {
    const _invitees = isEmailInvitees ? emailInvitees : invitees;
    return (
      !!invitees.find((item) => item?.crib_id === option?.crib_id) ||
      !!emailInvitees.find((item) => item?.crib_id === option?.crib_id) ||
      option?.existing_member ||
      _invitees.length >= 5
    );
  };

  const handleDelete = (member) => {
    const invitee = invitees.find((invitee) => invitee?.crib_id === member);
    if (invitee) {
      const newInvitees = invitees.filter(
        (item) => item?.crib_id !== invitee.crib_id
      );
      setInvitees([...newInvitees]);
    }
  };

  const handleEmailInputDelete = (member) => {
    const emailInvitee = emailInvitees.find(
      (invitee) => invitee?.crib_id === member
    );
    if (emailInvitee) {
      const newEmailInvitees = emailInvitees.filter(
        (item) => item?.crib_id !== emailInvitee.crib_id
      );
      setEmailInvitees([...newEmailInvitees]);
    }
  };

  const mutation = useMutation(
    (payload) => neighborhood.inviteExisitingMembers(payload, neighborhood_id),
    {
      onError: (error) => {
        triggerAlert({
          open: true,
          message: error?.response?.data?.error
            ? error?.response?.data?.error
            : "There was an error, please try again later",
          type: "error",
        });
      },
      onSuccess: (response) => {
        if (response?.status === "success") {
          triggerAlert({
            open: true,
            message: "Invitation sent successfully!",
            type: "success",
          });
          setInvitees([]);
          setEmailInvitees([]);
          queryClient.invalidateQueries([
            "neighborhoodsMembers",
            neighborhood_id,
          ]);
        } else {
          triggerAlert({
            open: true,
            message: "Something went wrong, please try again later",
            type: "error",
          });
        }
      },
    }
  );

  const handleInvite = (e) => {
    e.preventDefault();
    const invitee_ids = invitees.map((invitee) => invitee?.crib_id);
    const emailInvitee_ids = emailInvitees.map((invitee) => invitee?.crib_id);
    const payload = { invitees: [...invitee_ids, ...emailInvitee_ids] };
    mutation.mutate(payload, neighborhood_id);
  };

  return (
    <div className="add-members-within-rialto">
      <div>
        <button className="add-members-within-rialto__cta" onClick={onClose}>
          Back
        </button>
        <h2 className="add-members-within-rialto__heading">
          Invite people to MINWO Neighborhood
        </h2>
        <form
          action=""
          className="add-members-within-rialto__form"
          onSubmit={handleInvite}
        >
          <Autocomplete
            multiple
            options={cribMembers}
            getOptionLabel={(option) => option?.crib_first_name}
            getOptionDisabled={(option) => getDisabledOption(option)}
            filterOptions={(options) => options}
            inputValue={nameValue}
            onInputChange={(_, newInputValue) => {
              setNameValue(newInputValue);
            }}
            onChange={(_, value) =>
              value.map((item) => setInvitees([...invitees, item]))
            }
            value={invitees}
            loading={results[0]?.isLoading}
            loadingText={Array.from(new Array(7)).map((_, index) => (
              <LoadingSkeleton index={index} />
            ))}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  key={index}
                  variant="outlined"
                  label={`${option?.crib_first_name} ${option?.crib_last_name}`}
                  {...getTagProps({ index })}
                  onDelete={() => handleDelete(option?.crib_id)}
                />
              ))
            }
            renderOption={(option) => (
              <SelectInputList option={option} info={option?.workplace} />
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select members from Rialto"
                variant="outlined"
                className="add-members-within-rialto__input"
                inputProps={{
                  ...params.inputProps,
                }}
              />
            )}
          />
          <Autocomplete
            multiple
            options={emailMembers}
            getOptionLabel={(option) => option?.email}
            getOptionDisabled={(option) => getDisabledOption(option, true)}
            filterOptions={(options) => options}
            inputValue={emailValue}
            onInputChange={(_, newInputValue) => {
              setEmailValue(newInputValue);
            }}
            onChange={(_, value) =>
              value.map((item) => setEmailInvitees([...emailInvitees, item]))
            }
            value={emailInvitees}
            loading={results[1]?.isLoading}
            loadingText={Array.from(new Array(7)).map((_, index) => (
              <LoadingSkeleton index={index} />
            ))}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  key={index}
                  variant="outlined"
                  label={`${option?.crib_first_name} ${option?.crib_last_name}`}
                  {...getTagProps({ index })}
                  onDelete={() => handleEmailInputDelete(option?.crib_id)}
                />
              ))
            }
            renderOption={(option) => (
              <SelectInputList option={option} info={option?.email} />
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Invite by email"
                variant="outlined"
                className="add-members-within-rialto__input"
                inputProps={{
                  ...params.inputProps,
                }}
              />
            )}
          />
          <AddMembersActions
            onClose={onClose}
            isLoading={mutation?.isLoading}
            emailInviteesLength={emailInvitees?.length}
            inviteesLength={invitees?.length}
          />
        </form>
      </div>
    </div>
  );
};

export default AddMembersWithinRialto;
