import React, { useState } from "react";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";
import crib from "api/crib";
import ReactQuill from "react-quill";
import UserImageAvatar from "components/GlobalComponents/UserImageAvatar";
import "./CribProfile.css";
import routeToActiveSessionCrib from "utilities/session/routeToActiveSessionCrib";
import isvalidCribId from "utilities/session/isvalidCribId";

const CribProfile = () => {
  let { crib_id } = useParams();

  // re-route invalid crib page
  if (!isvalidCribId(crib_id)) {
    routeToActiveSessionCrib();
  }

  const [cribData, setCribData] = useState({});

  const { isLoading, isRefetching } = useQuery(
    "cribProfile",
    () => crib.getCribProfile(crib_id),
    {
      refetchOnWindowFocus: false,
      onError: (error) => {
        console.log(error);
      },
      onSuccess: (response) => {
        if (response?.status) {
          setCribData(response?.data);
        }
      },
    }
  );

  return (
    <div className="crib" data-testid="crib-profile">
      <div className="crib__container">
        <div className="crib__box">
          <div className="crib__header">
            <h2 className="crib__heading">Summary</h2>
            {!isLoading && (
              <Link to={`/crib/${crib_id}/edit`} className="crib__link">
                Edit
              </Link>
            )}
          </div>
          {isLoading || isRefetching ? (
            <Skeleton
              variant="rect"
              animation="pulse"
              style={{ width: "100%", height: "4.5em", borderRadius: "8px" }}
            />
          ) : (
            <ReactQuill
              className="crib__summary--text"
              readOnly={true}
              theme={"bubble"}
              value={
                cribData?.profile?.crib_summary ||
                "Your summary is the one place you define yourself in your own words, free of start dates and titles. Whether you use it to put career choices in context, highlight your biggest achievements, or show off your personality, the summary is your chance to put your best self out there."
              }
            />
          )}
        </div>
        <div className="crib__wrapper">
          <div className="crib__box">
            {isLoading || isRefetching ? (
              <h2 className="crib__heading">Neighborhoods</h2>
            ) : (
              <div className="crib__header">
                <h2 className="crib__heading">
                  Neighborhoods ({cribData?.neighborhoods?.length})
                </h2>
                <Link
                  to={`/crib/${crib_id}/neighborhoods`}
                  className="crib__link"
                >
                  See all
                </Link>
              </div>
            )}
            <div className="crib__neighborhood">
              {isLoading || isRefetching
                ? Array.from(new Array(2)).map((_, index) => (
                    <div key={index} className="crib__neighborhood--box">
                      <Skeleton
                        variant="circle"
                        animation="pulse"
                        className="crib__neighborhood--image"
                        style={{
                          border: "none",
                          display: "block",
                          margin: "12px auto 0",
                        }}
                      />
                      <Skeleton
                        variant="rect"
                        animation="pulse"
                        style={{
                          margin: "5px auto 0",
                          borderRadius: "4px",
                          height: "12px",
                          width: "150px",
                        }}
                      />
                      <Skeleton
                        variant="rect"
                        animation="pulse"
                        style={{
                          margin: "5px auto 0",
                          borderRadius: "4px",
                          height: "12px",
                          width: "100px",
                        }}
                      />
                    </div>
                  ))
                : cribData?.neighborhoods
                    ?.slice(0, 4)
                    ?.map(
                      ({
                        neighborhood_id,
                        neighborhood_name,
                        profile_picture,
                        neighborhood_description,
                      }) => (
                        <div
                          className="crib__neighborhood--box"
                          key={neighborhood_id}
                        >
                          <UserImageAvatar
                            s3_picture_url={profile_picture}
                            Alt={`${neighborhood_name} logo`}
                            className="crib__neighborhood--image"
                          />
                          <div className="crib__neighborhood--info">
                            <Link
                              to={`/neighborhood/${neighborhood_id}`}
                              className="crib__neighborhood--name"
                            >
                              {neighborhood_name}
                            </Link>
                            <p className="crib__neighborhood--description">
                              {neighborhood_description}
                            </p>
                          </div>
                        </div>
                      )
                    )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CribProfile;
