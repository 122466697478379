const OnboardingNeighborhoodprofileFormFields = {
    step1: { 
      Profile_Name: '', CompanyName: '', CompanyWebsite: '', FacebookPage: '', TwitterHandle: '', Instagram: '', LinkedIn: '', NeighborhoodDescription: '', File: '' 
    },
    step2: {
      Message: '', 
      ContentType: '', 
      Title: '',
      File: ''
    },
    step3: { 
      Category: '', 
      Title: '', 
      Message: '' 
    },
    step4: { 
      Members: []
    }
}

export default OnboardingNeighborhoodprofileFormFields
