import React from "react";
import Cancel from "../../../assets/svgs/cancel";
import Error from "../../../assets/svgs/error";
import InfoIcon from "../../../assets/svgs/infoIcon";
import BtnLoader from "../../../components/Loader/btn-loader";
import "./add-members-outside-rialto.css";

const AddMembersOutsideForm = ({
  onClose,
  formValues,
  members,
  handleChange,
  previewEmails,
  isLoading,
  handleSubmit,
  removeMember,
}) => {
  const regexName = /(^[a-zA-Z][a-zA-Z\s]{0,20}[a-zA-Z]$)/;
  const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const isFieldValid =
    regexName.test(formValues.firstname) &&
    regexName.test(formValues.lastname) &&
    regexEmail.test(formValues.email);

  return (
    <div className="add-members-outside-rialto" data-testid="addMembersOutside">
      <div>
        <button className="add-members-outside-rialto__cta" onClick={onClose}>
          Back
        </button>
        <form
          action=""
          className="add-members-outside-rialto__form"
          onSubmit={handleSubmit}
        >
          <div className="add-members-outside-rialto__form-control">
            <input
              type="text"
              name="firstname"
              onChange={handleChange}
              value={formValues.firstname}
              className="add-members-outside-rialto__input"
              placeholder="First name (required)"
              data-testid="firstname"
            />
            <input
              type="text"
              name="lastname"
              onChange={handleChange}
              value={formValues.lastname}
              className="add-members-outside-rialto__input"
              placeholder="Last name (required)"
              data-testid="lastname"
            />
          </div>
          <div className="add-members-outside-rialto__form-control">
            <input
              type="email"
              name="email"
              onChange={handleChange}
              value={formValues.email}
              className="add-members-outside-rialto__input"
              placeholder="Email (required)"
              data-testid="email"
            />
            <button
              className="add-members-outside-rialto__btn yellow-bg"
              onClick={previewEmails}
              data-testid="submitButton"
              disabled={
                !formValues.email ||
                !formValues.firstname ||
                !formValues.lastname ||
                !isFieldValid ||
                members?.length === 5
              }
            >
              Submit
            </button>
          </div>
          <div className="add-members-outside-rialto__btn--wrapper">
            {members?.length > 0 && (
              <div className="add-members-outside-rialto__btn--container">
                {members?.map((member, index) => (
                  <div
                    className="add-members-outside-rialto__tag"
                    key={`${index}-${member?.email}`}
                    data-testid="emailPreview"
                  >
                    <p className="add-members-outside-rialto__tag--name">
                      {member?.email}
                    </p>
                    <button
                      className="add-members-outside-rialto__tag--btn"
                      onClick={() => removeMember(member)}
                    >
                      <Cancel className="add-members-outside-rialto__tag--icon" />
                    </button>
                  </div>
                ))}
              </div>
            )}
            <button
              className="add-members-outside-rialto__btn green-bg"
              disabled={members?.length <= 0}
            >
              {isLoading ? (
                <BtnLoader
                  border_bottom={"4px solid #FFFFFF"}
                  border_right={"4px solid #FFFFFF"}
                  border_left={"4px solid #838383"}
                  border_top={"4px solid #838383"}
                />
              ) : (
                `Add ${members?.length} ${members?.length > 1 ? "Members" : "Member"
                }`
              )}
            </button>
          </div>
          {members?.length === 5 && (
            <div className="add-members-outside-rialto__error">
              <Error className="add-members-outside-rialto__error--svg" />
              <p className="add-members-outside-rialto__error--text">
                The limit of invites has been reached.
              </p>
            </div>
          )}
        </form>
        <div className="add-members-outside-rialto__info">
          <InfoIcon className="add-members-outside-rialto__info--svg" />
          <p className="add-members-outside-rialto__info--text">
            You can add members manually through this form. To add in bulk, use
            the “Import” option.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AddMembersOutsideForm;
