import styled from "styled-components";
import { COLORS } from "styles/colors/minwo";

export const ProfilePicSectionDiv = styled.div`
  border-radius: 10px;
  border: 0.25px solid ${COLORS.green.grn1};
  background: ${COLORS.gray.gr6};
  box-shadow: 0px 4px 9px 1px rgba(0, 0, 0, 0.1);
  padding: 51px 44px;
  margin: 30px 0px 48px 0px;
  display: flex;
  justify-content: space-between;
  gap: 40px;
  flex-wrap: wrap;
  align-items: center;
`;

export const LeftSection = styled.div`
  display: flex;
  gap: 40px;
  align-items: center;
  flex-wrap: wrap;
  .Logo {
    width: 138px;
    height: 138px;
  }

  .text {
    p:first-child {
      font-size: 1.875rem;
      font-weight: 700;
      color: ${COLORS.purple.p500};
    }
    p:last-child {
      font-size: 1.25rem;
      color: #444;
      margin-top: 8px;
    }

    @media screen and (max-width: 768px) {
      p:first-child {
        font-size: 1.5rem;
      }
      p:last-child {
        font-size: 1rem;
      }
    }
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .buttons {
    padding: 12px 16px;
    max-width: 100%;
    min-width: 240px;
    max-height: 50px;
    border-radius: 10px;
    font-size: 1.25rem;

    @media screen and (max-width: 768px) {
      min-width: 100%;
      padding: 8px 16px;
      font-size: 1rem;
    }

    label {
      cursor: pointer;
    }
  }
`;

export const UploadConfirmDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 14px;
  }

  .preview_img_sec {
    text-align: center;
  }
`;

// confirm dialog

export const Overlay = styled.div`
  /* Layout Properties */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  align-content: center;
  justify-content: center;
  z-index: 13;
  /* UI Properties */
  background: #0000003d 0 0 no-repeat padding-box;
  opacity: 1;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
`;

export const ConfirmDialogDiv = styled.div`
  padding: 24px;
  width: 100%;
  max-width: 379px;
  align-self: center;
  justify-self: center;
  z-index: 10;
  position: fixed;
  background: #ffffff 0 0 no-repeat padding-box;
  box-shadow: 0 12px 28px rgba(33, 39, 73, 0.2);
  border-radius: 8px;

  .preview_img {
    width: 60px;
    height: 60px;
  }
`;

export const ConfirmDialogHeader = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  margin-bottom: 30px;

  .close-icon {
    padding: 6px;
    border-radius: 4px;
    cursor: pointer;
    :hover {
      background-color: ${COLORS.gray.gr6};
    }
  }
`;
