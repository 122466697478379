import React from "react";

const LinkedinIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 12.1598V19.5535H15.7126V12.6574C15.7126 10.9229 15.0942 9.7418 13.541 9.7418C12.3553 9.7418 11.6531 10.537 11.3415 11.3089C11.2299 11.5832 11.1974 11.9692 11.1974 12.3551V19.5581H6.91002C6.91002 19.5581 6.96582 7.87247 6.91002 6.66345H11.1974V8.49093C11.1881 8.50488 11.1788 8.51883 11.1695 8.53278H11.1974V8.49093C11.7694 7.61206 12.7831 6.36119 15.0616 6.36119C17.8842 6.35654 20 8.20262 20 12.1598ZM2.42734 0.446289C0.957917 0.446289 0 1.40886 0 2.67368C0 3.9106 0.930016 4.90107 2.37154 4.90107H2.39944C3.89677 4.90107 4.82678 3.9106 4.82678 2.67368C4.79423 1.40886 3.89212 0.446289 2.42734 0.446289ZM0.255754 19.5581H4.54313V6.6588H0.255754V19.5581Z"
        fill="white"
      />
    </svg>
  );
};

export default LinkedinIcon;
