import matchStringAsUrl from './checkStringIsUrl.js';

const parseLink = (type, text) => {
    const textIsUrl = matchStringAsUrl(text);
    let link = textIsUrl ? text : "";
    type = type.toLowerCase();
    if (!textIsUrl) {
        switch (type) {
            case "linkedin":
                link = `https://linkedin.com/in/${text}`;
                break;    
            case "facebook":
                link = `http://facebook.com/${text}`
                break;    
            case "twitter":
                link = `https://twitter.com/${text}`
                break;    
            case "instagram":
                link = `https://instagram.com/${text}`
                break;
            default:
                break;
        }        
    }

    return link;
}

export default parseLink;
