import React from "react";
import BulletinPostState from './BulletinPost_State.js';
import store from '../../../utilities/storage.js';

const {
    initialState, reducer, actions
} = BulletinPostState;

export const BulletinPost_Context = React.createContext();

const BulletinPost_Provider = ({ children }) => {
    const init = store.retrieve('neighborhood_bulletin_post') || initialState;
    const [state, dispatch] = React.useReducer(reducer, init);

    const BulletinPostActions = actions(dispatch);

    const value = {
        state,
        actions: { ...BulletinPostActions }
    };

    return (
      <BulletinPost_Context.Provider value={value}>
        {children}
      </BulletinPost_Context.Provider>
    );
};

export default BulletinPost_Provider;
