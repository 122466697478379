import * as yup from "yup";
import React, { useCallback, useState } from "react";
import { useMutation } from "react-query";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useErrorContext } from "hooks/useErrorContext";
import StepTracker from "../SetupTracker/step-tracker";
import crib from "api/crib";
import File from "./file";
import storeSession from "utilities/session/sessionInitialIzer";
import BtnLoader from "components/Loader/btn-loader";
import "./style.css";

const inputFields = [
  {
    name: "first_name",
    type: "text",
    placeholder: "First Name*",
  },
  {
    name: "last_name",
    type: "text",
    placeholder: "Last Name*",
  },
  {
    name: "title",
    type: "text",
    placeholder: "Job Title*",
  },
  {
    name: "company",
    type: "text",
    placeholder: "Company*",
  },
  {
    name: "website",
    type: "text",
    placeholder: "www.example.com",
    protocol: "https://",
  },
  {
    name: "twitter_handle",
    type: "text",
    placeholder: "XHandle",
    protocol: "https://www.x.com/",
  },
  {
    name: "linkedin",
    type: "text",
    placeholder: "LinkedinUsername",
    protocol: "https://www.linkedin.com/in/",
  },
  {
    name: "instagram_handle",
    type: "text",
    placeholder: "InstagramHandle",
    protocol: "https://www.instagram.com/",
  },
];

const schema = yup.object({
  first_name: yup
    .string()
    .min(2, "First name is required")
    .required("First name is required"),
  last_name: yup
    .string()
    .min(2, "Last name is required")
    .required("Last name is required"),
  title: yup
    .string()
    .min(2, "Job title is required")
    .required("Job title is required"),
  company: yup
    .string()
    .min(2, "Company name is required")
    .required("Company name is required"),
  website: yup.string().when({
    is: (val) => val.length > 0,
    then: () =>
      yup
        .string()
        .matches(
          /^(?!https?:\/\/)(www\.)[a-zA-Z0-9]{2,}\.[a-zA-Z]{2,}$/i,
          "Website must be a valid URL"
        )
        .required("Website is required"),
  }),
  twitter_handle: yup.string().when({
    is: (val) => val.length > 0,
    then: () =>
      yup
        .string()
        .matches(/^[a-zA-Z0-9_]{3,15}$/i, "Please enter a valid handle")
        .required("X handle is required"),
  }),
  linkedin: yup.string().when({
    is: (val) => val.length > 0,
    then: () =>
      yup
        .string()
        .matches(
          /^[a-zA-Z0-9-]{3,30}(\/[a-zA-Z0-9-]{1,})?$/i,
          "Please enter a valid profile"
        )
        .required("LinkedIn username is required"),
  }),
  instagram_handle: yup.string().when({
    is: (val) => val.length > 0,
    then: () =>
      yup
        .string()
        .matches(/^[a-zA-Z0-9_.]{3,30}$/i, "Please enter a valid handle")
        .required("Instagram handle is required"),
  }),
});

const SetupStep1 = ({
  currentStep,
  setCurrentStep,
  session,
  isLoading,
  userData,
  setPayload,
}) => {
  const triggerAlert = useErrorContext();
  const [file, setFile] = useState([]);

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    values: {
      first_name: session?.firstname,
      last_name: session?.lastname,
      title: userData?.job_title,
      company: userData?.workplace,
      website: userData?.website_link || "",
      twitter_handle: userData?.twitter || "",
      linkedin: userData?.linkedin || "",
      instagram_handle: userData?.instagram || "",
    },
    resolver: yupResolver(schema),
  });

  const onDrop = useCallback((acceptedFiles) => {
    const allowedExtensions = /(\.png|\.jpeg|\.jpg)$/i;
    if (acceptedFiles.length === 1) {
      // Checking file type
      if (!allowedExtensions.exec(acceptedFiles[0].path)) {
        triggerAlert({
          open: true,
          message: "Unsupported file format",
          type: "error",
        });
      } else {
        setFile(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      }
    } else {
      triggerAlert({
        open: true,
        message: "You can only upload one file",
        type: "error",
      });
    }
  });

  const cribSetupMutation = useMutation(
    (formData) => crib.setupUserCrib(formData),
    {
      onError: (error) => {
        triggerAlert({
          open: true,
          message:
            error?.response?.data?.error ||
            "There was an error, please try again later",
          type: "error",
        });
      },
      onSuccess: (response) => {
        if (response?.status === "success") {
          storeSession(response?.data?.session);
          setPayload(response?.data?.payload);
          setCurrentStep("step2");
        } else {
          triggerAlert({
            open: true,
            message: "Something went wrong, please try again later",
            type: "error",
          });
        }
      },
    }
  );

  const onSubmit = (data) => {
    const formData = new FormData();
    const payload = {
      ...data,
      file: file[0],
    };
    Object.keys(payload).forEach((key) => formData.append(key, payload[key]));
    cribSetupMutation.mutate(formData);
  };

  return (
    <div className="setup-one">
      <div className="setup-one__container">
        <div className="setup-one__header">
          <h2 className="setup-one__heading">Let's setup your Crib!</h2>
          <p className="setup-one__sub">
            In 2 short steps, you will have your Crib complete. First, let's get
            your profile started.
          </p>
        </div>
        <StepTracker currentStep={currentStep} />
        <form onSubmit={handleSubmit(onSubmit)} className="setup-one__form">
          <div className="setup-one__form--container">
            {inputFields.map(({ name, type, placeholder, protocol }) => {
              if (protocol) {
                return (
                  <Controller
                    key={name}
                    name={name}
                    control={control}
                    render={({ field }) => (
                      <div className="setup-one__form--control">
                        <div className="setup-one__form-input--container">
                          <p className="protocol-name">{protocol}</p>
                          <input
                            {...field}
                            type={type}
                            placeholder={placeholder}
                            value={field.value || ""}
                            className="setup-one__input"
                          />
                        </div>
                        <p className="setup-one__form--error">
                          {errors[name]?.message}
                        </p>
                      </div>
                    )}
                  />
                );
              }
              return (
                <Controller
                  key={name}
                  name={name}
                  control={control}
                  render={({ field }) => (
                    <div className="setup-one__form--control">
                      <input
                        {...field}
                        type={type}
                        placeholder={placeholder}
                        value={field.value || ""}
                        className="setup-one__input"
                      />
                      <p className="setup-one__form--error">
                        {errors[name]?.message}
                      </p>
                    </div>
                  )}
                />
              );
            })}
          </div>
          <File
            file={file}
            onDrop={onDrop}
            setFile={setFile}
            isLoading={isLoading}
            userData={userData}
          />
          <button
            className="setup-one__btn"
            disabled={cribSetupMutation.isLoading}
          >
            {cribSetupMutation?.isLoading ? (
              <BtnLoader
                border_bottom={"4px solid #FFFFFF"}
                border_right={"4px solid #FFFFFF"}
                border_left={"4px solid #838383"}
                border_top={"4px solid #838383"}
              />
            ) : (
              "Continue"
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default SetupStep1;
