import React from "react";

const PurpleCheckMarkCircle = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 13.75C10.9518 13.75 13.75 10.9518 13.75 7.5C13.75 4.04822 10.9518 1.25 7.5 1.25C4.04822 1.25 1.25 4.04822 1.25 7.5C1.25 10.9518 4.04822 13.75 7.5 13.75Z"
        fill="#461C3F"
      />
      <path
        d="M7.02846 9.59054C6.84846 9.59054 6.66846 9.52179 6.53127 9.3846L4.63033 7.48335C4.35564 7.20897 4.35564 6.76366 4.63033 6.48897C4.90471 6.21429 5.35033 6.21429 5.62471 6.48897L7.52565 8.39022C7.80033 8.6646 7.80033 9.10991 7.52565 9.3846C7.38846 9.52179 7.20846 9.59054 7.02846 9.59054Z"
        fill="white"
      />
      <path
        d="M7.0284 9.59064C6.8484 9.59064 6.6684 9.52189 6.53121 9.3847C6.25652 9.11033 6.25652 8.66501 6.53121 8.39032L9.51215 5.40938C9.78653 5.1347 10.2322 5.1347 10.5065 5.40938C10.7812 5.68376 10.7812 6.12907 10.5065 6.40376L7.52559 9.3847C7.3884 9.52189 7.2084 9.59064 7.0284 9.59064Z"
        fill="white"
      />
    </svg>
  );
};

export default PurpleCheckMarkCircle;
