import axios from "axios";
import urls from "./baseUrl";
import sessionInfo from "../utilities/session/sessionInfo";

let { token } = sessionInfo();
const officeHour = {
  AddOfficeHour: async (officeHour) => {
    const url = `${urls.office_hour_url}/OfficeHour/AddOH`;
    try {
      const resp = await axios.post(url, officeHour, {
        headers: {
          Authorization: `Bearer: ${token}`,
        },
      });
      return resp.data;
    } catch (error) {
      return error;
    }
  },
  getOfficeHoursByName: async (data) => {
    const url = `${urls.office_hour_url}/OfficeHour/ScheduledOH/${data}`;
    try {
      const resp = await axios.get(url, {
        headers: {
          Authorization: `Bearer: ${token}`,
        },
      });
      return resp;
    } catch (e) {
      return e;
    }
  },
  AttendOfficeHour: async (data) => {
    const url = `${urls.office_hour_url}/OfficeHour/SignUpOH`;
    try {
      const resp = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer: ${token}`,
        },
      });
      return resp;
    } catch (e) {
      return e;
    }
  },
  getAttendOfficeHours: async (data) => {
    const url = `${urls.office_hour_url}/OfficeHour/Attending/${data}`;
    try {
      const resp = await axios.get(url, {
        headers: {
          Authorization: `Bearer: ${token}`,
        },
      });
      return resp;
    } catch (e) {
      return e;
    }
  },
  SaveNote: async (Notes, Title, DateofOfficeHour) => {
    const url = `${urls.office_hour_url}/OfficeHour/`;
    const data = {
      rCategory: DateofOfficeHour,
      rTitle: Title,
      Upload: Notes,
    };
    try {
      const resp = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer: ${token}`,
        },
      });
      return resp.data;
    } catch (error) {
      return error;
    }
  },
  getTwilioCredentials: async (office_hour_id) => {
    const url = `${urls.office_hour_url}/video/token/{office_hour_id}`;
    try {
      const resp = await axios(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer:${token}`,
          "Content-Type": "application/json",
        },
      });
      return resp;
    } catch (error) {
      return error;
    }
  },
  getOfficeHourByNeighborhood: async (neighborhood_id) => {
    const url = `${urls.office_hour_url}/OfficeHour/${neighborhood_id}`;
    try {
      const resp = await axios.get(url, {
        headers: {
          Authorization: `Bearer: ${token}`,
        },
      });
      return resp.data;
    } catch (error) {
      return error;
    }
  },
  getOfficeHoursByCategory: async (data) => {
    const url = `${urls.office_hour_url}/OfficeHour/${data.neighborhood_id}/${data.category}`;
    try {
      const resp = await axios.get(url, {
        headers: {
          Authorization: `Bearer: ${token}`,
        },
      });
      return resp.data;
    } catch (error) {
      return error;
    }
  },
  getOfficeHours: async () => {
    const url = `${urls.office_hour_url}/OfficeHour`;
    try {
      const resp = await axios.get(url, {
        headers: {
          Authorization: `Bearer: ${token}`,
        },
      });
      return resp.data;
    } catch (error) {
      return error;
    }
  },
  getOfficeHourById: async (data) => {
    const url = `${urls.office_hour_url}/OfficeHour/ViewOH/${data}`;
    try {
      const resp = await axios.get(url, {
        headers: {
          Authorization: `Bearer: ${token}`,
        },
      });
      return resp.data;
    } catch (error) {
      return error;
    }
  },
  saveClientele: async (data) => {
    const url = `${urls.base_url}crib/clientele`;
    try {
      const resp = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer: ${token}`,
        },
      });
      return resp.data;
    } catch (error) {
      console.log({ error });
      return error;
    }
  },
  async joinOfficeHourCall(officehour_id) {
    const url = `${urls.office_hour_url}/officehour/${officehour_id}/join`;
    try {
      const resp = await axios.get(url, {
        headers: {
          Authorization: `Bearer: ${token}`,
        },
      });
      return resp.data;
    } catch (error) {
      throw error;
    }
  },
  async getOneOfficeHourById(officehour_id, attended_id) {
    const url = `${urls.office_hour_url}/officehour/${officehour_id}/${attended_id}`;
    try {
      const resp = await axios.get(url, {
        headers: {
          Authorization: `Bearer: ${token}`,
        },
      });
      return resp.data;
    } catch (error) {
      throw error;
    }
  },
  async setUpOfficeHour(data) {
    const url = `${urls.office_hour_url}officehour/setup`;
    try {
      const resp = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer: ${token}`,
        },
      });
      return resp.data;
    } catch (error) {
      console.log({ error });
      return error;
    }
  },
  async getExisitingOfficeHours() {
    const url = `${urls.office_hour_url}officehours/crib`;
    try {
      const resp = await axios.get(url, {
        headers: {
          Authorization: `Bearer: ${token}`,
        },
      });
      return resp.data;
    } catch (error) {
      throw error;
    }
  },
  async getOfficeHourDetailsById(id) {
    const url = `${urls.office_hour_url}officehours/crib/${id}`;
    try {
      const resp = await axios.get(url, {
        headers: {
          Authorization: `Bearer: ${token}`,
        },
      });
      return resp.data;
    } catch (error) {
      throw error;
    }
  },
  async updateOfficeHour(data, id) {
    const url = `${urls.office_hour_url}officehour/${id}`;
    try {
      const resp = await axios.patch(url, data, {
        headers: {
          Authorization: `Bearer: ${token}`,
        },
      });
      return resp.data;
    } catch (error) {
      throw error;
    }
  },
  async getFeaturedOfficeHours(neighborhood_id) {
    const url = `${urls.office_hour_url}/neighborhood/${neighborhood_id}/officehours/featured`;
    try {
      const resp = await axios.get(url, {
        headers: {
          Authorization: `Bearer: ${token}`,
        },
      });
      return resp.data;
    } catch (error) {
      throw error;
    }
  },
  async getFeaturedOfficeHourDetails(neighborhood_id, officehour_id) {
    const url = `${urls.office_hour_url}/neighborhood/${neighborhood_id}/officehours/${officehour_id}/featured`;
    try {
      const resp = await axios.get(url, {
        headers: {
          Authorization: `Bearer: ${token}`,
        },
      });
      return resp.data;
    } catch (error) {
      throw error;
    }
  },
  async getFeaturedOfficeHourDates(
    neighborhood_id,
    officehour_id,
    date_from,
    date_to
  ) {
    const url = `${urls.office_hour_url}neighborhood/${neighborhood_id}/officehours/${officehour_id}/featured/dates?date_from=${date_from}&date_to=${date_to}`;
    try {
      const resp = await axios.get(url, {
        headers: {
          Authorization: `Bearer: ${token}`,
        },
      });
      return resp.data;
    } catch (error) {
      throw error;
    }
  },
  async bookOfficeHour(
    neighborhood_id,
    officehour_id,
    available_time_id,
    data
  ) {
    const url = `${urls.office_hour_url}/neighborhood/${neighborhood_id}/officehours/${officehour_id}/featured/booking/${available_time_id}`;
    try {
      const resp = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer: ${token}`,
        },
      });
      return resp.data;
    } catch (error) {
      console.log(error);
      return error;
    }
  },
  async getBookedOfficeHours(page, limit) {
    const url = `${urls.office_hour_url}/officehours/bookings/gridview/calendar?page=${page}&limit=${limit}`;
    try {
      const resp = await axios.get(url, {
        headers: {
          Authorization: `Bearer: ${token}`,
        },
      });
      return resp.data;
    } catch (error) {
      console.log(error);
      return error;
    }
  },
  async viewOfficeHourDetails(officehour_id, available_time_id) {
    const url = `${urls.office_hour_url}officehours/${officehour_id}/booking/${available_time_id}`;
    try {
      const resp = await axios.get(url, {
        headers: {
          Authorization: `Bearer: ${token}`,
        },
      });
      return resp.data;
    } catch (error) {
      throw error;
    }
  },
  async joinOfficeHourVideoCall(officehour_id, booking_id) {
    const url = `${urls.office_hour_url}officehours/${officehour_id}/booking/${booking_id}/join/twilio/video`;
    try {
      const resp = await axios.get(url, {
        headers: {
          Authorization: `Bearer: ${token}`,
        },
      });
      return resp.data;
    } catch (error) {
      throw error;
    }
  },
};

export default officeHour;
