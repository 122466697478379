import React from "react";
import { useDropzone } from "react-dropzone";
import InfoIcon from "../../../assets/svgs/infoIcon";
import "./import-members.css";

const ImportMembersFile = ({ onClose, onDrop }) => {
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    maxFiles: 1,
    multiple: false,
    noClick: true,
    noKeyboard: true,
  });

  return (
    <>
      <button className="import-members__cta" onClick={onClose}>
        Back
      </button>
      <div {...getRootProps({ className: "import-members__wrapper" })}>
        <input {...getInputProps()} />
        <div className={isDragActive ? "import-members__drag-active" : ""}>
          <h2 className="import-members__heading">Drop files to upload</h2>
          <p className="import-members__text">or</p>
          <button className="import-members__btn" type="button" onClick={open}>
            Select files
          </button>
          <p className="import-members__format">
            Supported formats: .xlsx, .csv
          </p>
        </div>
      </div>
      <div className="import-members__info">
        <InfoIcon className="import-members__info--svg" />
        <p className="import-members__info--text">
          <span className="import-members__info--span">NOTE: &nbsp;</span>
          Upload lists with email addresses (required) and names (optional)
        </p>
      </div>
    </>
  );
};

export default ImportMembersFile;
