import React, { useState } from 'react';
import { SandiContainer } from './SandiChatbot.styles';
import { useOutsideClick } from 'hooks';
import { useQuery } from 'react-query';
import { MessageListQuery } from './MessageList/MessageList.query';
import { useSandiChatbotDisplay } from './utils/useSandiChatbotDisplay';
import { SandiHeader } from './SandiHeader/SandiHeader';
import * as testId from 'utilities/testIds';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { freeTextSchema } from './utils/createFreeTextForm';
import { getBotAccess } from './utils/getBotAccess';
import { getSandiCheckpoint } from 'api/sandibot/getSandiCheckpoint';

export function SandiChatbot() {
  const canSeeBot = getBotAccess();
  const form = useForm({
    defaultValues: {
      content: '',
    },
    mode: 'onChange',
    resolver: yupResolver(freeTextSchema),
  });

  const {
    formState: { isDirty },
  } = form;

  const [isHovered, setIsHovered] = useState(false);

  const chatsQuery = useQuery({
    queryKey: ['sandibotChats'],
    queryFn: getSandiCheckpoint,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: false,
  });
  const { isLoading, isError } = chatsQuery;
  const { botSize, setBotSize, toggleBotSize } = useSandiChatbotDisplay(
    isDirty,
    isHovered
  );
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const sandiBotRef = useOutsideClick(() => canSeeBot && setBotSize('min'));

  return (
    <FormProvider {...form}>
      <SandiContainer
        ref={sandiBotRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        data-bot-height={botSize}
        data-testid={testId.sandiChatbot.botContainer}
      >
        <SandiHeader botSize={botSize} toggleBotSize={toggleBotSize} />
        <MessageListQuery
          contentSize={botSize}
          isLoading={isLoading}
          isError={isError}
          chatsQuery={chatsQuery}
          isFocused={isHovered}
        />
      </SandiContainer>
    </FormProvider>
  );
}
