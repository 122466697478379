import React from "react";
import NavBar from "../components/RialtoNavBar";
import { Container, Content } from "./AppLayout.styles";
import { SandiChatbot } from "components";

export function AppLayout(props) {
  const { children } = props;

  return (
    <Container>
      <NavBar />
      <Content>{children}</Content>
      <SandiChatbot />
    </Container>
  );
}
