import React from 'react';
import { MessageTypingIndicator } from '../MessageTypingIndicator/MessageTypingIndicator';
import { MessageListRaw } from './MessageList.raw';
import { FlexMessageBox } from './MessageList.styles';
import BotGraphic from 'assets/svgs/bot-graphic.svg';
import { MessageListLayout } from '../MessageListLayout/MessageListLayout';

export function MessageListQuery(props) {
  const { chatsQuery, contentSize, isLoading, isError } = props;

  const {
    isLoading: chatsQueryLoading,
    isError: chatsQueryError,
    isIdle,
  } = chatsQuery;

  if ((isLoading && contentSize === 'max') || chatsQueryLoading || isIdle) {
    return (
      <MessageListLayout>
        <FlexMessageBox>
          <img src={BotGraphic} />
          <MessageTypingIndicator />
        </FlexMessageBox>
      </MessageListLayout>
    );
  }

  if (isError || chatsQueryError) {
    return <MessageListLayout>Error loading chats</MessageListLayout>;
  }

  if (
    chatsQuery.data?.chats?.length === 0 &&
    chatsQuery.data?.nextConversations?.length === 0
  ) {
    return <MessageListLayout>Empty chat</MessageListLayout>;
  }

  const { data } = chatsQuery;

  return <MessageListRaw records={data} contentSize={contentSize} />;
}
