import React from "react";
import NavBar from "../components/RialtoNavBar";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import { TextField, FormControl, Select, InputLabel } from "@material-ui/core";
import HiddenURLLink from "../components/HiddenURLLink";
import StepTrackerCard from "../components/StepTrackerCard";
import SingleFileUploadAutoSubmit from "../components/SingleFileUploadAutoSubmit";
import SnackbarAlert from "../components/SnackbarAlert";
// import Onboard from '../api/crib.js';
import ArrowForward from "../assets/svgs/ArrowForward.jsx";
import ArrowBackward from "../assets/svgs/ArrowBackward.jsx";
import "../styles/components/StepContents.css";
import "../styles/pages/OnboardingScreen.css";

export default class SetupCribStep3 extends React.Component {
  constructor(props) {
    super(props);

    const step = "step3";
    const form = this.props.hasOwnProperty("step3")
      ? this.props
      : this.props.form;
    this.state = {
      form,
      step,
      current: form[`${step}`],
      Snackbar: {
        message: "",
        severity: "success", // info : warning : success : error
      },
      Upload: "",
      Error: {
        Description: false,
        Name: false,
        CompanyWebsite: false,
        CompanyName: false,
      },
    };
    console.log("this.state ::::::: ");
    console.log(this.state);

    this.handleChange = this.handleChange.bind(this);
    this.updateForm = this.updateForm.bind(this);
    this.getImage = this.getImage.bind(this);
    this.setDataURL = this.setDataURL.bind(this);
    this.toggleImageWrapper = this.toggleImageWrapper.bind(this);
    this.clearImage = this.clearImage.bind(this);
    this.Next = this.Next.bind(this);
    this.Back = this.Back.bind(this);
  }

  componentDidMount() {
    const image = this.state.current.Upload;
    this.setDataURL(image);
    window.onbeforeunload = () => "promptBeforeUnload";
  }

  handleChange(e) {
    const newState = this.state;
    const newForm = newState.current;
    const fieldName = e.target.name;
    const value = e.target.value;
    var helperText = "";

    if (value.trim() === "") {
      helperText = this.state.helperTexts[`${fieldName}`];
      newState.helperTexts[`${fieldName}`] = helperText;
      newState.Error[`${fieldName}`] = true;
    }

    newForm[`${fieldName}`] = value;
    newState.current = newForm;
    this.setState(newState);
  }

  updateForm() {
    const newState = this.state;
    const step = this.state.step;
    newState.form[`${step}`] = this.state.current;
    const snackbar = this.state.Snackbar;
    snackbar.message = "Your input is being saved";
    snackbar.open = true;
    snackbar.severity = "info";
    newState.Snackbar = snackbar;
    this.setState(newState);
    console.log(this.state);
  }

  async getImage(image) {
    const newState = this.state;
    const newForm = newState.current;
    newForm[`Upload`] = image;
    console.log("image");
    console.log(image);
    await this.setDataURL(image);
    newState.current = newForm;
    this.setState(newState);
  }

  setDataURL(file) {
    console.log("reading file");
    if (!file) {
      console.log("couldnt find file");
    } else {
      console.log("found file");
      const prevState = this.state;
      const stateObj = this;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        prevState[`Upload`] = reader.result;
        stateObj.setState(prevState);
        stateObj.toggleImageWrapper();
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    }
  }

  /**
   * toggle ImageWrapper/Dropzone container visibility
   */

  toggleImageWrapper = () => {
    document.querySelector(".UploadWrapper").classList.toggle("hide");
    document.querySelector(".ImageWrapper").classList.toggle("hide");
  };

  /**
   * Removes uploaded image
   */

  clearImage = () => {
    const prevState = this.state;
    prevState.current.Upload = "";
    prevState[`Upload`] = "";
    const snackbar = this.state.Snackbar;
    snackbar.message = "You have removed saved image";
    snackbar.open = true;
    snackbar.severity = "info";
    prevState.Snackbar = snackbar;
    this.setState(prevState);
    this.toggleImageWrapper();
  };

  Next = () => {
    this.updateForm();
    setTimeout(() => {
      const el = document.querySelectorAll(".URLLink");
      console.log(el);
      el[1].click();
    }, 5000);
  };

  Back = () => {
    const el = document.querySelectorAll(".URLLink");
    el[2].click();
  };

  render() {
    return (
      <div>
        <NavBar />
        <div className="OnboardingContent">
          <div className="ContentWraper">
            <span className="HeaderText">Spread the knowledge</span>
            <p className="Details">Add a resource to share with others.</p>

            <StepTrackerCard
              currentStepIndex={3}
              stepsFor={"SetupCribOwnerAndSME"}
            />

            {/** Current Step Track 
                     Current Form Board */}

            <div className="FormWrapper">
              <FormControl variant="outlined" className="white Field">
                <InputLabel htmlFor="my-input">Category</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={this.state.current.Category}
                  name="Category"
                  onChange={this.handleChange}
                  label="Category"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="1">Marketing Operation</MenuItem>
                  <MenuItem value="2">Mail</MenuItem>
                  <MenuItem value="3">Email</MenuItem>
                </Select>
              </FormControl>
              <TextField
                className="Field"
                variant="outlined"
                name="Title"
                value={this.state.current.Title}
                onChange={this.handleChange}
                label="Title"
              />
              <TextField
                className="MultilineField FullWidth"
                variant="outlined"
                multiline
                name="Description"
                value={this.state.current.Description}
                onChange={this.handleChange}
                rows={5}
                label="Description"
              />
              <div className="DropZone">
                <div className="ImageWrapper hide">
                  <span className="ImgCloseWrapper">
                    <span onClick={this.clearImage} className="CloseIcon">
                      x
                    </span>
                  </span>
                  <img src={this.state.Upload} alt=""></img>
                </div>
                <div className="UploadWrapper">
                  <SingleFileUploadAutoSubmit
                    getImage={this.getImage}
                    text={"Drag Profile Here"}
                  />
                </div>
              </div>
              <Button className="Continue" onClick={() => this.Next()}>
                Continue <ArrowForward className="ArrowForward" />
              </Button>
            </div>
            <SnackbarAlert
              message={this.state.Snackbar.message}
              severity={this.state.Snackbar.severity}
              open={this.state.Snackbar.open}
              onClose={() =>
                this.setState({ ...this.state, Snackbar: { open: false } })
              }
            />
            <HiddenURLLink url={"/setup/crib/step4"} data={this.state.form} />
            <HiddenURLLink url={"/setup/crib/step2"} data={this.state.form} />
            <div className="StepControl">
              <Button className="Back" onClick={() => this.Back()}>
                <ArrowBackward className="ArrowBackward" /> Back
              </Button>
              <Button className="Skip" onClick={() => this.Next()}>
                Skip
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
