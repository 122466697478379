export const COLORS = {
  neutral: {
    n0: "#FFFFFF",
    n1: "#FDFDFD",
    n2: "F5F5F5",
  },
  purple: {
    p1: "#C5A3C9",
    p300: "#784870",
    p400: "#5E2F56",
    p500: "#461C3F",
    p600: "#644167",
  },
  gray: {
    gray02: "#4F4F4F",
    gr1: "#333333",
    gr2: "#f5f5f5",
    gr3: "#828282",
    gr6: "#f2f2f2",
  },
  green: {
    grn1: "#3b7a71",
  },
  gold: {
    go500: "#F3A712",
  },
  blue: {
    b1: "#1F49DF",
  },
  red: {
    rd1: "#EB5757",
  },
};
