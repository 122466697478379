import React from 'react'
import API from "../../../api/bulletin.js"
import store from "../../../utilities/storage.js"

const initialState = {
    bulletin_posts: [],
    get_bulletin_status: 'idle', // idle, in-progress, empty, success, failed
    total_posts: 0,
    has_neighborhood_create_access: false
}

const types = {
    FETCH_BULLETIN_STATUS: "FETCH_BULLETIN_STATUS",
    SET_BULLETIN_POSTS: "SET_BULLETIN_POSTS",
    SET_HAS_NEIGHBORHOOD_ACCESS: "SET_HAS_NEIGHBORHOOD_ACCESS",
    SET_TOTAL_POSTS: "SET_TOTAL_POSTS"
}

const actions = (dispatch) => {
    const getBulletinPosts = async (neighborhood_id, page, limit) => {
        setFetchStatus_InProgress();
        try {
            const res = await API
                .getNeighborhoodBulletinPosts(neighborhood_id, page, limit);

            if (res?.bulletin_posts?.length > 0) {
                setBulletinPosts(res.bulletin_posts);
                setHasNeighborhoodCreateAccess(res.has_neighborhood_create_access);
                setTotalPosts(res.total);
                setFetchStatus_Success();
            } else if (res.bulletin_posts?.length === 0) {
                setFetchStatus_Empty();
            } else {
                setFetchStatus_Failed();
            }
        } catch (error) {
            setFetchStatus_Failed();
            console.error({ error })
        }
    }
    const getBulletinPost = async (bulletin_id, neighborhood_id) => {
        setFetchStatus_InProgress();
        try {
            const bulletin_posts = await API.getNeighborhoodBulletinPost(bulletin_id, neighborhood_id);
            if (bulletin_posts?.length > 0) {
                setFetchStatus_Success();
                console.log(bulletin_posts);
                setBulletinPosts(bulletin_posts);
            } else if (bulletin_posts?.length === 0) {
                setFetchStatus_Empty();
            } else {
                setFetchStatus_Failed();
            }
        } catch (error) {
            setFetchStatus_Failed();
            console.error({ error })
        }
    }
    const setHasNeighborhoodCreateAccess = async (has_neighborhood_create_access) => {
        dispatch({
            type: types.SET_HAS_NEIGHBORHOOD_ACCESS,
            payload: !!has_neighborhood_create_access
        })
    }
    const setBulletinPosts = (bulletin_posts) => {
        dispatch({
            type: types.SET_BULLETIN_POSTS,
            payload: bulletin_posts
        })
    }
    const setFetchStatus_Idle = () => {
        dispatch({
            type: types.FETCH_BULLETIN_STATUS,
            payload: "idle"
        })
    }
    const setFetchStatus_InProgress = () => {
        dispatch({
            type: types.FETCH_BULLETIN_STATUS,
            payload: "in-progress"
        })
    }
    const setFetchStatus_Empty = () => {
        dispatch({
            type: types.FETCH_BULLETIN_STATUS,
            payload: "empty"
        })
    }
    const setFetchStatus_Success = () => {
        dispatch({
            type: types.FETCH_BULLETIN_STATUS,
            payload: "success"
        })
    }
    const setFetchStatus_Failed = () => {
        dispatch({
            type: types.FETCH_BULLETIN_STATUS,
            payload: "failed"
        })
    }
    const setTotalPosts = (total) => {
        dispatch({
            type: types.SET_TOTAL_POSTS,
            payload: total
        })
    }
    return {
        getBulletinPost,
        getBulletinPosts,
        setFetchStatus_Idle,
        setFetchStatus_InProgress,
        setFetchStatus_Empty,
        setFetchStatus_Success,
        setFetchStatus_Failed,
        setHasNeighborhoodCreateAccess,
        setTotalPosts
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case types.FETCH_BULLETIN_STATUS:
            return {
                ...state,
                get_bulletin_status: action.payload
            };
        case types.SET_HAS_NEIGHBORHOOD_ACCESS:
            return {
                ...state,
                has_neighborhood_create_access: action.payload
            }
        case types.SET_BULLETIN_POSTS:
            return {
                ...state,
                bulletin_posts: action.payload
            }
        case types.SET_TOTAL_POSTS:
            return {
                ...state,
                total_posts: action.payload
            }
        default:
            return state;
    }
}

export default {
    initialState,
    reducer, actions
};
