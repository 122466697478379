import React, { useState, useEffect } from "react";
import urls from "../api/baseUrl";
import sessionInfo from "../utilities/session/sessionInfo";
import "../styles/components/DashboardCard.css";
import "../styles/components/LibraryCard.css";
import DotGreen from "../assets/Icons/DotGreen.svg";
import BgPattern from "../assets/Roots/RIALTO-ROOT-Pattern 4_Root Pattern 3 - black.png";
import { Button } from "@material-ui/core";
import store from "../utilities/storage";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import ResourcePopup from "../components/CouncilModal/ResourcePopup";
import getNeighborhoodAccessRight from "../utilities/getNeighborhoodAccessRight";
import isEmpty from "../utilities/isEmpty";

const invite_link = store.retrieve("invite_link");
const { token } = sessionInfo();

var patternStyle = {
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundImage: "url(" + { BgPattern } + ")",
};

export default function LibraryCard({ content, activeTour, setActiveTour }) {
  const { neighborhood_id } = useParams("neighborhood_id");

  const session_access = getNeighborhoodAccessRight(neighborhood_id);

  const [getmember, setGetMember] = useState([]);

  const [accessmember, setAccessMember] = useState([]);

  if (session_access !== accessmember) {
    const newPriviledges = JSON.parse(
      store.retrieve("neighborhood_privileges")
    );
    if (newPriviledges?.length > 0) {
      newPriviledges.forEach((item) => {
        if (item.neighborhood_id === parseInt(neighborhood_id, 10)) {
          item.access_level = accessmember;
        }
      });
    }
  }
  const neighborhood_name = store.retrieve("neighborhood_name");

  const getMembersRank = () => {
    const url = `${urls.base_url}neighborhood/${neighborhood_id}/get_members_rank`;
    fetch(url, {
      method: "GET",
      headers: { Authorization: `Bearer: ${token}` },
    })
      .then((response) => response.json())

      .then(({ data }) => {
        if (!isEmpty(data?.acknowledge_access)) {
          setGetMember(data.acknowledge_access);
          setAccessMember(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getMembersRank();
  }, []);

  return (
    <div className="Card LibraryCard" id="library">
      <div className="Header">
        <span className="Name">Library</span>
        <Button className="ViewAll">SEE ALL RESOURCES</Button>
      </div>
      {getmember && getmember.resources === false ? (
        <ResourcePopup neighborhoodname={neighborhood_name} />
      ) : (
        ""
      )}

      <div className="MaskWrapper">
        <div className="OfficeHours ComingSoon Mask" style={patternStyle}>
          {/* <Link
            style={{ textAlign: "center" }}
            to={`/neighborhood/${neighborhood_id}/library/office-hours`}
          >
            <span className="Text">Office Hours</span>
          </Link> */}
          <span className="Text">Office Hours</span>
          <span className="Overlay">Coming Soon</span>
        </div>
        <div
          className="Resources Mask"
          style={{ ...patternStyle, backgroundColor: "#461C3F" }}
        >
          <Link
            style={{ textAlign: "center" }}
            to={`/neighborhood/${neighborhood_id}/library/resources`}
          >
            <span className="Text">Resources</span>
          </Link>
        </div>
        <div className="Events ComingSoon Mask" style={patternStyle}>
          <span className="Text">Events</span>
          <span className="Overlay">Coming Soon</span>
        </div>
        <div className="Services ComingSoon Mask" style={patternStyle}>
          <span className="Text">Services</span>
          <span className="Overlay">Coming Soon</span>
        </div>
      </div>
      <div className="ToolTip">
        <div
          className="Tip"
          style={activeTour === "library" ? { "": "" } : { display: "none" }}
        >
          <span className="data">{content}</span>
          <div className="controls">
            <a href={invite_link}>
              {" "}
              <button className="Create">Create Account</button>{" "}
            </a>{" "}
            <span className="Navigate">
              <a href="#forum" onClick={() => setActiveTour("forum")}>
                BACK
              </a>
              <img src={DotGreen} alt="Green Dot" />
              <a href="#members" onClick={() => setActiveTour("members")}>
                NEXT
              </a>
            </span>
          </div>
          <span className="Edge">&nbsp;</span>
        </div>
      </div>
    </div>
  );
}
