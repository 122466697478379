import React from "react";
import NavBar from "../components/RialtoNavBar";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
/* eslint-disable-next-line no-unused-vars */
import {
  TextField,
  FormControl,
  Select,
  FormHelperText,
  InputLabel,
  Input,
} from "@material-ui/core";
import HiddenURLLink from "../components/HiddenURLLink";
import StepTrackerCard from "../components/StepTrackerCard";
import SnackbarAlert from "../components/SnackbarAlert";
// import SingleFileUploadAutoSubmit from '../components/SingleFileUploadAutoSubmit';
// import Onboard from '../api/crib.js';
import ArrowForward from "../assets/svgs/ArrowForward.jsx";
import ArrowBackward from "../assets/svgs/ArrowBackward.jsx";
import "../styles/components/StepContents.css";
import "../styles/pages/OnboardingScreen.css";
import LogoBar from "../components/NeighborhoodLogoBanner/NeighborhoodLogoBar";

export default class OnboardingNeighborhoodprofileStep4 extends React.Component {
  constructor(props) {
    super(props);

    const step = "step3";
    this.state = {
      form: this.props.form,
      current: this.props.form[`${step}`],
      step: step,
      Snackbar: {
        message: "",
        severity: "success", // info : warning : success : error
      },
    };

    this.handleChange = this.handleChange.bind(this);
    this.updateForm = this.updateForm.bind(this);
    this.Next = this.Next.bind(this);
    this.Back = this.Back.bind(this);
  }

  componentDidMount() {
    window.onbeforeunload = () => "promptBeforeUnload";
  }

  handleChange = (e) => {
    const newState = this.state;
    const newForm = newState.current;
    const fieldName = e.target.name;
    const value = e.target.value;

    newForm[`${fieldName}`] = value;
    newState.current = newForm;
    this.setState(newState);
  };

  updateForm() {
    const newState = this.state;
    const step = this.state.step;
    newState.form[`${step}`] = this.state.current;
    const snackbar = this.state.Snackbar;
    snackbar.message = "Your input has been saved";
    snackbar.open = true;
    snackbar.severity = "info";
    newState.Snackbar = snackbar;
    this.setState(newState);
  }

  Next = () => {
    const el = document.querySelectorAll(".URLLink");
    this.updateForm();
    setTimeout(() => {
      console.log(el);
      el[1].click();
    }, 2000);
  };

  Back = () => {
    const el = document.querySelectorAll(".URLLink");
    el[2].click();
  };

  render() {
    return (
      <div>
        <LogoBar />
        <NavBar />
        <div className="OnboardingContent">
          <div className="ContentWraper">
            <span className="HeaderText">Setup Your Bulletin Board </span>
            <span className="Details">
              Tip: Use the Bulletin Board to share Neighborhood- wide
              announcements to your members!
            </span>
            <StepTrackerCard
              currentStepIndex={5}
              stepsFor={"OnboardingNeighborhoodprofile"}
            />
            {/** Current Step Track 

                     Current Form Board */}

            <div className="FormWrapper">
              <FormControl variant="outlined" className="white Field">
                <InputLabel htmlFor="my-input">Select Category</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={this.state.current.Category}
                  name="Category"
                  onChange={this.handleChange}
                  label="Category"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="1">Marketing Operation</MenuItem>
                  <MenuItem value="2">Mail</MenuItem>
                  <MenuItem value="3">Email</MenuItem>
                </Select>
              </FormControl>
              <TextField
                className="Field"
                variant="outlined"
                name="EventName"
                value={this.state.current.EventName}
                onChange={this.handleChange}
                label="Title"
              />
              <TextField
                className="MultilineField FullWidth"
                variant="outlined"
                multiline
                name="Message"
                value={this.state.current.Message}
                onChange={this.handleChange}
                rows={3}
                label="Message"
              />

              <Button className="Continue" onClick={() => this.Next()}>
                Continue <ArrowForward className="ArrowForward" />
              </Button>
            </div>
            <SnackbarAlert
              message={this.state.Snackbar.message}
              severity={this.state.Snackbar.severity}
              open={this.state.Snackbar.open}
              onClose={() =>
                this.setState({ ...this.state, Snackbar: { open: false } })
              }
            />
            <HiddenURLLink
              url={"/setup/neighborhoodprofile/step4"}
              data={this.state.form}
            />
            <HiddenURLLink
              url={"/setup/neighborhoodprofile/step2"}
              data={this.state.form}
            />
            <div className="StepControl">
              <Button className="Back" onClick={() => this.Back()}>
                <ArrowBackward className="ArrowBackward" /> Back
              </Button>
              <Button className="Skip" onClick={() => this.Next()}>
                Skip
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
