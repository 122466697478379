const CribsProfileBanner = {
    Name: '', 
    Description: '', 
    Link: '',
    Facebook: '',
    Instagram: '',
    Twitter: ''
}

export default CribsProfileBanner
