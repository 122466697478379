import React from "react";
import { useParams } from "react-router-dom";
import { appLogo } from "config";
import { ReactComponent as SignInRoot } from "../assets/Icons/CreateAccountRoot.svg";
import CityLabLogo from "assets/logos/CLP_logo.png";
import MemberUploadSuccess from "../assets/Icons/MemberUploadSuccess.svg";
import "../styles/pages/ForgotPasswordConfirmation.css";

const ForgotPasswordConfirmation = () => {
  const { customRoute } = useParams();
  return (
    <div className="confirmation">
      <div className="confirmation__banner">
        <div className="confirmation__banner--container">
          <div className="confirmation__banner--content">
            <div className="confirmation__logo--container">
              {customRoute ? (
                <img
                  src={CityLabLogo}
                  className="custom_logo"
                  alt="CityLab Logo"
                />
              ) : (
                <img
                  src={appLogo.desktop}
                  className="RialtoLogo"
                  alt="Rialto Logo"
                />
              )}
            </div>
            <div className="confirmation__banner--info">
              <span className="confirmation__banner--title">
                Check your mail
              </span>
              <span className="confirmation__banner--sub">
                A link has been sent to your email for you to recover your
                account.
              </span>
              {customRoute ? (
                <div className="powered_by">
                  <p className="powered_by_text">Powered by</p>
                  <img
                    src={appLogo.desktop}
                    className="powered_by_logo"
                    alt="Rialto Logo"
                  />
                </div>
              ) : null}
            </div>
          </div>
          <SignInRoot className="SignInRoot" />
        </div>
      </div>
      <div className="confirmation__flex">
        <div className="confirmation__container">
          <h2 className="confirmation__heading">Link sent!</h2>
          <div className="confirmation__icon--container">
            <img
              src={MemberUploadSuccess}
              className="confirmation__icon"
              alt="tick icon"
            />
          </div>
          <h3 className="confirmation__subheading">
            Password reset link sent! Please check your email.
          </h3>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordConfirmation;
