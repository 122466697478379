/**
  * check input validity
  *     - rules : required, minLenght, isText, isEmail
  * @param {String} value 
  * @param {Object} rule
  * @returns 
*/
const inputValidator = (value, rules) => {
    let isValid=true
    if(rules.required || !rules.required){
       isValid = value.trim() !=="" && isValid
    }
    if(rules.minLength){
       isValid = value.length>= rules.minLength && isValid
    }
    if(rules.isText){
       const textPattern = /^[a-zA-Z, ]+$/
       isValid = textPattern.test(value) && isValid
    }
    if (rules.isEmail) {
       const pattern = /[a-z0-9!#$%&'/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
       isValid = pattern.test(value) && isValid
    }
    return isValid
}

export default inputValidator ;
