import OfficeHour from "./OfficeHour.js";
import Neighborhood from "./neighborhood.js";
import Resource from "./resource.js";
import Crib from "./crib.js";
import CreateAccount from "./Account.js";

const index = {
  OfficeHour,
  Neighborhood,
  Resource,
  Crib,
  CreateAccount,
};

export default index;
