import React from "react";
import Close from "../../../assets/svgs/close";
import "./invite-members-popup.css";

const InviteMembersPopup = ({ onClose, children }) => {
  return (
    <>
      <div className="invite-members-popup">
        <div>
          <button className="invite-members-popup__cta" onClick={onClose}>
            <Close className="invite-members-popup__cta--svg" />
          </button>
          <h2 className="invite-members-popup__heading">
            Invite members to your Neighborhood
          </h2>
          <div className="invite-members-popup__box">{children}</div>
        </div>
      </div>
    </>
  );
};

export default InviteMembersPopup;
