import React from "react";
import NavBar from "../components/RialtoNavBar";
import Button from "@material-ui/core/Button";
import HiddenURLLink from "../components/HiddenURLLink";
import StepTrackerCard from "../components/StepTrackerCard";
import SnackbarAlert from "../components/SnackbarAlert";
import ArrowForward from "../assets/svgs/ArrowForward.jsx";
import ArrowBackward from "../assets/svgs/ArrowBackward.jsx";
import "../styles/components/StepContents.css";
import "../styles/pages/OnboardingScreen.css";
import { ReactComponent as ClearIcon } from "../assets/Icons/ClearIcon.svg";
import { ReactComponent as InfoIcon } from "../assets/Icons/InfoIcon.svg";
import { ReactComponent as ResourcesIcon } from "../assets/Icons/ResourcesIcon.svg";
import { ReactComponent as TimeIcon } from "../assets/Icons/TimeIcon.svg";
import { ReactComponent as ServicesIcon } from "../assets/Icons/ServicesIcon.svg";
import { ReactComponent as EventsIcon } from "../assets/Icons/EventsIcon.svg";
import LogoBar from "../components/NeighborhoodLogoBanner/NeighborhoodLogoBar";

const LibraryInfoCard = ({ InfoActive, handleClick }) => {
  return (
    <span className={InfoActive ? "Info Active" : "Info"}>
      <ClearIcon onClick={handleClick} />
      <div className="InfoWrapper">
        <TimeIcon />
        <span className="InfoText">
          <span className="Header"> Office Hours </span>
          <span className="Content">
            Host and attend sessions with other entrepreneurs to share/gain
            insights and tools for business and entrepreneurship
          </span>
        </span>
      </div>
      <div className="InfoWrapper">
        <ResourcesIcon />
        <span className="InfoText">
          <span className="Header"> Resources </span>
          <span className="Content">
            Share & get valuable resources such as books, videos and more to
            gain practical insights for career and entrepreneurial success.
          </span>
        </span>
      </div>
      <div className="InfoWrapper">
        <EventsIcon />
        <span className="InfoText">
          <span className="Header"> Events </span>
          <span className="Content">
            Host and attend events, webinars, conferences & more to grow your
            network and reach your business goals faster
          </span>
        </span>
      </div>
      <div className="InfoWrapper">
        <ServicesIcon />
        <span className="InfoText">
          <span className="Header"> Services </span>
          <span className="Content">
            Offer your skills & expertise to other members of the community and
            get paid for your services in a conducive, hassle-free manner
          </span>
        </span>
      </div>
    </span>
  );
};

export default class OnboardingNeighborhoodprofileStep4 extends React.Component {
  constructor(props) {
    super(props);

    const step = "step2";
    this.state = {
      form: this.props.form,
      current: this.props.form[`${step}`],
      step: step,
      Snackbar: {
        message: "",
        severity: "success", // info : warning : success : error
      },
      InfoActive: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.updateForm = this.updateForm.bind(this);
    this.Next = this.Next.bind(this);
    this.Back = this.Back.bind(this);
    this.toggleInfoIcon = this.toggleInfoIcon.bind(this);
  }

  componentDidMount() {
    window.onbeforeunload = () => "promptBeforeUnload";
  }

  handleChange = (e) => {
    const newState = this.state;
    const newForm = newState.current;
    const fieldName = e.target.name;
    const value = e.target.value;

    newForm[`${fieldName}`] = value;
    newState.current = newForm;
    this.setState(newState);
  };

  updateForm() {
    const newState = this.state;
    const step = this.state.step;
    newState.form[`${step}`] = this.state.current;
    const snackbar = this.state.Snackbar;
    snackbar.message = "Your input has been saved";
    snackbar.open = true;
    snackbar.severity = "info";
    newState.Snackbar = snackbar;
    this.setState(newState);
  }

  Next = () => {
    const el = document.querySelectorAll(".URLLink");
    this.updateForm();
    setTimeout(() => {
      console.log(el);
      el[1].click();
    }, 2000);
  };

  Back = () => {
    const el = document.querySelectorAll(".URLLink");
    el[2].click();
  };

  toggleInfoIcon = () =>
    this.setState({ ...this.state, InfoActive: !this.state.InfoActive });

  render() {
    return (
      <div>
        {/* <LogoBar /> */}
        <NavBar />
        <div className="OnboardingContent">
          <div className="ContentWraper">
            <span className="HeaderText">
              Library <InfoIcon onClick={this.toggleInfoIcon} />
            </span>
            <span className="Details">
              Tip: With the Library you can get everything you need to help with
              your business! From Office Hours to Resources, it’s all here for
              you!
              <LibraryInfoCard
                InfoActive={this.state.InfoActive}
                handleClick={this.toggleInfoIcon}
              />
            </span>
            <StepTrackerCard
              currentStepIndex={2}
              stepsFor={"OnboardingNeighborhoodprofile"}
            />
            {/** Current Step Track 
                     Current Form Board */}
            <div className="FormWrapper">
              <div className="CardStack">
                <div className="Info">
                  <span
                    className="Text"
                    style={{
                      textAlign: "right",
                      visibility: this.state.InfoActive ? "hidden" : "",
                    }}
                  >
                    Host and attend sessions with other entrepreneurs to
                    share/gain insights and tools for business and
                    entrepreneurship
                  </span>
                  <div className="Card OfficeHours ComingSoon">
                    <h5>Office Hours</h5>
                    <span>Coming Soon</span>
                  </div>
                </div>
                <div className="Info">
                  <div className="Card Resources">Resources</div>
                  <span
                    className="Text"
                    style={{
                      textAlign: "left",
                      visibility: this.state.InfoActive ? "hidden" : "",
                    }}
                  >
                    Share & get valuable resources such as books, videos and
                    more to gain practical insights for career and
                    entrepreneurial success.
                  </span>
                </div>
                <div className="Info">
                  <span
                    className="Text"
                    style={{
                      textAlign: "right",
                      visibility: this.state.InfoActive ? "hidden" : "",
                    }}
                  >
                    Host and attend events, webinars, conferences & more to grow
                    your network and reach your business goals faster
                  </span>
                  <div className="Card Events ComingSoon">
                    <h5>Events</h5>
                    <span>Coming Soon</span>
                  </div>
                </div>
                <div className="Info">
                  <div className="Card Services ComingSoon">
                    <h5>Services</h5>
                    <span>Coming Soon</span>
                  </div>
                  <span
                    className="Text"
                    style={{
                      textAlign: "left",
                      visibility: this.state.InfoActive ? "hidden" : "",
                    }}
                  >
                    Offer your skills & expertise to other members of the
                    community and get paid for your services in a conducive,
                    hassle-free manner
                  </span>
                </div>
              </div>
              {/* <ArrowForward className="ArrowForward" /> */}
              <Button className="Continue" onClick={() => this.Next()}>
                Continue{" "}
              </Button>
            </div>
            <SnackbarAlert>
              message={this.state.Snackbar.message}
              severity={this.state.Snackbar.severity}
              open={this.state.Snackbar.open}
              onClose=
              {() =>
                this.setState({ ...this.state, Snackbar: { open: false } })
              }
            </SnackbarAlert>
            <HiddenURLLink
              url={"/setup/neighborhoodprofile/step3"}
              data={this.state.form}
            />
            <HiddenURLLink
              url={"/setup/neighborhoodprofile/step1"}
              data={this.state.form}
            />
            <div className="StepControl">
              <Button className="Back" onClick={() => this.Back()}>
                <ArrowBackward className="ArrowBackward" /> Back
              </Button>
              <Button className="Skip" onClick={() => this.Next()}>
                Skip <ArrowForward className="ArrowForward" />{" "}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
