import React, { createContext, useState } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

export const SnackbarContext = createContext({});

const SnackbarProvider = ({ children }) => {
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    type: "error",
  });

  const triggerAlert = (args) => {
    setAlert({ ...alert, ...args });
  };

  const handleClose = () => {
    setAlert({ ...alert, open: false });
  };

  return (
    <SnackbarContext.Provider value={{ triggerAlert }}>
      <Snackbar
        open={alert.open}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ zIndex: "999999" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity={alert.type}
          sx={{ width: "100%" }}
        >
          <p className="alert-message">{alert.message}</p>
        </MuiAlert>
      </Snackbar>
      {children}
    </SnackbarContext.Provider>
  );
};

export default SnackbarProvider;
