import store from 'utilities/storage';
import { getBotAccess } from './getBotAccess';
import { useEffect } from 'react';

export function useChatSequenceActivator({ nextConversations }) {
  const canSeeBot = getBotAccess();

  useEffect(() => {
    if (canSeeBot) {
      const hasEndOfSequence = nextConversations?.some((x) => x?.endOfSequence);

      const isValidMessageFromBot = nextConversations[0]?.id;

      if (nextConversations.length === 0 || hasEndOfSequence) {
        store.write('endOfSequence', true);
      }

      if (
        nextConversations.length > 0 &&
        !hasEndOfSequence &&
        isValidMessageFromBot
      ) {
        store.write('endOfSequence', false);
      }
    }
  }, [nextConversations, canSeeBot]);
}
