import React, { useState } from "react";
import testimonialImage from "../../../assets//Images/testimonial-img.png";
import { ReactComponent as RightArrow } from "../../../assets/Icons/right-arrow.svg";
import { ReactComponent as LeftArrow } from "../../../assets/Icons/left-arrow.svg";
import "./Testimonials.css";
import { slidesData } from "../Slider/SlidesData";

const Testimonials = () => {
  const [slideIndex, setSlideIndex] = useState(1);

  const nextSlide = () => {
    if (slideIndex !== slidesData.length) {
      setSlideIndex(slideIndex + 1);
    } else if (slideIndex === slidesData.length) {
      setSlideIndex(1);
    }
  };

  const prevSlide = () => {
    if (slideIndex !== 1) {
      setSlideIndex(slideIndex - 1);
    } else if (slideIndex === 1) {
      setSlideIndex(slidesData.length);
    }
  };

  return (
    <div className="testimonials">
      <div className="testimonials__container">
        <div className="testimonials__header">
          <h3 className="testimonials__heading">People are talking...</h3>
          <p className="testimonials__quote">‘’</p>
        </div>
        <div className="testimonials__box">
          {slidesData.map((data, index) => {
            return (
              <div
                key={data.id}
                className={
                  slideIndex === index + 1
                    ? "testimonials__content--container active__slide"
                    : "testimonials__content--container"
                }
              >
                <div className="testimonials__content--box">
                  <div className="testimonials__image--container">
                    <img
                      src={data.image}
                      alt=""
                      className="testimonials__image"
                    />
                  </div>
                  <div className="testimonials__content">
                    <p className="testimonials__testimony">{data.testimony}</p>
                    {/* <p className="testimonials__testimony">
                                                And they're not really there to help you. So, yeah, this is a fantastic service!
                                            </p> */}
                    <div className="testimonials__info">
                      <h4 className="testimonials__author">
                        {data.authorName}
                      </h4>
                      <p className="testimonials__title">{data.authorTitle}</p>
                      <p className="testimonials__company">
                        {data.authorCompany}
                      </p>
                    </div>
                    <div className="testimonials__buttons">
                      <button className="testimonial__btn">
                        <LeftArrow
                          onClick={prevSlide}
                          className="testimonial__left--arrow"
                        />
                      </button>
                      <button className="testimonial__btn">
                        <RightArrow
                          onClick={nextSlide}
                          className="testimonial__right--arrow"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
