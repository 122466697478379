import React from "react";
import * as testId from "utilities/testIds";

import {
  StyledCheckbox,
  StyledFormControlLabel,
} from "./CheckboxSelect.styles";
import { ReactComponent as UnCheckedIcon } from "assets/svgs/unchecked_checkbox.svg";
import { ReactComponent as CheckedIcon } from "assets/svgs/checked_checkbox.svg";

export function CheckboxSelect(props) {
  const { label, checkboxProps } = props;

  return (
    <StyledFormControlLabel
      data-testid={testId.sandiChatbot.singleCheckbox}
      control={
        <StyledCheckbox
          icon={<UnCheckedIcon />}
          checkedIcon={<CheckedIcon />}
          disableRipple
          disableTouchRipple
          disableFocusRipple
          {...checkboxProps}
        />
      }
      label={label}
    />
  );
}
