import React from "react";
import EmptyNeighborhoodContainer from ".";
import "../NeighborhoodMemberCard.css";

const EmptyNeighborhoodCard = () => {
  return (
    <div className="empty-neighborhood-card">
      <div className="empty-neighborhood-card__container">
        <h3 className="empty-neighborhood-card__heading">
          It's a little lonely here!
        </h3>
        <p className="empty-neighborhood-card__sub">
          Invite people to your Neighborhood.
        </p>
      </div>
      <div className="empty-neighborhod-wrapper">
        <EmptyNeighborhoodContainer />
      </div>
    </div>
  );
};

export default EmptyNeighborhoodCard;
