import axios from "axios";
import urls from "./baseUrl.js";

const checkConnectionEstablished = {
  async rialtoAPI() {
    try {
      const resp = await axios.get(urls.base_url);
      return resp.status === 200;
    } catch (error) {
      throw new Error(error);
    }
  },
  async officehoursAPI() {
    try {
      const resp = await axios.get(urls.office_hour_url);
      return resp.status === 200;
    } catch (error) {
      throw new Error(error);
    }
  }
};

export default checkConnectionEstablished;
