import styled, { css } from 'styled-components';
import { zIndexes } from 'styles/zIndex';

// desktop view
const DesktopSandiContainer = css`
  @media only screen and (min-width: 768px) {
    bottom: 0;
    right: 24px;
  }
`;

// tablet view
const TabletSandiContainer = css`
  @media only screen and (max-width: 768px) and (min-width: 479px) {
    bottom: 60px;
    right: 0;
  }
`;

// mobile view
const MobileSandiContainer = css`
  @media only screen and (max-width: 480px) {
    right: 0;
    bottom: 60px;
    width: 200px;

    &[data-bot-height='min'] {
      transition: all 0.25s linear;
      border-radius: 12px 0px 0px 0px;
    }

    &[data-bot-height='max'] {
      border-radius: 0px;
      width: 100%;
      transition: all 0.25s linear;
    }
  }
`;

export const SandiContainer = styled.div`
  position: fixed;
  box-shadow: -2px -2px 24px 6px rgba(27, 24, 26, 0.15);
  z-index: 100;
  display: grid;
  grid-template-rows: min-content 1fr;
  height: 0px;
  max-height: 0px;
  width: 400px;
  border-radius: 12px 12px 0px 0px;

  &[data-bot-height='min'] {
    height: 60px;
    max-height: 60px;
    transition: height 0.25s linear, max-height 0.25s linear;
  }

  &[data-bot-height='max'] {
    height: calc(100% - 50px);
    max-height: 600px;
    transition: max-height 0.25s linear, height 0.25s linear;
  }

  ${DesktopSandiContainer}

  ${MobileSandiContainer}

  ${TabletSandiContainer}
`;
