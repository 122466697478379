import React from 'react';
import { MessageHistoryContainer } from '../MessageContainer/MessageHistoryContainer';
import { FlexMessageBox } from '../MessageList/MessageList.styles';
import { getChatBotGraphics } from '../utils/getBotGraphics';
import { flattenPages } from 'utilities/flattenPages';

export const ChatHistoryRaw = (props) => {
  const { ChatHistoryData } = props;
  const flatData = flattenPages(ChatHistoryData);

  const slice = flatData?.results;

  const nodes = slice
    ?.sort((a, b) => a.id - b.id)
    ?.map((chat, _idx) => {
      if (chat.byUser) {
        return <MessageHistoryContainer key={_idx} chat={chat} />;
      }
      return (
        <FlexMessageBox key={_idx}>
          {getChatBotGraphics(slice, _idx)}
          <MessageHistoryContainer chats={slice} chat={chat} />
        </FlexMessageBox>
      );
    });

  return <>{nodes}</>;
};
