import React, { useState } from "react";
import classes from "./OwnedNeighborhood.module.css";
import Card from "../../../UI/Card/Card";
import Minwo_search from "../../../assets/Images/minwo_search.svg";
import Minwo_nav from "../../../assets/Images/minwo_neighborhood_nav.svg";
import Minwo_favorite from "../../../assets/Images/minwo_favorite_color.svg";
import Minwo_unfavorite from "../../../assets/Images/minwo_unfavorite.svg";
import UserImageAvatar from "../../GlobalComponents/UserImageAvatar";
import { useNavigate } from "react-router-dom";

const OwnedNeighborhood = (props) => {
  const { neighborhoods, pictureUrl } = props;
  const [fav, setFav] = useState({});
  const [_neighborhoods, setNeighborhoods] = useState(neighborhoods);
  const navigate = useNavigate();
  const favoriteAndUnfavoriteItem = (event, id) => {
    event.preventDefault();
    setFav((prevState) => {
      return {
        ...prevState,
        [id]: false,
      };
    });
    if (!fav[id]) {
      setFav((prevState) => {
        return {
          ...prevState,
          [id]: !prevState[id],
        };
      });
    }
  };

  const handleNeighborhoodsFilter = (e) => {
    const { value } = e.target;
    const filteredNeighborhoods = neighborhoods.filter((neighborhood) =>
      neighborhood.neighborhood_name.includes(value.toLowerCase())
    );

    value.length
      ? setNeighborhoods(filteredNeighborhoods)
      : setNeighborhoods(neighborhoods);
  };
  return (
    <div className={classes.neighborhoodContainer}>
      <button onClick={() => navigate(-1)} className={classes.takeMeBack}>
        Back
      </button>
      <Card className={classes.myNeighborhood}>
        <header>
          <div className={classes.topDetail}>
            <h1>Neighborhoods</h1>
            <div className={classes.count}>({neighborhoods.length})</div>
          </div>
          <div className={classes.inputElement}>
            <div className={classes.input}>
              <input
                type="text"
                placeholder="Search"
                onChange={handleNeighborhoodsFilter}
              />
              <div className={classes.search}>
                <img src={Minwo_search} alt="Search" />
              </div>
            </div>
            <img src={Minwo_nav} className={classes.nav} alt="Navigation" />
          </div>
        </header>
        {_neighborhoods.length === 0 ? (
          <div className={classes.empty}>There are no Neighborhoods</div>
        ) : (
          <ul>
            {_neighborhoods.map((neighborhood) => {
              const {
                neighborhood_id,
                neighborhood_name,
                neighborhood_description,
              } = neighborhood;
              const neighborhood_link = `${window.location.origin}/neighborhood/${neighborhood_id}`;
              const photo = !fav[neighborhood_id]
                ? Minwo_unfavorite
                : Minwo_favorite;
              return (
                <li key={neighborhood_id} className={classes.myhood}>
                  <div className={classes.photo}>
                    <a href={neighborhood_link}>
                      <UserImageAvatar
                        s3_picture_url={pictureUrl}
                        Alt="Neighborhood Avatar"
                      />
                    </a>
                  </div>
                  <a href={neighborhood_link}>{neighborhood_name}</a>
                  <h4>{neighborhood_description}</h4>
                  <div className={classes.fav}>
                    <img
                      src={photo}
                      alt="favorite or unfavorite"
                      onClick={(event) =>
                        favoriteAndUnfavoriteItem(event, neighborhood_id)
                      }
                    />
                  </div>
                </li>
              );
            })}
          </ul>
        )}
      </Card>
    </div>
  );
};

export default OwnedNeighborhood;
