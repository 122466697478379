import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
import CribBanner from "components/CribBanner/CribBanner";
import AppLoader from "../components/Loader/loader";
import OHVideoCallProvider from "../state/OfficeHours/VideoCall/provider.js";
import { AppLayout } from "./AppLayout";

const CribLayout = () => {
  return (
    <Suspense fallback={<AppLoader />}>
      <OHVideoCallProvider>
        <AppLayout>
        <CribBanner />
        <Outlet />
        </AppLayout>
      </OHVideoCallProvider>
    </Suspense>
  );
};

export default CribLayout;
