import React from 'react';
import { Skeleton } from '@material-ui/lab';

const BulletinLoadingSkeleton = () => {
    return (
        <div className="Announcement NoBulletinAnouncement">
            < div className="NoBulletin">
                <Skeleton style={{ height: "10rem", width: "100%", maxWidth: "100%", borderRadius: "10px" }}>
                    <p className="Detail">
                        Create  your first bulletin post
                    </p>
                </Skeleton>
            </div>
        </div>
    )
}

export default BulletinLoadingSkeleton;
