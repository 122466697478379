import React, { forwardRef } from "react";
import { Menu, useMenuState } from "@szhsin/react-menu";
import { DropdownItem } from "./DropdownPopover.styles";
import { COLORS } from "styles/colors/minwo";
import classes from "./DropdownPopover.module.css";
import { ReactComponent as VericalMore } from "assets/svgs/vertical-more.svg";

export const DropdownPopover = forwardRef((props, ref) => {
  const { items, onClose, onOpen, align, target } = props;

  const [menuProps, toggleMenu] = useMenuState({
    transition: false,
    unmountOnClose: true,
  });

  const isOpen = menuProps.state === "open";

  const closeMenu = () => {
    toggleMenu(false);
    onClose && onClose();
  };

  const openMenu = () => {
    toggleMenu(true);
    onOpen && onOpen();
  };

  const targetProps = {
    btnProps: {
      onClick: openMenu,
    },
    ref,
    isOpen,
  };

  function getTarget() {
    if (target) {
      return target(targetProps);
    }
    return (
      <VericalMore
        className={classes.target}
        color={COLORS.neutral.n0}
        icon="more"
        {...targetProps.btnProps}
      />
    );
  }
  return (
    <Menu
      {...menuProps}
      menuButton={<div ref={ref}>{getTarget()}</div>}
      align={align || "end"}
      anchorRef={ref}
      menuClassName={classes["menu-list"]}
      portal
      position="anchor"
    >
      {items.map((item) => (
        <DropdownItem
          key={item.label}
          onClick={() => {
            item.onClick();
            closeMenu();
          }}
        >
          {item.label}
        </DropdownItem>
      ))}
    </Menu>
  );
});
