import types from './types.js';
import API from '../../../api/OfficeHour.js';

const actions = (dispatch) => {
    /**
     * 
     * @param {Number} crib_id 
     * @param {Number} OfficeHourId 
     * @param {Number} attendOfficeHourID 
     */
    const joinSession = async (office_hour_id) => {
        // make api call
        // set token
        // set username
        try {
            const res = await API.joinOfficeHourCall(office_hour_id);
            if (res.status === "success") {
                const name = res.data.user_name;
                const room = res.data.room_name;
                const {
                    video_token,
                    chat_token,
                    chat_identity,
                } = res.data;

                setVideoToken(video_token);
                setChatToken(chat_token);
                setUserName(name);
                setRoomName(room);
                setUserChatIdentity(chat_identity);

                dispatch({
                    type: types.JOIN_SESSION
                })
            } else {
                // TODO: Handle permission error
            }
        } catch (error) {
            // TODO: Handle network error
        }
    }
    /**
     * exit video call
     * 
     * @param {Object} payload - initial state
     */
    const exitSession = async (payload) => {
        // exit call
        // reset session info
        dispatch({
            type: types.EXIT_SESSION,
            payload: payload
        })
    }
    /**
     * set twilio token
     * 
     * @param {String} payload 
     */
    const setVideoToken = (payload) => {
        dispatch({
            type: types.SET_VIDEO_TOKEN,
            payload: payload
        })
    }
    /**
     * set twilio token
     * 
     * @param {String} payload 
     */
    const setChatToken = (payload) => {
        dispatch({
            type: types.SET_CHAT_TOKEN,
            payload: payload
        })
    }
    /**
     * set username
     * 
     * @param {String} payload 
     */
    const setUserName = (payload) => {
        dispatch({
            type: types.SET_USERNAME,
            payload: payload
        })
    }
    /**
     * set user chat identity
     * 
     * @param {String} payload 
     */
     const setUserChatIdentity = (payload) => {
        dispatch({
            type: types.SET_USER_CHATIDENTITY,
            payload: payload
        })
    }    
    /**
     * set call room name
     * 
     * @param {String} payload 
     */
    const setRoomName = (payload) => {
        dispatch({
            type: types.SET_ROOMNAME,
            payload: payload
        })
    }
    /**
     * toggle mic control
     */
    const setMicOn = () => {
        dispatch({
            type: types.SET_MIC_ON,
        })
    }
    /**
     * toggle video control
     */
    const setVideoOn = () => {
        dispatch({
            type: types.SET_VIDEO_ON,
        })
    }
    /**
     * toggle chat control
     */
    const setChatOn = () => {
        dispatch({
            type: types.SET_CHAT_ON,
        })
    }
    /**
     * toggle screen share control
     */
    const setScreenShareOn = () => {
        dispatch({
            type: types.SET_SCREEN_SHARE_ON,
        })
    }
    /**
     * Add participant to active user list
     * 
     * @param {Object} participant 
     * @param {Object} participants 
     */
    const addParticipant = (participant) => {
        dispatch({
            type: types.ADD_PARTICIPANT,
            payload: participant
        })
    }
    /**
     * remove participant from active user list
     * 
     * @param {Object} participant 
     * @param {Object} participants 
     */
    const removeParticipant = (participant) => {
        dispatch({
            type: types.REMOVE_PARTICIPANT,
            payload: participant
        })
    }

    return {
        joinSession,
        setUserName,
        setRoomName,
        setVideoOn,
        setMicOn,
        setChatOn,
        setScreenShareOn,
        setChatToken,
        setVideoToken,
        setUserChatIdentity,
        exitSession,
        addParticipant,
        removeParticipant
    }
}

export default actions;
