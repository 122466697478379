import axios from "axios";
import sessionInfo from "../utilities/session/sessionInfo.js";
import urls from "./baseUrl.js";

const { token } = sessionInfo();

const forumAPI = {
  postNeighborhoodBulletin: async (data, neighborhood_id) => {
    try {
      const url = `${urls.base_url}neighborhood/${neighborhood_id}/forum`;
      const res = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer: ${token}`,
        },
      });
      return res;
    } catch (error) {
      console.error(error.response);
      return false;
    }
  },
  async getAllForumPosts(neighborhood_id, limit, page) {
    const url = `${urls.base_url}neighborhood/${neighborhood_id}/forum?limit=${limit}&&page=${page}`;
    try {
      const resp = await axios.get(url, {
        headers: {
          Authorization: `Bearer: ${token}`,
        },
      });
      return resp.data;
    } catch (error) {
      throw error;
    }
  },
  async getForumPostById(neighborhood_id, forum_post_id) {
    const url = `${urls.base_url}neighborhood/${neighborhood_id}/forum/${forum_post_id}`;
    try {
      const resp = await axios.get(url, {
        headers: {
          Authorization: `Bearer: ${token}`,
        },
      });
      return resp.data;
    } catch (error) {
      throw error;
    }
  },
  async commentOnForumPost(data, neighborhood_id, forum_post_id) {
    try {
      const url = `${urls.base_url}neighborhood/${neighborhood_id}/forum/${forum_post_id}/comment`;
      const res = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer: ${token}`,
        },
      });
      return res;
    } catch (error) {
      console.error(error.response);
      return false;
    }
  },
  async getCommentsOnForumPost(neighborhood_id, forum_post_id, page, limit) {
    const url = `${urls.base_url}neighborhood/${neighborhood_id}/forum/${forum_post_id}/comments?page=${page}&&limit=${limit}`;
    try {
      const resp = await axios.get(url, {
        headers: {
          Authorization: `Bearer: ${token}`,
        },
      });
      return resp.data;
    } catch (error) {
      throw error;
    }
  },
  async favouriteForumPost({ vote, isLiked, neighborhood_id, forum_post_id }) {
    try {
      const url = `${urls.base_url}neighborhood/${neighborhood_id}/forum/${forum_post_id}/favorite`;
      if (isLiked) {
        return await axios.delete(
          url,
          {
            headers: {
              Authorization: `Bearer: ${token}`,
            },
          },
          { vote }
        );
      }
      return await axios.post(
        url,
        { vote },
        {
          headers: {
            Authorization: `Bearer: ${token}`,
          },
        }
      );
    } catch (error) {
      console.error(error.response);
      return false;
    }
  },
  async voteForumPost({ vote, neighborhood_id, forum_post_id, isVoted }) {
    try {
      const url = `${urls.base_url}neighborhood/${neighborhood_id}/forum/${forum_post_id}/vote/${vote}`;
      if (isVoted) {
        return await axios.delete(
          `${urls.base_url}neighborhood/${neighborhood_id}/forum/${forum_post_id}/vote`,
          {
            headers: {
              Authorization: `Bearer: ${token}`,
            },
          },
          { vote }
        );
      }
      return await axios.post(
        url,
        { vote },
        {
          headers: {
            Authorization: `Bearer: ${token}`,
          },
        }
      );
    } catch (error) {
      console.error(error.response);
      return false;
    }
  },
  async voteForumPostComment({
    vote,
    neighborhood_id,
    forum_post_id,
    comment_id,
    isCommentVoted,
  }) {
    try {
      const url = `${urls.base_url}neighborhood/${neighborhood_id}/forum/${forum_post_id}/comment/${comment_id}/vote/${vote}`;
      console.log(isCommentVoted);
      if (isCommentVoted) {
        return await axios.delete(
          `${urls.base_url}neighborhood/${neighborhood_id}/forum/${forum_post_id}/comment/${comment_id}/vote`,
          {
            headers: {
              Authorization: `Bearer: ${token}`,
            },
          },
          { vote }
        );
      }
      return await axios.post(
        url,
        { vote },
        {
          headers: {
            Authorization: `Bearer: ${token}`,
          },
        }
      );
    } catch (error) {
      console.error(error.response);
      return false;
    }
  },
  async getForumPostViews(neighborhood_id, forum_post_id) {
    try {
      const url = `${urls.base_url}neighborhood/${neighborhood_id}/forum/${forum_post_id}/track_user_view`;
      const res = await axios.post(
        url,
        {},
        {
          headers: {
            Authorization: `Bearer: ${token}`,
          },
        }
      );
      return res;
    } catch (error) {
      console.error(error.response);
      return false;
    }
  },
  async getForumPostStats(neighborhood_id) {
    const url = `${urls.base_url}neighborhood/${neighborhood_id}/forum/stats`;
    try {
      const resp = await axios.get(url, {
        headers: {
          Authorization: `Bearer: ${token}`,
        },
      });
      return resp.data;
    } catch (error) {
      throw error;
    }
  },
};

export default forumAPI;
