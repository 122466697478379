import React from "react";
import BtnLoader from "../../../../components/Loader/btn-loader";

const AddMembersActions = ({
  isLoading,
  inviteesLength,
  emailInviteesLength,
}) => {
  return (
    <div className="add-members-within-rialto__btn--wrapper">
      <div className="add-members-within-rialto__btn--container">
        <button
          className="add-members-within-rialto__btn green-bg"
          disabled={
            isLoading || (emailInviteesLength <= 0 && inviteesLength <= 0)
          }
        >
          {isLoading ? (
            <BtnLoader
              border_bottom={"4px solid #FFFFFF"}
              border_right={"4px solid #FFFFFF"}
              border_left={"4px solid #838383"}
              border_top={"4px solid #838383"}
            />
          ) : (
            "Invite"
          )}
        </button>
      </div>
    </div>
  );
};

export default AddMembersActions;
