import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { useErrorContext } from "../../../hooks/useErrorContext";
import neighborhood from "../../../api/neighborhood";
import store from "utilities/storage";
import AddMembersOutsideForm from "./add-members-outside-rialto-form";
import AddMembersOutsideRialtoSuccess from "./add-members-outside-rialto-success";
import AddMembersOutsideRialtoDetails from "./add-members-outside-rialto-details";
import "./add-members-outside-rialto.css";

const defaultFormValues = {
  firstname: "",
  lastname: "",
  email: "",
};

const AddMembersOutsideRialto = ({ onClose, handleClose }) => {
  const params = useParams();
  const triggerAlert = useErrorContext();
  const queryClient = useQueryClient();

  const setup_neighborhood_id = store.retrieve("setup_neighborhood_id");
  const neighborhood_id = params?.neighborhood_id || setup_neighborhood_id;

  const [activeScreen, setActiveScreen] = useState("AddMembersOutsideForm");
  const [formValues, setFormValues] = useState(defaultFormValues);
  const [members, setMembers] = useState([]);
  const [successData, setSuccessData] = useState();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const previewEmails = (e) => {
    e.preventDefault();
    const checkIfMemberExists = members.some(
      (el) => el.email === formValues.email.toLowerCase()
    );
    if (checkIfMemberExists) {
      triggerAlert({
        open: true,
        message: "Email already exists",
        type: "error",
      });
    } else if (members?.length <= 5) {
      setMembers((prevState) => {
        return [...prevState, formValues];
      });
      setFormValues(defaultFormValues);
    }
  };

  const removeMember = (membertoRemove) => {
    const filteredMembers = members.filter(
      (member) => member !== membertoRemove
    );
    setMembers(filteredMembers);
  };

  const handleViewDetails = () => {
    setActiveScreen("AddMembersOutsideRialtoDetails");
  };

  const mutation = useMutation(
    (payload) => neighborhood.inviteNewMembers(payload, neighborhood_id),
    {
      onError: (error) => {
        triggerAlert({
          open: true,
          message: error?.response?.data?.error
            ? error?.response?.data?.error
            : "There was an error, please try again later",
          type: "error",
        });
      },
      onSuccess: (response) => {
        if (response.status === "success") {
          setActiveScreen("AddMembersOutsideRialtoSuccess");
          setSuccessData(response?.data);
          queryClient.invalidateQueries([
            "neighborhoodsMembers",
            neighborhood_id,
          ]);
        } else {
          triggerAlert({
            open: true,
            message: "Something went wrong, please try again later",
            type: "error",
          });
        }
      },
    }
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = { members: members };
    setMembers([]);
    mutation.mutate(payload, neighborhood_id);
  };

  return (
    <div className="add-members-outside-rialto">
      {activeScreen === "AddMembersOutsideForm" && (
        <AddMembersOutsideForm
          onClose={onClose}
          formValues={formValues}
          members={members}
          previewEmails={previewEmails}
          handleChange={handleChange}
          isLoading={mutation.isLoading}
          handleSubmit={handleSubmit}
          removeMember={removeMember}
        />
      )}
      {activeScreen === "AddMembersOutsideRialtoSuccess" && (
        <AddMembersOutsideRialtoSuccess
          handleClose={handleClose}
          successData={successData}
          neighborhood_id={neighborhood_id}
          setup_neighborhood_id={setup_neighborhood_id}
          handleViewDetails={handleViewDetails}
        />
      )}
      {activeScreen === "AddMembersOutsideRialtoDetails" && (
        <AddMembersOutsideRialtoDetails
          successData={successData}
          handleClose={handleClose}
        />
      )}
    </div>
  );
};

export default AddMembersOutsideRialto;
