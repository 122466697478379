import React from 'react';
import { Icon } from '@blueprintjs/core';
import {
  BotSelectAutoComplete,
  PopperIcon,
  SelectedOptionSubmitButton,
} from './DropdownSelect.styles';
import ChevronUp from 'assets/svgs/arrow-up.svg';
import ChevronDown from 'assets/svgs/arrow-down.svg';

import { TextField } from '@material-ui/core';
import * as testId from 'utilities/testIds';
import { CustomPopper } from '../CustomPopper/CustomPopper';
import { ChipsContainer } from '../ChipsContainer/ChipsContainer';

export function DropdownSelect(props) {
  const {
    buttonText,
    selectPlaceholder,
    options,
    selectedOptions,
    setSelectedOptions,
    disabled,
    ...buttonRest
  } = props;

  const [openPopper, setOpenPopper] = React.useState(false);

  const containerRef = React.useRef(null);

  const handleSelection = (val) => {
    setSelectedOptions((prev) => [...prev, val]);
  };

  const handleSelectedOptionDelete = (optionToDelete) => {
    setSelectedOptions((options) =>
      options.filter((option) => option !== optionToDelete)
    );
  };

  const handleOpenPopper = () => {
    setOpenPopper(true);
  };

  const handleClosePopper = () => {
    setOpenPopper(false);
  };

  React.useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollLeft = containerRef.current.scrollWidth;
    }
  }, [selectedOptions]);
  return (
    <>
      <BotSelectAutoComplete
        open={openPopper}
        onOpen={handleOpenPopper}
        onClose={handleClosePopper}
        multiple
        disableCloseOnSelect
        size="medium"
        options={options}
        value={selectedOptions}
        freeSolo
        onChange={(_, newValue) => !disabled && setSelectedOptions(newValue)}
        getOptionLabel={(option) => option}
        getOptionSelected={(option, value) => option === value}
        disableClearable
        PopperComponent={CustomPopper}
        data-hasselectedstates={selectedOptions.length > 0}
        renderOption={(option, { selected }) => (
          <div
            key={option}
            onClick={() => handleSelection(option)}
            className="menu_item"
            disabled={selected || disabled}
          >
            {option}
            {selected && <Icon icon="tick" color="#569480" size={10} />}
          </div>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={selectPlaceholder}
            autoFocus={false}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              endAdornment: (
                <PopperIcon>
                  {openPopper ? (
                    <img
                      src={ChevronDown}
                      onClick={handleClosePopper}
                      data-testid={testId.sandiChatbot.optionSelectionToggle}
                    />
                  ) : (
                    <img
                      src={ChevronUp}
                      onClick={handleOpenPopper}
                      data-testid={testId.sandiChatbot.optionSelectionToggle}
                    />
                  )}
                </PopperIcon>
              ),
            }}
          />
        )}
        renderTags={(value, getTagProps) => (
          <ChipsContainer
            value={value}
            handleSelectedOptionDelete={handleSelectedOptionDelete}
            getTagProps={getTagProps}
            ref={containerRef}
            disabled={disabled}
          />
        )}
      />
      <SelectedOptionSubmitButton
        data-active={selectedOptions.length > 0 && !disabled}
        disabled={selectedOptions.length === 0 || disabled}
        data-testid={testId.sandiChatbot.submitLocationButton}
        {...buttonRest}
      >
        {buttonText}
      </SelectedOptionSubmitButton>
    </>
  );
}
