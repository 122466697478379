import React from 'react';
export default function LibraryIcon(props) {
    const IconClass = props.className;
    return <svg className={IconClass} viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M1 1H7C9.20914 1 11 2.79086 11 5V19C11 17.3431 9.65685 16 8 16H1V1Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M21 1H15C12.7909 1 11 2.79086 11 5V19C11 17.3431 12.3431 16 14 16H21V1Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    
}

