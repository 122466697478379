import React, { forwardRef, useEffect } from 'react';
import { ScrollChatWrapper, ChatBottom } from './ScrollChatContainer.styles';

const ScrollChatContainer = forwardRef((props, ref) => {
  const {
    scrollDependency,
    children,
    wasAtBottomRef,
    messageSentByUser,
    setMessageSentByUser,
  } = props;

  useEffect(() => {
    if (ref && ref.current) {
      const chatContainer = ref.current;
      if (messageSentByUser) {
        chatContainer.scrollTop = chatContainer.scrollHeight;
        setMessageSentByUser(false);
      } else if (wasAtBottomRef && wasAtBottomRef.current) {
        chatContainer.scrollTop = chatContainer.scrollHeight;
      }
    }
  }, [scrollDependency, messageSentByUser]);

  return (
    <ScrollChatWrapper className="invisible-scroll" ref={ref}>
      {children}

      <ChatBottom ref={ref} />
    </ScrollChatWrapper>
  );
});

export default ScrollChatContainer;
