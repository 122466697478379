import React from 'react';
import {Link} from 'react-router-dom';

export default function HiddenURLLink(props) { 
    const className = props.className ? props.className : "";
    return (
        <Link
            to={{
                pathname: props.url,
                data: props.data
            }}
            style={{display:"none"}}
            className={"URLLink "+ className}
        >
        </Link>
    );
}
