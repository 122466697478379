import React, { useContext, useMemo, useState } from "react";
import {
  Buttons,
  LeftSection,
  ProfilePicSectionDiv,
} from "./ProfilePicSection.styles";
import defaultPicture from "assets/Images/default-profile-picture.svg";
import { ReactComponent as UploadIcon } from "assets/Icons/upload.svg";
import { ReactComponent as DeleteIcon } from "assets/Icons/delete_outline.svg";
import { Button } from "components/Button/Button";
import * as testId from "utilities/testIds";
import store from "utilities/storage";
import { useParams } from "react-router-dom";
import sessionInfo from "utilities/session/sessionInfo";
import { useFormContext } from "react-hook-form";
import hyperid from "hyperid";
import { useMutation, useQueryClient } from "react-query";
import neighborhood from "api/neighborhood";
import { SnackbarContext } from "contextStore/SnackbarContext";

export function ProfilePicSection() {
  const { neighborhood_id } = useParams();
  const { triggerAlert } = useContext(SnackbarContext);

  const { role } = sessionInfo();
  const form = useFormContext();
  const queryClient = useQueryClient();
  const uniqueFileId = hyperid();

  const _neighborhood_id = store.retrieve("neighborhood_id");
  const formFile = form.watch("file");
  const possibleNeighbohoodId = neighborhood_id ?? _neighborhood_id;
  const isFieldDisabled = role && role !== "BCO";

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
    form.trigger("file");
    form.setValue(
      "file",
      {
        id: uniqueFileId,
        file,
      },
      {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      }
    );
  };

  const deletePictureMutation = useMutation({
    mutationFn: neighborhood.deleteNeighborhoodPicture,
    onSuccess: () => {
      queryClient.invalidateQueries(["neighborhood", possibleNeighbohoodId]);
      form.reset({
        file: null,
      });
      triggerAlert({
        open: true,
        message: "Neighborhood photo deleted successfully",
        type: "success",
      });
    },
    onError: () => {
      triggerAlert({
        open: true,
        message: "An error occurred",
        type: "error",
      });
    },
  });

  const handleFileDelete = () => {
    formFile.file &&
      possibleNeighbohoodId &&
      deletePictureMutation.mutate(possibleNeighbohoodId);
  };

  const neigbohoodImgSrc = useMemo(() => {
    if (!formFile || !formFile.file) return defaultPicture;
    else if (formFile.file instanceof FileList) return defaultPicture;
    else return URL.createObjectURL(formFile.file);
  }, [formFile]);

  const deleteText = deletePictureMutation.isLoading
    ? "Deleting"
    : "Delete Photo";

  return (
    <>
      <ProfilePicSectionDiv>
        <LeftSection>
          <img
            className="Logo"
            src={neigbohoodImgSrc}
            alt="neighborhood_image"
          />
          <div className="text">
            <p>Profile picture</p>
            <p>JPG or PNG</p>
          </div>
        </LeftSection>

        <Buttons>
          <Button
            className="buttons"
            size="small"
            kind="primary"
            startIcon={<UploadIcon />}
            disabled={isFieldDisabled}
          >
            <label htmlFor="upload">Upload Photo</label>
          </Button>

          <input
            id="upload"
            type="file"
            hidden
            accept=".jpg,.jpeg,.png"
            data-testid={testId.neighborhoods.inputField(
              "neighborhood_picture"
            )}
            {...form.register("file")}
            onChange={handleFileChange}
          />

          <Button
            className="buttons"
            size="small"
            kind="error"
            startIcon={<DeleteIcon />}
            onClick={handleFileDelete}
          >
            {deleteText}
          </Button>
        </Buttons>
      </ProfilePicSectionDiv>
    </>
  );
}
