import React, { useEffect, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { getSandiChatHistory } from 'api/sandibot/getSandiChatHistory';
import { useInView } from 'react-intersection-observer';
import { ChatHistoryQuery } from './ChatHistory.query';
import { ChatHistoryLoading } from './ChatHistory.loading';
import { ChatHistoryWrapper } from './ChatHistory.styles';

export function ChatHistory(props) {
  const { lastChatIdFromCheckpoint, chatContainerRef, wasAtBottomRef } = props;
  const [initialRenderFetched, setInitialRenderFetched] = useState(false);
  const { ref, inView } = useInView({
    delay: 100,
    threshold: 1,
  });

  const chatsHistoryQuery = useInfiniteQuery({
    queryKey: 'chatsHistory',
    queryFn: ({ pageParam = { limit: 20, from: lastChatIdFromCheckpoint } }) =>
      getSandiChatHistory({
        from: pageParam?.from,
        limit: pageParam?.limit,
      }),
    getPreviousPageParam: undefined,
    keepPreviousData: false,
    getNextPageParam: (lastPage) => {
      const records = lastPage?.records;
      const lastItem = records[records.length - 1];
      const someContentId = records.some(
        (content) => content?.contentId === '000'
      );

      return records.length > 0 && !someContentId
        ? { limit: 20, from: lastItem?.id }
        : undefined;
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: false,
  });

  const { fetchNextPage, hasNextPage, refetch } = chatsHistoryQuery;

  useEffect(() => {
    const chatContainer = chatContainerRef.current;
    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = chatContainer;
      wasAtBottomRef.current = scrollTop + clientHeight >= scrollHeight - 10;
    };

    if (chatContainer) {
      chatContainer.addEventListener('scroll', handleScroll);
      return () => chatContainer.removeEventListener('scroll', handleScroll);
    }
  }, []);

  useEffect(() => {
    if (inView && chatContainerRef.current && !!lastChatIdFromCheckpoint) {
      const chatContainer = chatContainerRef.current;
      const { scrollTop, scrollHeight: previousScrollHeight } = chatContainer;

      if (initialRenderFetched && hasNextPage) {
        scrollTop === 0 &&
          fetchNextPage().then(() => {
            if (!wasAtBottomRef.current) {
              const newScrollHeight = chatContainer.scrollHeight;
              chatContainer.scrollTop += newScrollHeight - previousScrollHeight;
            }
          });
      } else {
        refetch()
          .then(() => {
            const newScrollHeight = chatContainer.scrollHeight;
            chatContainer.scrollTop += newScrollHeight - previousScrollHeight;
          })
          .finally(() => setInitialRenderFetched(true));
      }
    }
  }, [initialRenderFetched, inView, hasNextPage, fetchNextPage, refetch]);

  return (
    <ChatHistoryWrapper>
      <ChatHistoryLoading query={chatsHistoryQuery} ref={ref} />
      <ChatHistoryQuery chatsHistoryQuery={chatsHistoryQuery} />
    </ChatHistoryWrapper>
  );
}
