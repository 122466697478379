import { useEffect, useState } from 'react';
import { CHATBOT_DISPLAY_DELAY } from 'config';
import sessionInfo from 'utilities/session/sessionInfo';
import { getBotAccess } from './getBotAccess';
import store from 'utilities/storage';

export function useSandiChatbotDisplay(userTyping, isFocused) {
	const [botSize, setBotSize] = useState(null);
	const canSeeBot = getBotAccess();
	const dependencies = [canSeeBot, store];

	const toggleBotSize = () => {
		if (['min', null].includes(botSize)) {
			setBotSize('max');
		} else if (botSize === 'max') {
			setBotSize('min');
		} else {
			setBotSize(null);
		}
	};

	useEffect(() => {
		if (canSeeBot) {
			const timeoutId = setTimeout(() => {
				const { endOfSequence } = sessionInfo();
				if (endOfSequence) {
					if ((userTyping || isFocused) && botSize === 'max') {
						setBotSize('max');
					} else {
						setBotSize('min');
					}
				} else {
					setBotSize('max');
				}
			}, CHATBOT_DISPLAY_DELAY);

			return () => clearTimeout(timeoutId);
		}

		return null;
	}, [dependencies]);

	return { botSize, setBotSize, toggleBotSize };
}
