import styled from 'styled-components';

export const FlexMessageBox = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;

  > :first-child {
    min-width: 40px;
  }
`;
