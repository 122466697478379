import React from "react";

const XIcon = () => {
    return <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9Z" fill="#979797"/>
    <path d="M11.6925 5.25L9 7.9425L6.3075 5.25L5.25 6.3075L7.9425 9L5.25 11.6925L6.3075 12.75L9 10.0575L11.6925 12.75L12.75 11.6925L10.0575 9L12.75 6.3075L11.6925 5.25Z" fill="#D8D8D8"/>
    </svg>    
}

const AuthFormErrorText = ({ formErrorText, setFormErrorText}) => {
    return<>
    {        
        formErrorText === "" ?
        <></>
        :
        <div className ="FormError" >
            {formErrorText}
            <span onClick={() => setFormErrorText("")}> 
                <XIcon />
            </span>
        </div>
    }
    </>
}
export default AuthFormErrorText;
