import React from "react";
import actions from './actions.js';
import reducer from './reducer.js';

const initialState = {
  // control settings
  videoOn: true,
  micOn: true,
  screenShareOn: false,
  chatOn: false,
  // room settings
  userName: '',
  roomName: '',
  participants: [],
  room: null,
  videoToken: '',
  chatToken: '',
  chatIdentity: ''
};

export const OfficeHourCall_Context = React.createContext();

const OfficeHour_Provider = ({ children }) => {
  const init = initialState;
  const [state, dispatch] = React.useReducer(reducer, init);

  const officeHourActions = actions(dispatch);

  const value = {
    state,
    actions: { ...officeHourActions }
  };

  return (
    // eslint-disable-next-line react/jsx-pascal-case
    <OfficeHourCall_Context.Provider value={value}>
      {children}
    </OfficeHourCall_Context.Provider>
  );
};

export default OfficeHour_Provider;
