import React from "react";

const Cancel = ({ className }) => {
  return (
    <svg
      className={className}
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 17C0 7.61116 7.61116 0 17 0C26.3888 0 34 7.61116 34 17C34 26.3888 26.3888 34 17 34C7.61116 34 0 26.3888 0 17Z"
        fill="#828282"
      />
      <path
        d="M22.0858 9.91669L17 15.0025L11.9141 9.91669L9.91663 11.9142L15.0025 17L9.91663 22.0859L11.9141 24.0834L17 18.9975L22.0858 24.0834L24.0833 22.0859L18.9975 17L24.0833 11.9142L22.0858 9.91669Z"
        fill="#D8D8D8"
      />
    </svg>
  );
};

export default Cancel;
