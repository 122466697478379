import React from "react";
import { useNavigate } from "react-router-dom";
import "./SubFooter.css";
import logo from "../../../assets/Images/Root.svg";
import greenVector from "../../../assets/Icons/Vector_green.svg";
import yellowVector from "../../../assets/Images/halfyellowvector.svg";

const SubFooter = () => {
  const navigate = useNavigate();
  const handleClick = () => navigate("/pricing");

  return (
    <div className="subFooter">
      <div className="sub_footer">
        <img src={logo} alt="logo" className="logo" />
        <div className="subfooter">
          <img
            src={yellowVector}
            alt="yellow vector"
            className="yellowvector"
          />
          <p className="subfootertext">
            <span className="texstname">Rialto</span> provides
            Community-as-a-Service for programs and organizations that
            prioritize Black business development. For these organizations and
            programs,<span className="textname"> Rialto </span> helps to
            centralize Black businesses, and increase connectivity. Community
            members can easily discover resources and opportunities that support
            the growth of their businesses.
          </p>

          <p className="subfootertext2">
            As a community or organization that supports and/or organizes around
            Black-owned businesses, <span className="text_name">Rialto </span>is
            designed to drastically reduce effort, time, and cost while
            improving program effectiveness.
          </p>
          <button className="subfooterbutton" onClick={handleClick}>
            Get Started
          </button>
        </div>
        <img src={greenVector} alt="green vector" className="vector" />
      </div>
    </div>
  );
};

export default SubFooter;
