import React from 'react';
import { NextConversationContainer } from '../MessageContainer/NextConversationContainer';
import { getChatBotGraphics } from '../utils/getBotGraphics';
import { FlexMessageBox } from './MessageList.styles';

// const checkRealtimeIsTyping = (positionInList, ) => {
//   console.log("@POS-COMPARE", positionInList , "=", messagesToSubmit.length, messagesToSubmit)
//   return (positionInList === messagesToSubmit.length);
// };

export function NextConversationsList(props) {
  const {
    messagesToSubmit,
    setMessagesToSubmit,
    nextConversations,
    triggerDelayCompleted,
    setLatestSubmitResponse,
    isBotTyping,
    setMessageSentByUser,
  } = props;

  // TODO: UPDATE THIS METHOD NAME TO `getRealtimeIsTyping`
  const checkRealtimeIsTyping = function (positionInList) {
    const run = function (_positionInList, _messagesToSubmit) {
      let messagesToSubmitLatest;

      // GET LATEST `messagesToSubmit` VALUE
      setMessagesToSubmit((prev) => {
        messagesToSubmitLatest = prev;
        return prev;
      });

      return _positionInList === messagesToSubmitLatest.length;
    };

    return run(positionInList, messagesToSubmit);
  };

  return (
    <>
      {messagesToSubmit?.length > 0 &&
        messagesToSubmit?.map((nextMessage, _idx) => {
          // REVIEW: Using OPTIONAL-CHAINING here, if it's possible for `messagesToSubmit` to be undefined, then this might throw an error
          const lastIdx = messagesToSubmit?.length - 1;

          if (!nextMessage) {
            return null;
          }

          if (nextMessage?.byUser) {
            return (
              <NextConversationContainer
                key={_idx + '_'}
                nextMessage={nextMessage}
                messagesToSubmit={nextConversations}
                positionInParentList={_idx + 1}
                triggerDelayCompleted={triggerDelayCompleted}
                checkRealtimeIsTyping={checkRealtimeIsTyping}
              />
            );
          }

          return (
            <FlexMessageBox key={_idx}>
              {getChatBotGraphics(messagesToSubmit, _idx)}
              <NextConversationContainer
                nextMessage={nextMessage}
                messagesToSubmit={nextConversations}
                setMessagesToSubmit={setMessagesToSubmit}
                positionInParentList={_idx + 1}
                triggerDelayCompleted={triggerDelayCompleted}
                checkRealtimeIsTyping={checkRealtimeIsTyping}
                isTyping={lastIdx === _idx && isBotTyping}
                setLatestSubmitResponse={setLatestSubmitResponse}
                setMessageSentByUser={setMessageSentByUser}
              />
            </FlexMessageBox>
          );
        })}
    </>
  );
}
