import React from 'react';
import { TypingIndicator } from './MessageTypingIndicator.styles';

export const MessageTypingIndicator = () => {
	return (
		<TypingIndicator>
			<div />
			<div />
			<div />
		</TypingIndicator>
	);
};
