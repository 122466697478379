import React from "react";
import { Outlet } from "react-router-dom";
import Navigation from "../pages/LandingPage/Navigation/Navigation";
import SubFooter from "../pages/LandingPage/SubFooter/SubFooter";
import Footer from "../pages/LandingPage/Footer/footer";

const MainApp = () => {
  return (
    <>
      <Navigation />
      <div>
        <Outlet />
      </div>
      <SubFooter />
      <Footer />
    </>
  );
};

export default MainApp;
