import React from "react";

const EyeSlash = ({ className }) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.69 6.52505L2.01 3.84505L3.42 2.42505L21.15 20.165L19.74 21.575L16.32 18.155C14.98 18.685 13.52 18.975 12 18.975C7 18.975 2.73 15.865 1 11.475C1.77 9.50505 3.06 7.80505 4.69 6.52505ZM12 5.97505C15.79 5.97505 19.17 8.10505 20.82 11.475C20.23 12.695 19.4 13.745 18.41 14.595L19.82 16.005C21.21 14.775 22.31 13.235 23 11.475C21.27 7.08505 17 3.97505 12 3.97505C10.73 3.97505 9.51 4.17505 8.36 4.54505L10.01 6.19505C10.66 6.06505 11.32 5.97505 12 5.97505ZM10.93 7.11505L13 9.18505C13.57 9.43505 14.03 9.89505 14.28 10.465L16.35 12.535C16.43 12.195 16.49 11.835 16.49 11.465C16.5 8.98505 14.48 6.97505 12 6.97505C11.63 6.97505 11.28 7.02505 10.93 7.11505ZM9.51 11.345L12.12 13.955C12.08 13.965 12.04 13.975 12 13.975C10.62 13.975 9.5 12.855 9.5 11.475C9.5 11.4501 9.5025 11.4301 9.505 11.4101L9.505 11.41L9.505 11.41C9.5075 11.39 9.51 11.37 9.51 11.345ZM7.86 9.69505L6.11 7.94505C4.9 8.86505 3.88 10.045 3.18 11.475C4.83 14.845 8.21 16.975 12 16.975C12.95 16.975 13.87 16.835 14.75 16.595L13.77 15.615C13.23 15.845 12.63 15.975 12 15.975C9.52 15.975 7.5 13.955 7.5 11.475C7.5 10.845 7.63 10.245 7.86 9.69505Z"
          fill="#3B7A71"
        />
      </g>
    </svg>
  );
};

export default EyeSlash;
