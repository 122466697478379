import React from "react";

import { FormProvider, useForm } from "react-hook-form";
import { EditNeighborhoodDiv } from "./EditNeighborhood.styles";

import {
  createNeighbohoodFormFromNeighborhood,
  neighborhoodEditFormSchema,
} from "./EditNeighborhood.utils";
import { yupResolver } from "@hookform/resolvers/yup";

import { EditNeighborhoodForm } from "../EditNeighborhoodForm/EditNeighborhoodForm";

export function EditNeighborhoodRaw(props) {
  const { neighborhoodData } = props;

  const form = useForm({
    defaultValues: createNeighbohoodFormFromNeighborhood(neighborhoodData),
    resolver: yupResolver(neighborhoodEditFormSchema),
    mode: "onChange",
  });

  return (
    <FormProvider {...form}>
      <EditNeighborhoodDiv>
        <p className="title">Edit your Neighborhood Profile</p>

        <EditNeighborhoodForm />
      </EditNeighborhoodDiv>
    </FormProvider>
  );
}
