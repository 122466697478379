import React from "react";
import "./loader.css";

const BtnLoader = ({
  border_bottom,
  border_top,
  border_left,
  border_right,
}) => {
  const styles = {
    borderTop: border_top,
    borderLeft: border_left,
    borderBottom: border_bottom,
    borderRight: border_right,
  };
  return <div className="btn-loader" style={styles}></div>;
};

export default BtnLoader;
