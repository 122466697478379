import React from "react";

import { TextInput } from "components/TextInput/TextInput";
import { ProfilePicSection } from "../ProfilePicSection/ProfilePicSection";
import { FormInputGrid } from "./EditNeighborhoodForm.styles";
import { Controller, useFormContext } from "react-hook-form";
import { EditNeighborhoodButtons } from "./EditNeighborhoodButtons";
import * as testId from "utilities/testIds";
import sessionInfo from "utilities/session/sessionInfo";

export function EditNeighborhoodForm() {
  const form = useFormContext();
  const { role } = sessionInfo();
  const isFieldDisabled = role && role !== "BCO";

  return (
    <>
      <ProfilePicSection />
      <FormInputGrid>
        {[
          { label: "Neighborhood name", fieldName: "neighborhood_name" },
          { label: "Affiliate company", fieldName: "company_name" },
          { label: "Company website", fieldName: "company_website" },
          { label: "Company Facebook page", fieldName: "facebook" },
          { label: "Company Twitter page", fieldName: "twitter" },
          { label: "Company Instagram page", fieldName: "instagram" },
          { label: "Company LinkedIn page", fieldName: "linkedin" },
          {
            label: "Neighborhood short description",
            fieldName: "neighborhood_description",
            as: "textarea",
          },
        ].map(({ label, fieldName, as }) => (
          <Controller
            key={fieldName}
            control={form.control}
            name={fieldName}
            render={({ field, fieldState }) => {
              const fieldProps = {
                value: field.value ?? undefined,
                onChange: field.onChange,
                label,
                testId: testId.neighborhoods.inputField(fieldName),
                error: fieldState?.error?.message,
              };
              return as ? (
                <TextInput
                  {...fieldProps}
                  as={as}
                  rows={5}
                  disabled={isFieldDisabled}
                />
              ) : (
                <TextInput {...fieldProps} disabled={isFieldDisabled} />
              );
            }}
          />
        ))}
      </FormInputGrid>

      <EditNeighborhoodButtons />
    </>
  );
}
