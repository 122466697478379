import styled from "styled-components";

export const FormInputGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 35px;
  column-gap: 120px;
  justify-content: space-between;
  width: 100%;

  :not(:last-child) {
    margin-bottom: 35px;
  }

  div:last-child {
    grid-column: 1 / -1;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 35px;
  }
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 80px;
  margin-bottom: 80px;
  flex-wrap: wrap;

  .buttons {
    border-radius: 10px;
    padding: 16px 12px;
    min-width: 248px;
    max-height: 60px;
    font-size: 1.375rem;

    @media screen and (max-width: 768px) {
      font-size: 1rem;
      min-width: 200px;
      padding: 8px 12px;
    }
  }
`;
