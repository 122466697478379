import React from "react";
import { LinkedIn } from "react-linkedin-login-oauth2";
import LinkedinIcon from "../../../assets/svgs/LinkedinIcon";
import "./LinkedinAuthButton.css";

const LinkedInClientId = process.env.REACT_APP_LINKEDIN_CLIENT_ID;

export const LinkedInButton = ({
  className,
  text,
  handleLinkedInSigninSuccess,
  handleLinkedInSigninFailure,
}) => {
  const baseUrl = `${window.location.origin}/linkedin`;
  return (
    <LinkedIn
      className={className}
      clientId={LinkedInClientId}
      onSuccess={handleLinkedInSigninSuccess}
      onFailure={handleLinkedInSigninFailure}
      redirectUri={baseUrl}
      scope={["openid", "email", "profile"]}
      state={"maks1hefH5EYB"}
      supportIE
    >
      <LinkedinIcon className="LinkednIcon" />
      <span>{text}</span>
    </LinkedIn>
  );
};
