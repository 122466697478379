import React, { useCallback, useState } from "react";
import Upload from "../assets/svgs/upload";
import Link from "../assets/svgs/link";
import Plus from "../assets/svgs/plus";
import InviteMembersPopup from "../pages/InviteMember/InviteMembersPopup/invite-members-popup";
import ImportMembers from "../pages/InviteMember/ImportMembers/import-members";
import AddMembersManually from "../pages/InviteMember/AddMembersManually/add-members-manually";
import AddMembersWithinRialto from "../pages/InviteMember/AddMembersWithinRialto/add-members-within-rialto";
import AddMembersOutsideRialto from "../pages/InviteMember/AddMembersOutsideRialto/add-members-outside-rialto";
import ShareLink from "../pages/InviteMember/ShareLink/share-link";

// Create a content component tree to hold the components and their children
const contents = [
  {
    id: "import",
    name: () => (
      <button className="invite-members-popup__card">
        <Upload className="invite-members-popup__card--svg" />
        <p className="invite-members-popup__card--text">Import</p>
      </button>
    ),
    color: "#D8D8D8",
    children: [
      {
        id: "import-members",
        name: ImportMembers,
      },
    ],
  },
  {
    id: "add-manually",
    name: () => (
      <button className="invite-members-popup__card">
        <Plus className="invite-members-popup__card--svg" />
        <p className="invite-members-popup__card--text">Add manually</p>
      </button>
    ),
    parent: AddMembersManually,
    children: [
      {
        id: "add-members-option1",
        color: "#242526",
        name: () => (
          <button className="add-members-manually__btn filled">
            <span className="add-members-manually__span">
              From Within Rialto
            </span>
            <span className="add-members-manually__span--plus">+</span>
          </button>
        ),
        children: [
          {
            id: "add-members-within-rialto",
            name: AddMembersWithinRialto,
          },
        ],
      },
      {
        id: "add-members-option2",
        name: () => (
          <button className="add-members-manually__btn outlined">
            <span className="add-members-manually__span">
              From Outside Rialto
            </span>
            <span className="add-members-manually__span--plus">+</span>
          </button>
        ),
        children: [
          {
            id: "add-members-outside-rialto",
            name: AddMembersOutsideRialto,
          },
        ],
      },
    ],
  },
  {
    id: "share",
    name: () => (
      <button className="invite-members-popup__card">
        <Link className="invite-members-popup__card--svg" />
        <p className="invite-members-popup__card--text">Share link</p>
      </button>
    ),
    color: "#D8D8D8",
    children: [
      {
        id: "share-link",
        name: ShareLink,
      },
    ],
  },
];

/**
 * @name findParent
 * @description Find parent function finds the parent of a particular child
 * @param {Object[]} items - The array of the passed in contents
 * @param {string} id - id of the current element
 * @param {*} parent - the current item in each iteration
 * @returns {*} - the current parent of a particular child
 */
const findParent = (items, id, parent) => {
  for (const item of items) {
    const currentParent =
      item.id === id
        ? parent
        : item.children && findParent(item.children, id, item);
    if (currentParent) return currentParent;
  }
};

const EmptyFragment = ({ children }) => <>{children}</>;

export const useDynamicContent = ({ setOpen }) => {
  const [dynamicContent, setDynamicContent] = useState();
  const [bgColor, setBgColor] = useState("#E0E0E0");

  /**
   * @name onClose
   * @description This function is responsible for going back to previous children or closing the modal when at the root of the array
   * @param {boolean} isRoot - checks if the content is at the root of the tree
   * @param {Object} content - represents the current content being displayed
   * @param {Object} rootContent - an item in the content array
   */
  const onClose = (isRoot, content, rootContent) => {
    const currentRoot = rootContent?.id === content?.id;
    if (isRoot || currentRoot) handleClose();
    else updateData(findParent(contents, content?.id) || contents, rootContent);
  };

  /**
   * @name triggerNextChildren
   * @description A function that triggers the next content/component to be displayed
   * @param {Array | Object} content - represents an array of components to be rendered or an item in the contents array
   * @param {Object} rootContent - an item in the content array
   * @returns {*} - a component that renders the children
   */
  const triggerNextChildren = useCallback(
    (content, rootContent) => {
      const isRoot = Array.isArray(content);
      const dataArr = isRoot ? content : content.children;

      const Wrapper = isRoot
        ? InviteMembersPopup
        : content.parent
        ? content.parent
        : EmptyFragment;

      return (
        <Wrapper onClose={() => onClose(isRoot, content, rootContent)}>
          {dataArr?.map((Popup) => (
            <div
              onClick={() => Popup.children && updateData(Popup, rootContent)}
              key={Popup.id}
              data-testid="popupContent"
            >
              <Popup.name
                onClose={() => onClose(isRoot, content, rootContent)}
                handleClose={handleClose}
              />
            </div>
          ))}
        </Wrapper>
      );
    },
    [dynamicContent, bgColor]
  );

  /**
   * @name updateData
   * @description This function sets the state of the content and the background color
   * @param {Array | Object} content - represents an array of components to be rendered or an item in the contents array
   * @param {Object} rootContent - an item in the content array
   * @returns {void}
   */
  const updateData = (content, rootContent) => {
    setDynamicContent(triggerNextChildren(content, rootContent));
    if (!Array.isArray(content)) setBgColor(content.color || "#E0E0E0");
  };

  /**
   * @name handleOpen
   * @description This function opens the modal and updates the dynamic content state
   * @returns {void}
   */
  const handleOpen = () => {
    setDynamicContent(triggerNextChildren(contents));
    setOpen(true);
  };

  /**
   * @name handleClose
   * @description This function closes the modal and updates the state of the content to undefined
   * @returns {void}
   */
  const handleClose = () => {
    setOpen(false);
    setDynamicContent(undefined);
  };

  return {
    dynamicContent,
    handleOpen,
    handleClose,
    updateData,
    contents,
    bgColor,
  };
};
