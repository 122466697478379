import * as yup from "yup";

export function getFileFromBuffer(fileData) {
  if (fileData) {
    const buffer = Buffer.from(fileData?.buffer?.data);
    const file = new File([buffer], fileData?.originalname, {
      type: fileData?.mimetype,
    });
    return file;
  } else return null;
}

export const createNeighbohoodFormFromNeighborhood = (data) => {
  return {
    company_name: data?.neighborhood?.company_name,
    company_website: data?.neighborhood?.company_website,
    facebook: data?.neighborhood?.facebook,
    instagram: data?.neighborhood?.instagram,
    linkedin: data?.neighborhood?.linkedin,
    neighborhood_description: data?.neighborhood?.neighborhood_description,
    neighborhood_name: data?.neighborhood?.neighborhood_name,
    twitter: data?.neighborhood?.twitter,
    file: {
      id: null,
      file: getFileFromBuffer(data?.neighborhood_picture),
    },
  };
};

export const neighborhoodEditFormSchema = yup.object({
  company_name: yup.string().required("Company name is required"),
  neighborhood_name: yup.string().required("Neighborhood name is required"),
  neighborhood_description: yup.string().nullable(),
  facebook: yup.string().nullable(),
  instagram: yup.string().nullable(),
  twitter: yup.string().nullable(),
  company_website: yup.string().url("Enter a correct url").nullable(),
  linkedin: yup.string().nullable(),
  file: yup
    .object({
      id: yup.string().nullable(),
      file: yup.mixed().nullable(),
    })
    .nullable(),
});
