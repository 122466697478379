import styled, { css } from "styled-components";
import { COLORS } from "styles/colors/minwo";

export const TextInputPlaceholder = styled.div`
  & .error {
    font-size: 12px;
    color: ${COLORS.red.rd1};
    margin-rop: 6px;
  }
`;

export const TextInputDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const FieldStyle = css`
  border-radius: 10px;
  border: 2px solid ${COLORS.gray.gr3};
  box-shadow: 2px 2px 10px 0px rgba(100, 65, 103, 0.2);
  background-color: unset;
  font-size: 20px;

  &:focus {
    outline: none;
  }

  @media screen and (max-width: 600px) {
    font-size: 1rem;
  }
`;

export const TextInputField = styled.input`
  ${FieldStyle}
  height: 65px;
  display: flex;
  width: 100%;
  padding: 10px 27px;

  @media screen and (max-width: 600px) {
    height: 50px;
  }
`;

export const TextareaField = styled.textarea`
  ${FieldStyle}
  width: 100%;
  padding: 10px;
  letter-spacing: normal;
  word-spacing: normal;
  line-height: normal;
`;
