import styled from "styled-components";

export const EditNeighborhoodDiv = styled.div`
  padding: 36px 56px;

  .title {
    font-size: 20px;
    font-weight: 700;
    maring-top: 36px;
  }

  &.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80vh;
  }

  @media screen and (max-width: 900px) {
    padding: 36px 42px;
  }
  @media screen and (max-width: 768px) {
    padding: 36px 32px;
  }
  @media screen and (max-width: 600px) {
    padding: 36px 22px;
  }
  @media screen and (max-width: 480px) {
    padding: 36px 18px;
  }
`;
