import React, { useState, useEffect, useContext, useRef } from "react";
import "../styles/components/RialtoNavBar.css";
import NotificationCard from "../components/NotificationCard";
import urls from "../api/baseUrl";
import axios from "axios";
import store from "../utilities/storage.js";
import sessionInfo from "../utilities/session/sessionInfo.js";
import UserModal from "../components/UserModal/UserModal";
import { Link } from "react-router-dom";
import Context from "../contextStore/neighborhood-context";
import closeButton from "../assets/Icons/cancel_24px.svg";
import hamburgers from "../assets/Images/minwo_hamburger.svg";
import api from "../api/crib";
import UserImageAvatar from "./GlobalComponents/UserImageAvatar";
import { appLogo } from "config";

const removeModal = () => {
  const MainNav = document.querySelector(".MainNav");
  MainNav.classList.remove("Active");
  document.querySelector(".NavbarToggle .Icon").innerHTML = "menu";
};

const session_info = sessionInfo();

const { token, account_id } = session_info;

const getUserNotifications = async () => {
  const url = `${urls.base_url}notifications/get_user_notifications`;

  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer: ${token}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      let totalCard = 0;
      data.data.forEach((element) => {
        if (element.viewed) {
          totalCard = totalCard + 1;
        }
      });
      store.write("total_notifications", totalCard);
    })
    .catch((error) => {});
};

getUserNotifications();

function HomeIcon() {
  return (
    <svg
      className="Icon"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 9L12 2L21 9V20C21 20.5304 20.7893 21.0391 20.4142 21.4142C20.0391 21.7893 19.5304 22 19 22H5C4.46957 22 3.96086 21.7893 3.58579 21.4142C3.21071 21.0391 3 20.5304 3 20V9Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 22V12H15V22"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
function Search() {
  return (
    <svg
      className="Icon Search"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.0004 19.0004L14.6504 14.6504"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function NotificationIcon({ total }) {
  const totalCount = store.retrieve("total_notifications") || 0;

  let active;
  totalCount > 0 ? (active = true) : (active = false);
  return (
    <div className="IconWrapper">
      <svg
        className="Icon"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.6667 7C17.6667 3.68629 14.6819 1 11 1C7.3181 1 4.33333 3.68629 4.33333 7C4.33333 14 1 16 1 16H21C21 16 17.6667 14 17.6667 7"
          style={{ stroke: active ? "#F3A712" : "white" }}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.9226 19.9998C12.525 20.6166 11.7926 20.9963 11.0003 20.9963C10.208 20.9963 9.47568 20.6166 9.07812 19.9998"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          style={{ stroke: active ? "#F3A712" : "white" }}
        />
      </svg>
      {active ? <div className="TotalNotifs">{totalCount}</div> : <></>}
    </div>
  );
}

function StorageIcon() {
  return (
    <svg
      className="Icon"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 11H15L13 14.75H9L7 11H1"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.45 2.3875L1 11V18.5C1 19.8807 1.89543 21 3 21H19C20.1046 21 21 19.8807 21 18.5V11L17.55 2.3875C17.2123 1.53793 16.5189 1.0005 15.76 1H6.24C5.48106 1.0005 4.78773 1.53793 4.45 2.3875Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function Avatar({ toggleUser, toggleMainNav }) {
  const [isSubscribed, setIsSubscribed] = useState(true);
  const ctx = useContext(Context);
  const toggleAvatar = (e) => {
    e.preventDefault();
    if (!window.location.pathname.includes(account_id)) {
      // history.push(`/crib/${account_id}`);
      // window.location.reload()
    }
    ctx.toggleNav();
    // toggleMainNav()
  };
  const { initials } = session_info;
  const [s3_picture_url, setS3PictureURL] = useState("");

  useEffect(() => {
    return () => setIsSubscribed(false);
  }, []);

  useEffect(() => {
    (async () => {
      const data = await api.getCribPicture(account_id);
      if (!!isSubscribed) {
        setS3PictureURL(data.data);
      }
    })();
  }, []);

  const defaultAvatar = () => (
    <>
      <div className="Icon Initials" onClick={toggleUser}>
        {initials}
      </div>
    </>
  );

  return (
    <>
      <UserImageAvatar
        s3_picture_url={s3_picture_url}
        Alt="Profile"
        className="Icon Initials"
        onClick={toggleAvatar}
        defaultAvatar={defaultAvatar()}
      />
    </>
  );
}

export default function RealtoNavBar({
  editUserProfile,
  setHamburgerState,
  setIsUserToggled,
  // setIsEditUserProfile,
  // changeLocation,
  // toggleMainNav,
  // hamburgerState,
  // isUserToggled,
  // cribInfo,
}) {
  const [isSubscribed, setIsSubscribed] = useState(true);
  const ctx = useContext(Context);
  const company = store.retrieve("company");

  let hamburger = document.querySelector(".NavbarToggle .Icon");
  const [message, setShowMessage] = useState(false);
  const [notifications, setNotification] = useState([]);

  useEffect(() => {
    return () => setIsSubscribed(false);
  }, []);

  const handleNotification = () => {
    if (!!isSubscribed) {
      setShowMessage(!message);
    }
  };
  let urlHref = "";

  const listClicked = () => {
    if (!!isSubscribed) {
      ctx.toggleNav();
      setHamburgerState((prevState) => !prevState);
    }
  };
  function toggleNavbar(e, setIsUserToggled) {
    // toggleMainNav()
    if (!!isSubscribed) {
      ctx.toggleNav();
    }
  }

  const [screenWidth, setScreenWidth] = useState(0);
  const editUserProfileHandler = () => {
    if (window.location.href.includes(`${session_info.account_id}`, 0)) {
      editUserProfile();
      document.querySelector(".MainNav").classList.remove("Active");
    } else {
      window.history.pushState("", "", `crib/${session_info.account_id}`);
    }
  };
  const createNewNeighborhoodHandler = () => {
    window.history.pushState("", "", `/setup/neighborhoodprofile/step1`);
  };
  let userTogglerHandler = (event) => {
    event.preventDefault();
    document.querySelector(".MainNav").classList.remove("Active");
  };

  /* eslint-disable-next-line no-unused-vars */
  const [total, setTotal] = useState(store.retrieve("total_notifications"));

  /* eslint-disable-next-line no-unused-vars */
  const [active, setActive] = useState(total > 0);
  const url = "";
  useEffect(() => {
    if (!!isSubscribed) {
      const width = Math.max(
        document.body.scrollWidth,
        document.documentElement.scrollWidth,
        document.body.offsetWidth,
        document.documentElement.offsetWidth,
        document.documentElement.clientWidth
      );
      setScreenWidth(width);
    }
  }, []);

  const getUserNotifications = () => {
    const url = `${urls.base_url}notifications/get_user_notifications`;

    axios(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer: ${token}`,
      },
    })
      .then((data) => {
        let totalCard = 0;
        if (data?.data?.forEach) {
          data.data.forEach((element) => {
            if (!element.viewed) {
              totalCard = totalCard + 1;
            }
          });
          store.write("total_notifications", totalCard);
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (!!isSubscribed) {
      getUserNotifications();
    }
  }, []);

  const checkUserRole = () => {
    if (
      sessionInfo().role &&
      sessionInfo().role.includes("RialtoAdmin") == true
    ) {
      return true;
    } else {
      return false;
    }
  };

  const BottomNavigation = ({ active, total }) => {
    return screenWidth < 601 ? (
      <nav className="MobileNav">
        <ul>
          {window.location.href.split("/")[
            window.location.href.split("/").length - 2
          ] === "neighborhood" ? (
            <li>
              <a
                href={`/crib/${session_info.account_id}`}
                className="NavLink Active"
              >
                <HomeIcon />
                <span>Home</span>
              </a>
            </li>
          ) : (
            <></>
          )}
          {window.location.href.split("/")[
            window.location.href.split("/").length - 2
          ] === "neighborhoodprofile" ? (
            <li>
              <a href={`/crib/${session_info.account_id}`} className="NavLink">
                <HomeIcon />
                <span>Home</span>
              </a>
            </li>
          ) : (
            <></>
          )}
          <li>
            <a href="/explore" className="NavLink">
              <Search />
              <span>Search</span>
            </a>
          </li>
          <li>
            <a onClick={handleNotification} className="NavLink">
              <NotificationIcon active={active} total={total} />
              <span>Notification</span>
            </a>
          </li>
          <li>
            <a href="#Storage" className="NavLink">
              <StorageIcon />
              <span>Storage</span>
            </a>
          </li>
          <li>
            <a href={url} className="NavLink">
              <Avatar
                className="Avatar"
                alt="avatar"
                toggleUser={() => {}}
                toggleMainNav={() => {}}
              />
              <span>Profile</span>
            </a>
          </li>
        </ul>
      </nav>
    ) : (
      <></>
    );
  };
  const modalRef = useRef();

  useEffect(() => {
    const handler = (event) => {
      if (modalRef.current && !modalRef.current.includes(event.target)) {
        ctx.closeModal();
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, [modalRef]);

  return (
    <>
      <nav className="RialtoNavBar">
        <span className="NavbarToggle" id="NavbarToggle">
          <img
            src={ctx.isNavOpen ? closeButton : hamburgers}
            alt="menu"
            onClick={(e) => toggleNavbar(e, setIsUserToggled)}
          />
        </span>
        <Link to={`/crib/${account_id}`}>
          <img
            className="RialtoLogo"
            src={appLogo.desktop}
            alt="Rialto Logo"
          ></img>
        </Link>
        <ul className="MainNav" id="js-menu">
          {window.location.href.split("/")[3] === "neighborhood" ? (
            <li>
              <a
                href={
                  session_info.account_id
                    ? `/crib/${session_info.account_id}`
                    : `/crib`
                }
                className="NavLink Active"
              >
                <HomeIcon />
                <span>Home</span>
              </a>
            </li>
          ) : (
            <></>
          )}
          <li>
            <a href="/explore" className="NavLink">
              <Search />
              <span>Search</span>
            </a>
          </li>
          <li>
            <a onClick={handleNotification} className="NavLink">
              <NotificationIcon active={active} total={total} />
              <span>Notification</span>
            </a>
          </li>
          <li>
            <a href="#Storage" className="NavLink">
              <StorageIcon />
              <span>Storage</span>
            </a>
          </li>
          <li>
            <a href={url} className="NavLink" onClick={userTogglerHandler}>
              <Avatar className="Avatar" alt="avatar" />
              <span>Profile</span>
            </a>
          </li>
        </ul>
        <div className="NavOverlay" onClick={(e) => toggleNavbar(e)}></div>
      </nav>
      {ctx.isNavOpen ? (
        <UserModal
          editUserProfile={editUserProfileHandler}
          createNewNeighborhood={createNewNeighborhoodHandler}
          listClicked={listClicked}
          cribInfo={company}
          setIsUserToggled={setIsUserToggled}
          roleCheck={checkUserRole()}
          closeMenu={ctx.closeNav}
        />
      ) : null}
      <BottomNavigation active={active} total={total} />
      {message ? <NotificationCard /> : null}
    </>
  );
}
