const SetupCrib = {
  step1: { Firstname: '', Lastname: '', Title: '', Company: '', Website: '', Instagram: '', Facebook: '', Twitter: '', LinkedIn: "", File: '' },
  step2: {
    Summary: '', 
  },
  step3: { Title: '', Category: '', Description: '', Upload: '' },
  step4: { EventName: '', Category: '', Date: '', Time: '', Description: '' },
  step5: { ServiceName: '', ServiceType: '', Cost: '', YearsOfExperience: '', Description: '' }
}

export default SetupCrib
