import React from "react";
import { NavLink } from "react-router-dom";
import classes from "./Menu.module.css";
import corner from "../../../../assets/Images/minwo_menu_cornerCircle.svg";
import minwo_menu_firstCircle from "../../../../assets/Images/minwo_menu_firstCircle.svg";
import minwo_menu_secondCircle from "../../../../assets/Images/minwo_menu_secondCircle.svg";
import minwo_menu_Root from "../../../../assets/Images/minwo_menu_Root.svg";
import minwo_menuCancel from "../../../../assets/Images/minwo_menuCancel.svg";

const Menu = (props) => {
  return (
    <div className={classes.menu}>
      <div className={classes.menuSec}>
        <div
          className={classes.cancel}
          onClick={() => props.setShowMenu(false)}
        >
          <img src={minwo_menuCancel} alt="close" />
        </div>
        <ul>
          {props.lists}
          <NavLink to="/sign-in" className={classes.signIn}>
            Sign in
          </NavLink>
        </ul>
        <div className={classes.btn}>
          <NavLink to="/pricing" className={classes.direct}>
            Sign up
          </NavLink>
        </div>
      </div>
      <div className={classes.root}>
        <img src={minwo_menu_Root} alt="root" />
      </div>
      <div className={`${classes.first} ${classes.circle}`}>
        <img src={minwo_menu_firstCircle} alt="figure" />
      </div>
      <div className={`${classes.corner} ${classes.circle}`}>
        <img src={corner} alt="figure" />
      </div>
      <div className={`${classes.second} ${classes.circle}`}>
        <img src={minwo_menu_secondCircle} alt="figure" />
      </div>
    </div>
  );
};

export default Menu;
