import store from "../../utilities/storage.js";

// remove existing session from local storage
const clearCurrentSession = () => {
  store.remove("login_time");
  store.remove("ShRSessToken");
  store.remove("firstname");
  store.remove("lastname");
  store.remove("job_title");
  store.remove("company");
  store.remove("role");
  store.remove("account_id");
  store.remove("initials");
  store.remove("crib_created");
  store.remove("neighborhood_created");
  store.remove("setup_neighborhood_id");
  store.remove("neighborhood_id");
  store.remove("profile_picture");
  store.remove("neighborhood_privileges");
  store.remove("total_notifications");
  store.remove("region");
  store.remove("years_of_experience");
  store.remove("size_of_clientele");
  store.remove("timezone_id");
  store.remove("first_neighborhood");
};

/**
 * Store session for new sign in or authorization
 * @param {Object} data
 *
 */
const storeSession = (data) => {
  clearCurrentSession();

  const date = new Date();
  store.write("login_time", date.getTime());
  store.write("ShRSessToken", data.token);
  store.write("firstname", data.firstname);
  store.write("lastname", data.lastname);
  store.write("job_title", data.job_title);
  store.write("role", data.role);
  store.write("company", data.company);
  store.write("account_id", data.account_id);
  store.write("initials", data.initials);
  store.write("crib_created", data.crib);
  store.write("neighborhood_created", data.neighborhood);
  store.write("neighborhood_id", data.neighborhood_id || "");
  store.write("profile_picture", data.profile_picture);
  store.write(
    "neighborhood_privileges",
    JSON.stringify(data.neighborhood_privileges || [])
  );
  store.write("total_notifications", "0");
  store.write("region", data.region);
  store.write("years_of_experience", data.years_of_experience);
  store.write("size_of_clientele", data.size_of_clientele);
  store.write("timezone_id", data.timezone_id);
  store.write("first_neighborhood", data.first_neighborhood);
};

export default storeSession;
