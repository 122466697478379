import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { appLogo } from "config";
import { ReactComponent as SignInRoot } from "../assets/Icons/CreateAccountRoot.svg";
import API from "../api/Account.js";
import CityLabLogo from "assets/logos/CLP_logo.png";
import TickSuccess from "assets/svgs/TickSuccess";
import TickError from "assets/svgs/TickError";
import "../styles/pages/ResetPassword.css";

const ResetPassword = () => {
  const { customRoute } = useParams();

  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [password1Valid, setPassword1Valid] = useState(false);
  const [password2Valid, setPassword2Valid] = useState(false);
  const { token } = useParams();

  const resetUserPassword = async (e) => {
    e.preventDefault();
    const payload = { password: password2, token };
    const res = await API.resetPassword(payload);
    if (res.status === "success") {
      alert("Your password has been succesfully reset, please sign-in");
      window.location.href = `${window.location.origin}/sign-in`;
    }
  };

  useEffect(() => {
    if (password1.length > 0) {
      const password1_valid = password1.length >= 8;
      setPassword1Valid(password1_valid);
    }
    if (password2.length > 0) {
      const password2_valid = password2.length >= 8 && password1 === password2;
      setPassword2Valid(password2_valid);
    }
  }, [password1, password2]);

  return (
    <div className="reset-password">
      <div className="reset-password__banner">
        <div className="reset-password__banner--container">
          <div className="reset-password__banner--content">
            <div className="reset-password__logo--container">
              {customRoute ? (
                <img
                  src={CityLabLogo}
                  className="custom_logo"
                  alt="CityLab Logo"
                />
              ) : (
                <img
                  src={appLogo.desktop}
                  className="RialtoLogo"
                  alt="Rialto Logo"
                />
              )}
            </div>
            <div className="reset-password__banner--info">
              <span className="reset-password__banner--title">
                Reset password
              </span>
              <span className="reset-password__banner--sub">
                Create a new password for your account.
              </span>
              {customRoute ? (
                <div className="powered_by">
                  <p className="powered_by_text">Powered by</p>
                  <img
                    src={appLogo.desktop}
                    className="powered_by_logo"
                    alt="Rialto Logo"
                  />
                </div>
              ) : null}
            </div>
          </div>
          <SignInRoot className="SignInRoot" />
        </div>
      </div>
      <div className="reset-password__form--flex">
        <div className="reset-password__form--container">
          <h1 className="reset-password__form--heading">
            Enter your new password
          </h1>
          <form className="reset-password__form">
            <div className="form__input--container">
              <input
                className="form__input"
                type="password"
                placeholder="New password"
                value={password1}
                onChange={(e) => setPassword1(e.target.value)}
              />
              {password1.length === 0 ? (
                <></>
              ) : password1Valid ? (
                <TickSuccess className="FormFieldTick" />
              ) : (
                <TickError className="FormFieldTick" />
              )}
            </div>
            <div className="form__input--container">
              <input
                className="form__input"
                type="password"
                placeholder="Confirm password"
                value={password2}
                onChange={(e) => setPassword2(e.target.value)}
              />
              {password2.length === 0 ? (
                <></>
              ) : password2Valid ? (
                <TickSuccess className="FormFieldTick" />
              ) : (
                <TickError className="FormFieldTick" />
              )}
            </div>
            <button
              disabled={!password2Valid}
              onClick={resetUserPassword}
              className="Submit"
            >
              Reset Password
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
