import React, { createContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import SignInREQ from "../api/Signin.js";
import AppLoader from "components/Loader/loader.jsx";
import NotFoundPage from "components/NotFound/NotFoundPage.jsx";

export const CustomRouteContext = createContext({});

const CustomRouteProvider = ({ children }) => {
  const { customRoute } = useParams();
  const [error, setError] = useState(false);

  const {
    isLoading,
    isRefetching,
    refetch,
    data: onboardingData,
  } = useQuery({
    retry: 1,
    enabled: false,
    refetchOnWindowFocus: false,
    queryKey: "getOnboardingData",
    queryFn: () => SignInREQ.getOnboardingData(customRoute),
    onError: () => {
      setError(true);
    },
  });

  useEffect(() => {
    if (customRoute) refetch();
  }, []);

  if (isLoading || isRefetching) return <AppLoader />;

  return (
    <CustomRouteContext.Provider value={{ onboardingData, customRoute }}>
      {error ? <NotFoundPage /> : <>{children}</>}
    </CustomRouteContext.Provider>
  );
};

export default CustomRouteProvider;
