import React from "react";
import classes from "../../components/NeighborhoodAndConnection/OwnedNeighborhood/OwnedNeighborhood.module.css";
import Card from "../../UI/Card/Card";
import { useNavigate } from "react-router-dom";
import { Shimmer } from "../../components";

export function NeighborhoodsLoading() {
  const navigate = useNavigate();
  return (
    <>
      <button onClick={() => navigate(-1)} className={classes.takeMeBack}>
        Back
      </button>
      <Card className={classes.myNeighborhood}>
        <header>
          <div className={classes.topDetail}>
            <h1>Neighborhoods</h1>
            <Shimmer
              style={{ height: 20, width: 20, borderRadius: 4 }}
              primary="#f1f1f1"
              secondary="#ececec"
            />
          </div>
          <Shimmer
            style={{ height: 30, width: 250, borderRadius: 4 }}
            primary="#f1f1f1"
            secondary="#ececec"
          />
        </header>
        <ul>
          <Shimmer
            style={{ height: 180, width: 180, borderRadius: 4 }}
            primary="#f1f1f1"
            secondary="#ececec"
          />
          <Shimmer
            style={{ height: 180, width: 180, borderRadius: 4 }}
            primary="#f1f1f1"
            secondary="#ececec"
          />
          <Shimmer
            style={{ height: 180, width: 180, borderRadius: 4 }}
            primary="#f1f1f1"
            secondary="#ececec"
          />
        </ul>
      </Card>
    </>
  );
}
