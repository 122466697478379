import React from "react";

import * as testId from "utilities/testIds";
import { Controller, useForm } from "react-hook-form";
import { messageSchema } from "./CheckboxSelectForm.utils";
import { CheckboxSelect } from "./CheckboxSelect";
import { SelectedOptionSubmitButton } from "../DropdownSelect/DropdownSelect.styles";
import { yupResolver } from "@hookform/resolvers/yup";

export function CheckboxSelectForm(props) {
  const {
    buttonText,
    options,
    handleOptionPost,
    message,
    messageKey,
    setMessageSentByUser,
    setMessagesToSubmit,
    activeChat,
    messageType,
    isSuccess,
  } = props;

  const form = useForm({
    defaultValues: {
      bizStructure: [],
    },
    mode: "onChange",
    resolver: yupResolver(messageSchema),
  });
  const selectedOptions = form.watch("bizStructure");
  const cleanSelectedOptions = selectedOptions.filter((each) => each);
  const cleanSelectedNames = cleanSelectedOptions.map((each) => each.name);
  const cleanSelectedValues = cleanSelectedOptions.map((each) => each.value);

  return (
    <>
      {options.map((each, index) => (
        <Controller
          key={index}
          name={`bizStructure[${index}]`}
          control={form.control}
          render={({ field }) => {
            const namesSelectedToArr =
              messageType === "history"
                ? activeChat?.content?.split(message?.mergeTkn)
                : cleanSelectedNames;

            const isPartOfChat = namesSelectedToArr?.includes(each.name);
            return (
              <CheckboxSelect
                key={each.value}
                checkboxProps={{
                  ...field,
                  ["data-testid"]: testId.sandiChatbot.checkedValues(index),
                  checked: isPartOfChat,
                  disabled: !!activeChat || isSuccess,
                  onChange: (e) => {
                    const { onChange } = field;
                    const checkedObj = { value: each.value, name: each.name };
                    const checkedVal = e.target.checked
                      ? checkedObj
                      : undefined;
                    selectedOptions[index] = checkedVal;

                    onChange(checkedVal);
                    form.setValue("bizStructure", selectedOptions, {
                      shouldValidate: true,
                    });
                  },
                }}
                label={each.name}
              />
            );
          }}
        />
      ))}

      <SelectedOptionSubmitButton
        data-active={
          cleanSelectedOptions.length > 0 && !activeChat && !isSuccess
        }
        disabled={
          cleanSelectedOptions.length === 0 || !!activeChat || isSuccess
        }
        data-testid={testId.sandiChatbot.submitAnswersButton}
        onClick={() => {
          const baseOptionInfo = {
            id: message[messageKey[0]],
            createdAt: new Date(),
          };
          const formattedCheckedVals = cleanSelectedNames.join(
            `${message?.mergeTkn} `
          );
          handleOptionPost({
            ...baseOptionInfo,
            content: cleanSelectedValues,
          });
          setMessageSentByUser(true);
          setMessagesToSubmit((prevMessages) => [
            ...prevMessages,
            {
              ...baseOptionInfo,
              content: formattedCheckedVals,
              byUser: true,
            },
          ]);
        }}
      >
        {buttonText}
      </SelectedOptionSubmitButton>
    </>
  );
}
