import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import hamburger from 'assets/Images/minwo_hamburger.svg';
import classes from './Navigation.module.css';
import Menu from './Menu/Menu';
import { appLogo } from 'config';

function Navigation() {
	const [showMenu, setShowMenu] = useState(false);
	const [changeNavbarBgColor, setChangeNavbarBgColor] = useState(false);

	const closeMenuHandler = () => {
		setShowMenu(false);
	};

	const navArray = [
		{ name: 'Why Rialto?', path: '/whyRialto' },
		{ name: 'Features', path: '/features' },
		{ name: 'Pricing', path: '/pricing' },
	];
	const mappedLinks = navArray.map((item, index) => {
		return (
			<NavLink
				key={index}
				to={item.path}
				className={({ isActive }) =>
					isActive ? classes.activeLink : undefined
				}
				onClick={closeMenuHandler}
			>
				{item.name}
			</NavLink>
		);
	});
	const handleChangeBackground = () => {
		if (window.scrollY >= 100) {
			setChangeNavbarBgColor(true);
		} else {
			setChangeNavbarBgColor(false);
		}
	};
	window.addEventListener('scroll', handleChangeBackground);

	return (
		<nav
			className={`${classes.nav} ${changeNavbarBgColor ? classes.active : ''}`}
		>
			<div className={classes.largeScreenNav}>
				<Link to='/' className={classes.logo}>
					<img src={appLogo.desktop} alt='logo' />
				</Link>
				<ul className={classes.navContainer}>{mappedLinks}</ul>
				<div className={classes.btn}>
					<NavLink to='/sign-in' className={classes.signIn}>
						Sign in
					</NavLink>
					<NavLink to='/pricing' className={classes.direct}>
						Sign up
					</NavLink>
				</div>
			</div>
			<div className={classes.smallScreenNav}>
				<Link to='/' className={classes.logo}>
					<img src={appLogo.mobile} alt='logo' />
				</Link>
				<div className={classes.menu} onClick={() => setShowMenu(true)}>
					<img src={hamburger} alt='menu' />
				</div>
				{showMenu ? (
					<ul className={classes.navContainer}>
						<Menu lists={mappedLinks} setShowMenu={setShowMenu} />
					</ul>
				) : (
					''
				)}
			</div>
		</nav>
	);
}

export default Navigation;
