import { sandiApi } from 'api/api';

export async function getSandiChatHistory(params) {
	const { limit, from } = params;
	const { data } = await sandiApi.get('/chats', {
		params: {
			limit,
			from,
		},
	});

	return data;
}
