import { useState, useEffect } from "react";

export const useCheckCookiesEnabled = () => {
  const [cookiesEnabled, setCookiesEnabled] = useState(true);

  const checkCookiesEnabled = () => {
    const expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() + 1000); // Set expiration to 1 second from now
    document.cookie = "testCookie=1; expires=" + expirationDate.toUTCString();

    const cookiesEnabled = document.cookie.indexOf("testCookie=") !== -1;
    setCookiesEnabled(cookiesEnabled);

    // Clear the test cookie
    document.cookie = "testCookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";

    return cookiesEnabled;
  };

  useEffect(() => {
    checkCookiesEnabled();
    return () => {
      setCookiesEnabled(false); // Clean-up function
    };
  }, []);

  return { cookiesEnabled, checkCookiesEnabled };
};
