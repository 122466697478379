import React from "react";
import UserImageAvatar from "../../../../components/GlobalComponents/UserImageAvatar";

const SelectInputList = ({ option, info }) => {
  return (
    <div className="input-list">
      <UserImageAvatar
        s3_picture_url={option?.profile_picture_url}
        className="input-list__img"
        alt={`${option?.crib_first_name}'s avatar`}
      />
      <div className="input-list__container">
        <h2 className="input-list__name">
          {option?.crib_first_name} {option?.crib_last_name}
        </h2>
        {option?.existing_member ? (
          <p className="input-list__text">Already a Member</p>
        ) : (
          <p className="input-list__company">{info}</p>
        )}
      </div>
    </div>
  );
};

export default SelectInputList;
