import React from 'react';
import BusinessOrganizer from '../Pricing/BusinessOrganizer';
import './TabNavigation.css';

const TabNavigation = (props) => {
    return (
        <div className="pricing__tab--navigation">
            <ul className="pricing__tab--item">
                {
                    props.tabs.map(tab => {
                        const active = (tab === props.activeTab ? 'active' : '');

                        return (
                            <li className="pricing__tab--list" key={tab}>
                                <button onClick={() => props.handleActiveTab(tab)} className={`pricing__tab--link ${active}`}>{tab}</button>
                            </li>
                        )
                    })
                }
            </ul>
            {props.children}
        </div>
    )
}

export default TabNavigation;