import React from "react";

const Plus = ({ className }) => {
  return (
    <svg
      className={className}
      width="73"
      height="73"
      viewBox="0 0 73 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.4828 3.4729V69.9963"
        stroke="#3B7A71"
        strokeWidth="5.32187"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.2207 36.7345H69.7441"
        stroke="#3B7A71"
        strokeWidth="5.32187"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Plus;
