import React from "react";
import { Skeleton } from "@material-ui/lab";

const LoadingSkeleton = ({ index }) => {
  return (
    <div className="input-list" key={index} style={{ marginBottom: "15px" }}>
      <Skeleton
        variant="circle"
        animation="pulse"
        style={{
          borderRadius: "50%",
          width: "40px",
          height: "40px",
        }}
      />
      <div className="input-list__container">
        <Skeleton
          variant="rectangle"
          width="200px"
          height={10}
          style={{ marginBottom: "10px", borderRadius: "3px" }}
        />
        <Skeleton
          variant="rectangle"
          width="100px"
          height={10}
          style={{ borderRadius: "3px" }}
        />
      </div>
    </div>
  );
};

export default LoadingSkeleton;
