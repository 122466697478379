export function getFilteredNextConversations(messagesToSubmit) {
	const newNextConversations = () => {
		if (!messagesToSubmit) return;
		return messagesToSubmit
			?.filter((chat) => chat.id && chat.content)
			?.map((messagesToSubmitObj) => {
				const { options, ...rest } = messagesToSubmitObj;

				// REVIEW: IS THERE A NEED TO SPREAD `rest` HERE SINCE IT'S A NEW OBJECT FROM THE PREVIOUS LINE & NOTHING MUCH IS GOING ON WITH IT
				// CAN JUST SET `createdAt` ON `rest` AND RETURN
				return { ...rest, createdAt: new Date() };
			});
	};

	return newNextConversations();
}
