import React from "react";
import Logo from "../assets/logos/Minwo.png";
import Logo2 from "../assets/logos/Pipeline Angels.png";
import Logo3 from "../assets/logos/GV.png";
import Logo4 from "../assets/logos/Minwo.png";
import HeartIcon from "../assets/Icons/HeartIconExplore.svg";
import GV from "../assets/logos/GV.png";
import Avatar from "../assets/Images/avi copy 2.png";
import Pattern from "../assets/Roots/RIALTO-ROOT-Pattern 2_Root Pattern 2 - white.png";
import "../styles/components/RialtoBar.css";
import "../styles/pages/Explore.css";

export default function Explore() {
  const ExploreBar = () => {
    return (
      <div className="RialtoBar">
        <span className="Title">Explore Rialto</span>
        <span className="Details">
          See what events, resources, and more for you!
        </span>
        <div className="SearchBar">
          <input className="SearchInput" placeholder="Start typing..."></input>
          <button className="Search">Search</button>
        </div>
        <img className="pattern" src={Pattern} alt="Pattern"></img>
      </div>
    );
  };

  return (
    <div className="explore">
      <ExploreBar />
      <div className="Contents-Container">
        <div className="Row">
          <div className="Explore-Card-Mask">
            <div className="Explore-Card Suggested-Neighborhood">
              <div className="Header">
                <span className="Title">Suggested Neighborhoods</span>
                <span className="ShowMore">SHOW MORE</span>
              </div>
              <div className="InfoWrapper">
                <div className="Info">
                  <img className="Logo" src={Logo} alt="Logo" />
                  <span className="Name">MINWO Community</span>
                  <span className="Details">Business Development</span>
                </div>
                <div className="Info">
                  <img className="Logo" src={Logo2} alt="Logo" />
                  <span className="Name">Pipeline Angels</span>
                  <span className="Details">Venture Capital</span>
                </div>
                <div className="Info">
                  <img className="Logo" src={Logo3} alt="Logo" />
                  <span className="Name">GV</span>
                  <span className="Details">Venture Capital</span>
                </div>
                <div className="Info">
                  <img className="Logo" src={Logo4} alt="Logo" />
                  <span className="Name">MINWO Community</span>
                  <span className="Details">Business Development</span>
                </div>
              </div>
            </div>
          </div>
          {/* Featured Office Hours */}
          <div className="Explore-Card-Mask">
            <div className="Explore-Card Featured-Office-Hours">
              <div className="Header">
                <span className="Title">Featured Office Hours</span>
                <span className="ShowMore">SHOW MORE</span>
              </div>
              <div className="InfoWrapper">
                <div className="Info">
                  <img className="Avatar" src={Avatar} alt="Avatar" />
                  <div className="Content">
                    <span className="Name">Melanie Akwule</span>
                    <span className="Details">MINWO | Founder & CEO </span>
                    <div className="Footer">
                      <span className="Tag">Host</span>
                      <img
                        className="HeartIcon"
                        alt="Heart Icon"
                        src={HeartIcon}
                      ></img>
                    </div>
                  </div>
                </div>
                <div className="Info">
                  <img className="Avatar" src={Avatar} alt="Avatar" />
                  <div className="Content">
                    <span className="Name">Melanie Akwule</span>
                    <span className="Details">MINWO | Founder & CEO </span>
                    <div className="Footer">
                      <span className="Tag">Host</span>
                      <img
                        className="HeartIcon"
                        src={HeartIcon}
                        alt="HeartIcon"
                      ></img>
                    </div>
                  </div>
                </div>
                <div className="Info">
                  <img className="Avatar" alt="Avatar" src={Avatar} />
                  <div className="Content">
                    <span className="Name">Melanie Akwule</span>
                    <span className="Details">MINWO | Founder & CEO </span>
                    <div className="Footer">
                      <span className="Tag">Host</span>
                      <img
                        className="HeartIcon"
                        alt="HeartIcon"
                        src={HeartIcon}
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Row">
          {/* Featured Services */}
          <div className="Explore-Card-Mask" style={{ overflow: "hidden" }}>
            <span className="ExploreOverlayText">Coming Soon</span>
            <div className="ExploreOverlay"></div>
            <div className="Explore-Card Featured-Services">
              <div className="Header">
                <span className="Title">Featured Services</span>
                <span className="ShowMore">SHOW MORE</span>
              </div>
              <div className="InfoWrapper">
                <div className="Info">
                  <div className="Content">
                    <span className="Name">Copywriting</span>
                    <span className="Details">Technical</span>
                    <div className="Footer">
                      <span className="Details"></span>
                      <img
                        className="HeartIcon"
                        alt="HeartIcon"
                        src={HeartIcon}
                      ></img>
                    </div>
                  </div>
                </div>
                <div className="Info">
                  <div className="Content">
                    <span className="Name">Copywriting</span>
                    <span className="Details">Technical</span>
                    <div className="Footer">
                      <span className="Details"></span>
                      <img
                        className="HeartIcon"
                        alt="HeartIcon"
                        src={HeartIcon}
                      ></img>
                    </div>
                  </div>
                </div>
                <div className="Info">
                  <div className="Content">
                    <span className="Name">Copywriting</span>
                    <span className="Details">Technical</span>
                    <div className="Footer">
                      <span className="Details"></span>
                      <img
                        className="HeartIcon"
                        alt="HeartIcon"
                        src={HeartIcon}
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Featured Events */}
          <div className="Explore-Card-Mask" style={{ overflow: "hidden" }}>
            <span className="ExploreOverlayText">Coming Soon</span>
            <div className="ExploreOverlay"></div>
            <div className="Explore-Card Featured-Events">
              <div className="Header">
                <span className="Title">Featured Events</span>
                <span className="ShowMore">SHOW MORE</span>
              </div>
              <div className="InfoWrapper">
                <div className="Info">
                  <img className="Avatar" alt="Avatar" src={GV} />
                  <div className="Content">
                    <span className="Name">Biz Dev Webinar</span>
                    <span className="Details">Hosted by GV</span>
                    <div className="Footer">
                      <span className="Details"></span>
                      <img
                        className="HeartIcon"
                        alt="HeartIcon"
                        src={HeartIcon}
                      ></img>
                    </div>
                  </div>
                </div>
                <div className="Info">
                  <img className="Avatar" alt="Avatar" src={GV} />
                  <div className="Content">
                    <span className="Name">Biz Dev Webinar</span>
                    <span className="Details">Hosted by GV</span>
                    <div className="Footer">
                      <span className="Details"></span>
                      <img
                        className="HeartIcon"
                        alt="HeartIcon"
                        src={HeartIcon}
                      ></img>
                    </div>
                  </div>
                </div>
                <div className="Info">
                  <img className="Avatar" alt="Avatar" src={GV} />
                  <div className="Content">
                    <span className="Name">Biz Dev Webinar</span>
                    <span className="Details">Hosted by GV</span>
                    <div className="Footer">
                      <span className="Details"></span>
                      <img
                        className="HeartIcon"
                        alt="HeartIcon"
                        src={HeartIcon}
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
