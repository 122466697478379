import React from "react";
import { useNavigate } from "react-router-dom";
import "./ProductDescription.css";
import Vector from "../../../assets/Images/vector.svg";
import Vector2 from "../../../assets/Images/vector2.svg";
import purpleVector from "../../../assets/Images/purplevector.svg";
import offerimage from "../../../assets/Images/Group 570.png";
import offerneighborhood from "../../../assets/Images/Group 571.png";
import Vector3 from "../../../assets/Images/yellowvector.svg";
import greenVector from "../../../assets/Images/greenvector.svg";
import greenbob from "../../../assets/Images/greenbob.svg";
import Members from "../../../assets/Images/Group 572.png";
import groupResources from "../../../assets/Images/groupResources.png";
import groupNeighborhood from "../../../assets/Images/Group neighborhood.png";
import groupMembers from "../../../assets/Images/Group members.png";

const ProductDescription = () => {
  const navigate = useNavigate();
  const handleClick = () => navigate("/pricing");

  return (
    <div className="productDescription">
      <img src={Vector} alt="logo" className="vector" />
      <div className="productDescriptionContainer">
        {/* <img src={Vector} alt="logo" className="vector" /> */}
        <div className="sectionboxone">
          <div className="sectionboxmain">
            <p className="productheader">
              Enable entrepreneurs to &nbsp;
              <span className="subheader">Hustle Forward</span>.
            </p>
            {/* <span className="subheader">Hustle Forward</span><span className="vectordot" style={vectordot}>.</span> */}
            <p className="subheadertext">
              With AI-enabled, data-driven technology, Rialto finds out what
              your community’s needs are and brings them to you - resources,
              networks, events, subject matter experts, and more. This saves you
              countless, valuable hours to actually focus on your community.
            </p>
            {/* <Link to ={`/crib/${account_id}`} className="criblink">Crib Settings</Link> to see or change your answer. */}
            <button
              type="button"
              className="getStartedbutton"
              onClick={handleClick}
            >
              Get Started
            </button>
          </div>
          <div>
            <img src={purpleVector} alt="offer" className="purplevector" />
            <img src={offerimage} alt="offer" className="offerImage" />
            <img src={groupResources} alt="group" className="resourceImage" />
          </div>
        </div>
        <div>
          <img src={Vector2} alt="yellow vector" className="yellowvector" />
        </div>
        <div className="sectionboxone">
          <div>
            <img src={Vector3} alt="yellow vector" className="yellow_vector" />

            <img src={offerneighborhood} alt="offer" className="offerImage2" />
            <img
              src={groupNeighborhood}
              alt="neighborhood"
              className="neighborhoodImage"
            />
          </div>
          <div className="sectionboxmain">
            <p className="productheader">
              <span className="subheader">Connecting</span> the unconnected.
            </p>

            <p className="subheadertext">
              An ecosystem of communities and programs that prioritize Black
              business development gives Black business owners a one-stop shop
              to find the things they need to grow and scale.
            </p>
            <button
              type="button"
              className="getStartedbutton"
              onClick={handleClick}
            >
              Get Connected
            </button>
          </div>
        </div>
        <img src={greenVector} alt="green vector" className="green_vector" />
        <div className="sectionboxone">
          <div className="sectionboxmain">
            <p className="productheaders">
              Leverage <span className="subheader">Community-as-a-Service</span>
              .
            </p>
            <p className="subheadertext">
              Entrepreneurship is said to be a lonely journey, and it often can
              be. By leveraging Rialto’s community-enabling features, members
              get benefits including but not limited to knowledge transfer,
              connections, inspiration, resources, and support.
            </p>
            {/* <Link to ={`/crib/${account_id}`} className="criblink">Crib Settings</Link> to see or change your answer. */}

            <button
              type="button"
              className="buildCommunitybutton"
              onClick={handleClick}
            >
              Build Your Community
            </button>
          </div>
          <div>
            <img src={greenbob} alt="green bob" className="green_bob" />
            <img src={Members} alt="members profile" className="offerImage3" />
            <img src={groupMembers} alt="members " className="memberImage" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDescription;
