import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import getDataURLFromByteArray from "../../../utilities/getDataURLFromByteArray";

const LatestBulletin = ({ post }) => {
  const {
    bulletin_id,
    neighborhood_id,
    bulletin_title,
    bulletin_cover,
    bulletin_post_type,
  } = post;
  // console.log({
  //     bulletin_id, neighborhood_id,
  //     bulletin_title, bulletin_cover
  // });
  const [cover_image, setCoverImage] = useState("");
  const [coverLoading, setCoverLoading] = useState(false);

  let bulletin_link = `/neighborhood/${neighborhood_id}/bulletin/${bulletin_id}`;
  useEffect(() => {
    (async () => {
      if (bulletin_cover) {
        try {
          const cover = await axios.get(bulletin_cover);
          if (cover?.data?.buffer?.data.length > 0) {
            const url = await getDataURLFromByteArray(
              cover.data.buffer.data,
              cover.data.mimeType
            );
            if (url.length > 10) {
              setCoverImage(url);
            }
          }
        } catch (error) {}
      }
      setCoverLoading(false);
    })();
  }, []);

  return (
    <Link to={bulletin_link}>
      <div className="bulletin-card__container">
        <div className="bulletin-card__text--container">
          <p className="bulletin-card__sub">{bulletin_post_type}</p>
          <h2 className="bulletin-card__header">{bulletin_title}</h2>
        </div>
        {cover_image === "" ? (
          <div className="bulletin-card__image">
            <div className="bulletin-board-post__placeholder--image"></div>
          </div>
        ) : (
          <img
            src={cover_image}
            alt="bulletin avatar"
            className="bulletin-card__image"
          />
        )}
      </div>
    </Link>
  );
};

export default LatestBulletin;
