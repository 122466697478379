import React from "react";

const InfoIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.00016 0.333496C3.32016 0.333496 0.333496 3.32016 0.333496 7.00016C0.333496 10.6802 3.32016 13.6668 7.00016 13.6668C10.6802 13.6668 13.6668 10.6802 13.6668 7.00016C13.6668 3.32016 10.6802 0.333496 7.00016 0.333496ZM6.3335 3.66683V5.00016H7.66683V3.66683H6.3335ZM6.3335 6.3335V10.3335H7.66683V6.3335H6.3335ZM1.66683 7.00016C1.66683 9.94016 4.06016 12.3335 7.00016 12.3335C9.94016 12.3335 12.3335 9.94016 12.3335 7.00016C12.3335 4.06016 9.94016 1.66683 7.00016 1.66683C4.06016 1.66683 1.66683 4.06016 1.66683 7.00016Z"
        fill="#3B7A71"
      />
    </svg>
  );
};

export default InfoIcon;
