import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "@material-ui/core";
import { useDynamicContent } from "../hooks/useDynamicContent";
import store from "../utilities/storage.js";
import Upload from "../assets/svgs/upload";
import Link from "../assets/svgs/link";
import Plus from "../assets/svgs/plus";
import ArrowBackward from "../assets/svgs/ArrowBackward.jsx";
import ArrowForward from "../assets/svgs/ArrowForward";
import NavBar from "../components/RialtoNavBar";
import StepTrackerCard from "../components/StepTrackerCard";
import "../styles/pages/OnboardingNeighborhoodProfileStep3.css";

const links = [
  {
    Icon: Upload,
    name: "Import",
  },
  {
    Icon: Plus,
    name: "Add manually",
  },
  {
    Icon: Link,
    name: "Share link",
  },
];

const OnboardingNeighborhoodprofileStep3 = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const neighborhood_id = store.retrieve("setup_neighborhood_id");

  const {
    dynamicContent,
    bgColor,
    contents,
    handleClose,
    handleOpen,
    updateData,
  } = useDynamicContent({ setOpen });

  /**
   * @name openHandler
   * @description - Opens the modal and updates the content of the modal
   * @param {number} index - represents the index of links array
   */
  const openHandler = (index) => {
    handleOpen();
    const content = contents[index];
    const isRoot = contents[index];
    updateData(content, isRoot);
  };

  return (
    <div className="neighborhood-add-members">
      <NavBar />
      <div className="neighborhood-add-members--wrapper">
        <div className="neighborhood-add-members__container">
          <div className="neighborhood-add-members__header">
            <h2 className="neighborhood-add-members__heading">Add Members</h2>
            <p className="neighborhood-add-members__sub">
              Tip: Accesss all of your members here! You can view their
              profiles, review their expertise and import members!
            </p>
          </div>
          <StepTrackerCard
            currentStepIndex={3}
            stepsFor={"OnboardingNeighborhoodprofile"}
          />
          <>
            <div className="neighborhood-add-members__box">
              {links.map(({ name, Icon }, index) => (
                <button
                  key={name}
                  onClick={() => openHandler(index)}
                  className="neighborhood-add-members__card"
                >
                  <Icon className="neighborhood-add-members__card--svg" />
                  <p className="neighborhood-add-members__card--text">{name}</p>
                </button>
              ))}
            </div>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="neighborhood-add-members-modal"
              aria-describedby="add a member"
              data-testid="inviteMemberModal"
            >
              <div
                className="neighborhood-add-members__content"
                style={{ backgroundColor: bgColor }}
              >
                {dynamicContent}
              </div>
            </Modal>
          </>
          <button
            className="neighborhood-add-members__btn"
            onClick={() => navigate(`/neighborhood/${neighborhood_id}`)}
          >
            Go to Your Neighborhood
          </button>
        </div>
        <div className="neighborhood-add-members__cta">
          <button
            className="neighborhood-add-members__cta--btn"
            onClick={() => navigate("/setup/neighborhoodprofile/step2")}
          >
            <ArrowBackward />
            <span>Back</span>
          </button>
          <button
            className="neighborhood-add-members__cta--btn"
            onClick={() => navigate(`/neighborhood/${neighborhood_id}`)}
          >
            <span>Skip</span>
            <ArrowForward />
          </button>
        </div>
      </div>
    </div>
  );
};

export default OnboardingNeighborhoodprofileStep3;
