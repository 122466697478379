import React, { useEffect, useRef, useState } from 'react';
import { NextConversationsList } from './NextConversationsList';
import { PreviousChatsList } from './PreviousChatsList';
import * as testId from 'utilities/testIds';
import { MessageListLayout } from '../MessageListLayout/MessageListLayout';
import { useChatSequenceActivator } from '../utils/useChatSequenceActivator';
import { ChatHistory } from '../MessageHistory/ChatHistory';

export function MessageListRaw(props) {
  const { records, contentSize } = props;

  const chats = records?.chats;

  const chatContainerRef = useRef(null);
  const wasAtBottomRef = useRef(true);
  const [messageSentByUser, setMessageSentByUser] = useState(false);

  const [nextConversations, setNextConversations] = useState(
    records?.nextConversations
  );
  const [messagesToSubmit, setMessagesToSubmit] = useState([]);
  const [latestSubmitResponse, setLatestSubmitResponse] = useState();

  const [botTyping, setBotTyping] = useState(false);
  const [approvedMsgCount, setApprovedMsgCount] = useState(0);
  const [canTriggerDelayCompleted, setCanTriggerDelayCompleted] =
    useState(true);

  const [latestResponseFreeTextErrorSeen, setLatestResponseFreeTextErrorSeen] =
    useState(0);

  const messagesWithoutApprovalLenght =
    messagesToSubmit?.length - approvedMsgCount;
  const nextBotConv =
    nextConversations[
      messagesWithoutApprovalLenght + latestResponseFreeTextErrorSeen
    ];
  const nextConvoWithoutFreetextLength =
    nextConversations?.length - latestResponseFreeTextErrorSeen;

  useEffect(() => {
    if (records?.nextConversations?.length && messagesToSubmit.length === 0) {
      setMessagesToSubmit(() => [
        {
          ...nextConversations[0],
          createdAt: new Date(),
        },
      ]);
    }
  }, []);

  useEffect(() => {
    const hasEqualLength =
      messagesWithoutApprovalLenght === nextConvoWithoutFreetextLength;
    if (contentSize === 'max' && !hasEqualLength) {
      setBotTyping(true);
    } else {
      setBotTyping(false);
    }
  }, [contentSize]);

  useEffect(() => {
    setLatestResponseFreeTextErrorSeen(0);

    if (latestSubmitResponse?.nextConversations?.length) {
      setNextConversations(latestSubmitResponse?.nextConversations);
      setBotTyping(true);

      // const extraCountForSavedErrMsg = latestSubmitResponse.nextConversations[0].id ? 0 : 1; // TODO: Maybe also check with the prefix `Hmmm..`, but i believe current line scales
      // setApprovedMsgCount(messagesToSubmit.length + extraCountForSavedErrMsg);

      setApprovedMsgCount(messagesToSubmit.length);
    }
  }, [latestSubmitResponse]);

  useEffect(() => {
    canTriggerDelayCompleted && approvedMsgCount && triggerDelayCompleted();
    setCanTriggerDelayCompleted(true);
  }, [approvedMsgCount, nextConversations]); // not sure why we have this for both `approvedMsgCount` & `nextConversations`, maybe only 1 is needed

  const triggerDelayCompleted = () => {
    // TODO: HAVE A VARIABLE FOR `messagesToSubmit?.length - approvedMsgCount` TO GIVE MORE UNDERSTANDING & RE-USE
    const nextChatLess =
      messagesWithoutApprovalLenght < nextConvoWithoutFreetextLength;

    if (nextChatLess) {
      setMessagesToSubmit((prevMessages) => {
        return [
          ...prevMessages,
          {
            ...nextBotConv,
            createdAt: new Date(),
          },
        ];
      });

      // !nextBotConv.id && setCanTriggerDelayCompleted(false) && console.log("@setApprovedMsgCount =>", approvedMsgCount + 1)
      // !nextBotConv.id && setCanTriggerDelayCompleted(false) && setApprovedMsgCount(val => val + 1);
    }

    if (messagesWithoutApprovalLenght === nextConvoWithoutFreetextLength) {
      setBotTyping(false);
    }
  };

  useChatSequenceActivator({
    nextConversations,
  });

  const lastChatIdFromCheckpoint = chats?.sort((a, b) => a?.id - b?.id)[0]?.id;

  return (
    <MessageListLayout
      botTyping={botTyping}
      setLatestSubmitResponse={setLatestSubmitResponse}
      data-testid={testId.sandiChatbot.chatHistory}
      setMessagesToSubmit={setMessagesToSubmit}
      messagesToSubmit={messagesToSubmit}
      approvedMsgCount={approvedMsgCount}
      botSize={contentSize}
      chatContainerRef={chatContainerRef}
      wasAtBottomRef={wasAtBottomRef}
      messageSentByUser={messageSentByUser}
      setMessageSentByUser={setMessageSentByUser}
      record={records}
    >
      {/* older chat history */}
      <ChatHistory
        lastChatIdFromCheckpoint={lastChatIdFromCheckpoint}
        chatContainerRef={chatContainerRef}
        messagesToSubmit={messagesToSubmit}
        wasAtBottomRef={wasAtBottomRef}
      />
      {/* last 30 chats */}
      <PreviousChatsList
        chats={chats}
        setLatestSubmitResponse={setLatestSubmitResponse}
        setMessagesToSubmit={setMessagesToSubmit}
        messagesToSubmit={messagesToSubmit}
        setMessageSentByUser={setMessageSentByUser}
      />

      {/* next conversations to submit */}
      <NextConversationsList
        messagesToSubmit={messagesToSubmit}
        setMessagesToSubmit={setMessagesToSubmit}
        nextConversations={nextConversations}
        triggerDelayCompleted={triggerDelayCompleted}
        setLatestSubmitResponse={setLatestSubmitResponse}
        isBotTyping={botTyping}
        approvedMsgCount={approvedMsgCount}
        setMessageSentByUser={setMessageSentByUser}
      />
    </MessageListLayout>
  );
}
