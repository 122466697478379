import React from 'react';
import NavBar from "../components/RialtoNavBar";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import SingleFileUploadAutoSubmit from '../components/SingleFileUploadAutoSubmit';
import SnackbarAlert from '../components/SnackbarAlert';
import StepTrackerCard from '../components/StepTrackerCard';
import HiddenURLLink from '../components/HiddenURLLink';
import { ReactComponent as OboardClearIcon } from '../assets/Icons/OboardClearIcon.svg';
import store from '../utilities/storage.js';
import '../styles/pages/OnboardingScreen.css';


export default class SetupCribProfile_ENT_Step1 extends React.Component {
    constructor(props) {
        super(props);
        const step = 'step1'
        const form = this.props.hasOwnProperty('step1') ? this.props : this.props.form;
        this.state = {
            form,
            step,
            current: form[`${step}`],
            Snackbar: {
                message: '',
                severity: 'success' // info : warning : success : error
            },
            File: "",
            FileName: "",
            width: window.innerWidth,
            helperTexts: {
                Firstname: "Please provide a firstname for your crib",
                Lastname: "Please provide a lastname for your crib",
                Title: "Please provide your title",
                Company: "Please provide company name",
                Website: "Please provide a valid website link"
            },
            Error: {
                Firstname: false,
                Lastname: false,
                Title: false,
                Company: false,
                Website: false
            },
        }

        this.handleChange = this.handleChange.bind(this);
        this.updateForm = this.updateForm.bind(this);
        this.getImage = this.getImage.bind(this);
        this.setDataURL = this.setDataURL.bind(this);
        this.toggleImageWrapper = this.toggleImageWrapper.bind(this);
        this.isFormFullyFilled = this.isFormFullyFilled.bind(this);
        this.clearImage = this.clearImage.bind(this);
        this.Next = this.Next.bind(this);
        this.matchStringAsUrl = this.matchStringAsUrl.bind(this)
    }

    componentDidMount() {
        const image = this.state.current.File;
        this.setDataURL(image);

        // remove invite ls info
        store.remove("invite_id");
        store.remove("i_neighborhood_id");
        store.remove("invite_link");
        store.remove("neighborhood_owner_firstname");
        store.remove("neighborhood_owner_lastname");
        store.remove("neighborhood_owner_profile_picture");
        store.remove("neighborhood_owner_neighborhood_name");
        store.remove("neighborhood_owner_workplace");

        return window.onbeforeunload = () => "promptBeforeUnload";
    }

    handleChange(e) {
        const newState = this.state;
        const newForm = newState.current;
        const fieldName = e.target.name;
        const value = e.target.value;
        const newError = this.state.Error;

        var helperText = "";

        if (value.trim() === "") {
            helperText = this.state.helperTexts[`${fieldName}`];
            newState.helperTexts[`${fieldName}`] = helperText;
            newState.Error[`${fieldName}`] = true;
        }

        if (fieldName === "Website") {
            const valueIsValid = this.matchStringAsUrl(value, fieldName);
            if (valueIsValid) {
                newError[`${fieldName}`] = false;
            } else {
                newError[`${fieldName}`] = true;
            }
            newState.Error = newError;
        }

        if (fieldName === "Firstname" || fieldName === "Lastname" || fieldName === "Company" || fieldName === "Title") {
            if (value === "") {
                newError[`${fieldName}`] = true;
            } else {
                newError[`${fieldName}`] = false;
            }
            newState.Error = newError;
        }

        newForm[`${fieldName}`] = value;
        newState.current = newForm;
        this.setState(newState);
    }

    updateForm() {
        const newState = this.state;
        const step = this.state.step;
        newState.form[`${step}`] = this.state.current;
        const snackbar = this.state.Snackbar;
        snackbar.message = "Your input is being saved, you can always step back to change your inputs";
        snackbar.open = true;
        snackbar.severity = "info";
        newState.Snackbar = snackbar;
        this.setState(newState);
    }

    async getImage(image) {
        const newState = this.state;
        const newForm = newState.current;
        newForm[`File`] = image;
        await this.setDataURL(image);
        newState.current = newForm;
        this.setState(newState);
    }

    setDataURL(file) {
        console.log('reading file');
        if (!file) {
            console.log('couldnt find file');
        } else {
            console.log('found file')
            const prevState = this.state;
            const stateObj = this;
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                prevState[`File`] = reader.result;
                prevState['FileName'] = file.name;
                stateObj.setState(prevState);
                stateObj.toggleImageWrapper();
            };
            console.log(this.state.File);
            reader.onerror = function (error) {
                console.log('Error: ', error);
            };
        }
    }

    /**
     * toggle ImageWrapper/Dropzone container visibility 
    */

    toggleImageWrapper = () => {
        document.querySelector('.UploadWrapper').classList.toggle('hide');
        document.querySelector('.ImageWrapper').classList.toggle('hide');
    }

    /**
     * Removes uploaded image
    */

    clearImage = () => {
        const prevState = this.state;
        prevState.current.File = "";
        prevState[`File`] = "";
        const snackbar = this.state.Snackbar;
        snackbar.message = "You have removed saved image";
        snackbar.open = true;
        snackbar.severity = "info";
        prevState.Snackbar = snackbar;
        this.setState(prevState);
        this.toggleImageWrapper();
    }

    isFormFullyFilled = () => {
        const fieldNames = ["Firstname", "Lastname", "Title", "Company"];
        const form = this.state.current;
        const errors = this.state.Error;
        const prevState = this.state;
        let isValidated = true;

        fieldNames.forEach((name) => {
            const error = errors[`${name}`];
            const value = form[`${name}`]
            if (error === false) {
                if (value === "") {
                    isValidated = false;
                    errors[`${name}`] = true;
                    this.setState({ ...prevState, Error: errors });
                }
            } else {
                isValidated = false;
                errors[`${name}`] = true;
                this.setState({ ...prevState, Error: errors });
            }
        });
        return isValidated;
    }

    Next = () => {
        const formIsFullyFilled = this.isFormFullyFilled();
        if (formIsFullyFilled) {
            this.updateForm();
            setTimeout(() => {
                const el = document.querySelectorAll('.URLLink');
                el[1].click();
            }, 5000);
        }
    }

    matchStringAsUrl(str, type) {
        let regexQuery = "^(https?://)?(http?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$";
        var url = new RegExp(regexQuery, "i");

        if (type === "Website") {
            return url.test(str);
        }

        if (type === "Twitter" || type === "Facebook" || type === "Instagram") {
            if (str[0] === "@") {
                str.replace("@", "");
                if (str.includes("@")) {
                    return false;
                }
            }
            return true;
        }

        if (type === "LinkedIn") {
            /* eslint-disable-next-line no-useless-escape */
            return /(ftp|http|https):\/\/?(?:www\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/.test("https://linkedin.com/company/" + str);
        }
        return false;
    }

    render() {
        return (
            <div>
                <NavBar />
                <div className="OnboardingContent Step1">
                    <div className="ContentWraper">
                        <span className="HeaderText">PROFILE INFO</span>
                        <span className="Details">
                            In 2 short steps, you will have your crib complete.
                            First, let’s get your profile started.
                        </span>
                        <StepTrackerCard currentStepIndex={1} stepsFor={"SetupCrib"} />
                        <div className="FormWrapper">
                            <TextField
                                className="Field"
                                value={this.state.current.Firstname}
                                name="Firstname"
                                variant="outlined"
                                onChange={this.handleChange}
                                label="First Name *"
                                error={this.state.Error.Firstname}
                            />
                            <TextField
                                className="Field"
                                value={this.state.current.Lastname}
                                name="Lastname"
                                variant="outlined"
                                onChange={this.handleChange}
                                label="Last name *"
                                error={this.state.Error.Lastname}
                            />
                            <TextField
                                className="Field"
                                value={this.state.current.Title}
                                name="Title"
                                variant="outlined"
                                onChange={this.handleChange}
                                label="Title *"
                                error={this.state.Error.Title}
                                placeholder=""
                            />
                            <TextField
                                className="Field"
                                value={this.state.current.Company}
                                name="Company"
                                variant="outlined"
                                onChange={this.handleChange}
                                label="Company *"
                                error={this.state.Error.Company}
                                placeholder="Company name *"
                            />
                            <TextField
                                className="Field"
                                value={this.state.current.Website}
                                name="Website"
                                variant="outlined"
                                onChange={this.handleChange}
                                label="Website "
                                error={this.state.Error.Website}
                                placeholder="https://website.com"
                            />
                            <TextField
                                className="Field"
                                value={this.state.current.Twitter}
                                name="Twitter"
                                variant="outlined"
                                onChange={this.handleChange}
                                label="Twitter Handle (Does not need @)"
                                placeholder=""
                            />
                            <TextField
                                className="Field"
                                value={this.state.current.Facebook}
                                name="Facebook"
                                variant="outlined"
                                onChange={this.handleChange}
                                label="Facebook username"
                                placeholder=""
                            />
                            <TextField
                                className="Field"
                                value={this.state.current.Instagram}
                                name="Instagram"
                                variant="outlined"
                                onChange={this.handleChange}
                                label="Instagram Handle"
                                placeholder=""
                            />
                            <TextField
                                className="Field"
                                value={this.state.current.LinkedIn}
                                name="LinkedIn"
                                variant="outlined"
                                onChange={this.handleChange}
                                label="Linkedin Handle"
                                placeholder=""
                            />
                            <div className="DropZone">
                                <div className="ImageWrapper hide">
                                    <div className="UploadPreview">
                                        <img src={this.state.File} alt=""></img>
                                        <span className="FileName">{this.state.FileName}</span>
                                    </div>
                                    <span onClick={this.clearImage} className="CloseIcon"><OboardClearIcon /></span>
                                </div>
                                <div className="UploadWrapper">
                                    <SingleFileUploadAutoSubmit
                                        getImage={this.getImage}
                                        text={
                                            this.state.width > 1000 ?
                                                "Drag picture here or click to upload logo (max 500kb)"
                                                :
                                                "Click to upload logo (max 500kb)"} />
                                </div>
                            </div>
                            {/* <ArrowForward className="ArrowForward" /> */}
                            <Button className="Continue" onClick={() => this.Next()}>Continue </Button>
                        </div>
                        <SnackbarAlert
                            message={this.state.Snackbar.message}
                            severity={this.state.Snackbar.severity}
                            open={this.state.Snackbar.open}
                            onClose={() => this.setState({ ...this.state, Snackbar: { open: false } })}
                        />
                        <HiddenURLLink url={"/setup/crib/step2"} data={this.state.form} />
                        <div className="StepControl">
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
