import axios from "axios";
import urls from "./baseUrl.js";

const base_url = urls.base_url;

const SignIn = {
  async linkedIn(code) {
    try {
      const data = await axios.post(base_url + "auth/sign-in/linkedin", {
        code,
      });
      return data;
    } catch (error) {
      return error;
    }
  },
  async google(access_token) {
    try {
      const data = await axios.post(base_url + "auth/sign-in/google", {
        token: access_token,
      });
      return data;
    } catch (error) {
      throw error;
    }
  },
  async form(formData) {
    try {
      const data = await axios.post(base_url + "auth/sign-in", formData);
      return data;
    } catch (error) {
      return error;
    }
  },
  async getOnboardingData(neighborhoodOnboardingName) {
    try {
      const data = await axios.get(
        `${base_url}account/neighborhood/${neighborhoodOnboardingName}/onboarding-metadata`
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  },
};

export default SignIn;
