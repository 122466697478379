import React, { useEffect, useState } from "react";
import NavBar from "../components/RialtoNavBar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import SingleFileUploadAutoSubmit from "../components/SingleFileUploadAutoSubmit";
import SnackbarAlert from "../components/SnackbarAlert";
import StepTrackerCard from "../components/StepTrackerCard";
import HiddenURLLink from "../components/HiddenURLLink";
import { ReactComponent as OboardClearIcon } from "../assets/Icons/OboardClearIcon.svg";
import api from "../api/neighborhood";
import LogoBar from "../components/NeighborhoodLogoBanner/NeighborhoodLogoBar";
import "../styles/pages/OnboardingScreen.css";
import "../styles/pages/LandingPage.css";
import "../styles/components/LandingPageBanner.css";
import "../styles/components/LandingPageCollective.css";
import store from "../utilities/storage";
import storeSession from "utilities/session/sessionInitialIzer";

const CountDownDescription = ({
  handleChange,
  value,
  NeighborhoodDescriptionError,
}) => {
  const [count, setCount] = useState(0);

  const update = (e) => {
    if (e.target.value.length <= 100) {
      console.log(e);
      handleChange(e);
    }
  };
  useEffect(() => {
    setCount(value.length);
  }, [value]);
  return (
    <>
      <div style={{ textAlign: "right" }}>
        <div>
          <TextField
            className="Field"
            value={value}
            name="NeighborhoodDescription"
            variant="outlined"
            onChange={(e) => update(e)}
            label={`Neighborhood Short Description *`}
            maxLength={100}
            error={NeighborhoodDescriptionError}
          />
        </div>
        <small>
          <i style={{ color: count > 99 ? "red" : "white" }}>{count}/100 </i>
        </small>
      </div>
    </>
  );
};

export default class OnboardingNeighborhoodprofileStep1 extends React.Component {
  constructor(props) {
    super(props);
    const step = "step1";
    const form = this.props.hasOwnProperty("step1")
      ? this.props
      : this.props.form;
    this.state = {
      form,
      step,
      current: form[`${step}`],
      Snackbar: {
        message: "",
        severity: "success", // info : warning : success : error
      },
      File: "",
      FileName: "",
      width: window.innerWidth,
      helperTexts: {
        Description: "Please provide a description",
        Name: "Please provide neighborhood name",
        CompanyWebsite: "Please provide a valid website link",
        CompanyName: "Please provide company name",
      },
      Error: {
        Profile_Name: false,
        CompanyWebsite: false,
        CompanyName: false,
        LinkedIn: false,
        NeighborhoodDescription: false,
        Instagram: false,
        FacebookPage: false,
        TwitterHandle: false,
      },
    };

    this.handleChange = this.handleChange.bind(this);
    this.updateForm = this.updateForm.bind(this);
    this.getImage = this.getImage.bind(this);
    this.setDataURL = this.setDataURL.bind(this);
    this.toggleImageWrapper = this.toggleImageWrapper.bind(this);
    this.isFormFullyFilled = this.isFormFullyFilled.bind(this);
    this.clearImage = this.clearImage.bind(this);
    this.Next = this.Next.bind(this);
    this.matchStringAsUrl = this.matchStringAsUrl.bind(this);
  }

  componentDidMount() {
    const image = this.state.current.File;
    this.setDataURL(image);
    window.onbeforeunload = () => "promptBeforeUnload";
  }

  handleChange(e) {
    const newState = this.state;
    const newForm = newState.current;
    const fieldName = e.target.name;
    let value = e.target.value;
    const newError = this.state.Error;

    if (
      fieldName === "TwitterHandle" ||
      fieldName === "FacebookPage" ||
      fieldName === "Instagram"
    ) {
      newError[`${fieldName}`] = false;
      if (value[0] === "@") {
        value.replace("@", "");
      }
      if (value.includes("@")) {
        newError[`${fieldName}`] = true;
      }
      newState.Error = newError;
    }

    if (fieldName === "CompanyWebsite") {
      const valueIsValid = this.matchStringAsUrl(value, fieldName);
      if (valueIsValid) {
        newError[`${fieldName}`] = false;
      } else {
        newError[`${fieldName}`] = true;
      }
      newState.Error = newError;
    }

    if (
      fieldName === "Profile_Name" ||
      fieldName === "CompanyName" ||
      fieldName === "NeighborhoodDescription"
    ) {
      if (value === "") {
        newError[`${fieldName}`] = true;
      } else {
        newError[`${fieldName}`] = false;
      }
      newState.Error = newError;
    }

    newForm[`${fieldName}`] = value;
    newState.current = newForm;
    this.setState(newState);
  }

  updateForm() {
    const newState = this.state;
    const step = this.state.step;
    newState.form[`${step}`] = this.state.current;
    this.setState(newState);
    const message =
      "Your input is being sent to the cloud, you can always step back to change your inputs";
    this.showSnackbar(message, "info");
  }

  /**
   * Show snackbar with custom message and severity
   *
   * - severity values:
   * - info : warning : success : error
   *
   * @param {String} message
   * @param {String} severity
   */
  showSnackbar(message, severity) {
    // TODO: add this update to other steps
    const newState = this.state;
    const snackbar = this.state.Snackbar;
    snackbar.message = message;
    snackbar.severity = severity;
    snackbar.open = true;
    newState.Snackbar = snackbar;
    this.setState(newState);
  }

  async getImage(image) {
    const newState = this.state;
    const newForm = newState.current;
    newForm[`File`] = image;
    await this.setDataURL(image);
    newState.current = newForm;
    this.setState(newState);
  }

  setDataURL(file) {
    console.log("reading file");
    if (!file) {
      console.log("couldnt find file");
    } else {
      console.log("found file");
      const prevState = this.state;
      const stateObj = this;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        prevState[`File`] = reader.result;
        prevState["FileName"] = file.name;
        stateObj.setState(prevState);
        stateObj.toggleImageWrapper();
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    }
  }

  /**
   * toggle ImageWrapper/Dropzone container visibility
   */

  toggleImageWrapper = () => {
    document.querySelector(".UploadWrapper").classList.toggle("hide");
    document.querySelector(".ImageWrapper").classList.toggle("hide");
  };

  /**
   * Removes uploaded image
   */

  clearImage = () => {
    const prevState = this.state;
    prevState.current.File = "";
    prevState[`File`] = "";
    const snackbar = this.state.Snackbar;
    snackbar.message = "You have removed saved image";
    snackbar.open = true;
    snackbar.severity = "info";
    prevState.Snackbar = snackbar;
    this.setState(prevState);
    this.toggleImageWrapper();
  };

  isFormFullyFilled = () => {
    const fieldNames = [
      "Profile_Name",
      "CompanyName",
      "NeighborhoodDescription",
    ];
    const form = this.state.current;
    const errors = this.state.Error;
    const prevState = this.state;
    let isValidated = true;
    fieldNames.forEach((name) => {
      const error = errors[`${name}`];
      const value = form[`${name}`];
      if (error === false) {
        if (value === "") {
          isValidated = false;
          errors[`${name}`] = true;
          this.setState({ ...prevState, Error: errors });
        }
      } else {
        isValidated = false;
        errors[`${name}`] = true;
        this.setState({ ...prevState, Error: errors });
      }
      console.log(name, " isValidated");
      console.log(isValidated);
    });
    return isValidated;
  };

  profileRequest = async () => {
    try {
      const req = await api.setupProfile(this.state.form["step1"]);
      if (req.status === "success") {
        const message = `Your neighborhood has been succesfully created`;
        this.showSnackbar(message, "success");

        storeSession(req.data.session);
        store.write("setup_neighborhood_id", req.data.neighborhood_id);

        setTimeout(() => {
          const el = document.querySelectorAll(".URLLink");
          el[1].click();
        }, 1000);
      } else {
        this.showSnackbar(
          `Could not create your neighborhood, please check your connection`,
          "error"
        );
      }
    } catch (error) { }
  };

  Next = () => {
    const formIsFullyFilled = this.isFormFullyFilled();
    if (formIsFullyFilled) {
      this.updateForm();
      this.profileRequest();
    }
  };

  matchStringAsUrl(str, type) {
    let regexQuery =
      "^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$";
    var url = new RegExp(regexQuery, "i");

    if (type === "CompanyWebsite") {
      return url.test(str);
    }

    if (type === "LinkedIn") {
      /* eslint-disable-next-line no-useless-escape */
      return /(ftp|http|https):\/\/?(?:www\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/.test(
        "https://linkedin.com/company/" + str
      );
    }
    return false;
  }

  render() {
    return (
      <div>
        <LogoBar />
        <NavBar />
        <div className="OnboardingContent Step1">
          <div className="ContentWraper">
            <span className="HeaderText">Let’s setup your Neighborhood!</span>
            <span className="Details">
              In 3 short steps, you will have your Neighborhood complete. First,
              let’s get your profile started.
            </span>
            <StepTrackerCard
              currentStepIndex={1}
              stepsFor={"OnboardingNeighborhoodprofile"}
            />
            <div className="FormWrapper">
              <span
                style={{
                  width: "100%",
                  textAlign: "left",
                  fontSize: "12px",
                  marginBottom: "10px",
                }}
              >
                * fields are required
              </span>
              <TextField
                className="Field"
                value={this.state.current.Profile_Name}
                name="Profile_Name"
                variant="outlined"
                onChange={this.handleChange}
                label="Neighborhood Name *"
                error={this.state.Error.Profile_Name}
              />
              <CountDownDescription
                value={this.state.current.NeighborhoodDescription}
                handleChange={this.handleChange}
                NeighborhoodDescriptionError={
                  this.state.Error.NeighborhoodDescription
                }
              />
              <TextField
                className="Field"
                value={this.state.current.CompanyName}
                name="CompanyName"
                variant="outlined"
                onChange={this.handleChange}
                label="Affiliate Company Name *"
                error={this.state.Error.CompanyName}
              />
              <TextField
                className="Field"
                value={this.state.current.CompanyWebsite}
                name="CompanyWebsite"
                variant="outlined"
                onChange={this.handleChange}
                label="Affiliate Company Website"
                error={this.state.Error.CompanyWebsite}
                placeholder="example.com"
              />
              <TextField
                className="Field"
                value={this.state.current.FacebookPage}
                name="FacebookPage"
                variant="outlined"
                onChange={this.handleChange}
                label="Affiliate Company Facebook Page"
                error={this.state.Error.FacebookPage}
                placeholder="https://facebook.com/example (Optional)"
              />
              <TextField
                className="Field"
                value={this.state.current.TwitterHandle}
                name="TwitterHandle"
                variant="outlined"
                onChange={this.handleChange}
                label="Affiliate Company Twitter Page"
                placeholder="https://twitter.com/example (Optional)"
              />
              <TextField
                className="Field"
                value={this.state.current.Instagram}
                name="Instagram"
                variant="outlined"
                onChange={this.handleChange}
                label="Affiliate Company Instagram Handle"
                placeholder="https://instagram.com/example (Optional)"
              />
              <TextField
                className="Field"
                value={this.state.current.LinkedIn}
                name="LinkedIn"
                variant="outlined"
                onChange={this.handleChange}
                label="Affiliate Company LinkedIn Page"
                placeholder="https://linkedin.com/company/example"
              />
              <div className="DropZone">
                <div className="ImageWrapper hide">
                  <div className="UploadPreview">
                    <img src={this.state.File} alt=""></img>
                    <span className="FileName">{this.state.FileName}</span>
                  </div>
                  <span onClick={this.clearImage} className="CloseIcon">
                    <OboardClearIcon />
                  </span>
                </div>
                <div className="UploadWrapper">
                  <SingleFileUploadAutoSubmit
                    getImage={this.getImage}
                    text={
                      this.state.width > 1000
                        ? "Drag picture here or click to upload logo (max 500kb)"
                        : "Click to upload logo (max 500kb)"
                    }
                  />
                </div>
              </div>
              {/* <ArrowForward className="ArrowForward" /> */}
              <Button className="Continue" onClick={() => this.Next()}>
                Continue{" "}
              </Button>
            </div>
            <SnackbarAlert
              message={this.state.Snackbar.message}
              severity={this.state.Snackbar.severity}
              open={this.state.Snackbar.open}
              onClose={() =>
                this.setState({ ...this.state, Snackbar: { open: false } })
              }
            />
            <HiddenURLLink
              url={"/setup/neighborhoodprofile/step2"}
              data={this.state.form}
            />
            <div className="StepControl"></div>
          </div>
        </div>
      </div>
    );
  }
}
