const formdata = {
        neighborhood_profile: (data) => {
                const formdata = new FormData();
                formdata.append('name', data.Profile_Name);
                formdata.append('company', data.CompanyName);
                formdata.append('website', data.CompanyWebsite);
                formdata.append('profile', data.FacebookPage);
                formdata.append('twitter', data.TwitterHandle);
                formdata.append('instagram', data.Instagram);
                formdata.append('linkedIn', data.LinkedIn);
                formdata.append('description', data.NeighborhoodDescription);
                formdata.append('file', data.File);
                return formdata;
        },
        update_neighborhood_profile: (data) => {
                const formdata = new FormData();
                formdata.append('neighborhood_id', data.neighborhood_id);
                formdata.append('name', data.Profile_Name);
                formdata.append('company', data.CompanyName);
                formdata.append('website', data.CompanyWebsite);
                formdata.append('profile', data.FacebookPage);
                formdata.append('twitter', data.TwitterHandle);
                formdata.append('instagram', data.Instagram);
                formdata.append('linkedIn', data.LinkedIn);
                formdata.append('description', data.NeighborhoodDescription);
                formdata.append('file', data.File);
                return formdata;
        },
        bulletin: (data) => {
                const form = {
                        message: data.Message,
                        content_type: data.ContentType,
                        title: data.Title,
                        file: data.File
                }
                return form;                
        },
        forum: (data) => {
                const form = {
                        message: data.Message,
                        content_type: data.ContentType,
                        title: data.Title,
                        file: data.File
                }
                return form;                
        },
        update_bulletin: (data) => {
                const form = {
                        bulletin_id: data.bulletin_id,
                        message: data.Message,
                        content_type: data.ContentType,
                        title: data.Title,
                        file: data.File
                }
                return form;                
        },
        update_forum: (data) => {
                const form = {
                        forum_id: data.forum_id,
                        message: data.Message,
                        content_type: data.ContentType,
                        title: data.Title,
                        file: data.File
                }
                return form;                
        }        
}

export default formdata;
