import { Button } from "@material-ui/core";
import styled from "styled-components";
import { COLORS } from "styles/colors/minwo";

export const ButtonComponent = styled(Button)`
  text-transform: unset;
  &[data-kind="primary"] {
    background: ${COLORS.green.grn1};
    color: ${COLORS.neutral.n0};
  }
  &[data-kind="error"] {
    background: ${COLORS.red.rd1};
    color: ${COLORS.neutral.n0};
  }
`;
