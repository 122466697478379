import types from './types.js';
const initialState = {
    // control settings
    videoOn: true,
    micOn: true,
    screenShareOn: false,
    chatOn: false,
    // room settings
    userName: '',
    roomName: '',
    participants: [],
    room: '',
    videoToken: '',
    chatToken: '',
    chatIdentity: ''
};

const reducer = (state, action) => {
    switch (action.type) {
        case types.SET_VIDEO_ON:
            return {
                ...state,
                videoOn: !state.videoOn
            }
        case types.SET_MIC_ON:
            return {
                ...state,
                micOn: !state.micOn
            }
        case types.SET_SCREEN_SHARE_ON:
            return {
                ...state,
                screenShareOn: !state.screenShareOn
            }
        case types.SET_CHAT_ON:
            return {
                ...state,
                chatOn: !state.chatOn
            }
        case types.SET_USERNAME:
            return {
                ...state,
                userName: action.payload
            }
        case types.SET_ROOMNAME:
            return {
                ...state,
                roomName: action.payload
            }
        case types.SET_VIDEO_TOKEN:
            return {
                ...state,
                videoToken: action.payload
            }
        case types.SET_CHAT_TOKEN:
            return {
                ...state,
                chatToken: action.payload
            }
        case types.SET_USER_CHATIDENTITY:
            return {
                ...state,
                chatIdentity: action.payload
            }
        case types.EXIT_SESSION:
            return initialState;
        case types.JOIN_SESSION:
            const controls_preference = {
                ...state,
                token: action.payload
            }
            return controls_preference;
        case types.ADD_PARTICIPANT:
            return {
                ...state,
                participants: [...state.participants, action.payload]
            }
        case types.REMOVE_PARTICIPANT:
            return {
                ...state,
                participants: state.participants.filter((p) => p !== action.payload)
            }
        default:
            throw new Error('action not recognized!');
    }
}

export default reducer;
