import React from "react";

import { EditNeighborhoodDiv } from "./EditNeighborhood.styles";
import { EditNeighborhoodRaw } from "./EditNeighbrhood.raw";
import AppLoader from "components/Loader/loader";
import { useGetNeighborhood } from "hooks/useGetNeighborhood";

export function EditNeighborhoodQuery() {
  const neighbohoodQuery = useGetNeighborhood();

  const isLoading = neighbohoodQuery.isLoading || neighbohoodQuery.isIdle;

  const isError = neighbohoodQuery.isError || !neighbohoodQuery.data;

  if (isLoading) {
    return (
      <EditNeighborhoodDiv className="loading">
        <AppLoader />
      </EditNeighborhoodDiv>
    );
  }

  if (isError) {
    return (
      <EditNeighborhoodDiv>
        Error loading neighborhood details
      </EditNeighborhoodDiv>
    );
  }

  const neighborhoodData = neighbohoodQuery.data.data;

  return <EditNeighborhoodRaw neighborhoodData={neighborhoodData} />;
}
