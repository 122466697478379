import React from "react";
import "../ImportMembers/ViewDetails/view-details.css";

const AddMembersOutsideRialtoDetails = ({ handleClose, successData }) => {
  return (
    <div className="view-details">
      <h2 className="view-details__heading">Invitation Details</h2>
      <div className="view-details__container">
        <div className="view-details__wrapper">
          {successData?.failed?.map((data, index) => (
            <div key={index} className="view-details__card">
              <div className="view-details__box">
                <h4 className="view-details__box--heading">Failed invites:</h4>
                <p className="view-details__box--text">{data?.email?.length}</p>
              </div>
              <div className="view-details__box">
                <h4 className="view-details__box--heading">Reason:</h4>
                <p className="view-details__box--text">{data?.cause}</p>
              </div>
              <div className="view-details__box">
                <h4 className="view-details__box--heading">Invite list:</h4>
                {data?.email?.map((email, index) => (
                  <p key={index} className="view-details__box--text">
                    {email},
                  </p>
                ))}
              </div>
            </div>
          ))}
          {successData?.successful?.length > 0 && (
            <div className="view-details__card">
              <div className="view-details__box">
                <h4 className="view-details__box--heading">
                  Successful invites:
                </h4>
                <p className="view-details__box--text">
                  {successData?.successful?.length}
                </p>
              </div>
              <div className="view-details__box">
                <h4 className="view-details__box--heading">Reason:</h4>
                <p className="view-details__box--text">
                  Invite successfully sent
                </p>
              </div>
              <div className="view-details__box">
                <h4 className="view-details__box--heading">Invite list:</h4>
                <p className="view-details__box--text">
                  {successData?.successful?.map((data, index) => (
                    <React.Fragment key={index}>{data}, </React.Fragment>
                  ))}
                </p>
              </div>
            </div>
          )}
        </div>
        <button className="view-details__btn" onClick={handleClose}>
          Done
        </button>
      </div>
    </div>
  );
};

export default AddMembersOutsideRialtoDetails;
