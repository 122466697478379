import React from "react";

const Upload = ({ className }) => {
  return (
    <svg
      className={className}
      width="73"
      height="73"
      viewBox="0 0 73 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M69.6501 46.0476V62.0132C69.6501 66.422 66.3409 69.996 62.2587 69.996H10.5182C6.43599 69.996 3.12671 66.422 3.12671 62.0132V46.0476"
        stroke="#3B7A71"
        strokeWidth="5.32187"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.3452 22.0995L37.7186 3.4729L19.092 22.0995"
        stroke="#3B7A71"
        strokeWidth="5.32187"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.7189 4.80298V47.378"
        stroke="#3B7A71"
        strokeWidth="5.32187"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Upload;
