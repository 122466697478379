import React from "react";
import "styles/components/NeighborhoodBanner.css";
import { Shimmer } from "components";
import { ReactComponent as Logo3 } from "assets/Roots/RIALTO-ROOT-Pattern 2_Root Pattern 2 - white 3.svg";
import { COLORS } from "styles/colors/minwo";

export function NeighborhoodBannerLoading() {
  return (
    <div className="NeighborhoodBanner">
      <div className="Header">
        <div className="Info">
          <div className="MobileWrapper">
            <Shimmer
              style={{
                width: 80,
                height: 80,
                borderRadius: "2px",
                opacity: "0.25",
              }}
              primary={COLORS.purple.p600}
              secondary={COLORS.gray.p500}
            />

            <span className="SocialIcons">
              {Array(4)
                .fill(0)
                .map((_, index) => (
                  <Shimmer
                    key={index}
                    style={{
                      width: 18,
                      height: 14,
                      borderRadius: "2px",
                      opacity: "0.25",
                    }}
                    primary={COLORS.purple.p600}
                    secondary={COLORS.gray.p500}
                  />
                ))}
            </span>
          </div>
        </div>

        <div className="Content">
          <span className="Title">
            <Shimmer
              style={{
                width: 120,
                height: 24,
                borderRadius: "2px",
                opacity: "0.25",
              }}
              primary={COLORS.purple.p600}
              secondary={COLORS.gray.p500}
            />
          </span>
          <Shimmer
            style={{
              width: 80,
              height: 18,
              borderRadius: "2px",
              opacity: "0.25",
              marginTop: "20px",
            }}
            primary={COLORS.purple.p600}
            secondary={COLORS.gray.p500}
          />
        </div>
        <Logo3 className="pattern" />
        <div className="banner-dropdown" />
      </div>
      <div className="NavBar">
        {Array(5)
          .fill(0)
          .map((_, index) => (
            <Shimmer
              key={index}
              style={{
                width: 80,
                height: 14,
                borderRadius: "2px",
                opacity: "0.25",
              }}
              primary={COLORS.purple.p600}
              secondary={COLORS.gray.p500}
            />
          ))}
      </div>
    </div>
  );
}
