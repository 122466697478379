import React from 'react';
import BotGraphic from 'assets/svgs/bot-graphic.svg';

const botGraphic = <img src={BotGraphic} alt='sandi' />;

export const getChatBotGraphics = (array, _idx) => {
	const previousObject = array[_idx - 1];
	const getBotImg = () => {
		if (previousObject) {
			if (previousObject?.byUser) {
				return botGraphic;
			}
			return <div />;
		}
		return botGraphic;
	};

	return getBotImg();
};
