import React from 'react';
import { COLORS } from 'styles/colors/minwo';
import { MenuBox } from './CustomPopper.styles';

export const CustomPopper = ({ children, style, ...props }) => {
	const customStyles = {
		...style,
		width: '80%',
		backgroundColor: COLORS.gray.gr2,
	};

	return (
		<MenuBox {...props} style={customStyles}>
			{children}
		</MenuBox>
	);
};
