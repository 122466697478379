export function flattenPages(infiniteData) {
	const pagesArray = infiniteData?.pages ?? [];
	const results = pagesArray
		.map((pageData) => pageData.records)
		.reduce(function reduce(prev, next) {
			return prev.concat(next);
		}, []);

	return { results, total: results?.length };
}
