/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import forumAPI from "../api/forum";
import sessionInfo from "../utilities/session/sessionInfo";
import { ReactComponent as RightArrow } from "../assets/Icons/chevron_right.svg";
import { ReactComponent as LeftArrow } from "../assets/Icons/chevron_left.svg";
import { Skeleton } from "@material-ui/lab";
import { timeDifference } from "../shared/utility";
// import neighborhood from '../api/neighborhood'
import DotGreen from "../assets/Icons/DotGreen.svg";
import store from "../utilities/storage";
import { SnackbarContext } from "../contextStore/SnackbarContext";
import UserImageAvatar from "./GlobalComponents/UserImageAvatar";
import "../styles/components/DashboardCard.css";
import "../styles/components/ForumCard.css";

const invite_link = store.retrieve("invite_link");

export default function ForumCard({ content, activeTour, setActiveTour }) {
  const { triggerAlert } = useContext(SnackbarContext);

  const itemsPerPage = 1;
  const [page, setPage] = useState(1);
  const [Message2, setMessage2] = useState("");
  const { neighborhood_id } = useParams();
  const [forumPost, setForumPost] = useState([]);

  useEffect(() => {
    (async () => {
      // TODO: replace with get forum request
      // var proof = await neighborhood.getNeighborhood();
      // console.log(proof.data[0].neighborhood_name);
      // if (proof.hasOwnProperty['data'] && proof.data[0]) {
      // setTitle(proof.data[0].title);
      // setMessage(proof.data[0].message);
      // setTitle2(proof.data[1].title)
      // setMessage2(proof.data[1].message)
      setMessage2("");
      // }
    })();
  }, []);

  const { isLoading } = useQuery(
    ["getForumPosts", page],
    () => forumAPI.getAllForumPosts(neighborhood_id, itemsPerPage, page),
    {
      retry: 0,
      onError: (error) => {
        console.log(error);
      },
      onSuccess: (response) => {
        if (response?.status === "success") {
          setForumPost(response?.data);
        } else {
          triggerAlert({
            open: true,
            message: "There was an error, please try again later",
            type: "error",
          });
        }
      },
    }
  );

  return (
    <div className="Card ForumCard" id="forum">
      {/* <span className="ForumOverlayText">Coming Soon</span> */}
      <div className="Header">
        <span className="Name">Forum</span>
        <Link className="ViewAll" to={`/neighborhood/${neighborhood_id}/forum`}>
          SEE MORE
        </Link>
      </div>
      {isLoading ? (
        <Skeleton
          style={{
            height: "10rem",
            marginTop: "-32px",
            width: "100%",
            borderRadius: "10px",
          }}
        />
      ) : (
        <div className="PostWrapper">
          {forumPost?.forum_posts?.length === 0 ? (
            <div>
              <h3 className="NoPost_Title">It's a little empty here!</h3>
              <p className="Detail">
                There are no posts yet. Please check again soon.
              </p>
            </div>
          ) : (
            forumPost?.forum_posts?.map((post) => (
              <div key={post?.forum_post_id}>
                <Link
                  to={`/neighborhood/${neighborhood_id}/forumcomment/${post?.forum_post_id}`}
                  className="Content"
                >
                  <UserImageAvatar
                    s3_picture_url={post?.picture_url}
                    className="Avatar"
                    alt="avatar"
                  />
                  <div className="Info">
                    <h2 className="Title">{post?.title}</h2>
                    <span className="Details">
                      <span className="User">
                        {post?.forum_post_owner_fname}&nbsp;
                        {post?.forum_post_owner_lname?.charAt(0) || ""}.{" "}
                        <span className="post_time">
                          {timeDifference(post?.updated_at)}{" "}
                        </span>
                      </span>
                      {Message2}
                    </span>
                    <span className="Category">
                      {post.category}
                      <span className="TextQ">{post?.post_type} </span>
                    </span>
                  </div>
                </Link>
                <div className="ToolTip">
                  <div className="BottomNavigation">
                    <div className="Arrow">
                      <button
                        className="BackArrow"
                        disabled={page === 1}
                        onClick={() => setPage(page - 1)}
                      >
                        <LeftArrow />
                      </button>
                      <button
                        className="ForwardArrow"
                        disabled={
                          Math.ceil(forumPost?.total / itemsPerPage) === page
                        }
                        onClick={() => setPage(page + 1)}
                      >
                        <RightArrow />
                      </button>
                    </div>
                  </div>
                  <div
                    className="Tip"
                    style={
                      activeTour === "forum" ? { "": "" } : { display: "none" }
                    }
                  >
                    <span className="data">{content}</span>
                    <div className="controls">
                      <a href={invite_link}>
                        <button className="Create">Create Account</button>{" "}
                      </a>
                      <span className="Navigate">
                        <a
                          href="#bulletin"
                          onClick={() => setActiveTour("bulletin")}
                        >
                          BACK
                        </a>
                        <img alt="green dot" src={DotGreen} />
                        <a
                          href="#library"
                          onClick={() => setActiveTour("library")}
                        >
                          NEXT
                        </a>
                      </span>
                      <span className="Edge">&nbsp;</span>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
}
