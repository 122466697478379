import React from "react";
import OwnedNeighborhood from "../../components/NeighborhoodAndConnection/OwnedNeighborhood/OwnedNeighborhood";
import { NeighborhoodsLoading } from "./Neighborhoods.loading";
import { NeighborhoodsError } from "./Neighborhoods.error";

export function NeighborhoodsQuery(props) {
  const { neighborhoodsQuery, pictureUrl } = props;
  const { isLoading, isError } = neighborhoodsQuery;

  if (isLoading)
    return <NeighborhoodsLoading message="Error loading Neighborhoods" />;
  if (isError) return <NeighborhoodsError />;
  const { data } = neighborhoodsQuery;
  const { neighborhoods } = data.data;

  return (
    <div>
      <OwnedNeighborhood
        neighborhoods={neighborhoods}
        pictureUrl={pictureUrl}
      />
    </div>
  );
}
