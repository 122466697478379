import styled from 'styled-components';
import { COLORS } from 'styles/colors/minwo';

export const InputContainer = styled.form`
  display: flex;
  column-gap: 12px;
  align-items: center;
  padding: 16px;
  background-color: ${COLORS.neutral.n0};
`;

export const Input = styled.input`
  flex: 1;
  border: none;
  outline: none;
  background-color: transparent;

  &::placeholder {
    color: ${COLORS.gray.gr1};
    font-size: 15px;
  }
`;

export const SendSandiChatButton = styled.button`
  all: unset;
`;
