import React from "react";
import { Link } from "react-router-dom";
import classes from "../../styles/pages/Privacy.module.css";
import Circle from "../../assets/Icons/circle.svg";
import yellowCircle from "../../assets/Images/yellowcircle.svg";
import yellowCircles from "../../assets/Images/yellowcircles.svg";
import doubleCircle from "../../assets/Icons/doublecircle.svg";
import greenCurve from "../../assets/Images/greencurve.svg";
import purpleCurve from "../../assets/Images/purplecurve.svg";
import Root from "../../assets/Images/Root2.svg";
import Navigation from "./Navigation/Navigation";
import SubFooter from "./SubFooter/SubFooter";
import Footer from "./Footer/footer";

const TermsOfUse = () => {
  return (
    <div className="Privacy">
      <div className={classes.privacyBox}>
        <Navigation />
        <div className={classes.privacyContainer}>
          <img src={Root} alt="image" className={classes.rootimage} />
          <div className={classes.privacyContentBox}>
            <div className={classes.contents}>
              <h1 className={classes.privacyheadertext}>Terms of Use</h1>

              <p className={classes.privacysubheadertext}>
                These Terms of Use (“Terms”), along with MINWO Inc.'s (“MINWO”,
                “we”, “our”, or “us”)
                <Link to="/privacy" className={classes.anchor}>
                  {" "}
                  Privacy Policy
                </Link>
                , define our relationship with you as you interact with our
                websites, including{" "}
                <a
                  href="https://www.rialtoapp.co/"
                  target="_blank"
                  className={classes.anchor}
                >
                  www.minwo.co
                </a>{" "}
                and{" "}
                <a
                  href="https://www.rialtoapp.co/"
                  target="_blank"
                  className={classes.anchor}
                >
                  www.rialtoapp.co
                </a>{" "}
                (the “Sites”) and the services we make available via the Sites,
                including Rialto, our AI-enabled Community-as-a-Service platform
                (collectively, the “Services”).
              </p>
              <p className={classes.privacysubheadertext}>
                By using the Services, you are agreeing to abide by both the
                Terms and the Privacy Policy, as modified by MINWO from time to
                time, and any applicable supplemental terms that are made
                available to you from time to time. These Terms also apply to
                any Content you submit to the Services, actions you take while
                using the Services, and your interactions with other users of
                the Services
              </p>
              <p className={classes.privacysubheadertext}>
                Last Updated: April 15, 2022
              </p>
            </div>
            <div></div>
          </div>
          <img src={Circle} alt="circle" className={classes.circleimage} />
          <img
            src={doubleCircle}
            alt="double circle"
            className={classes.doublecircleimage}
          />
          <img
            src={yellowCircle}
            alt="offer"
            className={classes.yellowcircleimage}
          />
        </div>
        <section className={classes.privacyContents}>
          <div className={classes.sectionboxone}>
            <div className={classes.content}>
              <h3>Our Terms</h3>
              <p>
                We are incorporated under the laws of the State of Delaware and
                operate under the laws of the United States. Accordingly, we
                make no representations that the Services are accessible,
                appropriate, or available for use in other locations. Those who
                choose to access the Services from other locations do so on
                their own initiative and are responsible for compliance with any
                applicable local laws.
              </p>
              <h3 className={classes.heading} style={{ paddingTop: "10px" }}>
                The services we provide
              </h3>
              <p>
                We provide the Services to help build, grow, and scale Black
                businesses. Specifically, the Services help connect you to the
                networks, tools, and resources you need to Hustle Forward. We
                want our users to feel safe to collaborate and build community,
                and your use of the Services is subject to these Terms, our
                Privacy Policy, and any applicable supplemental terms that are
                made available to you from time to time.
              </p>
              <p>
                The communities and neighborhoods available through the Services
                (<i>“Communities”</i>) are controlled by third party
                administrators or moderators (<i>“Administrators”</i>). Such
                Administrators control their respective Communities, including
                your access to those Communities, the rules for your
                participation in those Communities, and the Content within those
                Communities. Administrators may also sell products or services
                to you in their Communities and collect payment from you for
                those sales. Although we do not review, verify, endorse,
                support, represent, or guarantee the completeness, truthfulness,
                accuracy, reliability, or other attributes of any Content or
                actions within Communities, if we learn of a misuse of the
                Services or of Content or conduct that may be harmful to us,
                others, or any Community, we may remove or disable access to any
                Content or the Services. Your Administrators may also have the
                right to remove or disable your access to your Communities and
                Content within those Communities; please make sure you
                understand your Administrators' rules for participating in each
                Community. MINWO is not responsible for any action or non-action
                by your Administrators.
              </p>
              <p>
                We strive to develop new tools and features to improve the
                Services. This means that we routinely conduct scheduled
                maintenance. This also means that we may add or remove features
                and functionality, increase or decrease limits to the Services,
                or start offering new services or stop offering old ones. If we
                make material changes to the Services, if our scheduled
                maintenance materially negatively impacts your use of the
                Services, or if we stop offering a material feature, we will
                provide you with reasonable notice, except in urgent situations
                such as preventing abuse, responding to legal requirements, or
                addressing security and operability issues. We are not
                responsible for any damages or costs incurred due to any
                unavailability of the Services. If you download our software,
                you give us permission to download and install updates to the
                software as it becomes available.
              </p>
              <p>
                By providing your email address to MINWO or by subscribing to
                our communications, you may receive periodic information from
                MINWO regarding our current and future services or regarding
                third-party services. You may unsubscribe from certain
                MINWO-initiated email communications by following the directions
                contained in such communications or unsubscribe from all
                MINWO-initiated email communications by terminating your
                account. You may also receive emails from the various
                Communities of which you are a part. Those communications are
                controlled by your Administrators. Please contact your
                Administrators if you would like to unsubscribe from such
                communications.
              </p>
            </div>
          </div>
          <div className={classes.sectionboxtwo}>
            <div className={classes.content}>
              <h3 className={classes.heading}>
                Your commitments to MINWO and the MINWO community
              </h3>
              <p>
                <b>By using the Services, you agree to the following</b>:
              </p>
              <ul>
                <li>You are at least eighteen (18) years of age or older</li>
                <li>
                  You will create only one (1) account using truthful and
                  accurate registration information, including any applicable
                  billing information.
                </li>
                <li>You will maintain the accuracy of such information.</li>
                <li>
                  You will not share your password, give access to your account
                  to others, or transfer your account to anyone else without the
                  permission of your Administrators. You are responsible for all
                  activities conducted through your account regardless of who
                  conducts those activities.
                </li>
                <li>
                  You will promptly notify your Administrators and MINWO if you
                  become aware of, or reasonably suspect, any illegal or
                  unauthorized activity, or a security breach involving your
                  account.
                </li>
                <li>
                  Your use of the Services does not violate any applicable law
                  or regulation.
                </li>
              </ul>
              <h5 className={classes.subheader}>
                You agree to act with integrity and respect others.
              </h5>
              <ul>
                <p>
                  We take seriously the integrity of our community and users.
                  You must follow these rules of conduct in your use of the
                  Services:
                </p>
                <li>
                  Do not engage in misleading, discriminatory, fraudulent,
                  harassing, threatening, inflammatory, libelous, or otherwise
                  harmful actions. For example, don't express hatred or
                  intolerance for people on the basis of race, ethnicity,
                  nationality, gender or gender identity, religion, sexual
                  orientation, age, or disability, including by promoting
                  organizations with such views.
                </li>
                <li>
                  {" "}
                  Do not abuse, harm, interfere with, or disrupt the Services.
                  For example, don't upload viruses or malicious code or do
                  anything that could disable, overburden, or impair the proper
                  working of the Services, servers, or networks
                </li>
                <li>
                  Do not collect or aggregate information regarding other users’
                  actions relating to the Services.
                </li>
                <li>
                  Do not—or attempt to—reverse engineer, decompile, disassemble,
                  or use any other method to view or recreate any of the source
                  code of any part of the Services or extract any trade secrets
                  from it.
                </li>
              </ul>
              <h5 className={classes.subheader}>
                We may terminate, suspend, or disable your access to the
                Services at any time, with or without notice to you. We may do
                this if:
              </h5>
              <ul>
                <li>
                  You clearly, seriously, or repeatedly breach these Terms or
                  any applicable supplemental terms.
                </li>
                <li>
                  You infringe a third party's intellectual property rights.
                </li>
                <li>
                  We're required to do so for legal reasons, such as complying
                  with a court order.
                </li>
                <li>
                  Your behavior causes harm or liability to a user, third party,
                  or MINWO.
                </li>
              </ul>
              <p>
                Your Administrators may have additional rules of conduct for
                their respective Communities. They also have the right to
                terminate, suspend, or disable your access to the Communities
                they control and the Content in those Communities. Please
                contact your Administrators if you have questions about those
                rules.
              </p>
            </div>
            <div className={classes.positionedImage}>
              <img
                src={greenCurve}
                alt="green vector"
                className={classes.green_vector}
              />
            </div>
            <img
              src={purpleCurve}
              alt="purple vector"
              className={classes.purplevector}
            />
          </div>
          <div className={classes.sectionboxthree}>
            <div>
              <img
                src={yellowCircles}
                alt="offer"
                className={classes.yellowcirclesimage}
              />
            </div>
            <div className={classes.content}>
              <h3 className={classes.heading}>Content in MINWO services</h3>
              <p>
                The Services allow you to upload, share, send, receive, or
                interact with Content whether yours, ours, or another party's.{" "}
                <b>“Content”</b> means any text, graphics, images, photographs,
                trademarks, service marks, logos, audio files, sounds, music,
                videos, artwork, visual interfaces, computer code, software,
                applications, and other documents, materials, information, or
                other type or form of content displayed, distributed, or
                otherwise made available to a party through the Services,
                including your Content, our Content, and third-party Content.
              </p>
              <p>
                This section explains your rights related to the Content on the
                Services, including what you are and are not allowed to do and
                what permissions you give us. Should you upload or share
                Content, it should be lawful Content that does not invade
                others' privacy or intellectual property rights.
              </p>
              <h5 className={classes.subheader}>Your Content</h5>
              <p>
                MINWO takes the security of Personal Data seriously. We utilize
                industry-accepted reasonable security practices and have
                implemented reasonable technical and organizational measures to
                protect the confidentiality, security, and integrity of your
                Personal Data. The measures we have implemented take into
                account the currently available security technologies, cost, and
                risk presented by the type of Personal Data we collect, process,
                and use.
              </p>
              <p>
                You are solely responsible for the content of, and any harm
                resulting from, any Content that you make available through the
                Services regardless of its form. MINWO is not responsible for
                any misuse of your Content by third parties.
              </p>
              <p>
                By posting Content to the Services, you give us your permission,
                or a “license,” to use your Content. This license is:
              </p>
              <ul>
                <li>
                  Non-exclusive, which means that you can license your Content
                  to others.
                </li>
                <li>
                  Transferable, which means that we can transfer this license to
                  others.
                </li>
                <li>
                  Sub-licensable, which means that we can grant to another any
                  rights or privileges we have under this license.
                </li>
                <li>
                  Worldwide, which means that it's valid anywhere in the world
                </li>
                <li>
                  Royalty-free, which means that we will not pay you any
                  royalties for using your Content.
                </li>
              </ul>
              <p>
                This license allows us to access, use, host, copy, display,
                process, transmit, deliver, modify, and create derivative works
                of your Content as necessary or convenient for us to comply with
                our obligations and rights. For example, if you share a post, we
                may make it available to your Community on the Services and
                translate it for users who may speak other languages.
              </p>
              <p>
                This license will end when your Content is deleted from our
                systems by you (which you can do while your account is active)
                or your Content is no longer protected by intellectual property
                rights. If you delete your Content from the Services, we will
                stop making that content visible to others in a reasonable
                amount of time. However, it may continue to exist and be
                displayed on the Services if:
              </p>
              <ul>
                <li>
                  Your content has been used by others in accordance with this
                  license and they have not deleted it.
                </li>
                <li>
                  Immediate deletion is not possible due to technical
                  limitations.
                </li>
                <li>
                  Immediate deletion would restrict our ability to comply with
                  any legal obligations or authorities.
                </li>
              </ul>
              <p>
                If you delete your account, any Content that you do not delete
                prior to your account termination will be deleted based on the
                settings you have selected. This may mean your Content is not
                deleted if you have not selected a deletion or expiration
                option.
              </p>
              <p>
                You also agree—whether or not you have deleted certain
                Content—that we may use and keep your Content to provide,
                maintain, protect, and improve the Services (including its
                methodologies, data analytics, and predictive analytics models
                and solutions), to develop new software and services, and to
                protect us and our customers, provided that, when using or
                keeping your Content for these purposes, it will be anonymized
                and aggregated with other data of our other users.
              </p>
              <p>
                If you believe someone is infringing upon your intellectual
                property rights, you should contact your Administrators. You may
                also send us the following information in writing and we will
                escalate your claim to your Administrators:
              </p>
              <ul>
                <li>
                  A description of the copyrighted work claimed to have been
                  infringed or, if multiple copyrighted works at a single online
                  site are covered by a single notification, a representative
                  list of such works at that site.
                </li>
                <li>
                  A description of the infringing material and information
                  reasonably sufficient to permit us to locate the material.
                </li>
                <li>
                  Your contact information, including your address, telephone
                  number, and email.
                </li>
                <li>
                  A statement by you that you have a good faith belief that use
                  of the material in the manner complained of is not authorized
                  by the copyright owner, its agent, or the law.
                </li>
                <li>
                  A statement that the information in the notification is
                  accurate and, under the pains and penalties of perjury, that
                  you are authorized to act on behalf of the copyright owner.
                </li>
              </ul>
              <p>
                Claims can be sent to The Green Suite 6862, Dover, Delaware
                19901, Attn: MINWO Legal. If you do not comply with all of the
                above requirements, your notice may not be valid.
              </p>
              <p>
                We appreciate any feedback and suggestions you may submit to us
                about the Services. We want to clarify that feedback and
                suggestions are not your Content. We may use them without
                restriction and without paying to you any royalties or
                compensation. This means that if you submit feedback to us, we
                may use your feedback to develop a new feature or product and
                are under no obligation to pay you.
              </p>
              <p>
                <b>
                  We may remove or restrict your access to Content that violates
                  these Terms or any applicable laws or that harms, or has the
                  potential to harm, our users, third parties, or MINWO.
                </b>{" "}
                This includes instances, as determined by us, where it is
                reasonably necessary to avoid or mitigate adverse legal or
                regulatory impacts to MINWO or our other users. Your
                Administrator also has these rights in the Communities they
                control.
              </p>
              <h5 className={classes.subheader}>Our Content</h5>
              <p>
                Just as your Content remains yours, our Content—including our
                trademarks, service marks, logos displayed on the Sites, and any
                de-identified data related to the Services—remains ours. The
                Services our Content, and all intellectual property rights in,
                and to, them are owned by MINWO. We grant you a license to
                access and use the Services. This license is:
              </p>
              <ul>
                <li>
                  Limited, which means that it is available to you unless or
                  until your access is terminated as described in these terms.
                </li>
                <li>
                  Personal, which means that it is for your individual,
                  non-commercial use and not anyone else's. Non-commercial means
                  that you may not use our Content in a way that is intended to
                  make a profit. If you are an Administrator, you may use our
                  Content for your internal business purposes, including
                  servicing users of the Services who are members of your
                  Communities, subject to these Terms and any separate agreement
                  between MINWO and you that governs your use of the Services.
                </li>
                <li>
                  Revocable, which means that we can revoke or cancel this
                  license.
                </li>
                <li>
                  Non-exclusive, which means that we can license this to others.
                </li>
                <li>
                  Non-sublicensable, which means that you cannot grant to
                  another any rights or privileges you enjoy under this license.
                  If you are an Administrator, you may make our Content
                  available to other users of the Services who are members of
                  your Communities
                </li>
                <li>
                  Non-transferable, which means that you cannot give this
                  license to another.
                </li>
              </ul>
              <p>
                Any derivative work you create of our Content and the Services,
                and all rights therein, is ours. You irrevocably transfer and
                convey to us, without further consideration, all right, title,
                and interest that you may have or acquire in any such derivative
                work. You also agree to take all reasonably necessary steps to
                help us, at our expense, enforce our rights related to such
                derivative works.
              </p>
              <h5 className={classes.subheader}>Other Content</h5>
              <p>
                The Services may give you access to Content that belongs to
                other people or organizations (including Administrators). You
                may not use this Content without that person or organization's
                permission or as otherwise allowed by law. The views expressed
                in other people's or organizations' content (including
                Administrators') are theirs and do not reflect our views. Again,
                we are not responsible for the Content that other people or
                organizations provide, including its accuracy, completeness,
                timeliness, validity, legality, decency, quality, or any other
                aspect thereof. We are not liable to you or any other person or
                organization for any of this Content.
              </p>
            </div>
          </div>
          <div className={classes.sectionboxfour}>
            <div className={classes.content}>
              <h3 className={classes.heading}>
                Disputes, problems or disagreements
              </h3>
              <p>
                We work to continuously improve the Services to meet your needs
                and establish clear guidelines for all of our users. We hope
                that no problems or disputes arise as you use the Services;
                however, if a dispute does arise, it is useful to know what each
                of us (you and MINWO) can expect from the other as well as how
                and where it can be resolved.
              </p>
              <h5 className={classes.subheader}>Warranties</h5>
              <p>
                The Services are provided on an “as is” and “as available” basis
                for your use. Additionally, to the extent permitted by
                applicable law, we offer the Services without warranties: The
                law requires that we explain this using specific language and
                that it be in capital letters to ensure that you see it.
              </p>
              <p>
                YOU AGREE THAT THE USE OF THE SITES AND SERVICES IS AT YOUR SOLE
                RISK. THE SITES AND SERVICES ARE PROVIDED WITHOUT WARRANTIES
                (INCLUDING, WITHOUT LIMITATION WARRANTIES OF MERCHANTABILITY,
                FITNESS FOR A PARTICULAR PURPOSE, DATA ACCURACY, SYSTEM
                INTEGRATION, SYSTEM RELIABILITY, TITLE, NON-INFRINGEMENT,
                NON-INTERFERENCE, AND QUIET ENJOYMENT) OR CONDITIONS OF ANY
                KIND, EITHER EXPRESS OR IMPLIED. NO WARRANTIES ARE MADE ON THE
                BASIS OF TRADE USAGE, COURSE OF TRADE, OR COURSE OF DEALING OR
                PERFORMANCE.
              </p>
              <p>
                We do not represent or warrant that the Services or any Content
                will be accurate, reliable, error-free, or uninterrupted, that
                defects will be corrected, that the Services or the servers that
                make them available are free of viruses or other harmful
                components, or that the Services or any Content will otherwise
                meet your needs or expectations.
              </p>
              <p>
                We do not control or direct what people do or say, and we are
                not responsible for their actions or conduct or any Content they
                post or share (including harmful, inaccurate, unlawful, or
                otherwise objectionable content). This applies to all other
                people, including Administrators. Again, MINWO is not
                responsible for any action or non-action by your Administrators.
              </p>
              <h5 className={classes.subheader}>Liabilities</h5>
              <p>
                Although we strive to make sure that the Services are the best
                we can provide, we cannot predict if, and when, issues might
                arise. This section describes what you or MINWO can claim should
                a problem arise. The law allows us to limit certain liabilities
                as described below.
              </p>
              <p>
                To the extent permitted by applicable law, we are not liable for
                any indirect, incidental, consequential, special, exemplary, or
                punitive damages, nor any liability for lost profits, loss of
                data, loss of business opportunity, or business interruption,
                regardless of the theory of liability (including theories of
                contractual liability, tort liability, or strict liability),
                even if we knew or should have known that those kinds of damages
                were possible. Our total liability arising out of or relating to
                any claims related to your use of the Services (including
                Content included on the Services) will not exceed the greater of
                $100 or the total amount you have paid us (if anything) in the
                twelve (12) months preceding the occurrence of the event giving
                rise to liability.
              </p>
              <h5 className={classes.subheader}>Indemnification</h5>
              <p>
                You will indemnify us for any third-party legal claims arising
                out of or relating to:
              </p>
              <ul>
                <li>
                  Your use of, or activities in connection with, the Services.
                </li>
                <li>
                  Any violation of these Terms by you, through your account, or
                  using your username.
                </li>
                <li>
                  Any allegation that any Content you create or share through
                  the Services infringes or otherwise violates the intellectual
                  property rights of any third party.
                </li>
              </ul>
              <p>
                This indemnity covers any liability or expense arising from
                claims, losses, damages, judgments, fines, litigation costs and
                expenses such as reasonable attorney's fees.
              </p>
              <h5 className={classes.subheader}>
                Settling disputes, governing law, and arbitration
              </h5>
              <p>
                These Terms and any claims, causes of action, or disputes you
                have against us arising from or relating to these Terms or the
                Services will be governed by the laws of the State of Delaware
                without regard to principles or conflicts of laws. These
                disputes will be resolved exclusively by binding arbitration as
                administered by the American Arbitration Association and through
                a single arbitrator. Arbitration shall take place in Wilmington,
                Delaware unless all parties mutually agree to another location.
              </p>
            </div>
          </div>
          <div className={classes.sectionboxfive}>
            <div className={classes.content}>
              <h3 className={classes.heading}>Other</h3>
              <p>
                You will not transfer any of your rights or obligations under
                these Terms to anyone else without our consent.
              </p>
              <p>
                If you have any questions or concerns about this Privacy Policy
                or our privacy practices, please contact us as follows:
              </p>
              <p>
                If any part of these Terms is not valid or enforceable, the
                remaining portion will remain in full force and effect. This
                means that the particular term which is invalid or unenforceable
                does not affect any other terms in this Terms of Use.
              </p>
              <p>
                If we don't take immediate action following any violations by
                you of these Terms, the Privacy Policy, or any supplemental
                terms, we are not giving up any rights that we may have,
                including taking action in the future.
              </p>
              <p>
                We may update these Terms, the Privacy Policy, or any
                supplemental terms periodically. We will provide you with
                reasonable notice (which may include posting to our Sites) when
                we do so except when we launch a new service, feature, or
                software, or in urgent situations. If you disagree with the
                updates, you should remove your Content and stop using the
                Services.
              </p>
              <p>
                If you are a California user, you are entitled to the following
                specific consumer rights notice under California Civil Code
                section 1789.3: The Complaint Assistance Unit of the Division of
                Consumer Services of the California Department of Consumer
                Affairs may be contacted in writing at 400 R Street, Suite 1080,
                Sacramento, California 95814, or by telephone at (916) 445-1254
                or (800) 952-5210.
              </p>
              <p>
                You may terminate your account at any time. The termination will
                be effective upon our confirmation of termination. If you delete
                your account, or if MINWO or your Administrator terminates or
                deletes your account, these Terms will also terminate and no
                longer apply. However, the following sections will remain in
                place: “Your Commitments to MINWO and the MINWO Community,”
                “Warranties,” “Liabilities,” “Indemnification,” and “Other.”
              </p>
              <p>
                If you have any questions about the Services or any complaints
                or concerns about these Terms, please write to us at The Green
                Suite 6862, Dover, Delaware 19901, Attn: MINWO Legal to engage
                in good faith resolution before any further escalation.
              </p>
            </div>
          </div>
        </section>
        <SubFooter />
        <Footer />
      </div>
    </div>
  );
};
export default TermsOfUse;
