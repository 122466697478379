import React, {useReducer} from 'react'
import NeighborhoodContext from './neighborhood-context'
const intialState = {
   isNeighborhoodOpen: false,
   isNavOpen:false
}
const neighborhoodReducer = (state, action)=>{
   switch (action.type) {
      case 'TOGGLE':
         return {
            ...state,
            isNeighborhoodOpen: !state.isNeighborhoodOpen
         }
      case 'TOGGLENAV':
         return {
            ...state,
            isNavOpen: !state.isNavOpen
         }
      case 'CLOSE':
         return {
            ...state,
            isNeighborhoodOpen:false
         }
      case 'CLOSENAV':
         return {
            ...state,
            isNavOpen:false
         }
      default:
         return state
   }
}
const NeighborhoodProvider = (props) => {
   const [neighborState, dispatchAction] = useReducer(neighborhoodReducer, intialState)
   const toggle = () => {
      dispatchAction({type:'TOGGLE'})
   }
   const close = () => {
      dispatchAction({type:'CLOSE'})
   }
   const toggleNav = () => {
      dispatchAction({type:'TOGGLENAV'})
   }
   const closeNav = () => {
      dispatchAction({type:'CLOSENAV'})
   }
   const neighborhoodContext = {
      isNeighborhoodOpen: neighborState.isNeighborhoodOpen,
      isNavOpen: neighborState.isNavOpen,
      toggleModal: toggle,
      closeModal: close,
      toggleNav,
      closeNav,
   }
   return <NeighborhoodContext.Provider value={neighborhoodContext}>
      {props.children}
   </NeighborhoodContext.Provider>
}
export default NeighborhoodProvider

