import React from 'react';
import { Link } from 'react-router-dom';
import './footer.css';

const Footer = () => {
    return (
        <div className="footer">
            <div className="footer__container">
                <div className="footer__form--container">
                    <h3 className="footer__form--header">Join our mailing list</h3>
                    <form action="" className="footer__form">
                        <input type="email" placeholder="Your email address" className="footer__form--input" />
                        <button className="footer__btn">Join</button>
                    </form>
                    <p className="footer__copy">&copy; 2021, Rialto by MINWO Inc. &nbsp; &#8212;<a href="http://www.minwo.co" target="_blank" className="footer__copy--span">Learn more about MINWO.</a></p>
                </div>
                <div className="footer__links--container">
                    <div className="footer__links">
                        <h3 className="footer__links--header">Product</h3>
                        <ul className="footer__item">
                            <li className="footer__list">
                                <Link to="/whyRialto" className="footer__link">Why rialto</Link>
                            </li>
                            <li className="footer__list">
                                <Link to="/features" className="footer__link">Features</Link>
                            </li>
                            <li className="footer__list">
                                <Link to="/pricing" className="footer__link">Get started</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <p className="footer__copy--mobile">&copy; 2021, Rialto by MINWO Inc. &nbsp; &#8212; <a href="http://www.minwo.co" target="_blank" className="footer__copy--span">Learn more about MINWO.</a></p>
        </div>
    )
}

export default Footer;