import React from "react";
import store from '../utilities/storage.js';
import { ReactComponent as CreateAccountRoot } from '../assets/Icons/CreateAccountRoot.svg';
import '../styles/pages/CreateAccount.css';

const CheckSuccess = () => {
    return <svg style={{cursor: "unset"}} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z" fill="#3B7A71"/>
    <path d="M16.9688 30.3747L7.75 21.156C7.625 21.031 7.625 20.8435 7.75 20.7185L10.4063 18.0623C10.5313 17.9373 10.7188 17.9373 10.8438 18.0623L17.1875 24.406L29.125 12.4685C29.25 12.3435 29.4375 12.3435 29.5625 12.4685L32.2188 15.1248C32.3438 15.2498 32.3438 15.4373 32.2188 15.5623L17.4063 30.3747C17.2813 30.4997 17.0938 30.4997 16.9688 30.3747Z" fill="white"/>
    </svg>    
}

export default function SuccessScreen() {
    return <div className="CreateAccountContainer Success">
        <div className="CreateAccount">
            <div className="CreateAccountBanner">
                <div className="BannerContent">
                    <div className="BannerInfo">                    
                        <span className="Title" style={{"textAlign":"left"}} > Welcome to Rialto, <br/> {store.retrieve("firstname")}! </span>
                            <span className="Content">Now, complete your signup by setting up your Crib Profie.</span>                        
                            <span className="Content">Or you can Explore to get familiar with Rialto and its features.</span>
                    </div>
                </div>
                <CreateAccountRoot className="CreateAccountRoot" />
            </div>
            <div className="SuccesNotificationWrapper">
                <div className="SuccessNotif">
                    <span className="Title">Congratulations!</span>
                    <CheckSuccess />
                    <span className="Content">You’ve been added to the waitlist!</span> 
                </div>
            </div>
        </div>
    </div>
}

