import neighborhood from "api/neighborhood";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import store from "utilities/storage";
import inviteApi from "api/signupFromInvite.js";

export function useGetNeighborhood() {
  const { neighborhood_id, invite_id } = useParams();

  const _neighborhood_id = store.retrieve("neighborhood_id");

  const possibleNeighbohoodId = neighborhood_id ?? _neighborhood_id;

  const neighbohoodQuery = useQuery({
    queryKey: ["neighborhood", possibleNeighbohoodId, invite_id],
    queryFn: () => {
      if (invite_id) {
        return inviteApi.getInviteNeighborhood(
          possibleNeighbohoodId,
          invite_id
        );
      } else {
        return neighborhood.getNeighborhood(possibleNeighbohoodId);
      }
    },
    enabled: !!possibleNeighbohoodId || !!invite_id,
    staleTime: 0,
    cacheTime: 0,
  });

  return neighbohoodQuery;
}
