import React, { useState } from "react";
import { useQuery } from "react-query";
import crib from "api/crib";
import SetupStep1 from "./SetupStep1";
import SetupStep2 from "./SetupStep2";
import sessionInfo from "utilities/session/sessionInfo";

const forms = {
  step1: SetupStep1,
  step2: SetupStep2,
};

const CribSetup = () => {
  const session = sessionInfo();
  const crib_id = session?.account_id;
  const [currentStep, setCurrentStep] = useState("step1");
  const [payload, setPayload] = useState({});
  const [userData, setUserData] = useState({});

  /* TODO: Add state management tool and store crib profile information so API call won't be made every time*/

  const { isLoading } = useQuery(
    "cribProfile",
    () => crib.getCribProfile(crib_id),
    {
      retry: false,
      onError: (error) => {
        console.log(error);
      },
      onSuccess: (response) => {
        setUserData(response?.data?.profile);
      },
    }
  );

  const CurrentForm = forms[currentStep];

  return (
    <div className="crib-setup">
      <CurrentForm
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        session={session}
        isLoading={isLoading}
        userData={userData}
        payload={payload}
        setPayload={setPayload}
      />
    </div>
  );
};

export default CribSetup;
