import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { appLogo } from "config";
import CityLabLogo from "assets/logos/CLP_logo.png";
import MemberUploadSuccess from "../assets/Icons/MemberUploadSuccess.svg";
import { ReactComponent as SignInRoot } from "../assets/Icons/CreateAccountRoot.svg";
import "../styles/pages/PasswordResetSuccess.css";

const PasswordResetSuccess = () => {
  const { customRoute } = useParams();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(customRoute ? `/${customRoute}/sign-in` : `/sign-in`);
  };

  return (
    <div className="password-reset-success">
      <div className="password-reset-success__banner">
        <div className="password-reset-success__banner--container">
          <div className="password-reset-success__banner--content">
            <div className="password-reset-success__logo--container">
              {customRoute ? (
                <img
                  src={CityLabLogo}
                  className="custom_logo"
                  alt="CityLab Logo"
                />
              ) : (
                <img
                  src={appLogo.desktop}
                  className="RialtoLogo"
                  alt="Rialto Logo"
                />
              )}
            </div>
            <div className="password-reset-success__banner--info">
              <span className="password-reset-success__banner--title">
                Password reset successful
              </span>
              <span className="password-reset-success__banner--sub">
                Your account has been recovered successfully.
              </span>
              {customRoute ? (
                <div className="powered_by">
                  <p className="powered_by_text">Powered by</p>
                  <img
                    src={appLogo.desktop}
                    className="powered_by_logo"
                    alt="Rialto Logo"
                  />
                </div>
              ) : null}
            </div>
          </div>
          <SignInRoot className="SignInRoot" />
        </div>
      </div>
      <div className="password-reset-success__flex">
        <div className="password-reset-success__container">
          <h2 className="password-reset-success__heading">Congratulations!</h2>
          <div className="password-reset-success__icon--container">
            <img
              src={MemberUploadSuccess}
              className="password-reset-success__icon"
              alt="check icon"
            />
          </div>
          <p className="password-reset-success__subheading">
            Password reset successful. You can now sign in to your Rialto
            account with your new password.
          </p>
          <button className="Submit" onClick={handleClick}>
            Sign in
          </button>
        </div>
      </div>
    </div>
  );
};
export default PasswordResetSuccess;
