import React from 'react';
import { NavLink } from 'react-router-dom';
import minwo_landingPage_header from '../../../assets/Images/minwo_landingPage_header.svg';
import minwo_yellow_irregular from '../../../assets/Images/minwo_yellow_irregular.svg';
import minwo_green_irregular from '../../../assets/Images/minwo_green_irregular.svg';
import minwo_gold_irregular_bottom from '../../../assets/Images/minwo_gold_irregular_bottom.svg';
import minwo_header_footer_root from '../../../assets/Images/minwo_header_footer_root.svg';
import classes from './Header.module.css';

const Header = () => {
	return (
		<header className={classes.header}>
			<div className={`${classes.placedImages} ${classes.yellow}`}>
				<img src={minwo_yellow_irregular} alt='irregular circle' />
			</div>
			<div className={`${classes.placedImages} ${classes.green}`}>
				<img src={minwo_green_irregular} alt='irregular circle' />
			</div>
			<div className={`${classes.placedImages} ${classes.gold}`}>
				<img src={minwo_gold_irregular_bottom} alt='irregular circle' />
			</div>
			<div className={`${classes.placedImages} ${classes.bottom}`}>
				<img src={minwo_header_footer_root} alt='bottom image' />
			</div>
			<div className={classes.community}>
				<div className={classes.text}>
					<h2>
						It takes (a) <span>community</span> to grow a business.
					</h2>
					<p>
						<span>Rialto</span> is the Community-as-a-Service platform that
						connects you to networks and helps you discover the tools and
						resources you need to Hustle Forward.
					</p>
				</div>
				<div className={classes.btn}>
					<NavLink to='/pricing' className={classes.direct}>
						Sign up
					</NavLink>
					<NavLink
						to={{
							pathname:
								'https://calendly.com/d/dg92-6m9b/rialto-demo-with-minwo',
						}}
						target='_blank'
						className={`${classes.direct} ${classes.transparent} `}
					>
						Schedule demo
					</NavLink>
				</div>
			</div>
			<div className={classes.img}>
				<img src={minwo_landingPage_header} alt='header image' />
			</div>
			<div className={classes.footer}>
				<p>
					Business community leaders and organizations are using{' '}
					<span>Rialto</span> to empower entrepreneurs, giving them a space to
					build business through community.
				</p>
			</div>
		</header>
	);
};

export default Header;
