import React from "react";
import { Link } from "react-router-dom";
import classes from "../../styles/pages/Privacy.module.css";
import Circle from "../../assets/Icons/circle.svg";
import yellowCircle from "../../assets/Images/yellowcircle.svg";
import yellowCircles from "../../assets/Images/yellowcircles.svg";
import doubleCircle from "../../assets/Icons/doublecircle.svg";
import greenCurve from "../../assets/Images/greencurve.svg";
import purpleCurve from "../../assets/Images/purplecurve.svg";
import Root from "../../assets/Images/Root2.svg";
import Navigation from "./Navigation/Navigation";
import SubFooter from "./SubFooter/SubFooter";
import Footer from "./Footer/footer";

const Privacy = () => {
  return (
    <div className="Privacy">
      <div className={classes.privacyBox}>
        <Navigation />
        <div className={classes.privacyContainer}>
          <img src={Root} alt="image" className={classes.rootimage} />
          <div className={classes.privacyContentBox}>
            <div className={classes.contents}>
              <h1 className={classes.privacyheadertext}>Privacy Policy</h1>

              <p className={classes.privacysubheadertext}>
                At <span className={classes.privacytextsname}>Rialto</span>,
                accessible from{" "}
                <a
                  href="https://www.rialtoapp.co/"
                  target="_blank"
                  className={classes.anchor}
                >
                  {" "}
                  https://www.rialtoapp.co{" "}
                </a>
                , one of our main priorities is the privacy of our visitors.
                This Privacy Policy document contains types of information that
                is collected and recorded by Rialto and how we use it.
              </p>

              <p className={classes.privacysubheadertext}>
                {" "}
                If you have additional questions or require more information
                about our Privacy Policy, do not hesitate to contact us.
              </p>

              <p className={classes.privacysubheadertext}>
                {" "}
                This Privacy Policy applies only to our online activities and is
                valid for visitors to our website with regards to the
                information that they shared and/or collect in Rilato. This
                policy is not applicable to any information collected offline or
                via channels other than this website.{" "}
              </p>
              <p className={classes.privacysubheadertext}>
                Last Updated: April 5, 2022
              </p>
            </div>
            <div></div>
          </div>
          <img src={Circle} alt="circle" className={classes.circleimage} />
          <img
            src={doubleCircle}
            alt="double circle"
            className={classes.doublecircleimage}
          />
          <img
            src={yellowCircle}
            alt="offer"
            className={classes.yellowcircleimage}
          />
        </div>
        <section className={classes.privacyContents}>
          <div className={classes.sectionboxone}>
            <div className={classes.content}>
              <h3>Privacy Statement</h3>
              <p>
                Your privacy is valued and respected at MINWO Inc. (“MINWO”,
                “we”, “our”, or “us”). This Privacy Policy (“Privacy Policy”)
                describes the types of information that we gather from you when
                you use our websites, including www.minwo.co, www.rialtoapp.co
                or Rialto, our AI-enabled Community-as-a-Service platform (our
                “Services”), as well as your choices regarding the use and
                disclosure of your Personal Data.
              </p>
              <p>
                As used in this Privacy Policy, the term “Personal Data” means
                information that identifies an individual, household, or place
                of business directly or indirectly, by reference to
                identifier(s) such as name, identification number, location
                data, online identifier or one or more factors specific to an
                individual’s physical, physiological, genetic, mental, economic,
                cultural, educational, commercial, professional or social
                identity.
              </p>
              <p>
                MINWO must collect and use certain information from our users to
                run our business and provide our Services. By accepting our{" "}
                <Link className={classes.linkText} to="/terms">
                  Terms of Use
                </Link>
                , you confirm that you have read this Privacy Policy and consent
                to MINWO using your information as described in this Privacy
                Policy. If you do not want MINWO to collect or use your personal
                information, do not use the Services.
              </p>
              <p>
                We encourage you to review this Privacy Policy whenever you use
                or access the Services in order to stay informed about our
                information practices and the ways you can help protect your
                privacy. If you have additional questions or require more
                information about our Privacy Policy, do not hesitate to contact
                us.
              </p>
              <h3>Personal Data Collected by MINWO</h3>
              <p>
                MINWO collects Personal Data about individuals as described in
                this section. When you use our Services, MINWO collects
                information that you (i) knowingly and voluntarily provide when
                you use our Services, (ii) that we are able to collect based on
                your use, and (iii) that we are able to collect using cookies
                and other similar technologies.
              </p>
              <p>
                <b>Personal Data that you Knowingly and Willingly Provide</b>
                &nbsp; &#8212; MINWO collects and stores Personal Data provided
                to us by our end users as part of the Services. MINWO also
                collects information that individuals voluntarily provide when
                using our Services, including registration and account set up
                information, profile details, and payment information. We
                primarily use this information to fulfill your transaction
                requests. You can choose not to provide us with certain Personal
                Data, but then you might not be able to take advantage of our
                Services or other available features that we offer. The
                information that we collect may include the following:
              </p>
              <ul>
                <li> First and Last Name</li>
                <li>Email Address</li>
                <li>Telephone Number</li>
                <li>Employer Name</li>
                <li>
                  Payment Information, including credit card or debit card
                  information and purchase history
                </li>
                <li>
                  {" "}
                  Login or account name, screen name, nick name, handle or any
                  other identifier that permits the physical or online
                  contacting of a specific individual
                </li>
                <li>
                  Information relating to an employer or business, including
                  industry, number of employees, revenue, address, and
                  certification statuses including certification as a Minority
                  Business Enterprise (MBE) or Women’s Business Enterprise (WBE)
                </li>
                <li>Race</li>
                <li>Gender or Gender Identity</li>
                <li>Age</li>
              </ul>
              <p>
                <b>Personal Data that we Collect Based on Your Use</b>&nbsp;
                &#8212; MINWO collects information that is sent automatically to
                us by your web browser. MINWO may use this information to
                generate statistics about visitors to our Services and to
                administer the Services. This information typically includes
                details about your:
              </p>
              <ul>
                <li> IP Address</li>
                <li>Browser Type</li>
                <li>Browser Plug-In Details</li>
                <li>Operating System</li>
                <li>Local Time Zone</li>
                <li>
                  Date and Time of Each Visitor Request (e.g., web pages
                  selected, and time spent on each page)
                </li>
                <li>Referring Site and/or Search Engine</li>
                <li>
                  Device Type (e.g., desktop, laptop, tablet, phone, etc.)
                </li>
                <li>Screen Size, Color, Depth, and System Fonts</li>
                <li>Language Preference</li>
              </ul>
              <p>
                <b>
                  Personal Data Collected by Cookies and Other Similar
                  Technologies
                </b>{" "}
                &nbsp; &#8212; MINWO may obtain information about you by
                installing “cookies” on your computer’s hard drive. We use
                cookies to collect statistical information about the ways
                visitors use our Services including which pages they visit,
                which links they use, and how long they stay on each page. MINWO
                may also use cookies to store your previous choices and
                preferences, saving you time and effort.
              </p>
            </div>
          </div>
          <div className={classes.sectionboxtwo}>
            <div className={classes.content}>
              <h3>How Is Personal Data Used?</h3>
              <p>
                We process Personal Data, including sharing with third parties,
                where processing is necessary for the purposes of our legitimate
                commercial interests and performance of the Services, except
                where otherwise required by law.
              </p>
              <p>
                We may use or sell Personal Data we collect on its own or
                combine it with other information we have about an individual
                to:
              </p>

              <ul>
                <li>Achieve our business purpose in providing the Services;</li>
                <li> Create user accounts or profiles through registration;</li>
                <li>
                  Perform and improve the Services (including providing Personal
                  Data to our partners);
                </li>
                <li>
                  Perform internal operations, such as tracking and improving
                  the effectiveness of our Services;
                </li>
                <li>
                  {" "}
                  Advertise and market our Services – and those of our partners
                  – including to present targeted advertising, send or provide
                  you with advertisements, and to help determine whether you may
                  be interested in new products or services
                </li>
                <li>Conduct audits;</li>
                <li>
                  Conduct research to develop new products, services, features,
                  and functionalities;
                </li>
                <li>
                  Protect the rights, safety, and information of our users,
                  third parties, and our Services, including fraud detection and
                  prevention.
                </li>
              </ul>
              <h3>Who Can Access the Personal Data We Collect?</h3>
              <p>
                MINWO will only disclose Personal Data as set forth in this
                Privacy Policy, as permitted by law, or with your consent.
              </p>
              <p>
                Where third parties are processing Personal Data on our behalf,
                they will be required to agree, by contractual means or
                otherwise, to process the Personal Data in accordance with
                applicable law and to act only on our instructions, or as
                permitted by law.
              </p>
              <p>
                MINWO may disclose your Personal Data to the following entities
                and for the listed purposes:
              </p>
              <ol>
                <li>
                  <b> Users</b> &nbsp; &#8212; For the purpose of providing the
                  Services, we share Personal Data with users of our Services.
                </li>
                <li>
                  {" "}
                  <b>Other Users</b> &nbsp; &#8212; Please remember that any
                  information you share in public areas of our Services, such as
                  on message boards or feedback sections, becomes public and
                  anyone may take and use that information. Please be careful
                  about what you disclose in these portions of our Services and
                  do not post any Personal Data that you expect to keep private.
                  We strongly encourage you not to use any Personal Data in your
                  username or password.
                </li>
                <li>
                  <b>Employees</b> &nbsp; &#8212;Only authorized employees have
                  access to your Personal Data
                </li>
                <li>
                  {" "}
                  <b>Advertisers</b> &nbsp; &#8212;Third-party advertisers and
                  advertising platforms also may serve targeted advertisements
                  as part of our Services. Please be aware that the information
                  practices of third-party advertisers or platforms collecting
                  data on our Services are not covered by this Privacy Policy.
                </li>
                <li>
                  <b>Service Providers</b> &nbsp; &#8212; We employ other
                  companies and individuals to perform functions on our behalf.
                  Examples of such functions may include sending postal mail and
                  email, removing repetitive information from customer lists,
                  analyzing data, providing technical support, providing search
                  results and links (including paid listings and links) and
                  providing customer service. We may share Personal Data with
                  these agents as we deem necessary for such third parties to
                  perform their functions.
                </li>
                <li>
                  <b>
                    To Comply with Legal Process, Protect MINWO, or Enforce our
                    Rights
                  </b>{" "}
                  &nbsp; &#8212; We may further disclose your Personal Data when
                  it is necessary to: (i) conform to legal requirements or
                  comply with legal processes; (ii) enforce or apply our
                  conditions of use and other agreements; (iii) protect the
                  rights, safety, or property of MINWO, our partners, our users,
                  service providers, you, or the public, or (iv) prevent a crime
                  or protect national security (including exchanging information
                  with other companies and organizations for fraud protection
                  and credit risk reduction).
                </li>
                <li>
                  <b>As Part of a Merger or Sale of Business</b> &nbsp; &#8212;
                  We may disclose or transfer Personal Data in connection with a
                  substantial corporate transaction, such as the sale of our
                  business, a divestiture, merger, consolidation, or asset sale,
                  or in the unlikely event of bankruptcy.
                </li>
              </ol>
            </div>
            <div className={classes.positionedImage}>
              <img
                src={greenCurve}
                alt="green vector"
                className={classes.green_vector}
              />
            </div>
            <img
              src={purpleCurve}
              alt="purple vector"
              className={classes.purplevector}
            />
          </div>
          <div className={classes.sectionboxthree}>
            <div>
              <img
                src={yellowCircles}
                alt="offer"
                className={classes.yellowcirclesimage}
              />
            </div>
            <div className={classes.content}>
              <h3>Your Rights Regarding Personal Data and MINWO Processes</h3>
              <ol>
                <li>
                  <b>Correction & Removal</b> &nbsp;&#8212; If any of the
                  information that we have about you is incorrect, or if you
                  wish to have information (including Personal Data) removed
                  from our records, please contact us at
                  rialto.cyberops@minwo.co. We will respond to such inquiries
                  within thirty (30) business days.
                </li>
                <li>
                  <b>Your California Privacy Rights</b>&nbsp; &#8212;
                  California’s “Shine the Light” law (Civil Code Section §
                  1798.83) permits individuals with whom we have a business
                  relationship who are California residents to request certain
                  information regarding our disclosure of Personal Data to third
                  parties for their direct marketing purposes. To make such a
                  request, please contact us at rialto.cyberops@minwo.co.
                </li>
                <li>
                  <b>Notice to California Residents</b>&nbsp;&#8212; The
                  California Consumer Privacy Act (“CCPA”) provides several
                  rights to California residents with regards to the collection,
                  disclosure, sale, and deletion of their Personal Data. If you
                  have questions about exercising your CCPA Rights, please
                  contact us at rialto.cyberops@minwo.co.
                </li>
                <li>
                  <b>Notice to Nevada Residents</b> &nbsp;&#8212; We currently
                  do not transfer personal information for monetary
                  consideration. If in the future our approach to transferring
                  personal information changes and you would like to tell us not
                  to sell your information at that time, please contact us at
                  rialto.cyberops@minwo.co. Include your name, postal address,
                  telephone number, and email address with “Nevada do not sell”
                  in the subject line.
                </li>
                <li>
                  {" "}
                  <b>Notice to Virginia and Colorado Residents</b> &nbsp;&#8212;
                  We currently do not transfer personal information for monetary
                  consideration. If in the future our approach to transferring
                  personal information changes and you would like to tell us not
                  to sell your information at that time, please contact us at
                  rialto.cyberops@minwo.co. Include your name, postal address,
                  telephone number, and email address with “Do not sell” in the
                  subject line.
                </li>
              </ol>
              <h3>Minors and Children Privacy</h3>
              <p>
                MINWO does not knowingly collect Personal Data from children
                under the age of 16. If we learn that the Personal Data of
                persons less than 16 years of age has been collected through our
                Services, we will take the appropriate steps to delete this
                information. If you are a parent or guardian and discover that
                your child or a minor under the age of 13 has posted, submitted,
                or otherwise communicated Personal Data to our Services without
                your consent, please contact us at rialto.cyberops@minwo.co so
                that we may take appropriate action to remove the minor's
                Personal Data from our systems.
              </p>
              <h3>Site-Specific Terms</h3>
              <ol>
                <li>
                  <b>Do Not Track Policy</b>&nbsp; &#8212; MINWO supports Do Not
                  Track (“DNT”). If you have DNT enabled in your browser, MINWO
                  will not receive browser-related information from our
                  advertising partners for tailoring advertisements.
                </li>
                <li>
                  {" "}
                  <b>Cookies Policy</b> &nbsp; &#8212;By using our websites, you
                  agree to our use of cookies as described in this Privacy
                  Policy. If you do not wish to receive cookies, you may set
                  your browser to reject cookies or to alert you when a cookie
                  is placed on your computer. If you limit or disable the use of
                  cookies when you visit the websites, you may be unable to use
                  the full functionality of our Services. We may also permit
                  select third parties to use cookies to collect information
                  about our Services’ visitors’ online activities across other
                  websites or over time to assist us with advertising or
                  analytics. The use of cookies by any third parties is not
                  covered by our Privacy Policy, as we do not have access to or
                  control over their cookies.
                </li>
                <li>
                  <b>Links to Third-Party Services</b> &nbsp; &#8212;Our
                  Services may contain links to third party websites,
                  applications, and services not operated by MINWO. These links
                  are provided as a service and do not imply any endorsement by
                  MINWO of the activities or content of these sites,
                  applications, or services nor any association with their
                  operators. MINWO is not responsible for the privacy policies
                  or practices of any third party including websites or services
                  directly linked to or from our Services.
                </li>
                <li>
                  <b>Social Media Features</b> &nbsp; &#8212;You can log in to
                  our Services using sign-in services connected through Google,
                  Linkedin, or an Open ID provider. These services will
                  authenticate your identity and provide you with the option to
                  share certain Personal Data with us such as your name and
                  email address to pre-populate our sign-up form. Social media
                  features and widgets are either hosted by a third party or
                  hosted directly on our Services. Your interactions with these
                  features are governed by the privacy policy of the company
                  providing it.
                </li>
              </ol>
            </div>
          </div>
          <div className={classes.sectionboxfour}>
            <div className={classes.content}>
              <h3>Security</h3>
              <p>
                MINWO takes the security of Personal Data seriously. We utilize
                industry-accepted reasonable security practices and have
                implemented reasonable technical and organizational measures to
                protect the confidentiality, security, and integrity of your
                Personal Data. The measures we have implemented take into
                account the currently available security technologies, cost, and
                risk presented by the type of Personal Data we collect, process,
                and use.
              </p>
              <p>
                Although we use reasonable security measures to help protect
                Personal Data against unauthorized disclosure or loss, we cannot
                guarantee the security of Personal Data transmitted to us over
                the Internet. While we strive to use commercially acceptable
                means to protect Personal Data, there is no guarantee that
                information may not be accessed, disclosed, altered, or
                destroyed.
              </p>
              <h3>Storage and Retention</h3>
              <p>
                The Personal Data that we store is generally stored on MINWO’s
                servers located in the United States. If you are located in
                another jurisdiction, you should be aware that once your
                Personal Data is submitted through our Services, it will be
                transferred to our servers in the United States and that the
                United States currently does not have uniform data protection
                laws in place. We will retain Personal Data for as long as
                necessary to fulfill the purpose for which it was collected or
                to comply with legal requirements.
              </p>
              <h3>International Users</h3>
              <p>
                We are headquartered in the United States. Personal Data may be
                accessed by us or transferred to us in the United States or to
                our partners or service providers who are located worldwide. If
                you are using our Services from outside the United States, be
                aware that your information may be transferred to, stored, and
                processed in the United States where our servers are located,
                and our central database is operated. By using our Services, you
                consent to any transfer of this information.
              </p>
              <p>
                We will protect the privacy and security of Personal Data
                according to this privacy statement, regardless of where it is
                processed or stored; however, you explicitly acknowledge and
                consent to the fact that Personal Data stored or processed in
                the United States will be subject to the laws of the United
                States, including the ability of governments, courts, or law
                enforcement or regulatory agencies of the United States to
                obtain disclosure of Personal Data.
              </p>
            </div>
          </div>
          <div className={classes.sectionboxfive}>
            <div className={classes.content}>
              <h3>Changes to this Privacy Policy</h3>
              <p>
                MINWO reserves the right to update or modify this Privacy Policy
                from time to time. If we make any material changes to this
                Privacy Policy or the way we use, share, or collect Personal
                Data, we will revise the “Last Updated” date at the top of this
                Privacy Policy and prominently post an announcement of the
                changes on our website prior to the new policy taking effect.
                Changes to the Privacy Policy will become effective within a
                reasonable period of time after posting the announcement of the
                changes. We encourage you to review this Privacy Policy
                regularly to ensure that you are familiar with MINWO’s current
                practices.
              </p>

              <h3>Privacy Inquiries</h3>
              <p>
                If you have any questions or concerns about this Privacy Policy
                or our privacy practices, please contact us as follows:
              </p>
              <ul>
                <li>
                  By emailing the Privacy Department at:
                  rialto.cyberops@minwo.co
                </li>
                <li>
                  By mail post to:&nbsp;
                  <span>
                    Attn: MINWO Legal The Green Suite 6862 Dover, Delaware 19901
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <SubFooter />
        <Footer />
      </div>
    </div>
  );
};
export default Privacy;
