import React from 'react';
import { ChatHistoryRaw } from './ChatHistory.raw';
import { ChatHistoryErrorText, ChatHistoryWrapper } from './ChatHistory.styles';

export const ChatHistoryQuery = (props) => {
  const { chatsHistoryQuery } = props;

  const { isLoading, isError } = chatsHistoryQuery;

  if (isLoading) {
    return null;
  }

  if (isError) {
    return (
      <ChatHistoryWrapper>
        <ChatHistoryErrorText>Error loading chat history</ChatHistoryErrorText>
      </ChatHistoryWrapper>
    );
  }

  if (chatsHistoryQuery.data?.records?.length === 0) {
    return <div />;
  }

  const { data } = chatsHistoryQuery;

  return <ChatHistoryRaw ChatHistoryData={data} />;
};
