import React, { useState, useEffect, useRef, useContext } from "react";
import sessionInfo from "../../utilities/session/sessionInfo";
import classes from "./UserModal.module.css";
import { useNavigate, Link } from "react-router-dom";
import { ReactComponent as DefaultPicture } from "../../assets/Images/default-profile-picture.svg";
import getDataURLFromByteArray from "../../utilities/getDataURLFromByteArray";
import context from "../../contextStore/neighborhood-context";
import api from "../../api/crib";
import UserImageAvatar from "../GlobalComponents/UserImageAvatar";
import { NeighborhoodsDropdown } from "./NeighborhoodsDropdown";

const UserModal = ({
  editUserProfile,
  listClicked,
  cribInfo,
  setIsUserToggled,
  createNewNeighborhood,
  roleCheck,
  closeMenu,
}) => {
  const [isSubscribed, setIsSubscribed] = useState(true); // handle state update before and after mount

  useEffect(() => {
    return () => setIsSubscribed(false);
  }, [isSubscribed]);

  const ctx = useContext(context);
  const modalRef = useRef();
  // closeNav

  const { firstname, lastname, jobtitle, account_id, role } = sessionInfo();
  const isUserTypeBCO = role && role === "BCO";
  const navigate = useNavigate();
  const handleSignout = () => {
    window.localStorage.clear();
    navigate("/sign-in");
  };
  const editUserProfileHandler = (event) => {
    event.preventDefault();
    navigate(`/crib/${account_id}/edit`);
    listClicked();
    editUserProfile();
    setIsUserToggled(false);
  };
  const closeHamburger = (event) => {
    setIsUserToggled(false);
    event.preventDefault();
    listClicked();
  };
  var hide = true;
  const checkRole = (roleCheck) => {
    if (roleCheck == true) {
      hide = false;
    }
  };
  const createNewNeighborhoodHandler = (event) => {
    event.preventDefault();
    listClicked();
    createNewNeighborhood();
    if (!!isSubscribed) setIsUserToggled(false);
  };

  const [picture, setPicture] = useState();
  useEffect(async () => {
    const data = await api.getCribPicture(account_id);
    if (Array.isArray(data?.data?.buffer?.data)) {
      const cribpic = await getDataURLFromByteArray(
        data.data.buffer.data,
        data.mimetype
      );
      if (!!isSubscribed) setPicture(cribpic);
    } else {
      if (!!isSubscribed) setPicture(data.data);
    }
  }, []);

  const url = "";

  const handleChangeProfile = () => {
    // navigate(`/crib/${account_id}`)
    if (!!isSubscribed) ctx.closeNav();
  };
  return (
    <ul className={classes.user} ref={modalRef}>
      <div className={classes.header} style={{ cursor: "pointer" }}>
        <a
          href={`/crib/${account_id}`}
          onClick={handleChangeProfile}
          className={classes.img}
        >
          <UserImageAvatar
            s3_picture_url={picture}
            alt={`${firstname} avatar`}
            className="ProfilePic"
          />
        </a>
        <a
          href={`/crib/${account_id}`}
          className={classes.subTitle}
          onClick={handleChangeProfile}
        >
          <h5>
            {firstname} {lastname}
          </h5>
          <p>{jobtitle}</p>
          <span>{cribInfo}</span>
        </a>
      </div>
      <ul onLoad={checkRole(roleCheck)} ref={modalRef}>
        <li>
          <a onClick={editUserProfileHandler} href={url}>
            Edit Crib profile
          </a>
        </li>
        {isUserTypeBCO && (
          <li>
            <NeighborhoodsDropdown closeMenu={closeMenu} />
          </li>
        )}

        <li>
          <a href="https://help.rialtoapp.com/en/" target="_blank">
            Rialto Help Center
          </a>
        </li>
        {/*
            <li>
               <a href={url} id="admin" hidden={hide} onClick={createNewNeighborhoodHandler}>Create Neighborhood Profile</a>
            </li>
            <li>
               <a href="#">
                  {window.location.href.includes('edit') ? 'Crib settings' : 'Upgrade Account'}
               </a>
            </li>
            <li>
               <a href="#">FAQs</a>
            </li>
            <li>
               <a href="#">Contact Support</a>
            </li>
            */}
        <li>
          <a
            href="https://forms.clickup.com/f/266j3-2244/Y78LPUZU28GEGCVUR2"
            target="_blank"
          >
            Report an Error{" "}
          </a>
        </li>
      </ul>
      <a href={url} className={classes.signOut} onClick={handleSignout}>
        Sign Out
      </a>
    </ul>
  );
};

export default UserModal;
