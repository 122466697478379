import React from "react";
import { useNavigate } from "react-router-dom";
import Root from "../../../assets/Images/Root2.svg";
import greenbob from "../../../assets/Images/greenbob.svg";
import Forum from "../../../assets/Images/Forumimage.png";
import Chatbot from "../../../assets/Images/chatbotimage.png";
import purpleVector from "../../../assets/Images/purplevector.svg";
import Vector3 from "../../../assets/Images/yellowvector.svg";
import Circle from "../../../assets/Icons/circle.svg";
import yellowCircle from "../../../assets/Images/yellowcircle.svg";
import yellowCircles from "../../../assets/Images/yellowcircles.svg";
import greenCurve from "../../../assets/Images/greencurve.svg";
import purpleCurve from "../../../assets/Images/purplecurve.svg";
import doubleCircle from "../../../assets/Icons/doublecircle.svg";
import GroupImage from "../../../assets/Images/Group members.png";
import GroupForumImage from "../../../assets/Images/Groupforum.png";
import GroupChat from "../../../assets/Images/Groupchat.png";
import Members from "../../../assets/Images/Frame.png";
import "./WhyRialto.css";

const WhyRialto = () => {
  const navigate = useNavigate();
  const handleClick = () => navigate("/pricing");
  return (
    <>
      <div className="whyRialto">
        {/* <Navigation/> */}
        <div className="whyRialtobox">
          <div className="whyRialtoContainer">
            <img src={Root} alt="offer" className="rootimage" />
            <div className="contentboxone">
              <div>
                <h1 className="whyrialtoproductheader">
                  <span className="textsname">Rialto</span> creates a space for
                  Black entrepreneurs and the communities that support them to
                  do business better, together.
                </h1>
                {/* <span className="subheader">Hustle Forward</span><span className="vectordot" style={vectordot}>.</span> */}
                <p className="whyrialtosubheadertext">
                  Last year, U.S. companies raised record amounts of venture
                  capital, at just under $150 billion. But of that capital, only
                  $1 billion went to Black or African-American startup founders,
                  which comes out to less than 1 percent of total funding, per
                  Crunchbase data. Leveraging the power of AI, Rialto enables
                  programs and organizations like Venture Capitalists,
                  Incubators, Accelerators, Consultants, and others to
                  organically find, collaborate with, and invest in Black
                  founders.
                </p>
              </div>
              <div></div>
            </div>
            <img src={Circle} alt="circle" className="circleimage" />
            <img
              src={doubleCircle}
              alt="double circle"
              className="doublecircleimage"
            />
            <img src={yellowCircle} alt="offer" className="yellowcircleimage" />
          </div>
          <section className="whyrialtocontents">
            <div className="sectionboxone contentbox">
              <div className="content">
                <p className="whyrialtoproductinfo">
                  A{" "}
                  <span className="whyrialtosubheader">
                    Community-as-a-Service
                  </span>{" "}
                  platform tailored uniquely to your community’s needs.
                </p>

                <p className="whyrialtotext">
                  The world is becoming increasingly interconnected and access
                  to the right networks can be a game changer for business
                  owners and the communities that support them.
                </p>
                <button className="whyrialtoSignupbutton" onClick={handleClick}>
                  Sign Up
                </button>
              </div>
              <div>
                <img src={greenbob} alt="green vector" className="greenbob" />

                <img src={Members} alt="members" className="memberImage" />
                <img src={GroupImage} alt="members" className="groupImage" />
              </div>
            </div>
            <article className="sectionboxtwo">
              {/* <div className="sectionboxtwo"> */}

              <div>
                <img
                  src={purpleVector}
                  alt="purple vector"
                  className="purple_vector"
                />
                <img src={Forum} alt="forumpage" className="forumImage" />
                <img
                  src={GroupForumImage}
                  alt="forumpage"
                  className="groupsImage"
                />
              </div>
              <div className="positionedImage">
                <img
                  src={greenCurve}
                  alt="green vector"
                  className="green_vector"
                />
              </div>
              <img
                src={purpleCurve}
                alt="purple vector"
                className="purplevector"
              />
              <div className="subsection">
                <p className="whyrialtoproductinfo">
                  <span className="whyrialtosubheader">
                    Data-driven conversations
                  </span>{" "}
                  to build your brand and stand out.
                </p>
                <br />
                <p className="whyrialtotext">
                  Rialto helps you 10x your efforts by exposing your community
                  members to resources previously beyond their reach. They gain
                  visibility and exposure to other founders, community
                  organizers and resource providers through a suite of
                  engagement tools like discussion forums, events, and bulletin
                  boards.
                </p>
                <br />
                <p className="whyrialtotext">
                  {" "}
                  With tags, categories and other classification data, Rialto
                  recommends
                  <br /> the right resources and experts to your community
                  members.
                </p>
                <button className="whyrialtoCreatebutton" onClick={handleClick}>
                  Create Account
                </button>
              </div>

              {/* </div> */}
            </article>
            <div className="sectionboxone">
              <div className="content">
                <p className="whyrialtoproductinfo">
                  Save time and energy with &nbsp;
                  <span className="whyrialtosubheader">
                    recommended resources and connections
                  </span>{" "}
                  .
                </p>

                <p className="whyrialtotext">
                  Part of the struggle for first time founders is navigating
                  blindly through uncharted spaces. Rialto leverages the power
                  of data analytics to provide curated recommendations for
                  neighborhoods, services, resources, events, and connections
                  that are right for you.
                </p>
                <button
                  className="whyrialtoStartupbutton"
                  onClick={handleClick}
                >
                  Get Started
                </button>
              </div>

              <img src={Vector3} alt="yellow vector" className="yellowblob" />
              <div className="box">
                <img src={Chatbot} alt="chatbot" className="chatbotImage" />
                <img
                  src={GroupChat}
                  alt="chatbot"
                  className="groupchatbotImage"
                />
              </div>
              <div>
                <img
                  src={yellowCircles}
                  alt="offer"
                  className="yellowcirclesimage"
                />
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default WhyRialto;
