import React, {useState} from 'react';
import { ReactComponent as CheckCircle } from '../../../assets/Icons/check-circle.svg';
import { ReactComponent as ChevronDown } from '../../../assets/Icons/chevron-down.svg';
import hustleForward from '../../../assets/logos/HustleForward.png';
// import minwo from '../../../assets/logos/Minwo.png';
// import udacity from '../../../assets/logos/Udacity.png';
// import pipelineAngels from '../../../assets/logos/Pipeline\ Angels.png';
// import gv from '../../../assets/logos/GV.png';
import './Pricing.css';

const Entrepreneur = (props) => {
    const [ content, setContent ] = useState(false);
    const [ isSelected, setIsSelected ] = useState(false)

    const handleContent = () => {
        setContent(!content)
    }

    const handleSelected = () => {
        setIsSelected(!isSelected)
    }

    if(props.isActive) {
        return (
            <div className={props.isActive ? "pricing__content pricing__content--visible" : "pricing__content"}>
                <div className="pricing__content--container">
                    <div className="pricing__content--box">
                        <div className="pricing__flex">
                            <div className="pricing__title--container">
                                <h3 className="pricing__title">Entrepreneur</h3>
                                <p className="pricing__title--brief">
                                Are you a Black business owner? Gain access to a world of smartly curated 
                                Neighborhoods and resources to grow your business.
                                </p>
                            </div>
                            <div className="pricing__benefits">
                                <h4 className="pricing__benefits--heading">Join a Neighborhood</h4>
                                <p className="pricing__benefits--sub">
                                    Right now, you need to be a member of an 
                                    existing Neighborhood to use Rialto. If you’re not currently part of one, you can 
                                    join any of the following existing Neighborhoods to get started.
                                </p>
                                <div className="pricing__neighborhood--benefits">
                                    <ul className="pricing__neighborhood--item">
                                        <li className="pricing__neighborhood--list">
                                            <div className="pricing__neighborhood--container">
                                                <img src={hustleForward} alt="" className="pricing__neighborhood--logo" />
                                                <span className="pricing__neighboorhood--name">Hustle Forward&#8482;</span>
                                                <ChevronDown onClick={handleContent} className={content ? "pricing__dropdown pricing__dropdown--rotate" : "pricing__dropdown"} />
                                                <button onClick={handleSelected} className="pricing__neighborhood--selection">
                                                    {
                                                        isSelected ? 
                                                        <CheckCircle className="pricing__neighborhood--checkmark" />
                                                        :
                                                        <div className="pricing__radio"></div>
                                                    }
                                                </button>
                                            </div>
                                            <p className={content ? "pricing__neighborhood--text open" : "pricing__neighborhood--text"}>
                                                <span className="pricing__neighborhood--span">MINWO Inc.</span> aims to provide strategic business products and 
                                                services that will enable our clients to <span className="pricing__neighborhood--span">Hustle Forward&#8482;</span>. 
                                                Through our <span className="pricing__neighborhood--span">Hustle Forward&#8482;</span> community on Rialto, we continue to 
                                                support entrepreneurs on their journey as they build, grow, 
                                                and scale their companies.
                                            </p>
                                        </li>
                                        {/* <li className="pricing__neighborhood--list">
                                            <img src={minwo} alt="" className="pricing__neighborhood--logo" />
                                            <span className="pricing__neighboorhood--name">MINWO community</span>
                                            <CheckCircle />
                                        </li>
                                        <li className="pricing__neighborhood--list">
                                            <img src={gv} alt="" className="pricing__neighborhood--logo" />
                                            <span className="pricing__neighboorhood--name">GV</span>
                                            <CheckCircle />
                                        </li>
                                        <li className="pricing__neighborhood--list">
                                            <img src={udacity} alt="" className="pricing__neighborhood--logo" />
                                            <span className="pricing__neighboorhood--name">Udacity</span>
                                            <div className="pricing__radio"></div>
                                        </li>
                                        <li className="pricing__neighborhood--list">
                                            <img src={pipelineAngels} alt="" className="pricing__neighborhood--logo" />
                                            <span className="pricing__neighboorhood--name">Pipeline angels</span>
                                            <div className="pricing__radio"></div>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="pricing__flex">
                            <div className="pricing__price--container">
                                <h3 className="pricing__price pricing__price--right">$0<span className="pricing__price--span">/month</span></h3>
                                <div className="pricing__price--text-container">
                                    <p className="pricing__price--text">Yes, Rialto is <span className="pricing__price--green">free</span>, forever! </p>
                                    <p className="pricing__price--text">All you need to do is join a community </p>
                                    <p className="pricing__price--text">and be a contributor of value.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <a href="https://laue3bw2yp7.typeform.com/to/fdndkMW6" className="pricing__button">Select plan</a>
                </div>
            </div>
        )
    }
    return '';
}

export default Entrepreneur;