import React, { useState, useEffect } from "react";
import HiddenURLLink from '../components/HiddenURLLink';
import '../styles/components/StepContents.css';

const stepsObj = {
    "OnboardingNeighborhoodprofile": 
     [ "CREATE PROFILE", "LIBRARY", "ADD MEMBERS"],
    "SetupCrib":    [ "PROFILE", "SUMMARY"],
    // "SetupCribProfile_ENT_":    [ "PROFILE", "SUMMARY"]
    // [ "CREATE PROFILE", "BULLETIN BOARD", "LIBRARY", "ADD MEMBERS"],
    //        [ "Add DESCRIPTION", "BULLETIN BOARD", "COMMUNITY FORUM", "LIBRARY", "ADD MEMBERS" ],
    //    [ "PROFILE INFO", "SUMMARY", "RESOURCES", "EVENTS", "SERVICES" ],
}

export default function StepTrackerCard(props) {
    const [url, setUrl] = useState('');
    const { currentStepIndex, stepsFor } = props;
    const stepNames = stepsObj[`${stepsFor}`];
    const totalSteps = typeof stepNames === ("object" || "array") ? 
                                                        stepNames.length : 0;

    const Steps = [];

    useEffect(() => route(), [url]);

    const route = (link) => {
        setUrl(link);
        const el = document.querySelector('.URLLink.StepLink');
        el.click();
    }

    for (let i = 0; i < totalSteps; i++) {
        const step = stepNames[i];
        const routeStepIndex = i + 1;
        const link = `step${routeStepIndex}`;
        const class_ = i === 0 ? "Step First " : "Step ";
    
        if (routeStepIndex === currentStepIndex ) {
            Steps.push(
                <div className={class_ + " Active"} key={i}>
                   <span className="Text"> {step} </span>
                </div> 
            )
        } 
        else if (routeStepIndex < currentStepIndex && currentStepIndex > 0 ) {
            Steps.push(
                <div className={class_ +" Visited"} key={i} onClick={() => route(link)}>
                    <span className="Text"> {step} </span>
                </div> 
            )          
        }
        else {
            Steps.push(
                <div className={class_} key={i} >
                <span className="Text"> {step} </span>
                </div> 
            )
        }
    }

    return (
        <React.Fragment>
            <HiddenURLLink url={url} data={props.data} className='StepLink'/>
            <div className="StepTracker">
                { Steps }
            </div>
        </React.Fragment>
    )
}
