import React, { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import clsx from "clsx";
import Download from "../../../../assets/svgs/download";
import Cancel from "../../../../assets/svgs/cancel";
import UserImageAvatar from "components/GlobalComponents/UserImageAvatar";
import { Skeleton } from "@material-ui/lab";

const File = ({ file, onDrop, setFile, isLoading, userData }) => {
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    maxFiles: 1,
    multiple: false,
    noClick: true,
    noDrag: file.length > 0,
    noKeyboard: true,
  });

  useEffect(() => {
    return () => file.forEach((item) => URL.revokeObjectURL(item.preview));
  }, []);

  return (
    <div {...getRootProps({ className: "step-one__file-input" })}>
      <label
        htmlFor="attachment"
        className={clsx(
          "step-one__file-btn",
          isDragActive && "active",
          file.length === 0 && !userData?.profile_picture && "file"
        )}
        onClick={file.length === 0 && open}
      >
        {file?.length === 0 && !userData?.profile_picture ? (
          <>
            <Download className="step-one__file-icon" />
            <span>Drag picture here or click to upload logo</span>
          </>
        ) : (
          <>
            {file?.length > 0 ? (
              <div className="accepted-file">
                <div className="accepted-file__container">
                  <img
                    src={file[0]?.preview || userData?.profile_picture}
                    alt={file[0]?.name}
                    onLoad={() => {
                      URL.revokeObjectURL(file[0]?.preview);
                    }}
                    className="accepted-file__img"
                  />
                  <p className="accepted-file__name">{file[0]?.name}</p>
                </div>
                <button
                  type="button"
                  className="accepted-file__btn"
                  onClick={() => setFile([])}
                >
                  <Cancel className="accepted-file__btn--icon" />
                </button>
              </div>
            ) : (
              <div className="accepted-file__box">
                {isLoading ? (
                  <Skeleton
                    variant="circle"
                    animation="pulse"
                    className="accepted-file__img"
                  />
                ) : (
                  <UserImageAvatar
                    s3_picture_url={userData?.profile_picture}
                    Alt={`${userData?.crib_first_name}'s avatar`}
                    className="accepted-file__img"
                  />
                )}
                <p className="accepted-file__name">
                  Drag and drop here or click to change picture
                </p>
              </div>
            )}
          </>
        )}
      </label>
      <input
        {...getInputProps()}
        accept="image/png, image/jpeg, image/jpg"
        className="step-one__input"
      />
    </div>
  );
};

export default File;
