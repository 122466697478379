import React from "react";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { Skeleton } from "@material-ui/lab";
import { useWindowSize } from "hooks/useWindowSize";
import { useErrorContext } from "hooks/useErrorContext";
import neighborhood from "../../api/neighborhood";
import NeighborhoodMemberBox from "./NeighborhoodMemberBox";
import EmptyNeighborhoodCard from "./EmptyNeighborhood/EmptyNeighborhoodCard";
import "./NeighborhoodMemberCard.css";

const NeighborhoodMemberCard = () => {
  const { width } = useWindowSize();
  const { neighborhood_id } = useParams();
  const triggerAlert = useErrorContext();

  const itemsToShow = width <= 767 ? 4 : width > 1200 ? 6 : 3;

  const { isLoading, data } = useQuery(
    "baseNeighborhoodMembers",
    () => neighborhood.getNeighborhoodBaseMembers({ neighborhood_id }),
    {
      retry: false,
      onError: (error) => {
        triggerAlert({
          open: true,
          message:
            error?.response?.data?.error ||
            "There was an error, please try again later",
          type: "error",
        });
      },
      onSuccess: (response) => {
        if (response?.status !== "success") {
          triggerAlert({
            open: true,
            message: "An error occured, please try again later",
            type: "error",
          });
        }
      },
    }
  );

  return (
    <div
      className="neighborhood-member-card"
      data-testid="neighborhoodMemberCard"
    >
      <div className="neighborhood-member-card__container">
        <div className="neighborhood-member-card__header">
          <h2 className="neighborhood-member-card__heading">Members</h2>
          <Link
            to={`/neighborhood/${neighborhood_id}/members`}
            className="neighborhood-member-card__link"
          >
            See more
          </Link>
        </div>
        {isLoading ? (
          <div className="neighborhood-member-card__box">
            {Array.from({ length: itemsToShow }).map((_, index) => (
              <div key={index} className="neighborhood-member-card__card">
                <Skeleton
                  variant="circle"
                  animation="pulse"
                  className="neighborhood-member-card__image"
                />
                <div className="neighborhood-member-card__details">
                  <Skeleton
                    variant="rect"
                    animation="pulse"
                    style={{
                      margin: "5px auto 0",
                      height: "12px",
                      borderRadius: "4px",
                      width: "180px",
                    }}
                  />
                  <Skeleton
                    variant="rect"
                    animation="pulse"
                    style={{
                      margin: "5px 0",
                      height: "10px",
                      borderRadius: "4px",
                      width: "80px",
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        ) : data?.data?.members?.length > 1 ? (
          <div className="neighborhood-member-card__box">
            {data?.data?.members?.slice(0, itemsToShow)?.map((member) => (
              <NeighborhoodMemberBox key={member?.crib_id} member={member} />
            ))}
          </div>
        ) : (
          <EmptyNeighborhoodCard />
        )}
      </div>
    </div>
  );
};

export default NeighborhoodMemberCard;
