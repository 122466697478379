import React from "react";
import "./step-tracker.css";

const StepTracker = ({ currentStep }) => {
  return (
    <div className="step-tracker" data-testid="step-tracker">
      {currentStep === "step1" ? (
        <p className="step-tracker__step1">Profile Info</p>
      ) : (
        <p className="step-tracker__step2">Summary</p>
      )}
      <div className="step-tracker__container">
        <div
          style={
            currentStep === "step1"
              ? { backgroundColor: "#f3a712" }
              : { backgroundColor: "#3b7a71" }
          }
        ></div>
        <div
          style={
            currentStep === "step1"
              ? { backgroundColor: "#c4c4c4" }
              : { backgroundColor: "#3b7a71" }
          }
        ></div>
        <div
          style={
            currentStep === "step1"
              ? { backgroundColor: "#c4c4c4" }
              : { backgroundColor: "#3b7a71" }
          }
        ></div>
      </div>
    </div>
  );
};

export default StepTracker;
