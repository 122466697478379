import React, { useState } from "react";
import TabNavigation from "../TabNavigation/TabNavigation";
import BusinessOrganizer from "./BusinessOrganizer";
import ResourceProvider from "./ResourceProvider";
import Entrepreneur from "./Entrepreneur";
import Testimonials from "../Testimonials/Testimonials";
import "./Pricing.css";

const Pricing = () => {
  const [activeTab, setActiveTab] = useState("Business community organizer");

  const handleActiveTab = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <div className="pricing">
        <div className="pricing__hero">
          <div className="pricing__hero--container">
            <h2 className="pricing__hero--heading">
              Supercharge your community and help your members do business
              better,
              <span className="pricing__hero--text"> together</span>.
            </h2>
            <p className="pricing__hero--sub">Let's do this!</p>
          </div>
        </div>
        <TabNavigation
          tabs={[
            "Business community organizer",
            "Resource provider",
            "Entrepreneur",
          ]}
          activeTab={activeTab}
          handleActiveTab={(tab) => handleActiveTab(tab)}
        />
        <BusinessOrganizer
          isActive={activeTab === "Business community organizer"}
        />
        <ResourceProvider isActive={activeTab === "Resource provider"} />
        <Entrepreneur isActive={activeTab === "Entrepreneur"} />
        <Testimonials />
      </div>
    </>
  );
};

export default Pricing;
