import React from 'react';

import { FreeTextForm } from '../FreeTextForm/FreeTextForm';
import ScrollChatContainer from '../ScrollChatContainer/ScrollChatContainer';
import { SandiBody } from './MessageListLayout.styles';

export function MessageListLayout(props) {
  const {
    botTyping,
    setLatestSubmitResponse,
    messagesToSubmit,
    setMessagesToSubmit,
    approvedMsgCount,
    botSize,
    children,
    chatContainerRef,
    wasAtBottomRef,
    setMessageSentByUser,
    messageSentByUser,
    record,
  } = props;

  return (
    <SandiBody>
      <ScrollChatContainer
        scrollDependency={[botTyping, botSize]}
        ref={chatContainerRef}
        wasAtBottomRef={wasAtBottomRef}
        messageSentByUser={messageSentByUser}
        setMessageSentByUser={setMessageSentByUser}
      >
        {children}
      </ScrollChatContainer>

      <FreeTextForm
        setLatestSubmitResponse={setLatestSubmitResponse}
        setMessagesToSubmit={setMessagesToSubmit}
        messagesToSubmit={messagesToSubmit}
        approvedMsgCount={approvedMsgCount}
        setMessageSentByUser={setMessageSentByUser}
        record={record}
      />
    </SandiBody>
  );
}
