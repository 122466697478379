import React, { useState, useEffect, useContext } from "react";
import store from "../utilities/storage";
import NeighborhoodProvider from "../state/NeighborhooBulletin/BulletinPost/BulletinPost_Provider";
import NeighborhoodContext from "../contextStore/neighborhood-context";
import NeighborhoodMemberCard from "components/NeighborhoodMemberCard/NeighborhoodMemberCard";
import ForumCard from "../components/ForumCard";
import LibraryCard from "../components/LibraryCard";
import BulletinBoardCard from "../components/BulletinBoard/BulletinPreview/BulletinBoardCard";
import "../styles/pages/NeighborhoodProfile.css";

const content = {
  bulletin:
    "The Bulletin Board lets you share and get insights on any topic or make Neighborhood-wide publications.",
  forum:
    "The Forum lets you ask questions and share insights with other members of the community.",
  library:
    "The Library lets you have one-on-one sessions with business leaders and leading entrepreneurs, offer and receive professional services, attend and host events, and get access to countless resources for your career or business.",
  members:
    "Enlarge your network by joining Neighborhoods and connecting with other members of the communities.",
};

export default function NeighborhoodProfile() {
  localStorage.removeItem("congratulation");
  const my_neighborhood_access_rights = JSON.parse(
    store.retrieve("neighborhood_privileges")
  );

  const ctx = useContext(NeighborhoodContext);
  useEffect(() => {
    setGoToCrib(false);
  }, []);
  const [activeTour, setActiveTour] = useState("");
  const [goToCrib, setGoToCrib] = useState(false);
  const toggleToCrib = () => {
    setGoToCrib(true);
  };
  if (goToCrib) {
    window.location.href = `${window.location.origin}/crib/${store.retrieve(
      "crib_id"
    )}`;
  }
  return (
    <div className="neighborhoodContainer">
      <div className="Dashboard">
        <NeighborhoodProvider>
          <BulletinBoardCard
            content={content.bulletin}
            activeTour={activeTour}
            setActiveTour={setActiveTour}
          />
        </NeighborhoodProvider>
        <ForumCard
          content={content.forum}
          activeTour={activeTour}
          setActiveTour={setActiveTour}
        />
        <LibraryCard
          content={content.library}
          activeTour={activeTour}
          setActiveTour={setActiveTour}
        />
        <NeighborhoodMemberCard />
      </div>
    </div>
  );
}
