import styled from "styled-components";

export const Container = styled.div`
  --shimmer-primary: ${({ primary = "#f1f1f1" }) => primary};
  --shimmer-secondary: ${({ secondary = "#ececec" }) => secondary};

  background-image: linear-gradient(
    -90deg,
    var(--shimmer-secondary) 0%,
    var(--shimmer-primary) 50%,
    var(--shimmer-secondary) 100%
  );
  background-size: 400% 400%;
  animation: shimmer 1.2s ease-in-out infinite;

  @keyframes shimmer {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
`;
