
import useCasesImageOne from '../../../assets/Images/Use Cases - 1.svg';
import useCasesImageTwo from '../../../assets/Images/Use Cases - 2.svg';
import useCasesImageThree from '../../../assets/Images/Use Cases - 3.svg';

export const useCasesData = [
    {
        id: 1,
        carouselHeading: 'Venture Capitalists, Incubators & Accelerators',
        carouselImage: useCasesImageOne,
        carouselSubHeading: 'Diversify your funding and program pipeline, and simplify communication with applicants.'
    },
    {
        id: 2,
        carouselHeading: 'Business community managers & Consultants',
        carouselImage: useCasesImageTwo,
        carouselSubHeading: 'Streamline how you manage your business community to increase engagement while reducing the time and effort spent.'
    },
    {
        id: 3,
        carouselHeading: 'Professional associations & bodies',
        carouselImage: useCasesImageThree,
        carouselSubHeading: 'Run your professional association on Rialto. Give members a space to engage, connect and collaborate.'
    }
]