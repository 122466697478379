import React from "react";
import Header from "../Header/Header";
import ProductDescription from "../ProductDescription/ProductDescription";
import Testimonials from "../Testimonials/Testimonials";
import UseCases from "../UseCases/UseCases";
import classes from "./HomePage.module.css";
const HomePage = () => {
  return (
    <>
      <div className={classes.header}>
        <Header />
        <ProductDescription />
        <Testimonials />
        <UseCases />
      </div>
    </>
  );
};

export default HomePage;
