import React, { useRef } from "react";
import { useCasesData } from "./UseCasesData";
import { ReactComponent as ArrowLeft } from "../../../assets/Icons/left-filled-arrow.svg";
import { ReactComponent as ArrowRight } from "../../../assets/Icons/right-filled-arrow.svg";
import "./UseCases.css";

const UseCases = () => {
  const useCasesRef = useRef(null);

  const handleScrollLeft = () => {
    if (useCasesRef.current) {
      useCasesRef.current.scrollBy({
        left: -200,
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const handleScrollRight = () => {
    if (useCasesRef.current) {
      useCasesRef.current.scrollBy({
        left: 200,
        top: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="use-cases">
      <div className="use-cases__container">
        <h2 className="use-cases__heading">
          Who is <span className="use-cases__span">Rialto </span>for?
        </h2>
      </div>
      <div className="use-cases__carousel" ref={useCasesRef}>
        {useCasesData.map((data, index) => {
          return (
            <div key={index} className="use-cases__carousel--container">
              <div className="use-cases__carousel--box">
                <h3 className="use-cases__carousel--heading">
                  {data.carouselHeading}
                </h3>
                <div className="use-cases__image--container">
                  <img
                    src={data.carouselImage}
                    alt=""
                    className="use-cases__image"
                  />
                </div>
                <p className="use-cases__sub">{data.carouselSubHeading}</p>
              </div>
            </div>
          );
        })}
      </div>
      <div className="use-cases__btn--container">
        <button
          className="use-cases__carousel--btn left"
          onClick={handleScrollLeft}
        >
          <ArrowLeft width="10" />
        </button>
        <button
          className="use-cases__carousel--btn right"
          onClick={handleScrollRight}
        >
          <ArrowRight width="10" />
        </button>
      </div>
    </div>
  );
};

export default UseCases;
