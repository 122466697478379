import React, { useState } from "react";
import { Modal } from "@material-ui/core";
import { useDynamicContent } from "../../../hooks/useDynamicContent";
import Upload from "../../../assets/svgs/upload";
import Link from "../../../assets/svgs/link";
import Plus from "../../../assets/svgs/plus";
import "./style.css";

const links = [
  {
    Icon: Upload,
    name: "Import",
  },
  {
    Icon: Plus,
    name: "Add manually",
  },
  {
    Icon: Link,
    name: "Share link",
  },
];

const EmptyNeighborhoodContainer = () => {
  const [open, setOpen] = useState(false);

  const {
    dynamicContent,
    bgColor,
    contents,
    handleClose,
    handleOpen,
    updateData,
  } = useDynamicContent({ setOpen });

  /**
   * @name openHandler
   * @description - Opens the modal and updates the content of the modal
   * @param {number} index - represents the index of links array
   */
  const openHandler = (index) => {
    handleOpen();
    const content = contents[index];
    const isRoot = contents[index];
    updateData(content, isRoot);
  };

  return (
    <>
      <div className="neighborhood-invite__container">
        {links.map(({ name, Icon }, index) => (
          <button
            key={name}
            onClick={() => openHandler(index)}
            className="neighborhood-invite__card"
          >
            <Icon className="neighborhood-invite__card--svg" />
            <p className="neighborhood-invite__card--text">{name}</p>
          </button>
        ))}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="neighborhood-invite-member-modal"
        aria-describedby="invite a member"
        data-testid="inviteMemberModal"
      >
        <div
          className="neighborhood-invite__content"
          style={{ backgroundColor: bgColor }}
        >
          {dynamicContent}
        </div>
      </Modal>
    </>
  );
};

export default EmptyNeighborhoodContainer;
