import React, { forwardRef, useEffect } from 'react';
import { MessageParentBox } from './MessageContainer.styles';
import { MessageTypingIndicator } from '../MessageTypingIndicator/MessageTypingIndicator';
import { NEXT_CHAT_DELAY } from 'config';
import * as testId from 'utilities/testIds';
import { usePostSandiChat } from '../utils/usePostSandiChat';
import { getFilteredNextConversations } from '../utils/getFilteredNextConversations';
import store from 'utilities/storage';
import { MessageContainer } from './MessageContainer';

export const NextConversationContainer = forwardRef((props, ref) => {
  const {
    nextMessage,
    messagesToSubmit,
    setMessagesToSubmit,
    isTyping,
    triggerDelayCompleted,
    checkRealtimeIsTyping,
    positionInParentList,
    setLatestSubmitResponse,
    setMessageSentByUser,
  } = props;

  const postSandiChatMutation = usePostSandiChat((data) => {
    setLatestSubmitResponse(data);
  });

  const filteredNewNextConversations =
    getFilteredNextConversations(messagesToSubmit);

  const handleOptionPost = (selectedOption) => {
    postSandiChatMutation.mutate({
      chats: [...filteredNewNextConversations, selectedOption],
    });
  };

  useEffect(() => {
    if (isTyping) {
      if (nextMessage)
        if (nextMessage.endOfSequence && !nextMessage.options) {
          postSandiChatMutation.mutate({
            chats: filteredNewNextConversations,
          });
          store.write('endOfSequence', true);
        }
      const timeout = setTimeout(() => {
        if (!checkRealtimeIsTyping(positionInParentList)) {
          return;
        }

        triggerDelayCompleted();
      }, NEXT_CHAT_DELAY);

      return () => clearTimeout(timeout);
    }
  }, [isTyping]);

  if (!nextMessage) return null;

  if (isTyping) {
    return (
      <MessageParentBox data-testid={testId.sandiChatbot.typingIndicator}>
        <MessageTypingIndicator />
      </MessageParentBox>
    );
  }

  const messageKey = ['id'];

  return (
    <MessageContainer
      ref={ref}
      message={nextMessage}
      messageKey={messageKey}
      handleOptionPost={handleOptionPost}
      setMessageSentByUser={setMessageSentByUser}
      setMessagesToSubmit={setMessagesToSubmit}
      messageType="present"
      isSuccess={
        postSandiChatMutation.isSuccess || postSandiChatMutation.isLoading
      }
    />
  );
});
