import sessionInfo from "./sessionInfo";

/**
 * Route current crib page to active session crib
 * - useful for when crib page has empty crib_id
 */
const routeToActiveSessionCrib = () => {
    const crib_id = sessionInfo().account_id;
    const url = `${window.origin}/crib/${crib_id}`;
    window.location = url;
}

export default routeToActiveSessionCrib;
