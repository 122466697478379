import * as yup from "yup";

export const messageSchema = yup.object().shape({
  bizStructure: yup.array().of(
    yup.object({
      name: yup.string(),
      value: yup.string(),
    })
  ),
});
