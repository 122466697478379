import React from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import styled from 'styled-components';
import { COLORS } from 'styles/colors/minwo';

export const StyledCheckbox = styled(Checkbox)`
  background-color: unset !important;
  &:hover {
    background-color: unset;
  }
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    color: ${COLORS.gray.gray02};
    font-size: 0.9rem;
  }
`;
