import React from "react";
import UserImageAvatar from "../../../components/GlobalComponents/UserImageAvatar.jsx";
import Checkmark from "../../../assets/svgs/checkmark.jsx";
import CheckMarkCircle from "../../../assets/svgs/CheckMarkCircle.jsx";
import store from "utilities/storage.js";
import "./CongratulationsPrompt.css";

const CongratulationsPrompt = () => {
  let info = JSON.parse(store.retrieve("CreateAccountFromInvite"));
  info = info?.sender ? info : {};
  store.remove("CreateAccountFromInvite");

  const routeToUrl = (url) => {
    window.onbeforeunload = () => null;
    window.location.href = url;
  };

  if (info)
    return (
      <div className="congratulations-prompt__container">
        <div className="congratulations-prompt__info">
          <div className="congratulations-prompt__header">
            <h2 className="congratulations-prompt__heading">
              Welcome to Rialto!
            </h2>
            <p className="congratulations-prompt__sub">
              You have been invited to join{" "}
              <span>{info?.sender?.neighborhood_name}</span> by
            </p>
          </div>
          <div className="congratulations-prompt__name--card">
            <UserImageAvatar
              s3_picture_url={info?.sender?.profile_picture}
              alt={`${info?.sender?.first_name}'s avatar`}
              className="congratulations-prompt__avatar"
            />
            <div className="congratulations-prompt__user-info">
              <h4 className="congratulations-prompt__name">
                {info?.sender?.first_name} {info?.sender?.last_name?.charAt(0)}.
              </h4>
              <p className="congratulations-prompt__workplace">
                {info?.sender?.workplace}
              </p>
            </div>
          </div>
          <div className="congratulations-prompt__prompt">
            <div className="congratulations-prompt__prompt--box">
              <CheckMarkCircle className="congratulations-prompt__prompt--icon" />
              <p>Host or attend Office Hour Sessions</p>
            </div>
            <div className="congratulations-prompt__prompt--box">
              <CheckMarkCircle className="congratulations-prompt__prompt--icon" />
              <p>Join educative and empowering forums</p>
            </div>
            <div className="congratulations-prompt__prompt--box">
              <CheckMarkCircle className="congratulations-prompt__prompt--icon" />
              <p>Host or attend Office Hour Sessions</p>
            </div>
            <div className="congratulations-prompt__prompt--box">
              <CheckMarkCircle className="congratulations-prompt__prompt--icon" />
              <p>And much more</p>
            </div>
          </div>
        </div>
        <div className="congratulations-prompt__congrats">
          <h2 className="congratulations-prompt__congrats__heading">
            Congratulations!
          </h2>
          <Checkmark className="congratulations-prompt__congrats__icon" />
          <div className="congratulations-prompt__message">
            <h4 className="congratulations-prompt__message--heading">
              Account has been successfully created.
            </h4>
            <p className="congratulations-prompt__message--text">
              Next, setup your crib profile.
            </p>
          </div>
          <button
            className="congratulations-prompt__btn"
            onClick={() => routeToUrl("/setup/crib")}
          >
            Setup Crib Profile
          </button>
        </div>
      </div>
    );
};

export default CongratulationsPrompt;
