import React, { useState, useEffect } from "react";
// import Notify from '../api/getNotifications'
import "../styles/components/NotificationCard.css";
import parse from "html-react-parser";
import urls from "../api/baseUrl";
import sessionInfo from "../utilities/session/sessionInfo";
// import axios from "axios";
// import { NotificationOndemandVideo } from "material-ui/svg-icons";
import defaultPicture from "../assets/Images/default-profile-picture.svg";

const { token } = sessionInfo();

const NotificationCard = () => {
  // const [title, setTitle] = useState(store.retrieve('pop_up_notification_msg'));
  const [notifications, setNotification] = useState([]);

  //const notificationsStr = store.retrieve("notifications")
  //const notificationsObj = JSON.parse(notificationsStr)
  const style = {
    position: "fixed",
    top: "50px",
    bottom: "60px",
    zIndex: "11111111",
    right: "0",
    left: "0",
    // overflowY: 'auto',
    width: "100vw",
  };
  const style2 = {
    // overflowY: 'auto',
    width: "100%",
    height: "88%",
    display: "flex",
    flexDirection: "column",
  };
  const getUserNotifications = () => {
    const url = `${urls.base_url}notifications/get_user_notifications`;
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer: ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setNotification(data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getUserNotifications();
  }, []);

  const viewedNotification = async (notification_id) => {
    const url = `${urls.base_url}notifications/mark_as_viewed/${notification_id}`;

    const h = new Headers({
      accept: "application/json",
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    });
    const req = new Request(url, {
      method: "PATCH",
      headers: h,
    });
    try {
      const response = await fetch(req);
      const data = await response.json();

      return data;
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  useEffect(() => {
    viewedNotification();
  }, []);

  let unread = notifications.filter(
    (notification) => notification.viewed === false
  ).length;

  return (
    <div style={style}>
      <div className="NotificationCard">
        <div className="NotificationTextContainer">
          <div className="NotificationTextWrapper">
            <h2 className="NotificationText">Notifications</h2>
            <p className="NotificationUnreadText">{unread} unread</p>
          </div>
        </div>
        <div style={style2}>
          {notifications.map((notification, notification_id) => {
            return (
              <div className="ContentWrapperContainer">
                <div
                  className={
                    notification.viewed === true
                      ? "ContentWrapper2"
                      : "ContentWrapper"
                  }
                  key={notification_id}
                  onClick={() => {
                    viewedNotification(notification.notification_id);
                  }}
                >
                  <div className="content">
                    <span className="Title">{notification.title}</span>
                    <a href={notification.link}>
                      <p className="Message"> {parse(notification.content)} </p>
                    </a>
                    {/* <span className="Time">2hrs ago</span> */}
                    {/* <div className="NotificationCta">
                      <button className="NotificationAcceptBtn">Accept</button>
                      <button className="NotificationDismissBtn">Dismiss</button>
                    </div> */}
                  </div>

                  <div className="Avatar">
                    <img
                      alt="Avatar"
                      src={defaultPicture}
                      // src={"data:image/*;base64, " + notification.picture}
                    ></img>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default NotificationCard;
