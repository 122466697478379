import React, { useEffect, useState, useContext, useRef } from "react";
import TwitterIcon from "assets/svgs/TwitterIcon.jsx";
import FacebookIcon from "assets/svgs/FacebookIcon.js";
import InstagramIcon from "assets/svgs/InstagramIcon.jsx";
import { ReactComponent as LinkedInIcon } from "assets/Icons/LinkedinIcon.svg";
import HomeIcon from "assets/svgs/HomeIcon.jsx";
import BulletinIcon from "assets/svgs/BulletinIcon.jsx";
import ForumIcon from "assets/svgs/ForumIcon.jsx";
import LibraryIcon from "assets/svgs/LibraryIcon.jsx";
import MembersIcon from "assets/svgs/MembersIcon.jsx";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ReactComponent as Logo3 } from "assets/Roots/RIALTO-ROOT-Pattern 2_Root Pattern 2 - white 3.svg";
import socialMediaHandleParser from "utilities/textToSocialMediaLink.js";
import minwo_library_dropdown from "assets/Images/minwo_library_dropdown.svg";
import defaultPicture from "assets/Images/default-profile-picture.svg";
import getDataURLFromByteArray from "utilities/getDataURLFromByteArray.js";
import "styles/components/NeighborhoodBanner.css";
import NeighborhoodContext from "contextStore/neighborhood-context.jsx";
import { DropdownPopover } from "components/DropdownPopover/DropdownPopover";
import sessionInfo from "utilities/session/sessionInfo";

const parseToWebLink = (link) => {
  return link.includes("https://") || link.includes("http://")
    ? link
    : `http://${link}`;
};

const routeToLink = (link) => {
  if (link) {
    window.location.href = parseToWebLink(link);
  }
};

export default function NeighborhoodBannerRaw({ neighborhoodData }) {
  const modalRef = useRef();
  const dropdownPopoverRef = useRef();
  const { role } = sessionInfo();
  const isUserTypeBCO = role && role === "BCO";
  const navigate = useNavigate();
  const [imageUrlURL, setImageURL] = useState(null);
  const [libraryVal, setLibraryVal] = useState("Library");
  const [spanVal, setSpanVal] = useState("Library");
  const [open, setOpen] = useState(false);

  const ctx = useContext(NeighborhoodContext);
  let { neighborhood_id } = useParams();

  const toggle = () => {
    setOpen((prev) => !prev);
  };
  const neighborhood_picture = neighborhoodData?.neighborhood_picture;
  const neighborhood = neighborhoodData?.neighborhood;

  useEffect(() => {
    const handler = (event) => {
      if (modalRef.current && !modalRef.current.value === event.target) {
        ctx.closeModal();
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, [modalRef]);

  useEffect(() => {
    if (neighborhood_picture) {
      getDataURLFromByteArray(
        neighborhood_picture?.buffer?.data,
        neighborhood_picture?.neighborhood_picture?.mimetype
      ).then((result) => {
        setImageURL(result);
      });
    }
  }, [neighborhood_picture]);

  useEffect(() => {
    setSpanVal(libraryVal);
  }, [libraryVal]);

  const active_link = window.location.href;

  const rotateImg = !ctx.isNeighborhoodOpen
    ? { transform: "rotate(0)" }
    : { transform: "rotate(180deg)" };

  const clickOptionHandler = (event, name) => {
    event.preventDefault();
    setOpen(false);
    setLibraryVal(name);
  };

  const libraryOptions = [
    {
      name: "Resources",
      link: `/neighborhood/${neighborhood_id}/library/resources`,
    },
    { name: "Events", link: `/neighborhood/${neighborhood_id}/library/events` },
    {
      name: "Services",
      link: `/neighborhood/${neighborhood_id}/library/services`,
    },
  ];
  const librarayOptionsMapped = libraryOptions.map((item) => {
    return (
      <li
        key={item.name}
        onClick={(event) => clickOptionHandler(event, item.name)}
      >
        <Link to={item.link}>{item.name}</Link>
      </li>
    );
  });

  const noImage =
    neighborhood_picture === "Image not found" || !neighborhood_picture;

  return (
    <div className="NeighborhoodBanner">
      <div className="Header">
        <div className="Info">
          <a
            href={`http://${neighborhood.company_website}`}
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="Logo"
              alt="Logo"
              src={noImage ? defaultPicture : imageUrlURL}
            />
          </a>
          <div className="MobileWrapper">
            <span
              className="Link pointer"
              onClick={() => routeToLink(neighborhood.company_website)}
            >
              {neighborhood.company_name}
            </span>
            <span className="SocialIcons">
              <a
                href={socialMediaHandleParser(
                  "facebook",
                  neighborhood.facebook
                )}
                target="_blank"
                rel="noreferrer"
                className="LinkPointer"
              >
                <FacebookIcon className="Icon" />
              </a>
              <a
                href={socialMediaHandleParser(
                  "instagram",
                  neighborhood.instagram
                )}
                target="_blank"
                rel="noreferrer"
                className="LinkPointer"
              >
                <InstagramIcon className="Icon" />
              </a>
              <a
                href={socialMediaHandleParser("twitter", neighborhood.twitter)}
                target="_blank"
                rel="noreferrer"
                className="LinkPointer"
              >
                <TwitterIcon className="Icon LinkPointer" />
              </a>
              <a
                href={socialMediaHandleParser(
                  "linkedin",
                  neighborhood.linkedin
                )}
                target="_blank"
                rel="noreferrer"
                className="LinkPointer"
              >
                <LinkedInIcon className="Icon LinkPointer" />
              </a>
            </span>
          </div>
        </div>

        <div className="Content">
          <span className="Title">{neighborhood.neighborhood_name}</span>
          <span className="Description">
            {neighborhood.neighborhood_description}
          </span>
        </div>
        <Logo3 className="pattern" />
        {isUserTypeBCO && (
          <div className="banner-dropdown">
            <DropdownPopover
              ref={dropdownPopoverRef}
              items={[
                {
                  label: "Edit Neighborhood profile",
                  onClick: () =>
                    navigate(`neighborhood/${neighborhood_id}/edit`),
                },
                {
                  label: "Contact Support",
                  onClick: () => window.open("https://help.rialtoapp.com/en/"),
                },
              ]}
            />
          </div>
        )}
      </div>
      <div className="NavBar">
        <a href={`/neighborhood/${neighborhood_id}`}>
          <span
            className={`Link ${
              active_link ===
              `${window.location.origin}/neighborhood/${neighborhood_id}`
                ? "Active"
                : ""
            }`}
          >
            <HomeIcon className="Icon" />
            <span>Home</span>
          </span>
        </a>
        <a href={`/neighborhood/${neighborhood_id}/bulletin`}>
          <span
            className={`Link ${active_link === "Bulletin" ? "Active" : ""}`}
          >
            <BulletinIcon className="Icon" /> <span>Bulletin Board</span>
          </span>
        </a>
        <a href={`/neighborhood/${neighborhood_id}/forum`}>
          <span
            className={`Link ${
              active_link ===
              `${window.location.origin}/neighborhood/${neighborhood_id}/forum`
                ? "Active"
                : ""
            }`}
          >
            <ForumIcon className="Icon" />
            <span>Forum</span>
          </span>
        </a>

        <div
          className={`Link ${
            active_link.includes("library") ? "Active" : ""
          } library`}
        >
          <LibraryIcon className="Icon" />
          <div className="libSpan" onClick={toggle}>
            {spanVal}{" "}
            <img
              src={minwo_library_dropdown}
              alt="dropdown"
              style={rotateImg}
            />{" "}
          </div>
          {open ? (
            <ul className="dropDown" ref={modalRef}>
              {librarayOptionsMapped}
            </ul>
          ) : null}
        </div>
        <a href={`/neighborhood/${neighborhood_id}/members`}>
          <span
            className={`Link ${
              active_link ===
              `${window.location.origin}/neighborhood/${neighborhood_id}/members`
                ? "Active"
                : ""
            } `}
          >
            <MembersIcon className="Icon" />
            <span>Members</span>
          </span>
        </a>
      </div>
    </div>
  );
}
