export const updatedObject=(oldObject,updatedObjects)=>{
   return{
      ...oldObject,
      ...updatedObjects
   }
}

export const checkValidity = (value, rule)=>{
   let isValid=true
   if(rule.required || !rule.required){
      isValid = value.trim() !=="" && isValid
   }
   if(rule.minLength){
      isValid = value.length>= rule.minLength && isValid
   }
   if(rule.isText){
      const textPattern = /^[a-zA-Z, ]+$/
     
      isValid = textPattern.test(value) && isValid
   }
   if (rule.isEmail) {
      const pattern = /[a-z0-9!#$%&'/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
      isValid = pattern.test(value) && isValid
   }
   return isValid
}
   
export const timeDifference = (date1) => {
   let result,
   date2 = new Date(),
   difference = date2.getTime() - new Date(date1).getTime();
 
   let minute=60000;   
   result = Math.round(difference/minute); 
   if(result < 1) {
      return `few seconds ago`;
   } else if(result === 1){
      return `1 minute ago`;	
   } else if(result < 61){
      return `${result} minutes ago`;
   }
 
   let hour = minute*60;
   result = Math.round(difference/hour);  
   if(result === 1) {
      return `1 hour ago`;	
   } else if(result < 25){
      return `${result} hours ago`;	
   }
 
   let day = hour*24;
   result = Math.round(difference/day); 
   if(result === 1){
      return `1 day ago`;	
   } else if(result < 31){
      return `${result} days ago`;
   }
 
   let month = day*30;
   result = Math.round(difference/month);
   if(result === 1){
      return `1 month ago`;	
   } else if(result < 13){
      return `${result} months ago`
   }
 
   let year = month * 12;
   result = Math.round(difference/year);
   if(result === 1){
      return `1 year ago`;	
   } else{
      return `${result} years ago`;
   }
 }