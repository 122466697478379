import React from "react";
import { BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Containers from "./containers";
import Intercom from "@intercom/messenger-js-sdk";
import sessionInfo from "utilities/session/sessionInfo";
import sessionStatus from "utilities/session/sessionStatus";
import "./App.css";

const App = () => {
  const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  // TODO: Use semantic versioning standard for rialto app versioning
  // see: https://semver.org/

  console.info("APP VERSION: 1.1.2");

  // Initialize Intercom when there's an active session
  if (sessionStatus() === 3) {
    const { firstname } = sessionInfo();
    const data = {
      app_id: process.env.REACT_APP_INTERCOM_APP_ID,
      // user_id: account_id,
      name: firstname,
    };
    Intercom(data);
  }

  return (
    <GoogleOAuthProvider clientId={CLIENT_ID}>
      <BrowserRouter>
        <Containers />
      </BrowserRouter>
    </GoogleOAuthProvider>
  );
};

export default App;
