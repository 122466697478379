import React from "react";
import "./import-members.css";

const ImportMembersProgress = ({ progress }) => {
  return (
    <div className="import-members-progress">
      <div className="import-members-progress__wrapper">
        <h2 className="import-members-progress__heading">{`Uploading (${progress}%)`}</h2>
        <div
          className="import-members-progress__progress--bar"
          role="progressbar"
          aria-label="progressbar"
          aria-valuenow={progress}
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <div
            className="import-members-progress__progress--indicator"
            style={{ width: `${progress}%` }}
          ></div>
        </div>
      </div>
      <div className="import-members-progress__info">
        <p className="import-members-progress__info--text">
          We're currently uploading your file. Hang tight, we'll be done soon
        </p>
      </div>
    </div>
  );
};

export default ImportMembersProgress;
