import React from "react";
import { Link } from "react-router-dom";
import { useWindowSize } from "hooks/useWindowSize";
import UserImageAvatar from "components/GlobalComponents/UserImageAvatar";
import PurpleCheckMarkCircle from "assets/svgs/purpleCheckmarkCircle";

const NeighborhoodMemberBox = ({ member }) => {
  const { width } = useWindowSize();

  return (
    <div
      key={member?.crib_id}
      className="neighborhood-member-card__card"
      data-testid="neighborhoodMemberDetails"
    >
      <UserImageAvatar
        s3_picture_url={member?.profile_picture}
        Alt={`${member?.crib_first_name}'s name`}
        className="neighborhood-member-card__image"
      />
      <div className="neighborhood-member-card__details">
        <div className="neighborhood-member-card__name--box">
          <Link
            to={`/crib/${member?.crib_id}`}
            className="neighborhood-member-card__name"
          >
            {member?.crib_first_name} {member?.crib_last_name}
          </Link>
          {member?.access_level > 1 ? <PurpleCheckMarkCircle /> : null}
        </div>
        <p className="neighborhood-member-card__workplace">
          {member?.workplace}
        </p>
      </div>
      {width >= 1024 ? (
        <div className="neighborhood-member-tooltip">
          <div className="neighborhood-member-tooltip__container">
            <UserImageAvatar
              s3_picture_url={member?.profile_picture}
              Alt={`${member?.crib_first_name}'s name`}
              className="neighborhood-member-tooltip__image"
            />
            <div className="neighborhood-member-card__details">
              <h3 className="neighborhood-member-card__name">
                {member?.crib_first_name} {member?.crib_last_name}
              </h3>
              <p className="neighborhood-member-card__workplace">
                {member?.workplace}
              </p>
              <p className="neighborhood-member-card__job">
                {member?.job_title}
              </p>
            </div>
          </div>
          {member?.website_link ? (
            <div className="neighborhood-member-tooltip__box">
              <a
                href={`https://${member.website_link}`}
                className="neighborhood-member-tooltip__website"
              >
                {member.website_link}
              </a>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default NeighborhoodMemberBox;
