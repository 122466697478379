import React from "react";

const Instagram = ({ className }) => {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.4165"
        y="1"
        width="16"
        height="16"
        rx="5"
        stroke="#D8D8D8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6165 8.49602C12.8189 9.8616 12.1229 11.2031 10.8898 11.8238C9.65663 12.4444 8.16449 12.2043 7.18832 11.2281C6.21215 10.252 5.97205 8.75984 6.59271 7.52672C7.21338 6.2936 8.55487 5.59752 9.92045 5.80002C11.3148 6.00679 12.4097 7.10164 12.6165 8.49602Z"
        stroke="#D8D8D8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Instagram;
