import React from "react";
import { Outlet } from "react-router-dom";
import NeighborhoodNavBar from "../components/RialtoNavBar";
import { SandiChatbot } from "components";

const CribNavigationLayout = () => {
  const path = window.location.pathname;
  return (
    <>
      <NeighborhoodNavBar />
      <div>
        <Outlet />
      </div>
      {!path.includes("setup") && <SandiChatbot />}
    </>
  );
};

export default CribNavigationLayout;
