import forms from './forms/index.js'
import components from './components/index.js'
import settings from './settings/index.js'

const propsData =  {
    forms,
    components,
    settings
};

export default propsData;
