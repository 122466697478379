import React from "react";
import { useGetNeighborhood } from "hooks/useGetNeighborhood";
import { NeighborhoodBannerLoading } from "./NeighborhoodBanner.loading";
import NeighborhoodBannerRaw from "./NeighborhoodBanner.raw";

export function NeighborhoodBannerQuery() {
  const { isLoading, isError, data } = useGetNeighborhood();
  if (isLoading || isError) return <NeighborhoodBannerLoading />;
  if (!data) return <div>No data</div>;
  const neighborhoodData = data.data;

  return <NeighborhoodBannerRaw neighborhoodData={neighborhoodData} />;
}
