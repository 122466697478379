import React from "react";

const Link = ({ className }) => {
  return (
    <svg
      className={className}
      width="73"
      height="73"
      viewBox="0 0 73 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.7358 39.8398C32.6261 43.7038 37.0522 46.122 41.8652 46.4667C46.6783 46.8114 51.4037 45.0486 54.8152 41.6359L64.7937 31.6574C71.0963 25.1318 71.0062 14.7591 64.5911 8.34397C58.176 1.92889 47.8033 1.83875 41.2777 8.14138L35.5566 13.8291"
        stroke="#3B7A71"
        strokeWidth="5.32187"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.0408 33.187C40.1505 29.323 35.7244 26.9048 30.9114 26.5601C26.0984 26.2154 21.3729 27.9782 17.9615 31.3908L7.98294 41.3693C1.6803 47.8949 1.77044 58.2677 8.18552 64.6828C14.6006 71.0979 24.9734 71.188 31.499 64.8854L37.1867 59.1976"
        stroke="#3B7A71"
        strokeWidth="5.32187"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Link;
