const videoChatSettings = {
    videoOn: false,
    micOn: false,
    screenShareOn: false,
    userName: 'John Akins',
    roomName: '',
    token: ''
}

export default videoChatSettings
