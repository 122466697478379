import React, { useState } from 'react';
import { ReactComponent as CurvedArrow } from '../../../assets/Icons/curved-arrow.svg';
import './Pricing.css';

const Toggle = () => {
    return (
        <svg width="44" height="24" viewBox="0 0 44 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M12 24L32 24C38.62 24 44 18.62 44 12C44 5.38 38.62 0 32 0L12 0C5.38 0 0 5.38 0 12C0 18.62 5.38 24 12 24ZM12 4L32 4C36.42 4 40 7.58 40 12C40 16.42 36.42 20 32 20L12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4ZM18 12C18 15.32 15.32 18 12 18C8.68 18 6 15.32 6 12C6 8.68 8.68 6 12 6C15.32 6 18 8.68 18 12Z" fill="#461C3F"/>
        </svg>
    )
}

const BusinessOrganizer = (props) => {
    const [priceToggle, setPriceToggle] = useState(false);
    const [handleOpacity, setHandleOpacity ] = useState(false);

    const handleToggle = () => {
        setPriceToggle(!priceToggle);
        setHandleOpacity(!handleOpacity)
    }

    if (props.isActive) {
        return (
            <div className={props.isActive ? "pricing__content pricing__content--visible" : "pricing__content"}>
                <div className="pricing__content--container">
                    <div className="pricing__content--box">
                        <div className="pricing__flex">
                            <div className="pricing__title--container">
                                <h3 className="pricing__title">Business community organizer</h3>
                                <p className="pricing__title--brief">
                                    Provides a complete suite of tools and features to seamlessly manage your 
                                    community, drive engagement and simplify communication, leading to 
                                    exponential results.
                                </p>
                            </div>
                            <div className="pricing__benefits">
                                <h4 className="pricing__benefits--heading">Full-powered Neighborhood</h4>
                                <div className="pricing__benefits--container">
                                    <ul className="pricing__benefits--item">
                                        <li className="pricing__benefits--list">Community Forum</li>
                                        <li className="pricing__benefits--list">Events</li>
                                        <li className="pricing__benefits--list">Unlimited resources</li>
                                        <li className="pricing__benefits--list">Office hours</li>
                                        <li className="pricing__benefits--list">Bulletin board</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="pricing__flex">
                            <div className="pricing__selection">
                                <div className="pricing__toggle--container">
                                    <p className="pricing__toggle--text">Yearly</p>
                                    <button className={priceToggle ? 'rotate' : ''} onClick={() => handleToggle()}>
                                        <Toggle />
                                    </button>
                                    <p className="pricing__toggle--text">Monthly</p>
                                </div>
                                <p className="pricing__selection--text">
                                    <CurvedArrow className="pricing__curved--arrow" />
                                    Save $25/month (20%)
                                </p>
                            </div>
                            <div className="pricing__price--container">
                                {
                                    priceToggle ? (
                                        <h3 className={`pricing__price ${handleOpacity ? "pricing__price--fadeIn": "pricing__price--fadeOut"}`}>$125<span className="pricing__price--span">/month</span></h3>
                                    ) : (
                                        <h3 className={`pricing__price ${handleOpacity ? "pricing__price--fadeIn": "pricing__price--fadeOut"}`}>$100<span className="pricing__price--span">/month</span></h3>
                                    )
                                }
                                <p className="pricing__price--text">per Neighborhood</p>
                            </div>
                        </div>
                    </div>
                    <a href="https://laue3bw2yp7.typeform.com/to/aHXmgdHd" className="pricing__button">Select plan</a>
                </div>
            </div>
        )
    }
    return "";
}

export default BusinessOrganizer;
