import React, { useState, useEffect } from "react";
import store from "../utilities/storage.js";
import { useParams } from "react-router-dom";
import urls from "../api/baseUrl.js";
import axios from "axios";
import storeSession from "../utilities/session/sessionInitialIzer.js";
import routeUser from "../utilities/routeUser.js";
import isEmpty from "../utilities/isEmpty.js";
// import identifyUserWithLou from "../utilities/session/identifyUserWithLou.js";

export default function ConfirmSignup() {
  const initialStatus = "Verifying your account...";
  const [status, setStatus] = useState(initialStatus);
  const [sessionData, setSessionData] = useState(null);
  const { token, account_id } = useParams();

  useEffect(() => {
    if (status === initialStatus && token && account_id) {
      (async () => {
        try {
          const res = await axios.get(
            `${urls.base_url}account/${account_id}/verify/${token}`
          );
          if (res.status === 200) {
            if (res?.data?.data?.token) {
              storeSession(res.data.data);
              setSessionData(res.data.data);
              routeUser(
                res.data.data.role,
                res.data.data.crib,
                res.data.data.neighborhood
              );
            }
          }
        } catch (err) {
          if (err.response && err.response.status === 400) {
            setStatus(err.response.data.error);
            store.clear();
            window.setTimeout(function () {
              window.location.href = window.location.origin + "/sign-in";
            }, 1000);
          } else if (err.response && err.response.status === 500) {
            setStatus("Server error, please try again");
          } else {
            setStatus("Please check your connection");
          }
        }
      })();
    }
  }, [status, account_id, token]);

  useEffect(() => {
    if (!isEmpty(sessionData)) {
      // identifyUserWithLou(sessionData);
    }
  }, [sessionData]);

  return (
    <div className="VerifyAccountContainer">
      <div className="VerifyAccount">{status}</div>
    </div>
  );
}
