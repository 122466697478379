import React, { useEffect, useState } from "react";
import axios from "axios";
import defaultPicture from "../../assets/Images/default-profile-picture.svg";
import isEmpty from "../../utilities/isEmpty";
import getDataURLFromByteArray from "../../utilities/getDataURLFromByteArray";

/**
 * A globally reusabe global img component
 *
 *  - requires image url usually returned from the API
 *      - image url usually generated from S3 bucket
 *  - loads user image using axios
 *
 *  - return an image element that loads user
 *          image from S3 bucket asynchronously,
 *              without blocking the main thread
 *  - returns rialto defaultPicture for image not found
 *
 * @param {String} s3_picture_url - url to image
 * @param {String} Alt - image alt property
 * @param {String} className - image class name
 * @param {Function} onClick - onclick function for image
 * @param {Object} defaultAvatar - JSX Element
 *
 */

// TODO: Add skeleton loader to image indicate loading state;
const UserImageAvatar = ({
  s3_picture_url,
  Alt,
  className,
  onClick,
  defaultAvatar,
}) => {
  const [isSubscribed, setIsSubscribed] = useState(true); // handle state update before and after mount
  const [picture_uri, setPictureURI] = useState(defaultPicture);

  useEffect(() => {
    return () => setIsSubscribed(false);
  }, [isSubscribed]);

  className = className || "Avatar";
  Alt = Alt || "Avatar";
  onClick = !isEmpty(onClick) ? onClick : () => null;

  useEffect(() => {
    if (!isEmpty(s3_picture_url) && s3_picture_url !== "File Not Found") {
      (async () => {
        const picture = await axios.get(s3_picture_url);
        if (!isEmpty(picture?.data)) {
          const isLongString =
            typeof picture.data === "string" && picture.data.length > 500;
          const isBase64 =
            isLongString && picture.data.split(",")[0].includes("base64");
          const hasBufferProp = !!picture?.data?.buffer;
          const isArrayBuffer = picture?.data?.data?.length > 100;

          // prevent state update
          // when component is unmounted
          if (!!isSubscribed) {
            const mimeType = picture?.data?.mimeType || "image/*";
            const data =
              picture?.data?.buffer?.data ||
              picture?.data?.data ||
              picture?.data;
            if (isBase64 === true) {
              setPictureURI(picture.data);
            } else if (isLongString) {
              setPictureURI(`data:image/png;base64, ${data}`);
            } else if (hasBufferProp || isArrayBuffer) {
              const url = await getDataURLFromByteArray(data, mimeType);
              setPictureURI(url);
            }
          }
        }
      })();
    }
  }, [s3_picture_url]);

  return (
    <>
      {picture_uri === defaultPicture && !!defaultAvatar ? (
        defaultAvatar
      ) : (
        <img
          className={className}
          alt={Alt}
          src={picture_uri}
          onClick={onClick}
        />
      )}
    </>
  );
};

export default UserImageAvatar;
