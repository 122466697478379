import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DropzoneAreaBase } from 'material-ui-dropzone'

const useStyles = makeStyles({
  root: {
    background: "rgba(59, 122, 113, 1)",
    width: "100%",
    paddingTop: " 6.4vw",
    paddingBottom: " 6.4vw",
    outline: " none",
    border: "none",
    textAlign: "center",
    color: " #fff",
    borderRadius: "2.666vw",
    fontStyle: "normal",
    fontWeight: " normal",
    lineHeight: "130%",
    backgroundColor: " rgba(59, 122, 113, 1)",
    fontSize: " 4vw",
  }
})
const Dropzone = DropzoneAreaBase

const SingleFileAutoSubmit = (props) => {
  const classes = useStyles()
  const handleChangeStatus = (files) => {
    var file = files[0];
    props.getImage(file);
  }

  return (
    <React.Fragment>
      <Dropzone
        className={classes.root}
        acceptedFiles={['image/png', 'image/svg', 'image/jpg', 'image/jpeg']}
        dropzoneText={props.text}
        maxFiles={1}
        onDrop={handleChangeStatus}
        onAlert={(message, variant) => console.log(`${variant}: ${message}`)}
      />
    </React.Fragment>
  );
}

export default SingleFileAutoSubmit
