import React from 'react';
import { MessageBox } from '../MessageContainer/MessageContainer.styles';
import DOMPurify from 'dompurify';
import { DomPurifyConfig } from 'config';

export function SingleSelect(props) {
  const {
    messages,
    singleOptionProps,
    activeSingleOption,
    handleOptionPost,
    setMessagesToSubmit,
    setMessageSentByUser,
    setActiveOption,
    message,
    messageKey,
  } = props;

  return (
    <>
      {message.options.map((option, idx) => {
        const isPartOfHistory = messages?.some(
          (obj) => obj.content === option.name
        );
        const isChatActive =
          isPartOfHistory || activeSingleOption === option.name;
        return (
          <MessageBox
            key={idx}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(option.name, DomPurifyConfig),
            }}
            data-message-type="reactive"
            data-hover={!activeSingleOption}
            data-active={isChatActive}
            onClick={() => {
              if (!activeSingleOption) {
                setMessageSentByUser(true);
                setActiveOption(option.name);

                const baseOptionInfo = {
                  id: message[messageKey[0]],
                  createdAt: new Date(),
                };
                const optionObj = {
                  ...baseOptionInfo,
                  content: option.value,
                };

                setMessagesToSubmit((prevMessages) => [
                  ...prevMessages,
                  {
                    ...baseOptionInfo,
                    content: option.name,
                    byUser: true,
                  },
                ]);

                handleOptionPost(optionObj);
              }
            }}
            {...singleOptionProps}
          />
        );
      })}
    </>
  );
}
