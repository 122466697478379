import sessionInfo from 'utilities/session/sessionInfo';

function findLastWithFieldCondition(array, field, condition) {
  return array.reduceRight((found, item) => {
    if (found !== undefined) {
      return found;
    }
    if (item.hasOwnProperty(field) && condition(item[field])) {
      return item;
    }
    return undefined;
  }, undefined);
}

export function getFreeTextPayload(
  record,
  content,
  nextConversations,
  messagesToSubmit
) {
  const { endOfSequence } = sessionInfo();
  const commonPayload = {
    createdAt: new Date(),
    content,
  };

  const chats = record?.chats;

  const messagesToSubmitWithEmptyOptions = findLastWithFieldCondition(
    messagesToSubmit,
    'options',
    (val) => val?.length === 0
  );

  const lastChatWithEmptyOption = findLastWithFieldCondition(
    chats,
    'options',
    (val) => val?.length === 0
  );

  const hasEmptyOptions = nextConversations?.some(
    (each) => each?.options?.length === 0
  );

  if (messagesToSubmitWithEmptyOptions && !endOfSequence) {
    return {
      ...commonPayload,
      id: messagesToSubmitWithEmptyOptions?.id,
    };
  }
  if (lastChatWithEmptyOption && !endOfSequence) {
    return {
      ...commonPayload,
      id: lastChatWithEmptyOption?.contentId,
    };
  }
  if (!lastChatWithEmptyOption && hasEmptyOptions) {
    return commonPayload;
  }

  return commonPayload;
}
