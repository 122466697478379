import React, { useState, useContext, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import LatestBulletin from "./LatestBulletin";
import BulletinLoadinSkeleton from './BulletinLoadingSkeleton.js'
import { BulletinPost_Context } from '../../../state/NeighborhooBulletin/BulletinPost/BulletinPost_Provider';
import '../../../styles/components/DashboardCard.css';
import '../../../styles/components/BulletinBoardCard.css';
import DotGreen from '../../../assets/Icons/DotGreen.svg';
import store from '../../../utilities/storage';
import getNeighborhoodAccessRight from "../../../utilities/getNeighborhoodAccessRight";
const invite_link = store.retrieve('invite_link');

export default function BulletinBoardCard({ content, activeTour, setActiveTour }) {
    const Next = () => {
        setActiveTour('forum');
    }

    const [isBulletinEmpty, setBulletinEmpty] = useState(true);
    const [post, setPost] = useState({});

    const { neighborhood_id } = useParams();
    const memberPrivilege = getNeighborhoodAccessRight(neighborhood_id);

    const posts_context = useContext(BulletinPost_Context);

    useEffect(() => {
        (async () => {
            if (posts_context?.state?.bulletin_posts?.length === 0) {
                await posts_context.actions.getBulletinPosts(neighborhood_id, 1, 1);
            }
        })()
    }, [neighborhood_id, posts_context?.state?.bulletin_posts])

    useEffect(() => {
        if (posts_context?.state?.bulletin_posts?.length > 0 &&
            posts_context?.state?.get_bulletin_status === "success"
        ) {
            const posts = posts_context.state.bulletin_posts;
            setPost(posts[0])
            setBulletinEmpty(false);
        }
    }, [posts_context?.state])

    return (
        <div className="Card BulletinBoardCard" id="bulletin">
            <div className="Header">
                <span className="Name">Bulletin Board</span>
                <Link className="ViewAll" to={`/neighborhood/${neighborhood_id}/bulletin`}>See More</Link>
            </div>
            <div className="ToolTip">
                {
                    posts_context?.state.get_bulletin_status === "in-progress" ||
                        posts_context?.state.get_bulletin_status === "idle" ?
                        <BulletinLoadinSkeleton />
                        :
                        isBulletinEmpty ?
                            <div className="Announcement NoBulletinAnouncement">
                                < div className="NoBulletin">
                                    <p className="Title">It’s a little empty in here!</p>
                                    {
                                        memberPrivilege > 1 ?
                                            <>
                                                <p className="Detail">Create  your first bulletin post</p>
                                                <Link to={`/neighborhood/${neighborhood_id}/bulletin?open_modal=true`} className="Detail button">Create bulletin post</Link>
                                            </>
                                            :
                                            <p className="Detail">There are no posts yet. Please check again soon.</p>
                                    }
                                </div>
                            </div>
                            :
                            <LatestBulletin post={post} />
                }

                <div className="Tip" style={activeTour === 'bulletin' ? { "": "" } : { "display": "none" }} >
                    <span className="data">{content}</span>
                    <div className="controls">
                        <a href={invite_link}> <button className="Create">Create Account</button> </a>
                        <span className="Navigate">
                            {/* <span></span> */}
                            <a href="/sign-up">BACK</a>
                            <img src={DotGreen} alt="greendot" />
                            <a href="#forum" onClick={Next}>NEXT</a>
                        </span>
                    </div>
                    <span className="Edge">&nbsp;</span>
                </div>
            </div >
        </div >
    );
}