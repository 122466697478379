import React, { useContext } from "react";

import { Button } from "components/Button/Button";
import { ReactComponent as DeleteIcon } from "assets/Icons/delete_outline.svg";
import { ReactComponent as SaveIcon } from "assets/Icons/save.svg";
import { Buttons } from "./EditNeighborhoodForm.styles";
import { useFormContext } from "react-hook-form";
import * as testId from "utilities/testIds";
import { useMutation, useQueryClient } from "react-query";
import neighborhood from "api/neighborhood";
import store from "utilities/storage";
import { useParams } from "react-router-dom";
import { SnackbarContext } from "contextStore/SnackbarContext";

export function EditNeighborhoodButtons() {
  const { neighborhood_id, invite_id } = useParams();
  const { triggerAlert } = useContext(SnackbarContext);

  const setup_neighborhood_id = store.retrieve("setup_neighborhood_id");
  const possibleNeighbohoodId = neighborhood_id ?? setup_neighborhood_id;
  const {
    formState: { isDirty, isValid, dirtyFields },
    watch,
    reset,
  } = useFormContext();

  const queryClient = useQueryClient();

  const patchNeighborhoodMutation = useMutation({
    mutationFn: neighborhood.patchNeighborhood,
    onSuccess: () => {
      queryClient.invalidateQueries([
        "neighborhood",
        possibleNeighbohoodId,
        invite_id,
      ]);
      reset((v) => v);
      triggerAlert({
        open: true,
        message: "Neighborhood profile was edited successfully",
        type: "success",
      });
    },
    onError: () => {
      triggerAlert({
        open: true,
        message: "An error occurred",
        type: "error",
      });
    },
  });

  const {
    company_name,
    company_website,
    facebook,
    instagram,
    linkedin,
    neighborhood_description,
    neighborhood_name,
    twitter,
    file,
  } = watch() || {};

  const isSaveDisabled =
    !isDirty || !isValid || patchNeighborhoodMutation.isLoading;

  const saveBtnText = patchNeighborhoodMutation.isLoading
    ? " Saving Profile"
    : " Save Profile";
  return (
    <Buttons>
      <Button
        className="buttons"
        size="small"
        kind="primary"
        startIcon={<SaveIcon />}
        disabled={isSaveDisabled}
        data-testid={testId.neighborhoods.editNeighborhoodSaveButton}
        onClick={() => {
          const formData = new FormData();
          [
            { company: company_name },
            { website: company_website },
            { facebook },
            { twitter },
            { instagram },
            { linkedIn: linkedin },
            { description: neighborhood_description },
            { name: neighborhood_name },
          ].forEach((obj) => {
            const [key, field] = Object.entries(obj)[0];
            formData.append(key, field);
          });
          file && dirtyFields?.file && formData.append("file", file.file);
          patchNeighborhoodMutation.mutate({
            neighborhood_id: possibleNeighbohoodId,
            neighborhood_data: formData,
          });
        }}
      >
        {saveBtnText}
      </Button>
      <Button
        className="buttons"
        size="small"
        kind="error"
        startIcon={<DeleteIcon />}
        onClick={() => reset()}
      >
        Discard Changes
      </Button>
    </Buttons>
  );
}
