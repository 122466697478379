import React from "react";
import NavBar from "../components/RialtoNavBar";
import Button from "@material-ui/core/Button";
import { TextField } from "@material-ui/core";
import HiddenURLLink from "../components/HiddenURLLink";
import StepTrackerCard from "../components/StepTrackerCard";
import SnackbarAlert from "../components/SnackbarAlert";
import api from "../api/crib.js";
import ArrowBackward from "../assets/svgs/ArrowBackward.jsx";
import userSession from "../utilities/session/sessionInfo.js";
import "../styles/components/StepContents.css";
import "../styles/pages/OnboardingScreen.css";
import storeSession from "utilities/session/sessionInitialIzer";

const sessionInfo = userSession();

export default class SetupCribProfile_ENT_Step2 extends React.Component {
  constructor(props) {
    super(props);
    const step = "step2";
    this.state = {
      form: this.props.form,
      File: "",
      FileName: "",
      width: window.innerWidth,
      current: this.props.form[`${step}`],
      step: step,
      Snackbar: {
        message: "",
        severity: "success", // info : warning : success : error
      },
    };

    this.handleChange = this.handleChange.bind(this);
    this.updateForm = this.updateForm.bind(this);
    this.getImage = this.getImage.bind(this);
    this.setDataURL = this.setDataURL.bind(this);
    this.toggleImageWrapper = this.toggleImageWrapper.bind(this);
    this.clearImage = this.clearImage.bind(this);
    this.Next = this.Next.bind(this);
    this.Back = this.Back.bind(this);
  }

  componentDidMount() {
    const image = this.state.current.File;
    this.setDataURL(image);
    window.onbeforeunload = () => "promptBeforeUnload";
  }

  async getImage(image) {
    const newState = this.state;
    const newForm = newState.current;
    newForm[`File`] = image;
    await this.setDataURL(image);
    newState.current = newForm;
    this.setState(newState);
  }

  setDataURL(file) {
    if (!file) {
      // console.log('couldnt find file');
    } else {
      const prevState = this.state;
      const stateObj = this;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        prevState[`File`] = reader.result;
        prevState["FileName"] = file.name;
        stateObj.setState(prevState);
        stateObj.toggleImageWrapper();
      };
      reader.onerror = function (error) {
        //   console.log('Error: ', error);
      };
    }
  }

  /**
   * toggle ImageWrapper/Dropzone container visibility
   */
  toggleImageWrapper = () => {
    document.querySelector(".UploadWrapper").classList.toggle("hide");
    document.querySelector(".ImageWrapper").classList.toggle("hide");
  };

  /**
   * Removes uploaded image
   */
  clearImage = () => {
    const prevState = this.state;
    prevState.current.File = "";
    prevState[`File`] = "";
    const snackbar = this.state.Snackbar;
    snackbar.message = "You have removed saved image";
    snackbar.open = true;
    snackbar.severity = "info";
    prevState.Snackbar = snackbar;
    this.setState(prevState);
    this.toggleImageWrapper();
  };

  handleChange = (e) => {
    const newState = this.state;
    const newForm = newState.current;
    const fieldName = e.target.name;
    const value = e.target.value;

    newForm[`${fieldName}`] = value;
    newState.current = newForm;
    this.setState(newState);
  };

  updateForm() {
    const newState = this.state;
    const step = this.state.step;
    newState.form[`${step}`] = this.state.current;
    const snackbar = this.state.Snackbar;
    snackbar.message = "Please wait while we save your crib info";
    snackbar.open = true;
    snackbar.severity = "info";
    newState.Snackbar = snackbar;
    this.setState(newState);
  }

  Next = async () => {
    // const el = document.querySelectorAll('.URLLink');
    this.updateForm();
    const form = this.state.form;
    const data = await api.setupCribProfile(form);

    if (data.status === "success") {
      storeSession(data.data);
      const url =
        sessionInfo.role === "BCO"
          ? `${window.location.origin}/setup/neighborhoodprofile/step1`
          : `${window.location.origin}/crib/${sessionInfo.account_id}`;

      window.onbeforeunload = () => null;
      window.location.href = url;
    } else {
      const newState = this.state;
      const snackbar = this.state.Snackbar;

      if (
        data.response &&
        data.response.status === 400 &&
        data.response.status < 500
      ) {
        snackbar.message = data.response.data.error;
      } else if (data.response && data.response.status === 500) {
        snackbar.message = "Server error, please try again";
      } else {
        snackbar.message = "Please check your connection";
      }

      snackbar.severity = "error";
      snackbar.open = true;
      newState.Snackbar = snackbar;
      this.setState(newState);
    }
  };

  Back = () => {
    const el = document.querySelectorAll(".URLLink");
    el[2].click();
  };

  render() {
    return (
      <div>
        <NavBar />
        <div className="OnboardingContent">
          <div className="ContentWraper">
            <span className="HeaderText">Tell us about yourself</span>
            <span className="Details">Add your summary.</span>
            <StepTrackerCard currentStepIndex={2} stepsFor={"SetupCrib"} />
            {/** Current Step Track 
                     Current Form Board */}
            <div className="FormWrapper">
              <TextField
                className="MultilineField FullWidth"
                variant="outlined"
                multiline
                name="Message"
                value={this.state.current.Message}
                onChange={this.handleChange}
                rows={3}
                label="Message"
              />
              {/* <ArrowForward className="ArrowForward" /> */}
              <Button className="Continue" onClick={() => this.Next()}>
                Continue{" "}
              </Button>
            </div>
            <SnackbarAlert
              message={this.state.Snackbar.message}
              severity={this.state.Snackbar.severity}
              open={this.state.Snackbar.open}
              onClose={() =>
                this.setState({ ...this.state, Snackbar: { open: false } })
              }
            />
            <HiddenURLLink
              url={`crib/${sessionInfo.account_id}`}
              data={this.state.form}
            />
            <HiddenURLLink url={"/setup/crib/step1"} data={this.state.form} />
            <div className="StepControl">
              <Button className="Back" onClick={() => this.Back()}>
                <ArrowBackward className="ArrowBackward" /> Back
              </Button>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
