
    const types = {
        SET_VIDEO_ON: "SET_VIDEO_ON",
        SET_CHAT_ON: "SET_CHAT_ON",
        SET_MIC_ON: "SET_MIC_ON",
        SET_SCREEN_SHARE_ON: "SET_SCREEN_SHARE_ON",
        SET_USERNAME: "SET_USERNAME",
        SET_ROOMNAME: "SET_ROOMNAME",
        SET_USER_CHATIDENTITY: "SET_USER_CHATIDENTITY",
        ADD_PARTICIPANT: "ADD_PARTICIPANT",
        REMOVE_PARTICIPANT: "REMOVE_PARTICIPANT",        
        SET_VIDEO_TOKEN: "SET_VIDEO_TOKEN",
        SET_CHAT_TOKEN: "SET_CHAT_TOKEN",        
        EXIT_SESSION: "EXIT_SESSION",
        JOIN_SESSION: "JOIN_SESSION"
    }

    export default types;
