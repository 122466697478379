import styled from 'styled-components';

export const ChatHistoryWrapper = styled.div`
  & .loadmore_wrap {
    padding-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ChatHistoryErrorText = styled.div`
  font-size: 12px;
  text-align: center;
`;
