import React, { forwardRef } from 'react';
import { usePostSandiChat } from '../utils/usePostSandiChat';
import { getFilteredNextConversations } from '../utils/getFilteredNextConversations';
import { MessageContainer } from './MessageContainer';

export const MessageHistoryContainer = forwardRef((props, ref) => {
  const {
    chats,
    chat,
    setLatestSubmitResponse,
    setMessagesToSubmit,
    messagesToSubmit,
    setMessageSentByUser,
  } = props;

  const postSandiChatMutation = usePostSandiChat((data) => {
    setLatestSubmitResponse(data);
  });

  const filteredNewNextConversations =
    getFilteredNextConversations(messagesToSubmit);

  const handleOptionPost = (selectedOption) => {
    postSandiChatMutation.mutate({
      chats: [...filteredNewNextConversations, selectedOption],
    });
  };

  const messageKey = ['contentId'];

  return (
    <MessageContainer
      ref={ref}
      message={chat}
      messageKey={messageKey}
      handleOptionPost={handleOptionPost}
      setMessageSentByUser={setMessageSentByUser}
      setMessagesToSubmit={setMessagesToSubmit}
      messageType="history"
      messages={chats}
    />
  );
});
