/**
 * Check if variable value if false, null, undefined or an empty string / array
 * @param {*} val
 * @returns {boolean}
 */

const isEmpty = (val) => {
  return (
    val === null ||
    val === undefined ||
    val === false ||
    val === "undefined" ||
    val === "false" ||
    val === "null" ||
    val === "" ||
    (Array.isArray(val) && val.length === 0)
  );
};

export default isEmpty;
