import React, { useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import { useErrorContext } from "../../hooks/useErrorContext.jsx";
import signupFromInvite from "../../api/signupFromInvite.js";
import CreateAccountFromInvitePrompt from "./CreateAccountPrompt/CreateAccountFromInvitePrompt.jsx";
import CongratulationsPrompt from "./CongratulationsPrompt/CongratulationsPrompt.jsx";
import "./CreateAccountPrompt/CreateAccountFromInvitePrompt.css";
import store from "utilities/storage.js";

const CreateAccountFromInvite = () => {
  const triggerAlert = useErrorContext();

  const { invite_id } = useParams();
  const [searchParams] = useSearchParams();
  const invite_token = searchParams.get("token");

  const [info, setInfo] = useState({});
  const [inviteSuccess, setInviteSuccess] = useState(false);

  useQuery(
    "getUserInfo",
    () => signupFromInvite.getInviteInfo(invite_id, invite_token),
    {
      retry: 1,
      refetchOnWindowFocus: false,
      onError: (error) => {
        const msg = "Invalid magic link, signup instead?";
        const errMsg = error?.response?.data?.error;
        const customMsg = errMsg === msg ?
          "Invalid invite link, please signup to create account" :
          "There was an error, please try again later";

        triggerAlert({
          open: true,
          message: customMsg,
          type: "error",
        });
      },
      onSuccess: (response) => {
        if (response.status === "success") {
          setInfo(response?.data);
          store.write('CreateAccountFromInvite', JSON.stringify(response.data));
        } else {
          triggerAlert({
            open: true,
            message: "Something went wrong, please try again later",
            type: "error",
          });
        }
      },
    }
  );

  return (
    <div className="create-account-from-invite-prompt">
      <div className="create-account-from-invite-prompt__wrapper">
        {!inviteSuccess ? (
          <CreateAccountFromInvitePrompt
            info={info}
            invite_id={invite_id}
            invite_token={invite_token}
            setInviteSuccess={setInviteSuccess}
          />
        ) : (
          <CongratulationsPrompt info={info} />
        )}
      </div>
    </div>
  );
};

export default CreateAccountFromInvite;
