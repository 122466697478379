import * as yup from "yup";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useMutation } from "react-query";
import { useErrorContext } from "../../../hooks/useErrorContext.jsx";
import { LinkedInButton } from "../../../components/auth/LinkedInButton/index.jsx";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useGoogleLogin } from "@react-oauth/google";
import signupFromInvite from "../../../api/signupFromInvite.js";
import BtnLoader from "../../../components/Loader/btn-loader";
import Eye from "assets/svgs/eye.jsx";
import EyeSlash from "assets/svgs/eyeSlash.jsx";
import GoogleAuthButton from "../../../components/auth/GoogleButton/GoogleAuthButton.jsx";
import storeSession from "../../../utilities/session/sessionInitialIzer.js";
import { ReactComponent as CheckMarkCircle } from "../../../assets/svgs/CheckMarkCircle.svg";
import "./CreateAccountFromInvitePrompt.css";

const inputFields = [
  {
    name: "firstname",
    type: "text",
    placeholder: "First name",
  },
  {
    name: "lastname",
    type: "text",
    placeholder: "Last name",
  },
  {
    name: "email",
    type: "email",
    placeholder: "Email address",
  },
  {
    name: "password",
    type: "password",
    placeholder: "Password",
  },
];

const Line = () => {
  return (
    <svg
      width="110"
      height="1"
      viewBox="0 0 110 1"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line y1="0.75" x2="110" y2="0.75" stroke="#444444" strokeWidth="0.5" />
    </svg>
  );
};

const CreateAccountFromInvitePrompt = ({
  info,
  invite_token,
  invite_id,
  setInviteSuccess,
}) => {
  const triggerAlert = useErrorContext();
  const [passwordVisibility, setPasswordVisibility] = useState(false);

  const schema = yup.object({
    firstname: yup.string().min(2).required("First name is required"),
    lastname: yup.string().min(2).required("Last name is required"),
    email: yup
      .string()
      .email("Must be a valid email address")
      .required("Email is required"),
    password: yup
      .string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
  });

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
  } = useForm({
    values: {
      firstname: info?.invitee?.first_name,
      lastname: info?.invitee?.last_name,
      email: info?.invitee?.email,
    },
    resolver: yupResolver(schema),
  });

  const signUpMutation = useMutation(
    (payload) => signupFromInvite.signupFromInvite(payload),
    {
      onError: (error) => {
        triggerAlert({
          open: true,
          message:
            error?.response?.data?.error ||
            "There was an error, please try again later",
          type: "error",
        });
      },
      onSuccess: (response) => {
        if (response?.status === "success") {
          storeSession(response?.data?.session);
          setInviteSuccess(true);
        } else {
          triggerAlert({
            open: true,
            message: "Something went wrong, please try again later",
            type: "error",
          });
        }
      },
    }
  );

  const sendInviteSignupRequest = (data) => {
    const payload = {
      invite_id,
      invite_token,
      data,
    };
    signUpMutation.mutate(payload);
  };

  const handleGoogleSignIn = useGoogleLogin({
    onSuccess: (codeResponse) => {
      sendInviteSignupRequest({ invite_id, token: codeResponse.access_token });
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  const handleLinkedInSigninSuccess = (response) => {
    sendInviteSignupRequest({ invite_id, linkedin_token: response.code });
  };

  const handleLinkedInSigninFailure = () => {
    triggerAlert({
      open: true,
      message: "Something went wrong, please try again later",
      type: "error",
    });
  };

  return (
    <div className="create-account-from-invite-prompt__container">
      <div className="create-account-from-invite-prompt__info">
        <div className="create-account-from-invite-prompt__header">
          <h2 className="create-account-from-invite-prompt__heading">
            Welcome to Rialto!
          </h2>
          <p className="create-account-from-invite-prompt__sub">
            You have been invited to join{" "}
            <span>{info?.sender?.neighborhood_name}</span> by{" "}
            <span>
              {info?.sender?.first_name} {info?.sender?.last_name}
            </span>
            .
          </p>
        </div>
        <div className="create-account-from-invite-prompt__prompt">
          <p className="create-account-from-invite-prompt__text">
            Create your Rialto account to gain access to this community space.
          </p>
          <div className="create-account-from-invite-prompt__prompt--container">
            <div className="create-account-from-invite-prompt__prompt--box">
              <CheckMarkCircle className="create-account-from-invite-prompt__prompt--icon" />
              <p>Host or attend Office Hour Sessions</p>
            </div>
            <div className="create-account-from-invite-prompt__prompt--box">
              <CheckMarkCircle className="create-account-from-invite-prompt__prompt--icon" />
              <p>Join educative and empowering forums</p>
            </div>
            <div className="create-account-from-invite-prompt__prompt--box">
              <CheckMarkCircle className="create-account-from-invite-prompt__prompt--icon" />
              <p>Host or attend Office Hour Sessions</p>
            </div>
            <div className="create-account-from-invite-prompt__prompt--box">
              <CheckMarkCircle className="create-account-from-invite-prompt__prompt--icon" />
              <p>And much more</p>
            </div>
          </div>
        </div>
      </div>
      <form
        className="create-account-from-invite-prompt__form"
        onSubmit={handleSubmit((data) => sendInviteSignupRequest(data))}
      >
        {inputFields?.map(({ name, type, placeholder }) => {
          if (name === "password") {
            return (
              <Controller
                key={name}
                name={name}
                control={control}
                render={({ field }) => (
                  <div className="create-account-from-invite-prompt__form--control">
                    <input
                      type={passwordVisibility ? "text" : "password"}
                      name={name}
                      placeholder={placeholder}
                      value={field.value}
                      onChange={field.onChange}
                      readOnly={invite_token && name === "email"}
                      className="create-account-from-invite-prompt__input"
                    />
                    <button
                      className="create-account-from-invite-prompt__form--btn"
                      onClick={() => setPasswordVisibility(!passwordVisibility)}
                    >
                      {passwordVisibility ? <EyeSlash /> : <Eye />}
                    </button>
                    <p>{errors.name?.message}</p>
                  </div>
                )}
              />
            );
          }
          return (
            <Controller
              key={name}
              name={name}
              control={control}
              render={({ field }) => (
                <div className="create-account-from-invite-prompt__form--control">
                  <input
                    type={type}
                    name={name}
                    placeholder={placeholder}
                    value={field.value}
                    onChange={field.onChange}
                    readOnly={invite_token && name === "email"}
                    className="create-account-from-invite-prompt__input"
                  />
                  <p>{errors.name?.message}</p>
                </div>
              )}
            />
          );
        })}
        <div className="create-account-from-invite-prompt__btn--container">
          <button
            className="create-account-from-invite-prompt__btn"
            disabled={signUpMutation?.isLoading || !isValid}
          >
            {signUpMutation?.isLoading ? (
              <BtnLoader
                border_bottom={"4px solid #FFFFFF"}
                border_right={"4px solid #FFFFFF"}
                border_left={"4px solid #838383"}
                border_top={"4px solid #838383"}
              />
            ) : (
              "Create account"
            )}
          </button>
          <span className="create-account-from-invite-or-breaker">
            <Line />
            Or
            <Line />
          </span>
          <GoogleAuthButton
            btnText="Sign up with Google"
            handleGoogleSignIn={handleGoogleSignIn}
            isLoading={signUpMutation.isLoading}
            className="GoogleBtn"
          />
          <LinkedInButton
            className="LinkednButton"
            handleLinkedInSigninFailure={handleLinkedInSigninFailure}
            handleLinkedInSigninSuccess={handleLinkedInSigninSuccess}
            text={"Sign up with Linkedin"}
          />
        </div>
        <p className="create-account-from-invite-prompt__terms">
          By creating an account, you agree to the Rialto
          <Link to="/terms">&nbsp; Terms &nbsp;</Link>&
          <Link to="/privacy">&nbsp; Privacy Policy &nbsp;</Link>
        </p>
      </form>
    </div>
  );
};

export default CreateAccountFromInvitePrompt;
