import React from "react";
import Logo from "../../assets/Icons/RialtoBetaLogo.png";
import "../../styles/components/NeighborhoodLogoBar.css"
import useWindowSize from "../../utilities/useWindowSize";

export default function LandingPage() {
  const screenSize = useWindowSize();
  return <>
    {
      screenSize.width < 1024 ?
        <div className="MobileNavbar">
          <a href="/explore"><img src={Logo} alt="Logo" /></a>
        </div>
        :
        <></>
    }

  </>
}
