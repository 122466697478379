import React from 'react'
import testimonialImageOne from '../../../assets/Images/testimonial-img.png';
import testimonialImageTwo from '../../../assets/Images/Mary Kate Moore.png';

export const slidesData = [
    {
        id: 1,
        image: testimonialImageOne,
        testimony: "This is absolutely fantastic. The (entrepreneurship) journey, it can be very lonely. And, as you're trying to navigate, a lot of mistakes are made along the way because no one knows everything and sometimes, not being able to find the expertise that can really help you grow. There's so many people out there just trying to get to your money, or the little funds that you're using to bootstrap your business.",
        authorName: "Mumbi Dunjwa",
        authorTitle: "Co-Founder & CEO",
        authorCompany: "Naturaz Hair"
    },
    {
        id: 2,
        image: testimonialImageTwo,
        testimony: "The resource feature on Rialto is huge because we would love for our founders to go back and look up resources provided for them in one location instead of us having to remember what the resource is and search for it.",
        authorName: "Mary Kate Moore",
        authorTitle: "Community Organizer & Social Justice Advocate",
        authorCompany: "RMMFI"
    }
]