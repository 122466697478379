import React from "react";

const Download = ({ className }) => {
  return (
    <svg
      className={className}
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0836 10.1265V13.4865C15.0836 14.4144 14.383 15.1666 13.5187 15.1666H2.56484C1.7006 15.1666 1 14.4144 1 13.4865V10.1265"
        stroke="#644167"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.2671 5.08652L8.32373 1.1665L4.38037 5.08652"
        stroke="#644167"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.04192 1.1665V10.1266"
        stroke="#644167"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Download;
