import CribSetup from "pages/CribManagement/CribSetup";
import CribProfile from "pages/CribManagement/CribProfile/CribProfile";
import SignIn from "pages/Signin";
import CreateAccount from "pages/CreateAccount";
import ConfirmSignup from "pages/ConfirmSignup";
import ForgotPassword from "pages/ForgotPassword";
import ForgotPasswordConfirmation from "pages/ForgotPasswordConfirmation";
import ResetPassword from "pages/ResetPassword";
import PasswordResetSuccess from "pages/PasswordResetSuccess";

export const baseRoutes = [
  {
    path: `/:customRoute/sign-in`,
    Component: SignIn,
  },
  {
    path: `/:customRoute/sign-up`,
    Component: CreateAccount,
  },
  {
    path: `/:customRoute/sign-up/confirm`,
    Component: ConfirmSignup,
  },
  {
    path: `/:customRoute/forgot-password`,
    Component: ForgotPassword,
  },
  {
    path: `/:customRoute/forgot-password-confirmation`,
    Component: ForgotPasswordConfirmation,
  },
  {
    path: `/:customRoute/reset-password/:token`,
    Component: ResetPassword,
  },
  {
    path: `/:customRoute/reset-password-success`,
    Component: PasswordResetSuccess,
  },
];

export const cribNavigationCustomRoutes = [
  {
    path: `/:customRoute/setup/crib`,
    Component: CribSetup,
  },
];

export const cribCustomRoutes = [
  {
    path: `/:customRoute/crib/:crib_id`,
    Component: CribProfile,
  },
];
