import React from "react";

const Close = ({ className }) => {
  return (
    <svg
      className={className}
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6667 5.0777L11.7267 4.1377L8.00004 7.86436L4.27337 4.1377L3.33337 5.0777L7.06004 8.80436L3.33337 12.531L4.27337 13.471L8.00004 9.74436L11.7267 13.471L12.6667 12.531L8.94004 8.80436L12.6667 5.0777Z"
        fill="#BDBDBD"
      />
    </svg>
  );
};

export default Close;
