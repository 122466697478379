import sessionInfo from 'utilities/session/sessionInfo';

export function getBotAccess(session = sessionInfo) {
  const { crib_created, neighborhood_created, role, token } = session();

  const isCribCreated = crib_created && JSON.parse(crib_created);
  const isNeighborHoodCreated =
    neighborhood_created && JSON.parse(neighborhood_created);
  const isUserTypeBCO = role && role === 'BCO';

  const permittedBCO = isNeighborHoodCreated && isUserTypeBCO;

  const isRoleToAccessBot = !isUserTypeBCO || permittedBCO;

  if (isCribCreated && token) {
    return isRoleToAccessBot && isRoleToAccessBot;
  }
  return !token && !isCribCreated;
}
