import React, { forwardRef } from 'react';
import { ChatHistoryErrorText } from './ChatHistory.styles';

export const ChatHistoryLoading = forwardRef((props, ref) => {
  const { query } = props;

  const { isFetchingNextPage, isError } = query;

  const getLoadMoreText = () => {
    if (isError)
      return (
        <ChatHistoryErrorText>Error loading chat history</ChatHistoryErrorText>
      );
    if (isFetchingNextPage)
      return <ChatHistoryErrorText>Loading...</ChatHistoryErrorText>;
    return null;
  };

  return (
    <div className="loadmore_wrap" ref={ref}>
      {getLoadMoreText()}
    </div>
  );
});
