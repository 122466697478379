import styled, { css, keyframes } from 'styled-components';
import { COLORS } from 'styles/colors/minwo';

export const MessageParentBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  :not(:first-child) {
    margin-top: 5px;
  }

  &[data-by-user='true'] {
    align-items: flex-end;
  }
`;

const MessageBoxKeyframe = keyframes`
    0% {
        transform: scaleY(0.8);
        transform-origin: 100% 0%;
        opacity: 0.6;
    }
    100% {
        transform: scaleY(1);
        transform-origin: 100% 0%;
        opacity: 1;
    }
`;

export const ReactiveMessageBox = css`
  border-radius: 15.344px;
  background: rgba(70, 28, 63, 0);
  cursor: pointer;
  padding: 8px 24px;
  border: 0.6px solid ${COLORS.purple.p500};
`;

export const InteractiveStyling = css`
  background-color: ${COLORS.purple.p500};
  color: ${COLORS.neutral.n0};
`;

export const MessageBox = styled.div`
  color: ${COLORS.gray.gray02};
  font-size: 0.9rem;
  border-radius: 10px;
  border: 0.31px solid ${COLORS.purple.p500};
  background: ${COLORS.neutral.n1};
  padding: 12px;
  width: fit-content;
  max-width: 80%;
  animation: ${MessageBoxKeyframe} 0.1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  white-space: pre-line;

  &[data-message-type='reactive'] {
    ${ReactiveMessageBox}
    width: 100%;
    cursor: unset;

    &[data-hover='true']:hover {
      background-color: ${COLORS.purple.p400};
      color: ${COLORS.neutral.n0};
      cursor: pointer;
    }
  }

  &[data-by-user='true'] {
    align-self: flex-end;
    background-color: ${COLORS.gold.go500};
    border: none;
    margin: 11px 0;
  }

  &[data-active='true'] {
    ${InteractiveStyling}
  }

  a {
    display: inline-flex;
    color: ${COLORS.blue.b1};
    text-decoration: underline;
  }

  ul {
    margin-top: 10px;

    li {
      list-style: disc;
      margin: 0;
      padding: 0;
      margin-left: 30px;
    }
  }
`;
